import React from "react";
import { Users, Clock, Share2 } from "lucide-react";

const AnsweredReviewsSection = ({
  answeredReviews,
  showUserDropdown,
  setShowUserDropdown,
  selectedUser,
  handleUserSelect,
  handleRecordClick,
  handleReviewSelect,
  users,
}) => {
  return (
    <div>
      {answeredReviews.length === 0 ? (
        <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
            <Users className="h-3 w-3 text-gray-700" />
          </div>
          <p>No completed reviews yet</p>
        </div>
      ) : (
        <div className="space-y-2 max-h-60 overflow-y-auto pr-1 custom-scrollbar">
          {answeredReviews.map((review, index) => (
            <div
              key={review.id}
              className="p-3 rounded bg-gray-900 border-l border-gray-800 transition-all duration-300 cursor-pointer hover:border-l-2 hover:border-amber-600 animate-fadeIn"
              onClick={() => handleReviewSelect(review, "answeredReviews")}
              style={{ animationDelay: `${index * 0.05}s` }}
            >
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-start">
                  <h3 className="text-xs text-gray-300 font-light">
                    {review.reviews_text}
                  </h3>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowUserDropdown(review.id);
                    }}
                    className="ml-2 px-2 py-1 bg-gray-800 text-xs text-gray-400 rounded hover:bg-gray-700 transition-colors duration-300 flex items-center gap-1"
                  >
                    <Share2 className="w-3 h-3 text-amber-500" />
                    <span className="hidden sm:inline">Share</span>
                  </button>
                </div>
                <div className="flex items-center gap-1 opacity-70 transition-opacity duration-300">
                  <span className="inline-block w-1 h-1 bg-slate-500 rounded-full"></span>
                  <p className="text-xs text-gray-600 flex items-center gap-1">
                    <Clock className="w-3 h-3 text-gray-600" />
                    {new Date(review.expiration_date).toLocaleDateString()}
                  </p>
                </div>
              </div>

              {showUserDropdown === review.id && (
                <div
                  className="mt-3 pt-2 border-t border-gray-800"
                  onClick={(e) => e.stopPropagation()}
                >
                  <select
                    onChange={(e) => handleUserSelect(e.target.value)}
                    value={selectedUser}
                    className="w-full px-2 py-1 bg-gray-950 text-gray-300 text-xs rounded border border-gray-800 focus:border-amber-600 transition-colors duration-300"
                  >
                    <option value="">Select user to share with</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={() => handleRecordClick(review.id)}
                    className="w-full mt-2 px-2 py-1 bg-gray-800 hover:bg-gray-700 text-xs text-gray-300 rounded border-l border-amber-600 hover:border-l-2 transition-all duration-300 flex items-center justify-center"
                  >
                    <Share2 className="w-3 h-3 text-amber-500 mr-1" />
                    Submit
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnsweredReviewsSection;
