import React from "react";
import { MessageCircle } from "lucide-react";

const UserResponseCard = ({
  response,
  activeComment,
  setActiveComment,
  comments,
  setComments,
  handleCommentSubmit,
  handleResponseClick,
}) => {
  const isRead = response.read_responses;
  const cardId = `${response.user_id}-${response.date_answered}`;

  const hasValidUserId = (comment) => {
    return (
      typeof comment === "object" &&
      comment !== null &&
      comment.commenter_id &&
      comment.commenter_username
    );
  };

  const getCommentContent = (comment) => {
    if (typeof comment === "string") {
      return comment;
    }
    if (typeof comment === "object" && comment !== null) {
      return typeof comment.text === "string" ? comment.text : "";
    }
    return "";
  };

  return (
    <div
      onClick={() =>
        !isRead && handleResponseClick(response.user_id, response.date_answered)
      }
      className={`p-4 rounded-lg transition-all duration-300 cursor-pointer
        ${
          isRead
            ? "bg-gradient-to-b from-gray-900 to bg-slate-900 border border-slate-900 shadow-2xl hover:bg-slate-900"
            : "bg-[#0A0A0B] border border-slate-900 hover:border-slate-500/40 shadow-2xl"
        }`}
    >
      <div className="space-y-4">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="text-blue-200 text-md tracking-wide mb-1">
              {response.username}
            </h4>
            <p className="text-xs text-slate-400">
              {new Date(response.date_answered).toLocaleDateString()}
            </p>
          </div>
          {!isRead && (
            <span className="px-2 py-1 text-xs bg-slate-700 text-sky-400 rounded-lg border border-slate-800">
              New
            </span>
          )}
        </div>

        {/* Questions and Answers */}
        <div className="space-y-3">
          {[1, 2, 3, 4].map((num) => {
            const questionKey = `question_${num}`;
            const answerKey = `answer_${num}`;
            if (response[questionKey] && response[answerKey]) {
              return (
                <div key={num} className="space-y-4">
                  <p className="text-xs text-slate-300 tracking-wide">
                    {response[questionKey]}
                  </p>
                  <p className="text-xs text-slate-300 p-2 bg-black rounded-lg border border-slate-900">
                    {response[answerKey]}
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>

        {/* Comments Section */}
        <div className="pt-3 border-t border-slate-600">
          {response.comment && (
            <div className="mb-2 space-y-1">
              {(Array.isArray(response.comment)
                ? response.comment
                : [response.comment]
              ).map((comment, idx) => {
                const commentContent = getCommentContent(comment);
                if (!commentContent) return null;

                return (
                  <div
                    key={idx}
                    className="text-xs text-slate-300 p-0.25 bg-slate-900 rounded-lg "
                  >
                    {hasValidUserId(comment) ? (
                      <span className="text-blue-300">
                        {comment.commenter_username}:{" "}
                      </span>
                    ) : (
                      commentContent && (
                        <span className="text-slate-300">comment: </span>
                      )
                    )}
                    {commentContent}
                  </div>
                );
              })}
            </div>
          )}

          {activeComment === cardId ? (
            <div className="space-y-3" onClick={(e) => e.stopPropagation()}>
              <textarea
                value={comments[cardId] || ""}
                onChange={(e) =>
                  setComments({ ...comments, [cardId]: e.target.value })
                }
                className="w-full p-3 text-sm bg-[#0A0A0B] text-[#9A9A9D] rounded-lg border border-sky-600/60 focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20 transition-colors duration-200 resize-none"
                placeholder="Add a comment..."
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveComment(null);
                  }}
                  className="px-3 py-1.5 text-xs text-[#9A9A9D] bg-[#1F1F23] rounded-lg hover:bg-[#2F2F33] transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCommentSubmit(
                      response.user_id,
                      response.date_answered,
                    );
                  }}
                  className="px-3 py-1.5 text-xs bg-slate-800 text-slate-400 rounded-lg border border-cyan-500/20 hover:bg-cyan-500/20 transition-colors duration-200"
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveComment(cardId);
              }}
              className="flex items-center gap-2 text-sm text-yellow-500 mt-6 mb-2 hover:text-yellow-500 transition-colors duration-200"
            >
              <MessageCircle
                className="w-4 h-4 text-yellow-500"
                strokeWidth={1.5}
              />
              Add Comment
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserResponseCard;
