import React, { useState, useEffect } from "react";
import axios from "axios";
import { Users, Trash2, Shield, ShieldOff, Link } from "lucide-react";
import CreateAdmin from "./CreateAdmin";

const UserList = ({ businessName, currentUser }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inviteLink, setInviteLink] = useState("");
  const [inviteLinkLoading, setInviteLinkLoading] = useState(false);
  const [generateLinkError, setGenerateLinkError] = useState(null);
  const [inviteEmails, setInviteEmails] = useState("");
  const [sendingInvites, setSendingInvites] = useState(false);
  const [sendInvitesError, setSendInvitesError] = useState(null);
  const [inviteSuccess, setInviteSuccess] = useState("");
  const [failedInvites, setFailedInvites] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, [businessName]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (username) => {
    if (window.confirm(`Are you sure you want to delete user ${username}?`)) {
      try {
        const token = localStorage.getItem("access_token");
        await axios.delete(
          `/api/v1/businesses/${businessName}/users/${username}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        alert(`User ${username} has been deleted successfully.`);
        fetchUsers();
      } catch (err) {
        console.error("Error deleting user:", err);
        alert("Failed to delete user. Please try again.");
      }
    }
  };

  const toggleUserAdmin = async (username, currentStatus) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${username}/toggle-admin`,
        { is_admin: !currentStatus },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      fetchUsers();
    } catch (err) {
      console.error("Error toggling user admin status:", err);
      alert("Failed to update user admin status. Please try again.");
    }
  };

  const toggleViewAnswers = async (username, currentStatus) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${username}/toggle-view-answers`,
        { can_view_answers: !currentStatus },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      fetchUsers();
    } catch (err) {
      console.error("Error toggling view answers permission:", err);
      alert("Failed to update view answers permission. Please try again.");
    }
  };

  const generateInviteLink = async () => {
    setInviteLinkLoading(true);
    setGenerateLinkError(null);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/generate-invite`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setInviteLink(response.data.invite_link);
    } catch (err) {
      console.error("Error generating invite link:", err);
      setGenerateLinkError("Failed to generate invite link. Please try again.");
    } finally {
      setInviteLinkLoading(false);
    }
  };

  const sendInvites = async () => {
    setSendingInvites(true);
    setInviteSuccess("");
    setSendInvitesError(null);
    setFailedInvites([]);
    try {
      const token = localStorage.getItem("access_token");
      const emails = inviteEmails
        .split(/[,\s]+/)
        .filter((email) => email.trim() !== "")
        .map((email) => email.toLowerCase());
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/send-invites`,
        { emails: emails },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Send invites response:", response.data);
      setInviteSuccess(response.data.message);
      if (
        response.data.failed_invites &&
        response.data.failed_invites.length > 0
      ) {
        setFailedInvites(response.data.failed_invites);
      }
      setInviteEmails("");
    } catch (err) {
      console.error("Error sending invites:", err);
      if (err.response?.data?.detail) {
        if (
          typeof err.response.data.detail === "object" &&
          err.response.data.detail.errors
        ) {
          setSendInvitesError(err.response.data.detail.errors.join(", "));
        } else if (
          typeof err.response.data.detail === "object" &&
          err.response.data.detail.message
        ) {
          setSendInvitesError(err.response.data.detail.message);
        } else if (typeof err.response.data.detail === "string") {
          setSendInvitesError(err.response.data.detail);
        } else {
          setSendInvitesError(JSON.stringify(err.response.data.detail));
        }
      } else {
        setSendInvitesError("Failed to send invitations. Please try again.");
      }
    } finally {
      setSendingInvites(false);
    }
  };

  if (loading)
    return (
      <div className="min-h-screen bg-neutral-50 flex items-center justify-center">
        <div className="w-12 h-12 border-t-2 border-l-2 border-zinc-400 animate-spin rounded-full"></div>
      </div>
    );
  if (error)
    return (
      <div className="text-center p-6 bg-rose-50 border-l border-rose-500 text-rose-600">
        {error}
      </div>
    );

  return (
    <div className="bg-gray-200 text-zinc-800 border border-neutral-200 mt-20 rounded">
      <div className="max-w-6xl mx-auto px-6 py-8">
        <CreateAdmin businessName={businessName} />

        {/* Generate Invite Link Section */}
        <div className="mt-4 bg-gray-50 p-6 border-l border-zinc-200 shadow-2xl rounded-lg mb-4">
          <h4 className="text-md mb-4 flex text-zinc-700 items-center font-grotesk">
            <Link className="mr-2 text-slate-400" />
            Generate Invite Link
          </h4>
          <button
            onClick={generateInviteLink}
            disabled={inviteLinkLoading}
            className="w-full py-3 px-6 text-sm bg-gray-600 hover:bg-zinc-300 text-amber-300 rounded transition-all duration-200 shadow-md"
          >
            {inviteLinkLoading ? "Generating..." : "Generate Invite Link"}
          </button>
          {generateLinkError && (
            <p className="text-sm text-rose-600 mt-2">
              Error: {generateLinkError}
            </p>
          )}
          {inviteLink && (
            <div className="mt-4">
              <p className="text-sm font-bold mb-2 text-zinc-700">
                Invite Link:
              </p>
              <input
                type="text"
                value={inviteLink}
                readOnly
                className="w-full p-3 bg-neutral-50 border border-zinc-200 focus:border-zinc-400 transition-colors duration-200 text-zinc-700 rounded"
              />
              <p className="text-xs text-zinc-500 mt-2">
                Copy this link and send it to the user you want to invite.
              </p>
            </div>
          )}
        </div>

        {/* Send Invites Section */}
        <div className="mt-4 bg-gray-50 p-6 border-l border-zinc-200 shadow-md rounded-lg mb-8">
          <h4 className="text-md mb-2 font-grotesk flex text-zinc-700 items-center">
            <Link className="mr-2 text-slate-400" />
            Send Invites
          </h4>
          <textarea
            value={inviteEmails}
            onChange={(e) => setInviteEmails(e.target.value)}
            placeholder="Enter email addresses (separated by commas or spaces)"
            className="w-full p-3 bg-neutral-50 border border-zinc-200 focus:border-zinc-400 transition-colors duration-200 text-zinc-700 rounded mb-2 text-sm"
            rows="1"
          />
          <button
            onClick={sendInvites}
            disabled={sendingInvites || !inviteEmails.trim()}
            className="w-full py-3 px-6 text-sm bg-gray-600 hover:bg-zinc-300 text-amber-300 rounded transition-all duration-200 shadow-2xl"
          >
            {sendingInvites ? "Sending..." : "Send Invites"}
          </button>
          {sendInvitesError && (
            <p className="text-sm text-rose-600 mt-2">
              Error: {sendInvitesError}
            </p>
          )}
          {inviteSuccess && (
            <p className="text-sm text-green-600 mt-2">{inviteSuccess}</p>
          )}
          {failedInvites.length > 0 && (
            <div className="mt-2">
              <p className="text-sm text-yellow-600">
                Failed to send invites to:
              </p>
              <ul className="list-disc list-inside text-sm text-yellow-600">
                {failedInvites.map((email, index) => (
                  <li key={index}>{email}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* User List Section */}
        <h4 className="text-md mb-4 flex font-grotesk text-zinc-700 items-center ml-2 mt-16 font-semibold">
          <Users className="mr-2 w-4 h-4 text-slate-500" />
          User List
        </h4>
        <ul className="space-y-2">
          {users.map((u) => (
            <li
              key={u.id}
              className="flex items-center justify-between py-3 px-4 bg-neutral-50 border-l border-zinc-200 shadow-2xl rounded"
            >
              <span className="text-sm text-zinc-700 font-grotesk">
                {u.username} - {u.email}{" "}
                <span className="text-xs px-2 py-1 bg-zinc-200 rounded-full ml-2">
                  {u.is_admin ? "Admin" : "User"}
                </span>
                {u.can_view_answers && (
                  <span className="text-xs px-2 py-1 bg-green-200 rounded-full ml-2">
                    Can View Answers
                  </span>
                )}
              </span>
              <div className="flex items-center gap-2">
                {currentUser.is_admin &&
                  u.username !== currentUser.username && (
                    <>
                      <button
                        onClick={() => toggleUserAdmin(u.username, u.is_admin)}
                        className="p-2 text-slate-500 hover:text-yellow-400 transition-colors duration-200"
                        title={u.is_admin ? "Remove admin" : "Make admin"}
                      >
                        {u.is_admin ? (
                          <ShieldOff className="h-5 w-5" />
                        ) : (
                          <Shield className="h-5 w-5" />
                        )}
                      </button>
                      <button
                        onClick={() =>
                          toggleViewAnswers(u.username, u.can_view_answers)
                        }
                        className="p-2 text-blue-500 hover:text-blue-400 transition-colors duration-200"
                        title={
                          u.can_view_answers
                            ? "Remove view answers permission"
                            : "Grant view answers permission"
                        }
                      >
                        {/* Optionally add Eye/EyeOff icons here */}
                      </button>
                      <button
                        onClick={() => handleDeleteUser(u.username)}
                        className="p-2 text-red-500 hover:text-red-400 transition-colors duration-200"
                        title="Delete user"
                      >
                        <Trash2 className="h-5 w-5 text-amber-500" />
                      </button>
                    </>
                  )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserList;
