import React from "react";
import { X, AlertCircle, RefreshCw } from "lucide-react";

const Modal = ({
  isVisible,
  onClose,
  title,
  icon,
  isProcessing,
  statusMessage,
  isSuccess,
  children,
  submitButtonText,
  processingButtonText,
  submitButtonIcon,
  onSubmit,
}) => {
  // Early return pattern - no rendering when not visible
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black/80 backdrop-blur-sm"
        onClick={() => !isProcessing && onClose()}
      />

      {/* Modal Content */}
      <div className="relative bg-neutral-800 border border-neutral-700 rounded-xl w-11/12 max-w-lg overflow-hidden">
        {/* Header */}
        <div className="border-b border-neutral-700 p-4 flex justify-between items-center">
          <h3 className="text-lg font-medium text-white flex items-center gap-2">
            {icon && icon}
            {title}
          </h3>
          <button
            onClick={() => !isProcessing && onClose()}
            className="text-neutral-400 hover:text-white transition-colors"
            disabled={isProcessing}
            type="button"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Form */}
        <form onSubmit={onSubmit} className="p-6 space-y-6">
          {children}

          {/* Error/Success Message */}
          {statusMessage && (
            <div
              className={`p-3 rounded-lg text-sm ${
                isSuccess
                  ? "bg-green-900/30 text-green-400 border border-green-900"
                  : "bg-red-900/30 text-red-400 border border-red-900"
              }`}
            >
              <div className="flex items-start gap-2">
                {isSuccess ? (
                  <svg
                    className="h-5 w-5 text-green-500 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                )}
                <span>{statusMessage}</span>
              </div>
            </div>
          )}

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isProcessing}
              className="flex items-center gap-2 py-2 px-6 bg-amber-700 hover:bg-amber-600 disabled:bg-neutral-700 disabled:text-neutral-500 rounded-lg font-medium transition-colors"
            >
              {isProcessing ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin" />
                  {processingButtonText}
                </>
              ) : (
                <>
                  {submitButtonIcon}
                  {submitButtonText}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
