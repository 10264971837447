import React from "react";
import {
  Award,
  Activity,
  MessageSquare,
  MessageCircle,
  Crown,
  Medal,
} from "lucide-react";

const LeaderboardRow = ({ entry, index, getRankColor }) => {
  // Function to render rank icon based on position
  const renderRankIcon = (rank) => {
    switch (rank) {
      case 1:
        return <Crown size={20} className="text-amber-500" />;
      case 2:
        return <Medal size={18} className="text-gray-400" />;
      case 3:
        return <Medal size={18} className="text-amber-700" />;
      default:
        return <span className="w-6 text-center">{rank}</span>;
    }
  };

  return (
    <tr
      className={`hover:bg-gray-700 transition-colors ${
        index < 3 ? "bg-gray-750 border-l-4 border-amber-500/50" : ""
      }`}
    >
      <td className="px-4 py-4 whitespace-nowrap">
        <div className="flex items-center justify-center">
          {renderRankIcon(entry.rank)}
        </div>
      </td>
      <td className="px-4 py-4 whitespace-nowrap">
        <div
          className={`font-grotesk font-medium ${
            entry.rank <= 3
              ? `text-gray-100 ${
                  entry.rank === 1
                    ? "text-lg"
                    : entry.rank <= 3
                      ? "text-base"
                      : "text-sm"
                }`
              : "text-gray-400 text-sm"
          }`}
        >
          {entry.username}
          {entry.rank === 1 && (
            <span className="ml-2 text-xs bg-amber-500 text-gray-900 px-2 py-0.5 rounded-full">
              Champion
            </span>
          )}
        </div>
      </td>
      <td className="px-4 py-4 whitespace-nowrap">
        <div className="flex items-center text-gray-300">
          <Activity size={14} className="mr-1 text-amber-500" />
          <span>{entry.vibe_check_count}</span>
        </div>
      </td>
      <td className="px-4 py-4 whitespace-nowrap">
        <div className="flex items-center text-gray-300">
          <MessageSquare size={14} className="mr-1 text-amber-500" />
          <span>{entry.comment_count}</span>
        </div>
      </td>
      <td className="px-4 py-4 whitespace-nowrap">
        <div className="flex items-center text-gray-300">
          <MessageCircle size={14} className="mr-1 text-amber-500" />
          <span>{entry.reply_count}</span>
        </div>
      </td>
      {/* new column for depth*/}
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="text-sm text-gray-300 font-mono">
          {entry.vibe_check_depth}
          {entry.vibe_check_depth > 0 && entry.vibe_check_count > 0 && (
            <span className="ml-1 text-xs text-gray-500">
              (
              {Math.round(
                (entry.vibe_check_depth / entry.vibe_check_count) * 100,
              )}
              %)
            </span>
          )}
        </div>
      </td>
      <td className="px-4 py-4 whitespace-nowrap">
        <div
          className={`text-sm font-semibold ${
            index < 3
              ? "bg-gradient-to-r from-amber-500 to-amber-400 text-gray-900"
              : "bg-gray-700 text-gray-300"
          } rounded-full px-3 py-1 inline-flex justify-center min-w-8`}
        >
          {entry.total_activity}
        </div>
      </td>
    </tr>
  );
};

export default LeaderboardRow;
