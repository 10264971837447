import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PushReview = ({ businessName }) => {
  const [reviews, setReviews] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [expirationDate, setExpirationDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
  );
  const [scheduledPushTime, setScheduledPushTime] = useState(new Date());
  const [isPushImmediate, setIsPushImmediate] = useState(true);
  const [isTeamReview, setIsTeamReview] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchReviews();
    fetchTeams();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
    }
  };

  const fetchTeams = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/teams`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setTeams(response.data);
    } catch (err) {
      console.error("Error fetching teams:", err);
      setError("Failed to fetch teams. Please try again.");
    }
  };

  const handlePushReview = async () => {
    if (!selectedReview) return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/push-review`,
        {
          review_id: parseInt(selectedReview),
          expiration_date: expirationDate.toISOString(),
          scheduled_push_time: isPushImmediate
            ? null
            : scheduledPushTime.toISOString(),
          is_team_review: isTeamReview,
          team_id: isTeamReview ? parseInt(selectedTeam) : null,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      console.log("Push review response:", response.data);
      alert(
        isPushImmediate
          ? `Review ${isTeamReview ? "pushed to team" : "pushed to all users"} successfully!`
          : `Review scheduled to be ${isTeamReview ? "pushed to team" : "pushed to all users"} successfully!`,
      );
    } catch (err) {
      console.error("Error pushing review:", err.response?.data || err.message);
      setError(
        `Failed to push review: ${err.response?.data?.detail || err.message}`,
      );
    }
  };

  // Custom styles for DatePicker component
  const datePickerCustomStyles = {
    className:
      "w-full p-3 bg-white border border-zinc-300 text-zinc-800 rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500",
  };

  return (
    <div>
      <h3 className="flex items-center text-md font-light font-grotesk mb-4 text-zinc-700">
        <span className="font-grotesk tracking-wider">Push Review</span>
        <div className="h-px flex-grow bg-zinc-200 ml-4"></div>
      </h3>

      <p className="text-zinc-700 text-sm mb-4 font-grotesk">
        To push a review out to everyone or specific teams, select the review
        from the dropdown menu and then select a date to send or push it
        immediately.
      </p>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-zinc-700 mb-2">
            Select Review
          </label>
          <select
            className="w-full text-sm font-grotesk p-3 bg-white border border-zinc-300 text-zinc-800 rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
            onChange={(e) => setSelectedReview(e.target.value)}
            value={selectedReview || ""}
          >
            <option value="">Select a review</option>
            {reviews.map((review) => (
              <option key={review.id} value={review.id}>
                {review.reviews_text.substring(0, 50)}...
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-zinc-700 mb-2 font-grotesk">
            Expiration Date
          </label>
          <DatePicker
            selected={expirationDate}
            onChange={(date) => setExpirationDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMMM d, yyyy h:mm aa"
            {...datePickerCustomStyles}
          />
        </div>

        <div>
          <label className="flex items-center text-zinc-700">
            <input
              type="checkbox"
              checked={isPushImmediate}
              onChange={() => setIsPushImmediate(!isPushImmediate)}
              className="h-4 w-4 text-amber-500 border-gray-300 rounded focus:ring-amber-500 mr-2"
            />
            <span className="text-sm">Push Immediately</span>
          </label>
        </div>

        {!isPushImmediate && (
          <div>
            <label className="block text-sm font-medium text-zinc-700 mb-2">
              Scheduled Push Time
            </label>
            <DatePicker
              selected={scheduledPushTime}
              onChange={(date) => setScheduledPushTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              {...datePickerCustomStyles}
            />
          </div>
        )}

        <div>
          <label className="flex items-center text-zinc-700">
            <input
              type="checkbox"
              checked={isTeamReview}
              onChange={() => setIsTeamReview(!isTeamReview)}
              className="h-4 w-4 text-amber-500 border-gray-300 rounded focus:ring-amber-500 mr-2"
            />
            <span className="text-sm">Push to Team</span>
          </label>
        </div>

        {isTeamReview && (
          <div>
            <label className="block text-sm font-medium text-zinc-700 mb-2">
              Select Team
            </label>
            <select
              className="w-full p-3 bg-white border border-zinc-300 text-zinc-800 rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
              onChange={(e) => setSelectedTeam(e.target.value)}
              value={selectedTeam || ""}
            >
              <option value="">Select a team</option>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.team_name}
                </option>
              ))}
            </select>
          </div>
        )}

        <button
          onClick={handlePushReview}
          className="w-full mt-2 text-sm font-grotesk py-2 px-4 bg-amber-700 border text-white font-light rounded-md hover:bg-slate-500 transition-colors focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!selectedReview || (isTeamReview && !selectedTeam)}
        >
          {isPushImmediate ? "Push Review" : "Schedule Review Push"}
        </button>

        {error && (
          <div className="text-rose-400 p-4 mt-2 border border-rose-600 bg-rose-950/20 rounded-md">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default PushReview;
