import React from "react";
import { Calendar, X } from "lucide-react";
import CommentsSection from "./CommentsSection";

const ResponseDetailsModal = ({
  selectedResponse,
  handleCloseModal,
  onReply,
}) => {
  console.log("ResponseDetailsModal received props:", {
    hasSelectedResponse: !!selectedResponse,
    hasOnReply: !!onReply,
    selectedResponseData: selectedResponse,
  });

  if (!selectedResponse) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-slate-950/80 backdrop-blur-sm p-4">
      <div className="bg-slate-950 rounded-lg border border-slate-900 shadow-2xl w-full max-w-lg mx-auto overflow-y-auto max-h-[90vh] backdrop-blur-sm">
        {/* Modal Header */}
        <div className="border-b border-slate-500 p-4 sticky top-0 backdrop-blur-sm z-10">
          <div className="flex justify-between items-center">
            <h2 className="font-serif text-slate-300">Response Details</h2>
            <button
              onClick={handleCloseModal}
              className="text-slate-400 hover:text-red-400/90 transition-colors duration-200 p-1"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
          <div className="mt-4 flex items-center gap-1.5 text-slate-400">
            <Calendar className="w-3 h-3" />
            <span className="text-xs font-serif">
              {new Date(selectedResponse.date_answered).toLocaleString(
                "en-US",
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                },
              )}
            </span>
          </div>
        </div>

        {/* Modal Content */}
        <div className="p-4 space-y-4 mt-3">
          {[1, 2, 3, 4].map((num) => (
            <div key={num} className="space-y-1.5">
              <p className="text-slate-300 font-serif text-sm">
                Q{num}: {selectedResponse[`question_${num}`]}
              </p>
              <p className="text-slate-300 bg-slate-950 p-1.5 rounded-md border border-slate-950 text-sm font-serif">
                {selectedResponse[`answer_${num}`]}
              </p>
            </div>
          ))}

          <CommentsSection
            comments={selectedResponse.comment}
            onReply={(text, timestamp) => {
              console.log("ResponseDetailsModal handling reply:", {
                text,
                timestamp,
              });
              onReply(text, timestamp);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponseDetailsModal;
