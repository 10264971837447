import React from "react";
import { useDrag } from "react-dnd";

const DraggableBankQuestion = ({ question, onSelectQuestion }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "bankQuestion",
    item: {
      id: question.id,
      text: question.question_text,
      fromBank: true,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`p-3 bg-neutral-50 hover:bg-neutral-100 rounded-md border border-zinc-100 cursor-move transition-colors duration-200 mb-2 ${
        isDragging ? "opacity-50" : ""
      }`}
      onClick={onSelectQuestion}
    >
      <p className="text-sm text-zinc-700 mb-1">{question.question_text}</p>
      <div className="flex justify-between items-center">
        {question.category && (
          <span className="text-xs px-2 py-0.5 bg-zinc-200 text-zinc-600 rounded-full">
            {question.category}
          </span>
        )}
        <span className="text-xs text-zinc-400 italic">drag to section</span>
      </div>
    </div>
  );
};

export default DraggableBankQuestion;
