import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import MainContentRenderer from "./MainContentRenderer";
import MobileSidebarOverlay from "./MobileSidebarOverlay";
import Sidebar from "../Sidebar";
import useDashboardData from "./useDashboardData";
import businessNameMapping from "../../businessNameMapping.json";

const Dashboard = ({ user, onLogout }) => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("Dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const {
    users,
    setUsers,
    loading,
    error,
    responses,
    setResponses,
    hasReadResponses,
    averageScore,
    lastCalculated,
    averageScoreError,
    showReadNotification,
    setShowReadNotification,
    latestSurveyResponse,
    readResponses,
  } = useDashboardData(user);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    onLogout();
  };

  const getDisplayName = (businessName) => {
    return businessNameMapping[businessName] || businessName;
  };

  useEffect(() => {
    if (location.state && location.state.activeSection) {
      setActiveSection(location.state.activeSection);
    }
  }, [location]);

  return (
    <div className="flex h-screen bg-gradient-to-t from-zinc-900 via-zinc-800 to-zinc-900 text-gray-100">
      <Sidebar
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        isAdmin={user.is_admin}
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        user={user}
      />

      <div className="flex-1 flex flex-col overflow-hidden">
        <DashboardHeader
          user={user}
          handleLogout={handleLogout}
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          getDisplayName={getDisplayName}
        />

        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <div className="mx-auto px-3 lg:px-12 py-4">
            <MainContentRenderer
              activeSection={activeSection}
              loading={loading}
              error={error}
              user={user}
              responses={responses}
              setResponses={setResponses}
              users={users}
              setUsers={setUsers}
              showReadNotification={showReadNotification}
              hasReadResponses={hasReadResponses}
              setShowReadNotification={setShowReadNotification}
              latestSurveyResponse={latestSurveyResponse}
              averageScore={averageScore}
              lastCalculated={lastCalculated}
              averageScoreError={averageScoreError}
              getDisplayName={getDisplayName}
              readResponses={readResponses}
            />
          </div>
        </main>
      </div>

      <MobileSidebarOverlay isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </div>
  );
};

export default Dashboard;
