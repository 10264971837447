import axios from "axios";
import { API_URL } from "../../utils/apiConfig";

/**
 * Data service for fetching and processing eNPS quarterly stats.
 * Demonstrates "rolling 5 quarters" logic.
 */
const enpsDataService = {
  /**
   * Fetch eNPS data for the given business and year.
   * In this example, we assume the API can return the selected year + previous year
   * (via a param like `include_previous_year=true`) so we can slice out the last 5 quarters.
   */
  async fetchQuarterlyData(businessName, selectedYear) {
    try {
      const token = localStorage.getItem("access_token");
      console.log(
        `Fetching ENPS data for ${businessName}, year: ${selectedYear} plus previous year.`,
      );

      // Example: pass an extra query param to get the "current year + previous year" data.
      // Adjust according to your API.
      const response = await axios.get(
        `${API_URL}/api/v1/businesses/${businessName}/enps-quarterly-stats?year=${selectedYear}&include_previous_year=true`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      console.log("API Response:", response.data);

      // Process the data
      const processedData = this.processApiResponse(response.data);

      return {
        success: true,
        data: processedData,
        rawData: response.data,
      };
    } catch (err) {
      console.error(
        "Error fetching ENPS data:",
        err.response?.data || err.message,
      );

      return {
        success: false,
        error: "Failed to load quarterly ENPS data. Please try again later.",
        rawError: err,
      };
    }
  },

  /**
   * Take the raw API data and convert it into the shape used by the UI.
   */
  processApiResponse(data) {
    const quarterlyData = this.processQuarterlyData(data);

    const result = {
      quarterlyData,
      yearlyAverage: 0,
      previousYearAverage: null,
      trendDirection: null,
      availableYears:
        data.available_years && data.available_years.length > 0
          ? data.available_years
          : [2024], // fallback if none provided
    };

    // Calculate yearly average for the "selected_year" from the data.
    // (The API might pass back which year is selected in `data.selected_year`
    // or you can just rely on your own "selectedYear" state in the parent.)
    const selectedYearFromApi = data.selected_year || 2024;

    // Only consider quarters belonging to the "selected year"
    const activeQuarters = quarterlyData.filter(
      (q) => q.rawQuarter?.year === selectedYearFromApi && q.totalResponses > 0,
    );

    if (activeQuarters.length > 0) {
      const total = activeQuarters.reduce((sum, q) => sum + q.enpsScore, 0);
      const avg = parseFloat((total / activeQuarters.length).toFixed(1));
      result.yearlyAverage = avg;

      // If the API returns a "previous_year_average" for the selected year, set the trend
      if (data.previous_year_average !== undefined) {
        const prevAvg = data.previous_year_average;
        result.previousYearAverage = prevAvg;
        if (avg > prevAvg) {
          result.trendDirection = "up";
        } else if (avg < prevAvg) {
          result.trendDirection = "down";
        } else {
          result.trendDirection = "same";
        }
      }
    }

    return result;
  },

  /**
   * Sort and slice the data to show up to 5 quarters in chronological order.
   */
  processQuarterlyData(data) {
    if (!Array.isArray(data.quarters)) {
      return [];
    }

    // Sort ascending by (year, quarter)
    let sortedQuarters = data.quarters.sort((a, b) => {
      // if same year, compare quarter
      if (a.year === b.year) return a.quarter - b.quarter;
      // else compare year
      return a.year - b.year;
    });

    // Now slice the last 5
    if (sortedQuarters.length > 5) {
      sortedQuarters = sortedQuarters.slice(sortedQuarters.length - 5);
    }

    // Convert to the structure we need
    // Instead of Q1..Q4 for a single year, we do: "Q4 2024", "Q1 2025", etc.
    const processed = sortedQuarters.map((q) => ({
      name: `Q${q.quarter} ${q.year}`,
      enpsScore: Number(q.enps_score || 0),
      promotersPercentage: Number(q.promoters_percentage || 0),
      passivesPercentage: Number(q.passives_percentage || 0),
      detractorsPercentage: Number(q.detractors_percentage || 0),
      totalResponses: Number(q.total_responses || 0),
      rawQuarter: q, // the original quarter object
    }));

    return processed;
  },
};

export default enpsDataService;
