import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

const SharedReviewDetail = ({ businessName }) => {
  const { reviewId } = useParams();
  const [review, setReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSharedReview = async () => {
      try {
        const response = await axios.get(
          `/api/v1/businesses/${businessName}/shared-reviews/${reviewId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          },
        );
        setReview(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching shared review:", err);
        setError("Failed to fetch shared review. Please try again.");
        setLoading(false);
      }
    };

    fetchSharedReview();
  }, [businessName, reviewId]);

  if (loading) return <div>Loading shared review...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-4">
      <Link to="/my-reviews" className="text-indigo-400 hover:text-indigo-300">
        &larr; Back to Reviews
      </Link>
      <h3 className="text-3xl font-semibold my-4 text-indigo-400 font-serif">
        Shared Review Details
      </h3>
      <div className="bg-gray-800 p-4 rounded-lg">
        <h4 className="text-xl font-semibold mb-2 text-slate-300">
          {review.review_text}
        </h4>
        <p className="text-sm text-gray-400 mb-4">
          Shared by: {review.shared_by_username} on{" "}
          {new Date(review.shared_at).toLocaleDateString()}
        </p>
        {review.questions.map((question, index) => (
          <div key={index} className="mb-4">
            <p className="font-medium text-gray-300">Q: {question}</p>
            <p className="ml-4 text-white">A: {review.answers[index]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedReviewDetail;
