import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Building,
  Shield,
  Plus,
  Menu,
  Unlock,
  UserCheck,
  UserPlus,
} from "lucide-react";

// Your abstracted components
import TenantsList from "../components/MasterDashboard/TenantsList";
import MasterAdminsList from "../components/MasterDashboard/MasterAdminsList";
import Modal from "../components/MasterDashboard/Modal";
import TenantForm from "../components/MasterDashboard/TenantForm";
import MasterAdminForm from "../components/MasterDashboard/MasterAdminForm";

import useModalManagement from "../components/MasterDashboard/useModalManagement";

const MasterDashboard = () => {
  const [tenants, setTenants] = useState([]);
  const [masterAdmins, setMasterAdmins] = useState([]);
  const [activeTab, setActiveTab] = useState("tenants"); // "tenants" or "admins"
  const [loading, setLoading] = useState(true);
  const [adminLoading, setAdminLoading] = useState(true);
  const [error, setError] = useState(null);
  const [adminError, setAdminError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  // Use modal management hook for tenant creation
  const tenantModal = useModalManagement({
    businessName: "",
    adminUsername: "",
    adminEmail: "",
    adminPassword: "",
  });

  // Use modal management hook for admin creation
  const adminModal = useModalManagement({
    username: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    fetchTenants();
    fetchMasterAdmins();
    // eslint-disable-next-line
  }, []);

  const fetchTenants = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get("/api/v1/master-dashboard/tenants", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("master_token")}`,
        },
      });

      setTenants(response.data || []);
    } catch (err) {
      console.error("Error fetching tenants:", err);
      setError("Failed to fetch tenants. Please check your connection.");
    } finally {
      setLoading(false);
    }
  };

  const fetchMasterAdmins = async () => {
    try {
      setAdminLoading(true);
      setAdminError(null);

      const response = await axios.get(
        "/api/v1/master-dashboard/master-admins",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("master_token")}`,
          },
        },
      );

      setMasterAdmins(response.data || []);
    } catch (err) {
      console.error("Error fetching master admins:", err);
      setAdminError(
        "Failed to fetch master admins. Please check your connection.",
      );
    } finally {
      setAdminLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("master_token");
    localStorage.removeItem("is_master_admin");
    window.location.href = "/";
  };

  const handleCreateTenant = async (e) => {
    e.preventDefault();
    const newTenant = tenantModal.formData;

    if (
      !newTenant.businessName ||
      !newTenant.adminUsername ||
      !newTenant.adminEmail ||
      !newTenant.adminPassword
    ) {
      tenantModal.setError("Please fill in all fields");
      return;
    }

    try {
      tenantModal.setProcessing("Creating new tenant...");

      await axios.post(
        "/api/v1/master-dashboard/create-tenant",
        {
          tenant_data: {
            business_name: newTenant.businessName.toLowerCase().trim(),
          },
          admin_data: {
            username: newTenant.adminUsername.toLowerCase().trim(),
            email: newTenant.adminEmail.trim(),
            password: newTenant.adminPassword,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("master_token")}`,
            "Content-Type": "application/json",
          },
        },
      );

      tenantModal.closeModalWithSuccess(
        `Tenant "${newTenant.businessName}" created successfully!`,
        fetchTenants,
      );
    } catch (err) {
      tenantModal.setError(
        err.response?.data?.detail || "Failed to create tenant",
      );
      console.error("Error creating tenant:", err);
    }
  };

  const handleCreateMasterAdmin = async (e) => {
    e.preventDefault();
    const newAdmin = adminModal.formData;

    if (!newAdmin.username || !newAdmin.email || !newAdmin.password) {
      adminModal.setError("Please fill in all fields");
      return;
    }

    try {
      adminModal.setProcessing("Creating new master admin...");

      await axios.post(
        "/api/v1/master-dashboard/create-master-admin",
        {
          username: newAdmin.username.toLowerCase().trim(),
          email: newAdmin.email.trim(),
          password: newAdmin.password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("master_token")}`,
            "Content-Type": "application/json",
          },
        },
      );

      adminModal.closeModalWithSuccess(
        `Master admin "${newAdmin.username}" created successfully!`,
        fetchMasterAdmins,
      );
    } catch (err) {
      adminModal.setError(
        err.response?.data?.detail || "Failed to create master admin",
      );
      console.error("Error creating master admin:", err);
    }
  };

  const handleRevokeMasterAdmin = async (username) => {
    if (
      !window.confirm(
        `Are you sure you want to revoke master admin privileges from ${username}?`,
      )
    ) {
      return;
    }

    try {
      await axios.post(
        "/api/v1/master-dashboard/revoke-master-admin",
        { username },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("master_token")}`,
            "Content-Type": "application/json",
          },
        },
      );

      // Refresh the list
      fetchMasterAdmins();
    } catch (err) {
      alert(
        "Failed to revoke master admin privileges: " +
          (err.response?.data?.detail || err.message),
      );
      console.error("Error revoking privileges:", err);
    }
  };

  return (
    <div className="min-h-screen bg-neutral-900 text-white">
      {/* Header */}
      <header className="bg-neutral-800 border-b border-neutral-700 py-4 px-6">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center gap-3">
            <Shield className="text-amber-500 h-6 w-6" />
            <h1 className="text-xl font-grotesk font-bold">
              Master Admin Dashboard
            </h1>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="p-2 text-neutral-400 hover:text-white"
            >
              <Menu className="h-6 w-6" />
            </button>

            {showMobileMenu && (
              <div className="absolute top-16 right-4 bg-neutral-800 border border-neutral-700 shadow-lg rounded-lg py-2 z-50">
                <button
                  onClick={() => {
                    setActiveTab("tenants");
                    setShowMobileMenu(false);
                  }}
                  className={`block w-full px-4 py-2 text-left ${
                    activeTab === "tenants"
                      ? "bg-neutral-700 text-white"
                      : "text-neutral-400"
                  } hover:bg-neutral-700 hover:text-white`}
                >
                  <div className="flex items-center gap-2">
                    <Building className="h-4 w-4" />
                    Tenants
                  </div>
                </button>
                <button
                  onClick={() => {
                    setActiveTab("admins");
                    setShowMobileMenu(false);
                  }}
                  className={`block w-full px-4 py-2 text-left ${
                    activeTab === "admins"
                      ? "bg-neutral-700 text-white"
                      : "text-neutral-400"
                  } hover:bg-neutral-700 hover:text-white`}
                >
                  <div className="flex items-center gap-2">
                    <UserCheck className="h-4 w-4" />
                    Master Admins
                  </div>
                </button>
                <div className="border-t border-neutral-700 my-1"></div>
                <button
                  onClick={handleLogout}
                  className="block w-full px-4 py-2 text-left text-neutral-400 hover:bg-neutral-700 hover:text-white"
                >
                  <div className="flex items-center gap-2">
                    <Unlock className="h-4 w-4" />
                    Logout
                  </div>
                </button>
              </div>
            )}
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex items-center gap-4">
            <div className="flex border border-neutral-700 rounded-lg overflow-hidden">
              <button
                onClick={() => setActiveTab("tenants")}
                className={`px-4 py-2 flex items-center gap-2 ${
                  activeTab === "tenants"
                    ? "bg-amber-700 text-white"
                    : "bg-neutral-800 text-neutral-400 hover:bg-neutral-700 hover:text-white"
                }`}
              >
                <Building className="h-4 w-4" />
                Tenants
              </button>
              <button
                onClick={() => setActiveTab("admins")}
                className={`px-4 py-2 flex items-center gap-2 ${
                  activeTab === "admins"
                    ? "bg-amber-700 text-white"
                    : "bg-neutral-800 text-neutral-400 hover:bg-neutral-700 hover:text-white"
                }`}
              >
                <UserCheck className="h-4 w-4" />
                Master Admins
              </button>
            </div>

            <button
              onClick={handleLogout}
              className="px-4 py-2 bg-neutral-700 hover:bg-neutral-600 rounded-lg text-sm transition-colors flex items-center gap-2"
            >
              <Unlock className="h-4 w-4" />
              Logout
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto py-8 px-4">
        {/* Tenant Management Section */}
        {activeTab === "tenants" && (
          <TenantsList
            loading={loading}
            error={error}
            tenants={tenants}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            fetchTenants={fetchTenants}
            setShowCreateModal={tenantModal.setShowModal}
          />
        )}

        {/* Master Admin Management Section */}
        {activeTab === "admins" && (
          <MasterAdminsList
            adminLoading={adminLoading}
            adminError={adminError}
            masterAdmins={masterAdmins}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            fetchMasterAdmins={fetchMasterAdmins}
            setShowAdminModal={adminModal.setShowModal}
            handleRevokeMasterAdmin={handleRevokeMasterAdmin}
          />
        )}
      </main>

      {/* Create Tenant Modal */}
      <Modal
        isVisible={tenantModal.showModal}
        onClose={() => tenantModal.setShowModal(false)}
        title="Create New Tenant"
        isProcessing={tenantModal.creationStatus.isCreating}
        statusMessage={tenantModal.creationStatus.message}
        isSuccess={tenantModal.creationStatus.success}
        submitButtonText="Create Tenant"
        processingButtonText="Creating..."
        submitButtonIcon={<Plus className="h-4 w-4" />}
        onSubmit={handleCreateTenant}
      >
        <TenantForm
          newTenant={tenantModal.formData}
          handleTenantInputChange={tenantModal.handleInputChange}
          isCreating={tenantModal.creationStatus.isCreating}
        />
      </Modal>

      {/* Create Master Admin Modal */}
      <Modal
        isVisible={adminModal.showModal}
        onClose={() => adminModal.setShowModal(false)}
        title="Add Master Admin"
        icon={<Shield className="h-5 w-5 text-amber-500" />}
        isProcessing={adminModal.creationStatus.isCreating}
        statusMessage={adminModal.creationStatus.message}
        isSuccess={adminModal.creationStatus.success}
        submitButtonText="Add Master Admin"
        processingButtonText="Creating..."
        submitButtonIcon={<UserPlus className="h-4 w-4" />}
        onSubmit={handleCreateMasterAdmin}
      >
        <MasterAdminForm
          newAdmin={adminModal.formData}
          handleAdminInputChange={adminModal.handleInputChange}
          isCreating={adminModal.creationStatus.isCreating}
        />
      </Modal>
    </div>
  );
};

export default MasterDashboard;
