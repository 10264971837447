import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PushReview from "./PushReview";

const QReviews = ({ businessName }) => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    fetchReviews();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
    }
  };

  const handleReviewClick = (review) => {
    setSelectedReview(review);
  };

  const handleCreateReview = () => {
    navigate(`/businesses/${businessName}/create-review`);
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(
        `/api/v1/businesses/${businessName}/reviews/${reviewId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchReviews(); // Refresh the reviews list
    } catch (err) {
      console.error("Error deleting review:", err);
      setError("Failed to delete review. Please try again.");
    }
  };

  const displayedReviews = showAllReviews ? reviews : reviews.slice(-5);

  return (
    <div className="p-4 rounded-lg shadow-2xl mt-[-20px]">
      <h3 className="text-2xl text-right mb-4 font-mono">create reviews</h3>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        {/* Create New Review Button and PushReview are now side by side */}
        <div className="col-span-1 flex justify-center bg-slate-800 p-7 mt-2 text-center">
          <button
            onClick={handleCreateReview}
            className="w-full h-1/4 mt-20 text-md shadow-2xl font-mono py-2 bg-indigo-800 text-white rounded hover:bg-cyan-700"
          >
            create new review
          </button>
        </div>

        <div className="col-span-1 p-2 shadow-2xl">
          <PushReview businessName={businessName} />
        </div>

        {/* Existing Reviews Section */}
        <div className="lg:col-span-2 bg-slate-800 rounded-lg shadow-2xl p-6">
          <h4 className="text-xl mb-6 font-mono text-slate-200">
            existing reviews
          </h4>
          <div className="space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto">
            {displayedReviews.map((review) => (
              <ReviewCard
                key={review.id}
                review={review}
                onReviewClick={handleReviewClick}
                onDeleteReview={handleDeleteReview}
              />
            ))}
          </div>
          {reviews.length > 5 && (
            <button
              onClick={() => setShowAllReviews(!showAllReviews)}
              className="mt-4 px-4 py-2 bg-indigo-950 text-white rounded hover:bg-indigo-700"
            >
              {showAllReviews ? "Show Less" : `Show All (${reviews.length})`}
            </button>
          )}
        </div>
      </div>

      {error && <div className="mt-4 text-red-300">{error}</div>}

      {selectedReview && (
        <ReviewContent
          review={selectedReview}
          onClose={() => setSelectedReview(null)}
        />
      )}
    </div>
  );
};

const ReviewCard = ({ review, onReviewClick, onDeleteReview }) => {
  return (
    <div className="bg-slate-800 p-1 rounded-lg flex justify-between items-center">
      <div
        className="flex-grow ml-3 cursor-pointer hover:bg-gray-600 rounded-xl mr-3 transition-colors p-2"
        onClick={() => onReviewClick(review)}
      >
        <h4 className="mb-2 text-teal-300">review {review.id}</h4>
        <p className="text-xs text-gray-30 mt-1">
          {review.reviews_text.substring(0, 100)}...
        </p>
      </div>
      <button
        onClick={() => onDeleteReview(review.id)}
        className="bg-indigo-400 text-white mr-2 text-xs px-3 py-1 rounded hover:bg-indigo-500 transition-colors"
      >
        Delete
      </button>
    </div>
  );
};

const ReviewContent = ({ review, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-slate-800 p-6 rounded-lg max-w-2xl w-full">
        <h2 className="text-2xl font-semibold mb-4 text-teal-300">
          {review.reviews_text}
        </h2>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => {
          const question = review[`question_${num}`];
          if (question) {
            return (
              <div key={num} className="mb-4">
                <h3 className="font-semibold text-indigo-300">
                  Question {num}
                </h3>
                <p className="text-slate-200">{question}</p>
              </div>
            );
          }
          return null;
        })}
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default QReviews;
