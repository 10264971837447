import React, { useState, useEffect } from "react";
import { Bell, MessageCircle, Zap } from "lucide-react";

const NotificationSection = ({ readResponses, responses, currentUsername }) => {
  // Process recent comments
  const recentComments = responses
    ?.reduce((acc, response) => {
      if (response.username === currentUsername && response.comment) {
        const comments = Array.isArray(response.comment)
          ? response.comment
          : [response.comment];

        comments.forEach((comment) => {
          if (typeof comment === "string") {
            acc.push({
              text: comment,
              timestamp: response.date_answered,
              username: "comment",
              responseUsername: response.username,
              responseDate: response.date_answered,
            });
          } else if (typeof comment === "object" && comment !== null) {
            if (Array.isArray(comment.text)) {
              comment.text.forEach((nestedComment) => {
                acc.push({
                  text: nestedComment.text || "Invalid comment",
                  timestamp: nestedComment.timestamp || response.date_answered,
                  username: nestedComment.commenter_id
                    ? nestedComment.commenter_username
                    : "comment",
                  responseUsername: response.username,
                  responseDate: response.date_answered,
                });
              });
            } else {
              acc.push({
                text: comment.text || "Invalid comment",
                timestamp: comment.timestamp || response.date_answered,
                username: comment.commenter_id
                  ? comment.commenter_username
                  : "comment",
                responseUsername: response.username,
                responseDate: response.date_answered,
              });
            }
          }
        });
      }
      return acc;
    }, [])
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    .slice(0, 10);

  // Safely render read responses based on DB structure
  const renderReadResponse = (response) => {
    try {
      const date = new Date(response.date_answered);

      return (
        <div
          key={response.id}
          className="p-3 rounded bg-zinc-900 border-l border-amber-600/30 transition-all duration-500 hover:border-l-2 hover:border-amber-500 group"
        >
          <p className="text-gray-300 text-sm font-light group-hover:text-gray-200 transition-colors duration-300">
            "{response.question_text}"
          </p>
          <p className="text-xs text-gray-500 mt-2 mb-1 group-hover:text-gray-400 transition-colors duration-300">
            <span className="text-amber-600/80">↳</span> {response.answer}
          </p>
          <div className="flex items-center gap-1 mt-2 opacity-70 group-hover:opacity-100 transition-opacity duration-300">
            <span className="inline-block w-1 h-1 bg-amber-500 rounded-full"></span>
            <p className="text-xs text-gray-600">
              {date.toLocaleDateString()} ·{" "}
              {date.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
        </div>
      );
    } catch (error) {
      console.error("Error rendering response:", error, response);
      return null;
    }
  };

  return (
    <div className="space-y-8 bg-zinc-800 border-l border-amber-600 p-4 rounded-lg shadow-lg">
      {/* Comments Section */}
      <div className="bg-zinc-800 rounded">
        <div className="flex items-center px-4 py-3 border-b border-gray-800">
          <MessageCircle className="h-4 w-4 text-amber-500 mr-2" />
          <h2 className="text-xs uppercase tracking-wider text-gray-400 font-light">
            Comments
          </h2>
        </div>

        <div className="p-3">
          {!recentComments || recentComments.length === 0 ? (
            <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
              <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
                <MessageCircle className="h-3 w-3 text-gray-700" />
              </div>
              <p>No comments</p>
            </div>
          ) : (
            <div className="space-y-2">
              {recentComments.map((comment, index) => (
                <div
                  key={index}
                  className="p-2 rounded bg-gray-800 border-l border-slate-700/30 transition-all duration-500 hover:border-l-2 hover:border-amber-500 group animate-fadeIn"
                  style={{ animationDelay: `${index * 0.05}s` }}
                >
                  <div className="flex justify-between items-center">
                    <p className="text-xs">
                      {comment.username !== "comment" ? (
                        <span className="text-amber-500/80 font-light group-hover:text-amber-400 transition-colors duration-300">
                          {comment.username}
                        </span>
                      ) : (
                        <span className="text-slate-500 font-light group-hover:text-slate-400 transition-colors duration-300">
                          system
                        </span>
                      )}
                    </p>
                  </div>
                  <p className="text-xs text-gray-400 mt-1 group-hover:text-gray-300 transition-colors duration-300">
                    "{comment.text}"
                  </p>
                  <div className="flex items-center gap-1 mt-2 opacity-70 group-hover:opacity-100 transition-opacity duration-300">
                    <span className="inline-block w-1 h-1 bg-slate-500 rounded-full"></span>
                    <p className="text-xs text-gray-600">
                      {new Date(comment.timestamp).toLocaleDateString()} ·{" "}
                      {new Date(comment.timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Read Responses Section */}
      <div className="bg-zinc-800 rounded">
        <div className="flex items-center px-4 py-3 border-b border-gray-800">
          <Bell className="h-4 w-4 text-amber-500 mr-2" />
          <h2 className="text-xs uppercase tracking-wider text-gray-400 font-light">
            Recent Activity
          </h2>
        </div>

        <div className="p-3">
          {!readResponses || readResponses.length === 0 ? (
            <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
              <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
                <Bell className="h-3 w-3 text-gray-700" />
              </div>
              <p>No activity</p>
            </div>
          ) : (
            <div className="space-y-2">
              {readResponses
                .map((response, index) => (
                  <div
                    key={response.id || index}
                    className="animate-fadeIn"
                    style={{ animationDelay: `${index * 0.05}s` }}
                  >
                    {renderReadResponse(response)}
                  </div>
                ))
                .filter(Boolean)}
            </div>
          )}
        </div>
      </div>

      <style jsx global>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(4px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fadeIn {
          animation: fadeIn 0.4s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default NotificationSection;
