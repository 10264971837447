import React, { useState, useEffect } from "react";
import { Calendar, Clock, ChevronRight } from "lucide-react";
import axios from "axios";

const ResponseDateSelector = ({
  sortedDates,
  selectedDate,
  handleDateClick,
  groupedResponses,
  businessName,
  setResponses,
}) => {
  // State for available months
  const [availableMonths, setAvailableMonths] = useState([]);
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Extract unique months from available dates
  useEffect(() => {
    // Get unique months from currently available dates
    const uniqueMonths = new Set();
    const months = [];

    sortedDates.forEach((dateStr) => {
      const date = new Date(dateStr);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

      if (!uniqueMonths.has(monthKey)) {
        uniqueMonths.add(monthKey);
        months.push({
          key: monthKey,
          label: date.toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
          }),
          count: 0,
        });
      }
    });

    // Sort months newest first
    const sortedMonths = months.sort((a, b) => b.key.localeCompare(a.key));

    // Get count of responses per month
    sortedMonths.forEach((month) => {
      const monthDates = sortedDates.filter((date) =>
        date.startsWith(month.key),
      );
      month.count = monthDates.reduce(
        (sum, date) => sum + (groupedResponses[date]?.length || 0),
        0,
      );
    });

    setAvailableMonths(sortedMonths);
  }, [sortedDates, groupedResponses]);

  // Fetch responses for a specific month
  const fetchMonthResponses = async (monthKey) => {
    try {
      setIsLoading(true);

      const token = localStorage.getItem("access_token");
      const [year, month] = monthKey.split("-");

      // API call with month filter
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/viewable-responses?year=${year}&month=${month}&limit=100`,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // Update the responses in parent component
      setResponses((prevResponses) => {
        // Filter out existing responses from this month to avoid duplicates
        const otherMonthResponses = prevResponses.filter((resp) => {
          const respDate = new Date(resp.date_answered);
          const respMonthKey = `${respDate.getFullYear()}-${String(respDate.getMonth() + 1).padStart(2, "0")}`;
          return respMonthKey !== monthKey;
        });

        // Combine with new responses
        return [...otherMonthResponses, ...response.data];
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching month data:", error);
      setIsLoading(false);
    }
  };

  // Handle month change
  const handleMonthChange = async (e) => {
    const index = Number(e.target.value);
    setSelectedMonthIndex(index);

    // Reset date selection
    if (selectedDate) {
      handleDateClick(null);
    }

    // Fetch data for the selected month if needed
    await fetchMonthResponses(availableMonths[index].key);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString();
  };

  // Get visible dates for current month
  const visibleDates =
    availableMonths.length > 0
      ? sortedDates.filter((date) =>
          date.startsWith(availableMonths[selectedMonthIndex]?.key || ""),
        )
      : [];

  return (
    <div className="w-full">
      <div className="bg-zinc-800 border-l border-amber-600 rounded shadow-2xl">
        <div className="p-3 border-b border-zinc-500">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Calendar className="w-3 h-3 text-amber-500" />
                <span className="text-xs uppercase tracking-wider text-gray-400">
                  Select Month
                </span>
              </div>
              <ChevronRight className="w-3 h-3 text-amber-500 transition-transform duration-300 group-hover:translate-x-1" />
            </div>

            {/* Month selector dropdown */}
            <div className="w-full">
              <select
                value={selectedMonthIndex}
                onChange={handleMonthChange}
                disabled={isLoading}
                className="w-full bg-zinc-950 text-gray-300 text-xs px-3 py-2 rounded border border-gray-800 focus:border-amber-600 transition-colors duration-300"
              >
                {availableMonths.map((month, index) => (
                  <option key={month.key} value={index}>
                    {month.label} ({month.count} responses)
                  </option>
                ))}
                {availableMonths.length === 0 && (
                  <option value={0}>No data available</option>
                )}
              </select>
            </div>

            {/* Loading indicator */}
            {isLoading && (
              <div className="text-xs text-amber-500 flex items-center">
                <div className="w-3 h-3 mr-2 border-t-2 border-l-2 border-amber-500 animate-spin rounded-full"></div>
                Loading...
              </div>
            )}
          </div>
        </div>

        <div className="p-3">
          {/* Mobile View: Horizontal scrollable container */}
          <div className="block lg:hidden">
            <div className="overflow-x-auto custom-scrollbar pb-2">
              <div className="flex space-x-2 min-w-min">
                {visibleDates.map((date) => (
                  <DateButton
                    key={date}
                    date={date}
                    isSelected={selectedDate === date}
                    responseCount={groupedResponses[date]?.length || 0}
                    formatDate={formatDate}
                    handleDateClick={handleDateClick}
                  />
                ))}
                {visibleDates.length === 0 && !isLoading && (
                  <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
                    <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
                      <Calendar className="h-3 w-3 text-gray-700" />
                    </div>
                    <p>No dates for this month</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Desktop View: Vertical layout */}
          <div className="hidden lg:block">
            <div className="flex flex-col space-y-2">
              {visibleDates.map((date) => (
                <DateButton
                  key={date}
                  date={date}
                  isSelected={selectedDate === date}
                  responseCount={groupedResponses[date]?.length || 0}
                  formatDate={formatDate}
                  handleDateClick={handleDateClick}
                />
              ))}
              {visibleDates.length === 0 && !isLoading && (
                <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
                  <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
                    <Calendar className="h-3 w-3 text-gray-700" />
                  </div>
                  <p>No dates for this month</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DateButton = ({
  date,
  isSelected,
  responseCount,
  formatDate,
  handleDateClick,
}) => (
  <button
    onClick={() => handleDateClick(date)}
    className={`
      flex-none transition-all duration-300 w-40 lg:w-full
      ${
        isSelected
          ? "bg-zinc-700 border-l-2 border-amber-600 rounded"
          : "bg-zinc-700 border-l border-gray-800 rounded hover:border-l-2 hover:border-amber-600"
      }
    `}
  >
    <div className="p-3">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <Clock className="w-3 h-3 text-amber-500" />
          <span className="text-xs text-gray-300">{formatDate(date)}</span>
        </div>
        <div className="text-xs text-gray-500 flex items-center gap-1 opacity-70">
          <span className="inline-block w-1 h-1 bg-amber-500 rounded-full"></span>
          {responseCount} {responseCount === 1 ? "response" : "responses"}
        </div>
      </div>
    </div>
  </button>
);

export default ResponseDateSelector;
