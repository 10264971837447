import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ErrorBoundary from "./components/ErrorBoundary";
import ENPSSurvey from "./components/ENPSSurvey";
import CreateReview from "./components/CreateReview";
import SharedReviewsPage from "./components/SharedReviewsPage";
import ReviewAnswers from "./components/ReviewAnswers";
import SharedReviewDetail from "./SharedReviewDetail";
import SignUp from "./components/SignUp";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get("/api/v1/users/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      localStorage.removeItem("access_token");
    } finally {
      setLoading(false);
    }
  };

  const handleLoginSuccess = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("access_token");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ErrorBoundary>
      <Router>
        <div className="App">
          <Routes>
            {/* Public routes */}
            <Route
              path="/businesses/:businessName/enps-survey/:surveyToken"
              element={<ENPSSurvey />}
            />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/signup/:token" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            {/* Protected routes */}
            {user ? (
              <>
                <Route
                  path="/*"
                  element={<Dashboard user={user} onLogout={handleLogout} />}
                />
                <Route
                  path="/businesses/:businessName/create-review"
                  element={<CreateReview />}
                />
                <Route
                  path="/reviews/:reviewId"
                  element={<ReviewAnswers businessName={user.business_name} />}
                />
                <Route
                  path="/shared-reviewss"
                  element={
                    <SharedReviewsPage businessName={user.business_name} />
                  }
                />
                <Route
                  path="/shared-reviews/:reviewId"
                  element={
                    <SharedReviewDetail businessName={user.business_name} />
                  }
                />
              </>
            ) : (
              <Route
                path="/*"
                element={<Login onLoginSuccess={handleLoginSuccess} />}
              />
            )}

            {/* Redirect any unknown routes to the main page */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
