import React from "react";
import { Link } from "react-router-dom";
import {
  Users,
  HelpCircle,
  Star,
  BarChart,
  Shield,
  UserCircle,
  UserPlus,
  Home,
  TrendingUp,
  MessageSquare,
  SettingsIcon,
} from "lucide-react";

const Sidebar = ({
  activeSection,
  setActiveSection,
  isAdmin,
  isOpen,
  toggleSidebar,
}) => {
  const sections = [
    { name: "Dashboard", icon: Home, path: "/dashboard" },
    { name: "Vibe Checks", icon: MessageSquare, path: "/vibe-checks" },
    { name: "Reviews", icon: BarChart, path: "/my-reviews" },
    { name: "One-on-One", icon: UserPlus, path: "/one-on-one" },
  ];

  if (isAdmin) {
    sections.push(
      { name: "Analytics", icon: BarChart, path: "/analytics" },
      { name: "eNPS", icon: TrendingUp, path: "/enps" },
      { name: "Users", icon: UserCircle, path: "/users" },
      { name: "Create Reviews", icon: Star, path: "/create-reviews" },
      { name: "Questions", icon: HelpCircle, path: "/questions" },
      { name: "User Permissions", icon: Shield, path: "/user-permissions" },
      { name: "Settings", icon: SettingsIcon, path: "/settings" },
    );
  }

  return (
    <>
      {/* Sidebar */}
      <div
        className={`bg-gradient-to-b from-slate-950 to-[#070707] text-xs tracking-widest text-slate-300 font-sans w-64 md:w-60 space-y-7 py-7 px-2 fixed inset-y-0 left-0 transform transition-transform duration-200 ease-in-out z-50 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0`}
      >
        {sections.map((section) => (
          <Link
            key={section.name}
            to={section.path}
            className={`flex mt-11 items-center tracking-widest space-x-2 py-2.5 px-4 rounded transition duration-200 ${
              activeSection === section.name
                ? "bg-gradient-to-b from-slate-600 to bg-slate-950 text-slate-200 shadow-2xl"
                : "hover:bg-slate-400 hover:text-slate-950"
            }`}
            onClick={() => {
              setActiveSection(section.name);
              toggleSidebar(); // Close sidebar after selection on mobile
            }}
          >
            <section.icon className="h-5 w-5" />
            <span>{section.name}</span>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Sidebar;
