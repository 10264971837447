import React from "react";
import { X, Send } from "lucide-react";

const ReviewModal = ({
  isOpen,
  onClose,
  selectedReview,
  activeModalType,
  answers,
  handleAnswerChange,
  handleSubmitAnswers,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-950/90 backdrop-blur-sm p-4 transition-opacity duration-300">
      <div className="bg-gray-900 rounded-lg border border-gray-800 w-full max-w-2xl my-6 transform transition-transform duration-300">
        {/* Modal Header */}
        <div className="border-b border-gray-800 p-3 flex justify-between items-center">
          <h2 className="text-sm uppercase tracking-wider text-gray-300">
            {activeModalType === "reviewsToAnswer"
              ? "Answer Review"
              : activeModalType === "teamReviews"
                ? "Team Review"
                : activeModalType === "sharedWithMe"
                  ? "Shared Review"
                  : "Answered Review"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-amber-500 transition-colors duration-200"
          >
            <X className="w-4 h-4" />
          </button>
        </div>

        <div className="p-3 bg-gray-950 border-b border-gray-800">
          <h3 className="text-xs text-gray-400">
            {selectedReview.reviews_text || selectedReview.review_text}
          </h3>
        </div>

        {/* Modal Content */}
        <div className="p-4 space-y-3 overflow-y-auto max-h-[60vh] custom-scrollbar">
          {activeModalType === "sharedWithMe"
            ? selectedReview.questions.map((question, index) => (
                <div key={index} className="space-y-2">
                  <label className="text-xs text-gray-400">{question}</label>
                  <div className="w-full px-3 py-2 bg-gray-950 rounded border-l border-gray-800 text-gray-300 text-xs whitespace-pre-wrap break-words">
                    {answers[`answer_${index + 1}`] || ""}
                  </div>
                </div>
              ))
            : activeModalType === "answeredReviews"
              ? selectedReview.questions.map((question, index) => (
                  <div key={index} className="space-y-2">
                    <label className="text-xs text-gray-400">{question}</label>
                    <div className="px-3 py-2 bg-gray-950 rounded border-l border-gray-800 text-gray-300 text-xs whitespace-pre-wrap break-words">
                      {selectedReview.answers[index] || "Not answered"}
                    </div>
                  </div>
                ))
              : [1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                  <div key={num} className="space-y-2">
                    {selectedReview[`question_${num}`] && (
                      <>
                        <label className="text-xs text-gray-400">
                          {selectedReview[`question_${num}`]}
                        </label>
                        <textarea
                          className="w-full px-3 py-2 bg-gray-950 rounded border-l border-gray-800 text-gray-300 text-xs focus:border-l-2 focus:border-amber-600 transition-all duration-200 whitespace-pre-wrap break-words"
                          rows="2"
                          value={answers[`answer_${num}`] || ""}
                          onChange={(e) =>
                            handleAnswerChange(`answer_${num}`, e.target.value)
                          }
                          readOnly={activeModalType === "answeredReviews"}
                        />
                      </>
                    )}
                  </div>
                ))}

          {activeModalType !== "sharedWithMe" &&
            activeModalType !== "answeredReviews" && (
              <button
                onClick={handleSubmitAnswers}
                className="w-full px-3 py-2 bg-gray-800 hover:bg-gray-700 text-xs text-gray-300 border-l border-amber-600 rounded flex items-center justify-center gap-2 transition-all duration-300 hover:border-l-2 mt-4"
              >
                <Send className="w-3 h-3 text-amber-500" />
                Submit Answers
              </button>
            )}
        </div>
      </div>

      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #1f2937;
          border-radius: 2px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #374151;
          border-radius: 2px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #4b5563;
        }
      `}</style>
    </div>
  );
};

export default ReviewModal;
