import React, { useState, useEffect } from "react";
import { BarChart, Activity, ChevronRight, Award } from "lucide-react";

const AnalyticsWelcomePreview = () => {
  const [animationStage, setAnimationStage] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [replayCount, setReplayCount] = useState(0);

  const startAnimation = () => {
    // Reset states
    setAnimationStage(0);
    setShowContent(false);
    setCompleted(false);

    // Increment replay counter
    setReplayCount((prev) => prev + 1);

    // Ultra-fast timings (50% of current values)
    setTimeout(() => setShowContent(true), 150);
    setTimeout(() => setAnimationStage(1), 550);
    setTimeout(() => setAnimationStage(2), 900);
    setTimeout(() => {
      setAnimationStage(3);
      setTimeout(() => setCompleted(true), 300);
    }, 1250);
    // Total animation time: ~1550ms
  };
  // Start animation on first render
  useEffect(() => {
    startAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen h-screen bg-gray-800 flex flex-col items-center justify-center">
      {/** Replay button (up to 3 times) */}
      {completed && replayCount < 3 && (
        <div className="absolute top-6 left-0 right-0 flex justify-center">
          <button
            onClick={startAnimation}
            className="bg-zinc-800 text-white py-2 px-4 rounded-lg shadow-md hover:bg-zinc-700"
          >
            Replay Animation
          </button>
        </div>
      )}

      {/** Main animated container */}
      <div
        className={`transition-all duration-[525ms] transform ${
          showContent ? "scale-100 opacity-100" : "scale-90 opacity-0"
        }`}
      >
        <div className="bg-gray-800 p-10 rounded-lg border-l-4 border-amber-500 max-w-lg text-center shadow-2xl">
          {/** Icon area */}
          <div className="mb-8 flex justify-center">
            {animationStage >= 0 && (
              <div
                className={`transition-all duration-[375ms] ${
                  animationStage >= 1 ? "opacity-100" : "opacity-0"
                }`}
              >
                <div className="relative">
                  <div className="bg-gray-200 p-4 rounded-full">
                    <BarChart className="h-16 w-16 text-amber-400" />
                  </div>
                  <div
                    className={`absolute -top-2 -right-2 bg-emerald-600 p-1 rounded-full transition-all duration-[375ms] ${
                      animationStage >= 1
                        ? "opacity-100 scale-100"
                        : "opacity-0 scale-0"
                    }`}
                  >
                    <Award className="h-6 w-6 text-white" />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/** Title */}
          <h1
            className={`text-2xl font-bold font-grotesk text-zinc-50 mb-4 transition-all duration-[525ms] ${
              animationStage >= 1
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            You've Been Granted Analytics Access!
          </h1>

          {/** Subtitle */}
          <p
            className={`text-zinc-200 mb-6 transition-all duration-[525ms] ${
              animationStage >= 2
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            Explore insights, track performance, and make data-driven decisions
            with your new analytics dashboard.
          </p>

          {/** Button */}
          <div
            className={`flex justify-center transition-all duration-[525ms] ${
              animationStage >= 3
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            <button className="bg-gradient-to-r from-amber-500 to-amber-600 text-white py-3 px-8 rounded-lg shadow-md hover:shadow-lg transition-all flex items-center space-x-2 font-medium">
              <span>Continue to Analytics</span>
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>

          {/** Extra info */}
          <div
            className={`mt-10 flex justify-center space-x-2 transition-all duration-[525ms] ${
              animationStage >= 2 ? "opacity-70" : "opacity-0"
            }`}
          >
            <div className="flex items-center space-x-1 text-emerald-300 text-sm">
              <Activity className="h-4 w-4" />
              <span>Real-time Data</span>
            </div>
            <span className="text-zinc-300">•</span>
            <div className="flex items-center space-x-1 text-emerald-300 text-sm">
              <BarChart className="h-4 w-4" />
              <span>Advanced Insights</span>
            </div>
          </div>
        </div>
      </div>

      {/** Final message after 3 replays */}
      {completed && replayCount >= 3 && (
        <div className="mt-10 bg-white p-4 rounded-lg shadow border">
          <p className="text-zinc-600">
            Animation complete! This would typically transition to the analytics
            dashboard.
          </p>
        </div>
      )}
    </div>
  );
};

export default AnalyticsWelcomePreview;
