import React, { useState, useEffect } from "react";
import axios from "axios";
import { BarChart } from "lucide-react";

const QuarterlyVibeAverages = ({ businessName }) => {
  const [quarterlyData, setQuarterlyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchQuarterlyAverages();
  }, [businessName]);

  const fetchQuarterlyAverages = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/quarterly-vc-scores`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuarterlyData(response.data.quarterly_averages);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching quarterly averages:", err);
      setError("Failed to fetch quarterly vibe check averages.");
      setLoading(false);
    }
  };

  const getQuarterLabel = (quarter) => {
    return `Q${quarter}`;
  };

  if (loading)
    return <div className="text-center py-4">Loading quarterly data...</div>;
  if (error)
    return <div className="text-center text-red-300 py-4">{error}</div>;
  if (!quarterlyData || quarterlyData.length === 0)
    return (
      <div className="text-center text-neutral-400 py-4">
        No quarterly data available yet.
      </div>
    );

  return (
    <div>
      <div className="flex items-center mb-4">
        <BarChart className="w-5 h-5 mr-2 text-slate-600" />
        <h3 className="text-lg font-grotesk">Quarterly Vibe Check History</h3>
      </div>

      <div className="overflow-x-auto bg-gray-50 p-2">
        <table className="min-w-full divide-y divide-neutral-800">
          <thead>
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Quarter
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Date Range
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Average Score
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Responses
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-neutral-800">
            {quarterlyData.map((quarter, index) => (
              <tr
                key={index}
                className="hover:bg-neutral-800/50 transition-colors"
              >
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm text-zinc-600">
                    {getQuarterLabel(quarter.quarter)} {quarter.year}
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm text-zinc-600">
                    {new Date(quarter.quarter_start).toLocaleDateString()} -{" "}
                    {new Date(quarter.quarter_end).toLocaleDateString()}
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm font-medium text-zinc-600">
                    {quarter.average_score.toFixed(2)}
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm text-neutral-500">
                    {quarter.response_count}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuarterlyVibeAverages;
