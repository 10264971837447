import { useState, useEffect } from "react";
import axios from "axios";

const useOneOnOne = (businessName) => {
  const [users, setUsers] = useState([]);
  const [meetings, setMeetings] = useState({});
  const [selectedUser, setSelectedUser] = useState("");
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [meetingLink, setMeetingLink] = useState("");
  const [error, setError] = useState(null);
  const [selectedUserMeetings, setSelectedUserMeetings] = useState([]);
  const [notes, setNotes] = useState({});
  const [editingNotes, setEditingNotes] = useState({});
  const [selectedUsername, setSelectedUsername] = useState("");

  useEffect(() => {
    fetchUsers();
    fetchMeetings();
  }, [businessName]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/one-users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError(
        "Failed to fetch users: " + (err.response?.data?.detail || err.message),
      );
    }
  };

  const fetchMeetings = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/one-on-one`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setMeetings(response.data);

      if (selectedUsername) {
        setSelectedUserMeetings(response.data[selectedUsername] || []);
      }
    } catch (err) {
      console.error("Error fetching meetings:", err);
      setError(
        "Failed to fetch meetings: " +
          (err.response?.data?.detail || err.message),
      );
    }
  };

  const handleCreateMeeting = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("access_token");
      const localDate = new Date(meetingDate);
      const offset = localDate.getTimezoneOffset();
      const adjustedDate = new Date(localDate.getTime() - offset * 60 * 1000);

      const selectedUserObj = users.find(
        (user) => user.id.toString() === selectedUser,
      );
      if (!selectedUserObj) {
        throw new Error("Selected user not found");
      }

      const meetingData = {
        attendant_id: parseInt(selectedUser),
        meeting_date: adjustedDate.toISOString(),
        meeting_link: meetingLink,
      };

      await axios.post(
        `/api/v1/businesses/${businessName}/one-on-one`,
        meetingData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      setSelectedUsername(selectedUserObj.username);
      await fetchMeetings();
      setMeetingDate(new Date());
      setMeetingLink("");
      setError(null);
    } catch (err) {
      console.error("Error creating meeting:", err);
      setError(
        "Failed to create meeting: " +
          (err.response?.data?.detail || err.message),
      );
    }
  };

  const handleUpdateNotes = async (meetingId, isAttendant) => {
    try {
      const token = localStorage.getItem("access_token");
      const updateData = isAttendant
        ? { attendant_notes: notes[meetingId] }
        : { user_notes: notes[meetingId] };
      await axios.put(
        `/api/v1/businesses/${businessName}/one-on-one/${meetingId}`,
        updateData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setEditingNotes((prev) => ({ ...prev, [meetingId]: false }));
      fetchMeetings();
    } catch (err) {
      console.error("Error updating notes:", err);
      setError(
        "Failed to update notes: " +
          (err.response?.data?.detail || err.message),
      );
    }
  };

  const handleUserSelect = (username) => {
    setSelectedUserMeetings(meetings[username] || []);
    setSelectedUsername(username);
  };

  const handleNoteChange = (meetingId, value) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [meetingId]: value,
    }));
  };

  return {
    users,
    meetings,
    selectedUser,
    meetingDate,
    meetingLink,
    error,
    selectedUserMeetings,
    notes,
    editingNotes,
    selectedUsername,
    setSelectedUser,
    setMeetingDate,
    setMeetingLink,
    setEditingNotes,
    handleCreateMeeting,
    handleUpdateNotes,
    handleUserSelect,
    handleNoteChange,
  };
};

export default useOneOnOne;
