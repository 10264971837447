import axios from "axios";

/**
 * Service for fetching users for quarterly nominations
 * Handles multiple API endpoints with fallbacks
 */
const userFetchingService = {
  /**
   * Fetch users from various endpoints with fallback strategy
   * @param {string} businessName - The business name
   * @param {object} user - Current user object
   * @param {string} token - Authentication token
   * @returns {object} - Object containing users and debug information
   */
  async fetchUsers(businessName, user, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let debug = {
      businessName,
      username: user?.username,
      auth_username: user?.auth_user?.username,
      main_userId: user?.main_user?.id,
      token: token ? "Token exists" : "No token",
    };

    let activeUsers = [];
    let usersFound = false;

    // First try the specific endpoint for nomination users
    // This endpoint should already filter out excluded users on the backend
    try {
      console.log("[NOMINATION DEBUG] Trying active-users endpoint");
      const usersResponse = await axios.get(
        `/api/v1/businesses/${businessName}/quarterly-nominations/active-users`,
        { headers },
      );

      console.log(
        "[NOMINATION DEBUG] Active users response:",
        usersResponse.data,
      );
      debug.activeUsersResponse = usersResponse.data;

      if (usersResponse.data && usersResponse.data.length > 0) {
        activeUsers = usersResponse.data;
        usersFound = true;
      } else {
        console.warn(
          "[NOMINATION DEBUG] No users found with active-users endpoint",
        );
      }
    } catch (usersError) {
      console.error(
        "[NOMINATION DEBUG] Error fetching active users:",
        usersError.response?.status,
        usersError.message,
      );
      debug.activeUsersError = {
        message: usersError.message,
        status: usersError.response?.status,
        data: usersError.response?.data,
      };
    }

    // If first attempt failed, we need to try other endpoints and manually filter excluded users
    if (!usersFound) {
      // Try to get the excluded user IDs first
      let excludedUserIds = [];
      try {
        const excludedResponse = await axios.get(
          `/api/v1/businesses/${businessName}/quarterly-nominations/excluded-users`,
          { headers },
        );
        debug.excludedUsersResponse = excludedResponse.data;

        // Extract just the IDs from the excluded users list
        excludedUserIds = excludedResponse.data.map((user) => user.id);
        debug.excludedUserIds = excludedUserIds;
      } catch (excludedError) {
        console.error(
          "[NOMINATION DEBUG] Error fetching excluded users:",
          excludedError.response?.status,
          excludedError.message,
        );
        debug.excludedUsersError = {
          message: excludedError.message,
          status: excludedError.response?.status,
          data: excludedError.response?.data,
        };
      }

      // Then try the all-users endpoint
      try {
        console.log("[NOMINATION DEBUG] Trying all-users endpoint");
        const allUsersResponse = await axios.get(
          `/api/v1/businesses/${businessName}/all-users`,
          { headers },
        );

        console.log(
          "[NOMINATION DEBUG] All users response:",
          allUsersResponse.data,
        );
        debug.allUsersResponse = allUsersResponse.data;

        if (allUsersResponse.data && allUsersResponse.data.length > 0) {
          // Filter out current user and excluded users
          const filteredUsers = allUsersResponse.data.filter(
            (u) =>
              u.username !== user.username &&
              u.username !== user?.auth_user?.username &&
              !excludedUserIds.includes(u.id),
          );

          console.log(
            `[NOMINATION DEBUG] Filtered ${allUsersResponse.data.length} users to ${filteredUsers.length} users`,
          );
          debug.filteredUsers = filteredUsers;

          if (filteredUsers.length > 0) {
            activeUsers = filteredUsers;
            usersFound = true;
          }
        }
      } catch (allUsersError) {
        console.error(
          "[NOMINATION DEBUG] Error fetching all users:",
          allUsersError.response?.status,
          allUsersError.message,
        );
        debug.allUsersError = {
          message: allUsersError.message,
          status: allUsersError.response?.status,
          data: allUsersError.response?.data,
        };
      }
    }

    // Last resort - try the standard users endpoint used elsewhere in the app
    if (!usersFound) {
      let excludedUserIds = [];
      if (!debug.excludedUserIds) {
        try {
          const excludedResponse = await axios.get(
            `/api/v1/businesses/${businessName}/quarterly-nominations/excluded-users`,
            { headers },
          );
          excludedUserIds = excludedResponse.data.map((user) => user.id);
          debug.excludedUserIds = excludedUserIds;
        } catch (error) {
          debug.secondExcludedUsersError = { message: error.message };
        }
      } else {
        excludedUserIds = debug.excludedUserIds;
      }

      try {
        console.log("[NOMINATION DEBUG] Trying standard users endpoint");
        const standardUsersResponse = await axios.get(
          `/api/v1/businesses/${businessName}/users`,
          { headers },
        );

        console.log(
          "[NOMINATION DEBUG] Standard users response:",
          standardUsersResponse.data,
        );
        debug.standardUsersResponse = standardUsersResponse.data;

        if (
          standardUsersResponse.data &&
          standardUsersResponse.data.length > 0
        ) {
          // Filter out current user and excluded users
          const filteredUsers = standardUsersResponse.data.filter(
            (u) =>
              u.username !== user.username &&
              u.username !== user?.auth_user?.username &&
              !excludedUserIds.includes(u.id),
          );

          if (filteredUsers.length > 0) {
            activeUsers = filteredUsers;
            usersFound = true;
          }
        }
      } catch (standardUsersError) {
        console.error(
          "[NOMINATION DEBUG] Error fetching standard users:",
          standardUsersError.response?.status,
          standardUsersError.message,
        );
        debug.standardUsersError = {
          message: standardUsersError.message,
          status: standardUsersError.response?.status,
          data: standardUsersError.response?.data,
        };
      }
    }

    return {
      users: activeUsers,
      debug,
    };
  },

  /**
   * Fetch nomination status
   * @param {string} businessName - The business name
   * @param {string} token - Authentication token
   * @returns {object} - Status object and debug information
   */
  async fetchNominationStatus(businessName, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let debug = {};
    let hasSubmitted = false;
    let currentNomination = null;
    let currentQuarter = null;
    let currentYear = null;

    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/quarterly-nominations/status`,
        { headers },
      );

      console.log("[NOMINATION DEBUG] Status response:", response.data);
      debug.statusResponse = response.data;

      hasSubmitted = response.data.has_submitted;
      currentNomination = response.data.nomination;

      if (response.data.current_quarter) {
        currentQuarter = response.data.current_quarter;
        currentYear = response.data.current_year;
      }
    } catch (apiError) {
      console.error(
        "[NOMINATION DEBUG] API error checking status:",
        apiError.response?.status,
        apiError.message,
      );
      debug.statusError = {
        message: apiError.message,
        status: apiError.response?.status,
        data: apiError.response?.data,
      };
    }

    return {
      hasSubmitted,
      currentNomination,
      currentQuarter,
      currentYear,
      debug,
    };
  },
};

export default userFetchingService;
