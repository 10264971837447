import React from "react";
import MyResponses from "./VibeCheck/MyResponses";
import ViewUserResponses from "./VibeCheck/ViewUserResponses";
import { MessageCircle, ChartBar, ChevronRight } from "lucide-react";
import { AudioLines } from "lucide-react";

const Responses = ({
  responses,
  setResponses,
  businessName,
  currentUsername,
}) => {
  return (
    <div className="mt-24 bg-zinc-800 border-l border-amber-600 text-gray-300 p-4 font-light rounded-lg overflow-hidden shadow-2xl">
      <div className="max-w-[1400px] mx-auto px-2">
        <div className="p-2">
          {/* Header */}
          <div className="w-full flex items-center space-x-2 mb-6 border-b border-zinc-500 pb-3 group">
            <AudioLines className="w-4 h-4 text-amber-500 transition-transform duration-700 group-hover:rotate-12" />

            <h1 className="text-gray-300 ml-2 text-sm tracking-wider uppercase">
              Vibe<span className="text-amber-500">Check</span>
            </h1>
          </div>

          {/* Grid Layout */}
          <div className="space-y-4">
            {/* My Responses Section */}
            <div className="col-span-1">
              <div className="bg-zinc-800 p-4 border-l border-gray-200 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
                <div className="flex items-center justify-between border-b border-gray-800 pb-3">
                  <h2 className="text-gray-400 text-xs uppercase tracking-wider flex items-center gap-2">
                    <MessageCircle className="w-3 h-3 text-amber-500" />
                    My Responses
                  </h2>
                  <ChevronRight className="w-3 h-3 text-amber-600 transition-transform duration-300 group-hover:text-amber-500 group-hover:translate-x-1" />
                </div>
                <div className="pt-3">
                  <MyResponses
                    responses={responses}
                    username={currentUsername}
                    businessName={businessName}
                    setResponses={setResponses}
                  />
                </div>
              </div>
            </div>

            {/* View User Responses Section */}
            <div className="col-span-1">
              <div className="bg-zinc-800 p-4 border-l border-gray-200 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
                <div className="flex items-center justify-between border-b border-gray-800 pb-3">
                  <h2 className="text-gray-400 text-xs uppercase tracking-wider flex items-center gap-2">
                    <ChartBar className="w-3 h-3 text-amber-500" />
                    View User Responses
                  </h2>
                  <ChevronRight className="w-3 h-3 text-amber-600 transition-transform duration-300 group-hover:text-amber-500 group-hover:translate-x-1" />
                </div>
                <div className="pt-3">
                  <ViewUserResponses
                    responses={responses}
                    setResponses={setResponses}
                    businessName={businessName}
                    currentUsername={currentUsername}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Custom Scrollbar Styling */}
          <style jsx>{`
            @keyframes fadeIn {
              from {
                opacity: 0;
                transform: translateY(4px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }

            .animate-fadeIn {
              animation: fadeIn 0.4s ease-out forwards;
            }

            ::-webkit-scrollbar {
              width: 4px;
            }
            ::-webkit-scrollbar-track {
              background: #1f2937;
              border-radius: 2px;
            }
            ::-webkit-scrollbar-thumb {
              background: #374151;
              border-radius: 2px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: #4b5563;
            }
          `}</style>
        </div>
      </div>
    </div>
  );
};

export default Responses;
