import React, { useState } from "react";
import { MessageCircle, Clock, Reply } from "lucide-react";

const CommentsSection = ({ comments, onReply }) => {
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyText, setReplyText] = useState("");

  if (!comments || !Array.isArray(comments) || comments.length === 0) {
    return null;
  }

  const hasValidUserId = (comment) => {
    return (
      typeof comment === "object" &&
      comment !== null &&
      comment.commenter_id &&
      comment.commenter_username
    );
  };

  const handleSubmitReply = (commentIndex) => {
    if (replyText.trim()) {
      onReply(replyText, comments[commentIndex].timestamp);
      setReplyText("");
      setReplyingTo(null);
    }
  };

  const renderComment = (textItem, isReply = false) => (
    <div
      className={`flex justify-between items-start ${isReply ? "ml-4 mt-2 pt-2 border-t border-zinc-200" : ""}`}
    >
      <div className="flex items-start gap-2">
        {textItem.commenter_id || hasValidUserId(textItem) ? (
          <span className="text-zinc-700 font-medium text-sm">
            {textItem.commenter_username}:
          </span>
        ) : (
          <span className="text-zinc-600 font-medium text-sm">comment:</span>
        )}
        <span className="text-zinc-600 text-sm">
          {typeof textItem.text === "string" ? textItem.text : textItem}
        </span>
      </div>
      {!isReply && (
        <button
          onClick={() => setReplyingTo(comments.indexOf(textItem))}
          className="text-xs text-zinc-500 hover:text-cyan-600 flex items-center gap-1"
        >
          <Reply className="w-3 h-3" />
          Reply
        </button>
      )}
    </div>
  );

  return (
    <div className="mt-6 bg-white rounded-md border-l border-zinc-200 p-4 shadow-2xl">
      <div className="flex items-center gap-2 mb-3">
        <MessageCircle className="w-4 h-4 text-zinc-500" />
        <h3 className="text-sm text-zinc-700">Comments</h3>
      </div>
      <div className="space-y-3">
        {comments.map((comment, idx) => (
          <div
            key={idx}
            className="pb-3 border-b border-zinc-200 last:border-0"
          >
            {Array.isArray(comment.text) ? (
              <>
                {comment.text.map((textItem, textIdx) => (
                  <div key={textIdx}>
                    {renderComment(textItem, textIdx > 0)}
                    {textItem.timestamp && (
                      <div className="mt-1 flex items-center gap-1 text-xs text-zinc-500">
                        <Clock className="w-3 h-3" />
                        <span>
                          {new Date(textItem.timestamp).toLocaleString()}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                {renderComment(comment)}
                {comment.timestamp && (
                  <div className="mt-1 flex items-center gap-1 text-xs text-zinc-500">
                    <Clock className="w-3 h-3" />
                    <span>{new Date(comment.timestamp).toLocaleString()}</span>
                  </div>
                )}
              </>
            )}
            {replyingTo === idx && (
              <div className="mt-3 pl-4 space-y-2">
                <textarea
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  className="w-full p-2 text-sm bg-neutral-50 text-zinc-700 rounded-lg border border-zinc-200 focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 transition-colors duration-200 resize-none"
                  placeholder="Write a reply..."
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => {
                      setReplyingTo(null);
                      setReplyText("");
                    }}
                    className="px-2 py-1 text-xs text-zinc-600 bg-neutral-100 rounded-lg hover:bg-neutral-200 transition-colors duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmitReply(idx)}
                    className="px-2 py-1 text-xs bg-neutral-200 text-zinc-700 rounded-lg border border-cyan-500/20 hover:bg-cyan-500/20 transition-colors duration-200"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentsSection;
