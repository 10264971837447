import React, { useState } from "react";
import axios from "axios";
import { Users } from "lucide-react";
import ResponseDateSelector from "./ResponseDateSelector";
import ResponsesList from "./ResponsesList";

const ViewUserResponses = ({
  responses,
  setResponses,
  businessName,
  currentUsername,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [comments, setComments] = useState({});
  const [activeComment, setActiveComment] = useState(null);

  const otherUserResponses = responses.filter(
    (response) => response.username !== currentUsername,
  );

  const handleDateClick = (date) => {
    setSelectedDate(date === selectedDate ? null : date);
  };

  const handleResponseClick = async (userId, date) => {
    try {
      const token = localStorage.getItem("access_token");

      if (!date) {
        console.error("Invalid date provided");
        return;
      }

      // Format date to YYYY-MM-DD for API
      const formattedDate = new Date(date).toISOString().split("T")[0];

      const response = await axios.put(
        `/api/v1/businesses/${businessName}/mark-response-read`,
        {
          user_id: userId,
          date_answered: formattedDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.status === 200) {
        setResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.user_id === userId &&
            new Date(response.date_answered).toISOString().split("T")[0] ===
              formattedDate
              ? { ...response, read_responses: true }
              : response,
          ),
        );
        console.log("Successfully marked response as read");
      }
    } catch (error) {
      console.error(
        "Error marking response as read:",
        error.response?.data || error,
      );
    }
  };

  const handleCommentSubmit = async (userId, dateAnswered) => {
    try {
      const token = localStorage.getItem("access_token");
      const commentText = comments[userId + "-" + dateAnswered];

      const newComment = {
        text: commentText,
        timestamp: new Date().toISOString(),
        commenter_username: currentUsername,
        commenter_id: true,
      };

      await axios.post(
        `/api/v1/businesses/${businessName}/submit-comment`,
        {
          user_id: userId,
          date_answered: dateAnswered,
          comment: commentText,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setResponses((prevResponses) =>
        prevResponses.map((response) =>
          response.user_id === userId && response.date_answered === dateAnswered
            ? {
                ...response,
                comment: response.comment
                  ? [...response.comment, newComment]
                  : [newComment],
              }
            : response,
        ),
      );

      setActiveComment(null);
      setComments({
        ...comments,
        [userId + "-" + dateAnswered]: "",
      });
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  // Updated grouping logic to preserve local dates
  const groupedResponses = otherUserResponses.reduce((acc, response) => {
    // Get the date in local timezone, truncated to date only
    const dateObj = new Date(response.date_answered);
    const localDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, "0")}-${String(dateObj.getDate()).padStart(2, "0")}`;

    if (!acc[localDate]) {
      acc[localDate] = [];
    }
    acc[localDate].push(response);
    return acc;
  }, {});

  const sortedDates = Object.keys(groupedResponses).sort(
    (a, b) => new Date(b) - new Date(a),
  );

  if (otherUserResponses.length === 0) {
    return (
      <div className="flex items-center justify-center p-3 text-gray-400">
        <p className="flex items-center gap-1.5 text-sm">
          <Users className="w-4 h-4" />
          No responses from other users available to view.
        </p>
      </div>
    );
  }

  return (
    <div className="text-slate-200 px-2 sm:px-4">
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-3">
        <ResponseDateSelector
          sortedDates={sortedDates}
          selectedDate={selectedDate}
          handleDateClick={handleDateClick}
          groupedResponses={groupedResponses}
        />

        <ResponsesList
          selectedDate={selectedDate}
          groupedResponses={groupedResponses}
          activeComment={activeComment}
          setActiveComment={setActiveComment}
          comments={comments}
          setComments={setComments}
          handleCommentSubmit={handleCommentSubmit}
          handleResponseClick={handleResponseClick}
        />
      </div>
    </div>
  );
};

export default ViewUserResponses;
