import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-neutral-800 to-neutral-950">
      {/* Navigation */}
      <nav className="w-full flex p-4 bg-transparent ml-10">
        <div className="flex space-x-8">
          <Link
            to="/"
            className="text-neutral-100 hover:text-neutral-600 font-serif"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-neutral-100 hover:text-neutral-600 font-serif"
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="text-neutral-100 hover:text-neutral-600 font-serif"
          >
            Contact
          </Link>
        </div>
      </nav>

      {/* Logo Section */}
      <header className="p-2 flex justify-center items-center">
        <div className="flex items-end mt-10 md:mt-5 mb-1">
          <img
            src="/matcha9.svg"
            alt="Matcha Logo"
            className="w-[400px] h-full invert"
          />
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto bg-white bg-opacity-90 rounded-lg shadow-xl p-8">
          <h1 className="text-4xl font-serif text-neutral-800 mb-6">
            About Matcha
          </h1>

          <section className="mb-8">
            <h2 className="text-2xl font-serif text-neutral-700 mb-4">
              Our Mission
            </h2>
            <p className="text-neutral-600 mb-4">
              At Matcha, we're dedicated to transforming workplace dynamics
              through AI-driven insights. Our platform helps organizations build
              stronger teams and cultivate thriving company cultures.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-serif text-neutral-700 mb-4">
              Our Approach
            </h2>
            <p className="text-neutral-600 mb-4">
              We combine advanced analytics with human-centered design to
              provide actionable insights that drive employee engagement and
              business success. Our AI-powered platform helps identify key
              talent and create environments where teams can flourish.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-serif text-neutral-700 mb-4">
              Our Values
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-neutral-50 p-4 rounded-lg">
                <h3 className="text-xl text-neutral-700 mb-2">Innovation</h3>
                <p className="text-neutral-600">
                  Continuously evolving our solutions to meet the changing needs
                  of modern workplaces.
                </p>
              </div>
              <div className="bg-neutral-50 p-4 rounded-lg">
                <h3 className="text-xl text-neutral-700 mb-2">Trust</h3>
                <p className="text-neutral-600">
                  Building relationships based on transparency, reliability, and
                  data security.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default AboutUs;
