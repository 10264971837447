import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Users, Calendar, Link as LinkIcon, Clock } from "lucide-react";

const CreateMeetingForm = ({
  users,
  selectedUser,
  meetingDate,
  meetingLink,
  setSelectedUser,
  setMeetingDate,
  setMeetingLink,
  handleCreateMeeting,
}) => {
  return (
    <div className="bg-gray-800 rounded-lg">
      <div className="p-3 border-b border-gray-700">
        <div className="flex items-center space-x-2">
          <Calendar className="w-5 h-5 text-amber-500" strokeWidth={1.5} />
          <span className="text-gray-100 font-grotesk text-lg tracking-wider">
            Schedule New Meeting
          </span>
        </div>
      </div>

      <form onSubmit={handleCreateMeeting} className="p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Users className="w-4 h-4 text-amber-500" strokeWidth={1.5} />
              <label className="text-gray-300 text-xs font-grotesk tracking-wider">
                Select Team Member
              </label>
            </div>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg focus:border-amber-500/50 focus:ring-1 focus:ring-amber-500/20 text-gray-300 text-sm transition-colors duration-200"
              required
            >
              <option value="">Choose a user</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Calendar className="w-4 h-4 text-amber-500" strokeWidth={1.5} />
              <label className="text-gray-300 text-xs font-grotesk tracking-wider">
                Date & Time
              </label>
            </div>
            <DatePicker
              selected={meetingDate}
              onChange={(date) => setMeetingDate(date)}
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              timeFormat="h:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg focus:border-amber-500/50 focus:ring-1 focus:ring-amber-500/20 text-gray-300 text-sm transition-colors duration-200"
              required
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <LinkIcon className="w-4 h-4 text-amber-500" strokeWidth={1.5} />
              <label className="text-gray-300 text-xs font-grotesk tracking-wider">
                Meeting Link
              </label>
            </div>
            <input
              type="text"
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              placeholder="Enter meeting URL"
              className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg focus:border-amber-500/50 focus:ring-1 focus:ring-amber-500/20 text-gray-300 text-sm transition-colors duration-200"
            />
          </div>

          <div className="flex items-end">
            <button
              type="submit"
              className="w-full px-4 py-2 bg-gray-700 hover:bg-amber-500 text-gray-200 hover:text-gray-900 rounded-lg flex items-center justify-center space-x-2 transition-all duration-200 font-grotesk font-medium text-sm active:scale-[0.98]"
            >
              <Clock className="w-4 h-4" strokeWidth={1.5} />
              <span>Schedule Meeting</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMeetingForm;
