import React, { useEffect } from "react";
import { Calendar, Clock, ChevronRight } from "lucide-react";

const ResponseDateSelector = ({
  sortedDates,
  selectedDate,
  handleDateClick,
  groupedResponses,
}) => {
  const formatDate = (dateString) => {
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString();
  };

  return (
    <div className="w-full">
      <div className="border border-slate-900 rounded-lg">
        <div className="p-2 bg-gradient-to-b from-gray-900 to bg-gray-950 rounded-t border-b border-slate-900">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="h-[2px] w-4 bg-gradient-to-r from-slate-400 to-transparent" />
              <div className="flex items-center space-x-2">
                <Calendar
                  className="w-4 h-4 text-yellow-400"
                  strokeWidth={1.5}
                />
                <span className="text-slate-200 text-xs uppercase tracking-wider">
                  Select Date
                </span>
              </div>
            </div>
            <ChevronRight
              className="w-4 h-4 text-yellow-400"
              strokeWidth={1.5}
            />
          </div>
        </div>

        <div className="p-4">
          {/* Mobile View: Horizontal scrollable container */}
          <div className="block lg:hidden">
            <div className="overflow-x-auto no-scrollbar pb-2">
              <div className="flex space-x-3 min-w-min">
                {sortedDates.map((date) => (
                  <DateButton
                    key={date}
                    date={date}
                    isSelected={selectedDate === date}
                    responseCount={groupedResponses[date].length}
                    formatDate={formatDate}
                    handleDateClick={handleDateClick}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Desktop View: Vertical layout */}
          <div className="hidden lg:block">
            <div className="flex flex-col space-y-3">
              {sortedDates.map((date) => (
                <DateButton
                  key={date}
                  date={date}
                  isSelected={selectedDate === date}
                  responseCount={groupedResponses[date].length}
                  formatDate={formatDate}
                  handleDateClick={handleDateClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DateButton = ({
  date,
  isSelected,
  responseCount,
  formatDate,
  handleDateClick,
}) => (
  <button
    onClick={() => handleDateClick(date)}
    className={`
      flex-none transition-all duration-300 w-48 lg:w-full
      ${
        isSelected
          ? "bg-slate-950 border border-slate-800 rounded-lg"
          : "bg-slate-900 border border-slate-900 rounded-lg hover:border-slate-800"
      }
    `}
  >
    <div className="p-3">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <Clock className="w-4 h-4 text-slate-400" strokeWidth={1.5} />
          <span className="text-slate-300 text-xs">{formatDate(date)}</span>
        </div>
        <div className="text-xs text-slate-400">
          {responseCount} {responseCount === 1 ? "response" : "responses"}
        </div>
      </div>
    </div>
  </button>
);

export default ResponseDateSelector;
