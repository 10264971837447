import React from "react";
import {
  TrendingUp,
  Users,
  Activity,
  CheckCircle,
  XCircle,
  AlertTriangle,
} from "lucide-react";

/**
 * Displays a stat row with icon and optional trend indicator
 */
const StatRow = ({ label, value, icon: Icon, trend }) => {
  let trendIcon = null;
  let trendClass = "";

  if (trend) {
    if (trend === "up") {
      trendIcon = <TrendingUp className="h-3 w-3" />;
      trendClass = "text-emerald-500";
    } else if (trend === "down") {
      trendIcon = <TrendingUp className="h-3 w-3 transform rotate-180" />;
      trendClass = "text-rose-500";
    }
  }

  return (
    <div className="flex justify-between items-center p-3 rounded-lg hover:bg-zinc-50/80 transition-all duration-300 group backdrop-blur-sm">
      <div className="flex items-center gap-2">
        {Icon && (
          <Icon className="w-4 h-4 text-zinc-400 group-hover:text-zinc-600 transition-colors" />
        )}
        <span className="text-xs md:text-sm font-medium text-zinc-700 group-hover:text-zinc-900 transition-colors">
          {label}
        </span>
      </div>
      <div className="flex items-center gap-1.5">
        {trendIcon && <span className={`${trendClass}`}>{trendIcon}</span>}
        <span className="font-semibold text-zinc-800 px-3 py-1 rounded-full text-xs bg-zinc-50/90 shadow-sm group-hover:shadow transition-all">
          {value}
        </span>
      </div>
    </div>
  );
};

/**
 * Response Overview Card component
 * Displays the response metrics for ENPS data
 */
const ResponseOverviewCard = ({ stats }) => {
  return (
    <div className="bg-gray-50/80 backdrop-blur-sm p-6 rounded-xl border border-zinc-100/70 shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2.5 bg-gradient-to-br from-zinc-50 to-zinc-200 rounded-xl shadow-sm">
          <Users className="h-5 w-5 text-zinc-600" />
        </div>
        <div>
          <p className="text-sm font-medium text-zinc-600">Response Overview</p>
          <div className="flex items-baseline gap-2">
            <p className="text-2xl font-bold text-zinc-800 bg-gradient-to-r from-zinc-700 to-zinc-900 bg-clip-text text-transparent">
              {stats.answered_reviews}
            </p>
            <span className="text-xs text-zinc-500">total responses</span>
          </div>
        </div>
      </div>

      <div className="space-y-0.5 mt-4 bg-zinc-50/50 rounded-xl p-3 border border-zinc-100/70">
        <StatRow
          label="Promoters"
          value={stats.promoters}
          icon={CheckCircle}
          trend="up"
        />
        <StatRow label="Passives" value={stats.passives} icon={AlertTriangle} />
        <StatRow
          label="Detractors"
          value={stats.detractors}
          icon={XCircle}
          trend="down"
        />

        <div className="border-t border-dashed border-zinc-200 my-2 opacity-50" />

        <StatRow
          label="Recent Reviews (30 days)"
          value={stats.recent_reviews}
          icon={Activity}
        />
        <StatRow
          label="Recent Responses"
          value={stats.recent_answered}
          icon={Users}
        />
      </div>
    </div>
  );
};

export default ResponseOverviewCard;
