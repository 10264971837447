import React, { useState } from "react";
import { UserPlus, Search, X } from "lucide-react";

const UserSelection = ({
  users,
  selectedUsers,
  setSelectedUsers,
  title = "Select Team Members",
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleUserSelect = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId],
    );
  };

  const removeSelectedUser = (userId) => {
    setSelectedUsers((prev) => prev.filter((id) => id !== userId));
  };

  // Filter users based on search query
  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div className="bg-neutral-50 p-6 border-l border-neutral-100 shadow-lg rounded">
      <h2 className="text-md font-light mb-6 flex font-grotesk items-center gap-2 text-zinc-700">
        <UserPlus className="w-5 h-5 text-slate-500" />
        {title}
      </h2>

      {/* Search Input */}
      <div className="mb-4 relative">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            placeholder="Search users by name..."
            className="w-full px-4 py-3 pl-10 bg-neutral-50 border border-zinc-200 focus:border-zinc-400 transition-colors duration-200 text-zinc-700 font-grotesk"
          />
          <Search className="w-4 h-4 absolute left-3 top-3.5 text-amber-400" />
        </div>
      </div>

      {/* Selected Users Pills */}
      <div className="mb-4 flex flex-wrap gap-2">
        {selectedUsers.map((userId) => {
          const user = users.find((u) => u.id === userId);
          return user ? (
            <div
              key={`selected-${userId}`}
              className="flex items-center bg-neutral-100 px-3 py-1 rounded-full text-zinc-700 font-grotesk"
            >
              <span className="mr-1">{user.username}</span>
              <button
                onClick={() => removeSelectedUser(userId)}
                className="text-zinc-500 hover:text-zinc-700"
              >
                <X className="w-3 h-3" />
              </button>
            </div>
          ) : null;
        })}
      </div>

      {/* Users List for Selection */}
      <div className="max-h-64 overflow-y-auto border border-slate-100 rounded">
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => (
            <div
              key={user.id}
              className="flex items-center p-3 hover:bg-neutral-100 border-b border-zinc-100 last:border-b-0 transition-colors duration-200 cursor-pointer"
              onClick={() => handleUserSelect(user.id)}
            >
              <input
                type="checkbox"
                id={`user-${user.id}`}
                checked={selectedUsers.includes(user.id)}
                onChange={() => {}} // Handled by the div click
                className="w-4 h-4 border-zinc-300 text-zinc-600"
              />
              <label
                htmlFor={`user-${user.id}`}
                className="ml-3 text-zinc-700 font-grotesk cursor-pointer flex-grow"
              >
                {user.username}
              </label>
            </div>
          ))
        ) : (
          <div className="p-3 text-zinc-500 font-grotesk text-center">
            No users found
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSelection;
