import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

const ReviewAnswers = ({ businessName }) => {
  const { reviewId } = useParams();
  const [review, setReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const response = await axios.get(
          `/api/v1/businesses/${businessName}/reviews/${reviewId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          },
        );
        setReview(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching review:", err);
        setError("Failed to fetch review. Please try again.");
        setLoading(false);
      }
    };

    fetchReview();
  }, [businessName, reviewId]);

  if (loading) return <div>Loading review...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!review) return <div>Review not found</div>;

  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-2">
      <Link to="/my-reviews" className="text-indigo-400 hover:text-indigo-300">
        &larr; Back to My Reviews
      </Link>
      <h3 className="text-3xl text-right font-semibold mb-5 text-indigo-400 font-serif">
        Review Answers
      </h3>
      <div className="bg-gray-800 p-4 rounded-lg shadow-2xl">
        <h4 className="text-xl font-semibold font-serif mb-1 text-slate-300">
          {review.reviews_text}
        </h4>
        <p className="text-sm text-gray-400 mb-4">
          Answered on: {new Date(review.date_answered).toLocaleDateString()}
        </p>
        <div className="space-y-4">
          {review.questions.map((question, index) => (
            <div key={index} className="bg-gray-900 p-4 rounded-lg">
              <p className="font-medium text-gray-200 mb-2">Q: {question}</p>
              <p className="text-gray-300 ml-4">
                A: {review.answers[index] || "Not answered"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewAnswers;
