import React from "react";
import { MessageCircle, Clock, Users } from "lucide-react";

const UserResponseCard = ({
  response,
  activeComment,
  setActiveComment,
  comments,
  setComments,
  handleCommentSubmit,
  handleResponseClick,
}) => {
  const isRead = response.read_responses;
  const cardId = `${response.user_id}-${response.date_answered}`;

  const hasValidUserId = (comment) => {
    return (
      typeof comment === "object" &&
      comment !== null &&
      comment.commenter_id &&
      comment.commenter_username
    );
  };

  // Render comment supporting legacy and nested formats
  const renderComment = (comment) => {
    if (typeof comment === "string") {
      return <span className="text-gray-400">{comment}</span>;
    }
    if (Array.isArray(comment.text)) {
      return comment.text.map((reply, replyIdx) => (
        <div
          key={replyIdx}
          className={`text-xs ${replyIdx > 0 ? "ml-4 mt-2" : ""}`}
        >
          <div className="flex items-start gap-2">
            {reply.commenter_id ? (
              <span className="text-amber-500 font-light">
                {reply.commenter_username}:{" "}
              </span>
            ) : (
              <span className="text-slate-400 font-light">comment: </span>
            )}
            <span className="text-gray-400">{reply.text}</span>
          </div>
          {reply.timestamp && (
            <div className="mt-1 flex items-center gap-1 text-xs text-gray-600">
              <Clock className="w-3 h-3 text-gray-600" />
              <span>
                {new Date(reply.timestamp).toLocaleDateString()} ·{" "}
                {new Date(reply.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          )}
        </div>
      ));
    }
    return (
      <div className="text-xs text-gray-400">
        {hasValidUserId(comment) ? (
          <>
            <span className="text-amber-500 font-light">
              {comment.commenter_username}:{" "}
            </span>
            <span>
              {typeof comment.text === "string" ? comment.text : comment}
            </span>
          </>
        ) : (
          <>
            <span className="text-slate-400 font-light">comment: </span>
            <span>
              {typeof comment.text === "string" ? comment.text : comment}
            </span>
          </>
        )}
        {comment.timestamp && (
          <div className="mt-1 flex items-center gap-1 text-xs text-gray-600">
            <Clock className="w-3 h-3 text-gray-600" />
            <span>
              {new Date(comment.timestamp).toLocaleDateString()} ·{" "}
              {new Date(comment.timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={() =>
        !isRead && handleResponseClick(response.user_id, response.date_answered)
      }
      className={`p-3 rounded transition-all duration-300 cursor-pointer 
        ${
          isRead
            ? "bg-zinc-800 border-l border-gray-800 hover:border-l-2 hover:border-amber-600"
            : "bg-zinc-700 border-l-2 border-amber-600 shadow-2xl"
        } animate-fadeIn`}
    >
      <div className="space-y-3">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="text-xs text-gray-300 font-light mb-1">
              {response.username}
            </h4>
            <div className="flex items-center gap-1 text-xs text-gray-600">
              <span className="inline-block w-1 h-1 bg-amber-500 rounded-full"></span>
              <span>
                {new Date(response.date_answered).toLocaleDateString()}
              </span>
            </div>
          </div>
          {!isRead && (
            <span className="px-2 py-0.5 text-xs bg-gray-950 text-amber-500 rounded border border-gray-800">
              New
            </span>
          )}
        </div>

        <div className="space-y-2">
          {[1, 2, 3, 4].map((num) => {
            const questionKey = `question_${num}`;
            const answerKey = `answer_${num}`;
            if (response[questionKey] && response[answerKey]) {
              return (
                <div key={num} className="space-y-1">
                  <p className="text-xs text-gray-400">
                    {response[questionKey]}
                  </p>
                  <p className="text-xs text-gray-300 p-2 bg-gray-950 rounded border-l border-gray-800">
                    {response[answerKey]}
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>

        <div className="pt-2 border-t border-gray-800">
          {response.comment && (
            <div className="mb-2 space-y-2">
              {(Array.isArray(response.comment)
                ? response.comment
                : [response.comment]
              ).map((comment, idx) => (
                <div
                  key={idx}
                  className="p-2 bg-gray-950 rounded border-l border-gray-800"
                >
                  {renderComment(comment)}
                </div>
              ))}
            </div>
          )}

          {activeComment === cardId ? (
            <div className="space-y-2" onClick={(e) => e.stopPropagation()}>
              <textarea
                value={comments[cardId] || ""}
                onChange={(e) =>
                  setComments({ ...comments, [cardId]: e.target.value })
                }
                className="w-full p-2 text-xs bg-gray-950 text-gray-300 rounded border border-gray-800 focus:border-amber-600 transition-colors duration-300 resize-none"
                placeholder="Add a comment..."
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveComment(null);
                  }}
                  className="px-2 py-1 text-xs text-gray-400 bg-gray-950 rounded border border-gray-800 hover:text-gray-300 hover:bg-gray-800 transition-colors duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCommentSubmit(
                      response.user_id,
                      response.date_answered,
                    );
                  }}
                  className="px-2 py-1 text-xs bg-gray-950 text-gray-300 rounded border-l border-amber-600 hover:border-l-2 transition-all duration-300"
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveComment(cardId);
              }}
              className="flex items-center gap-1 text-xs text-gray-400 mt-2 hover:text-gray-300 transition-colors duration-300"
            >
              <MessageCircle className="w-3 h-3 text-amber-500" />
              Add Comment
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserResponseCard;
