import React, { useState, useEffect } from "react";
import axios from "axios";
import { Share2, Clock } from "lucide-react";

const SharedWithMe = ({ businessName, onReviewSelect, limit }) => {
  const [sharedReviews, setSharedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSharedReviews();
  }, [businessName]);

  const fetchSharedReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/shared-reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSharedReviews(response.data || []);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching shared reviews:", err);
      setError("Failed to fetch shared reviews");
      setLoading(false);
    }
  };

  const getReviewTitle = (reviewText) => {
    if (reviewText.includes("Review for team member:")) {
      const parts = reviewText.split("\n");
      if (parts.length > 1) {
        return parts[1].trim();
      }
    } else if (reviewText.includes("Review for")) {
      const parts = reviewText.split(":");
      if (parts.length > 1) {
        return parts.slice(1).join(":").trim();
      }
    }
    return reviewText;
  };

  if (loading)
    return (
      <div className="flex items-center justify-center py-6">
        <div className="w-5 h-5 border-t-2 border-l-2 border-amber-500 animate-spin rounded-full"></div>
      </div>
    );

  if (error)
    return (
      <div className="text-amber-400 p-3 border-l border-amber-600 bg-gray-900 rounded-md text-xs">
        {error}
      </div>
    );

  const displayedReviews = limit
    ? sharedReviews.slice(0, limit)
    : sharedReviews;

  return (
    <div className="space-y-3">
      {displayedReviews.length === 0 ? (
        <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
            <Share2 className="h-3 w-3 text-gray-700" />
          </div>
          <p>No shared reviews found</p>
        </div>
      ) : (
        displayedReviews.map((review, index) => (
          <div
            key={review.id}
            className="p-3 bg-gray-900 border-l border-gray-800 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600 animate-fadeIn"
            style={{ animationDelay: `${index * 0.05}s` }}
          >
            <h3 className="mb-2 text-xs text-gray-300 font-light">
              {getReviewTitle(review.review_text)}
            </h3>
            <div className="flex items-center justify-between">
              <p className="text-xs text-gray-500 flex items-center">
                <span className="inline-block w-1 h-1 bg-amber-500 rounded-full mr-1.5"></span>
                <span>{review.shared_by_username}</span>
                <span className="mx-1.5">·</span>
                <span>{new Date(review.shared_at).toLocaleDateString()}</span>
              </p>
              <button
                onClick={() => onReviewSelect(review)}
                className="px-2 py-1 bg-gray-800 hover:bg-gray-700 text-xs text-gray-400 rounded transition-colors duration-300 flex items-center gap-1"
              >
                <Clock className="w-3 h-3 text-amber-500" />
                View
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SharedWithMe;
