import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { Calendar, X } from "lucide-react";
import CommentsSection from "./CommentsSection";

const ResponseDetailsModal = ({
  selectedResponse,
  handleCloseModal,
  onReply,
}) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  if (!selectedResponse) return null;

  const modalContent = (
    <div
      className="fixed inset-0 z-[9999] flex items-center justify-center bg-gray-950/90 backdrop-blur-sm p-4"
      onClick={handleCloseModal}
    >
      <div
        className="rounded-lg border border-gray-800 w-full max-w-lg mx-auto overflow-y-auto max-h-[90vh] bg-zinc-900 custom-scrollbar"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal Header */}
        <div className="bg-zinc-900 border-b border-gray-800 p-3 sticky top-0 z-10">
          <div className="flex justify-between items-center">
            <h2 className="text-xs uppercase tracking-wider text-gray-300">
              Response Details
            </h2>
            <button
              onClick={handleCloseModal}
              className="text-gray-500 hover:text-amber-500 transition-colors duration-200"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
          <div className="mt-2 flex items-center gap-1.5 text-xs text-gray-500">
            <Calendar className="w-3 h-3" />
            <span>
              {new Date(selectedResponse.date_answered).toLocaleDateString()} ·{" "}
              {new Date(selectedResponse.date_answered).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        </div>

        {/* Modal Content */}
        <div className="p-4 space-y-3">
          {[1, 2, 3, 4].map(
            (num) =>
              selectedResponse[`question_${num}`] && (
                <div key={num} className="space-y-2">
                  <p className="text-xs text-gray-400">
                    Q{num}: {selectedResponse[`question_${num}`]}
                  </p>
                  <div className="p-3 bg-gray-950 border-l border-gray-800 text-gray-300 text-xs rounded">
                    {selectedResponse[`answer_${num}`] || "Not answered"}
                  </div>
                </div>
              ),
          )}

          <CommentsSection
            comments={selectedResponse.comment}
            onReply={(text, timestamp) => {
              onReply(text, timestamp);
            }}
          />
        </div>
      </div>

      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #1f2937;
          border-radius: 2px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #374151;
          border-radius: 2px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #4b5563;
        }
      `}</style>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default ResponseDetailsModal;
