import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";

import PriorityCheck from "./PriorityCheck";
import QuestionForm from "./QuestionForm";

const CurrentQuestions = ({ businessName }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [sliderValue, setSliderValue] = useState(3);
  const [sliderConfirmed, setSliderConfirmed] = useState(false);
  const [priority, setPriority] = useState("");
  const [priorityChecked, setPriorityChecked] = useState(false);
  const [submittingPriority, setSubmittingPriority] = useState(false);
  const [prioritySubmitError, setPrioritySubmitError] = useState(null);
  const [uncompletedPriorities, setUncompletedPriorities] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, [businessName]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/current-questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuestions(response.data.questions);
      setAlreadyAnswered(response.data.already_answered);
      setPriority(response.data.last_answer || "No priority set yet");
      setPriorityChecked(response.data.priority_checked);
      setUncompletedPriorities(response.data.uncompleted_priorities);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch questions. Please try again.");
      setLoading(false);
    }
  };

  const handlePrioritySubmit = async (params) => {
    setSubmittingPriority(true);
    setPrioritySubmitError(null);

    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/priority-check/complete`,
        params || {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      if (!params) {
        setPriorityChecked(true);
      }
      fetchQuestions();
    } catch (err) {
      console.error("Failed to update priority check:", err);
      setPrioritySubmitError("Failed to update priority. Please try again.");
    } finally {
      setSubmittingPriority(false);
    }
  };

  const handleInputChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSliderConfirm = () => {
    setAnswers((prev) => ({
      ...prev,
      [questions[0].id]: sliderValue.toString(),
    }));
    setSliderConfirmed(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sliderConfirmed) {
      setSubmitError("Please confirm your response for the first question.");
      return;
    }
    setSubmitting(true);
    setSubmitError(null);

    try {
      const formattedAnswers = questions.map((question, index) => ({
        question_id: question.id,
        answer: answers[question.id] || "",
        column_suffix: index === 0 ? "" : `_${index + 1}`,
      }));

      await axios.post(
        `/api/v1/businesses/${businessName}/answer-questions`,
        { answers: formattedAnswers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );

      setAlreadyAnswered(true);
    } catch (err) {
      setSubmitError("Failed to submit answers. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
    hover: { scale: 1.01, transition: { duration: 0.2 } },
  };

  const titleVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20,
      },
    },
  };

  if (loading)
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex justify-center items-center p-8"
      >
        <motion.div
          animate={{
            rotate: 360,
            transition: { repeat: Infinity, duration: 1, ease: "linear" },
          }}
          className="w-6 h-6 border-2 border-gray-500 border-t-amber-500 rounded-full"
        />
        <span className="ml-3 text-xs text-gray-500">Loading...</span>
      </motion.div>
    );

  if (error)
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, x: [20, 0] }}
        className="p-3 bg-gray-900 border-l-2 border-amber-600 rounded text-xs text-amber-400"
      >
        {error}
      </motion.div>
    );

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="bg-zinc-800 p-8 border-l border-gray-800 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600"
    >
      <motion.div className="flex items-center mb-4" variants={titleVariants}>
        <motion.h3
          className="text-3xl font-semibold uppercase tracking-widest text-gray-300"
          whileHover={{ color: "#f59e0b", originX: 0 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          vibe<span className="text-amber-500">check</span>
        </motion.h3>
        <motion.div
          animate={{
            scale: [1, 1.05, 1],
          }}
          transition={{
            scale: { repeat: Infinity, duration: 2, ease: "easeInOut" },
          }}
          className="w-1 h-1 bg-amber-500 rounded-full ml-2"
        />
      </motion.div>

      <motion.div variants={itemVariants} whileHover="hover">
        <PriorityCheck
          priority={priority}
          priorityChecked={priorityChecked}
          submittingPriority={submittingPriority}
          handlePrioritySubmit={handlePrioritySubmit}
          prioritySubmitError={prioritySubmitError}
          uncompletedPriorities={uncompletedPriorities}
        />
      </motion.div>

      <AnimatePresence mode="wait">
        {alreadyAnswered ? (
          <motion.p
            key="answered"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="p-3 mb-4 bg-zinc-950 border-l border-amber-600/30 text-xs text-gray-400 rounded"
          >
            <motion.span
              initial={{ scale: 0.8 }}
              animate={{ scale: [1, 1.1, 1] }}
              transition={{ duration: 0.5 }}
              className="inline-block text-amber-500 mr-2"
            >
              ✓
            </motion.span>
            Questions completed for today
          </motion.p>
        ) : questions.length > 0 ? (
          <motion.div
            key="questions"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, height: 0 }}
          >
            <QuestionForm
              questions={questions}
              answers={answers}
              handleInputChange={handleInputChange}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              sliderConfirmed={sliderConfirmed}
              handleSliderConfirm={handleSliderConfirm}
              handleSubmit={handleSubmit}
              submitting={submitting}
            />
          </motion.div>
        ) : (
          <motion.p
            key="no-questions"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              backgroundColor: [
                "rgba(38, 38, 38, 1)",
                "rgba(38, 38, 38, 0.8)",
                "rgba(38, 38, 38, 1)",
              ],
            }}
            transition={{
              backgroundColor: { repeat: Infinity, duration: 3 },
            }}
            className="p-3 mt-3 rounded bg-gray-950 border-l border-slate-700 text-xs text-gray-500"
          >
            No pending questions
          </motion.p>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {submitError && (
          <motion.p
            initial={{ opacity: 0, y: 5, height: 0 }}
            animate={{ opacity: 1, y: 0, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="mt-3 p-2 bg-gray-950 border-l border-amber-600 text-xs text-amber-400 rounded"
          >
            {submitError}
          </motion.p>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default CurrentQuestions;
