// QuestionSection.jsx
import React from "react";
import { useDrop } from "react-dnd";
import QuestionItem from "./QuestionItem";

const displayNames = {
  static_q_1: "Question 1",
  static_q_2: "Question 2",
  q_3: "Question 3",
  q_4: "Question 4",
};

const QuestionSection = ({
  title,
  questionId,
  questions,
  onDrop,
  bankQuestions,
  onBankQuestionUse,
}) => {
  // Find the question object that corresponds to the ID
  const question = questions.find((q) => q.id === questionId);

  const [{ isOver }, drop] = useDrop({
    accept: ["question", "bankQuestion"], // Accept both regular questions and bank questions
    drop: async (item, monitor) => {
      // If the item is from the bank, we need to create a real question first
      if (monitor.getItemType() === "bankQuestion" && item.fromBank) {
        const bankQuestion = bankQuestions.find((q) => q.id === item.id);
        if (bankQuestion) {
          const newQuestion = await onBankQuestionUse(bankQuestion);
          if (newQuestion) {
            // Now drop the newly created question
            onDrop(newQuestion.id, title);
          }
        }
      } else {
        // Regular question drop
        onDrop(item.id, title);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`bg-gray-100 p-4 rounded-md shadow-sm font-grotesk ${
        isOver ? "border-2 border-amber-500" : "border border-gray-100 text-sm"
      }`}
    >
      <h5 className="text-zinc-700 font-medium mb-3">{displayNames[title]}</h5>
      {question ? (
        <div className="relative">
          <QuestionItem
            id={question.id}
            text={question.question_text}
            isDraggable={true}
            onDeleteQuestion={() => {}} // We don't allow deletion from here
          />
          {isOver && (
            <div className="absolute inset-0 bg-amber-500/20 rounded-md flex items-center justify-center border-2 border-amber-500">
              <div className="bg-white px-3 py-1 rounded-md text-xs font-medium text-amber-800">
                Replace Question
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`bg-gray-50 border border-dashed ${
            isOver ? "border-amber-500 bg-amber-50" : "border-gray-300"
          } rounded-md p-3 text-zinc-500 text-sm text-center h-16 flex items-center justify-center`}
        >
          {isOver ? "Drop to add" : "Drop question here"}
        </div>
      )}
    </div>
  );
};

export default QuestionSection;
