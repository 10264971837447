import React, { useState } from "react";
import axios from "axios";

const CreateAdmin = ({ businessName }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setLoading(true);

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/create-admin`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      console.log("Admin invitation sent:", response.data);
      setSuccessMessage(
        "Admin invitation sent successfully. Check email for further instructions.",
      );

      // Clear form
      setEmail("");
    } catch (err) {
      console.error(
        "Error sending admin invitation:",
        err.response?.data || err.message,
      );
      if (err.response?.status === 400 && err.response?.data?.detail) {
        const errorDetail = err.response.data.detail;
        if (
          typeof errorDetail === "object" &&
          errorDetail.code === "EMAIL_ALREADY_EXISTS"
        ) {
          setError(errorDetail.message);
          setEmail(""); // Clear the email field
        } else {
          setError(
            typeof errorDetail === "string"
              ? errorDetail
              : "Failed to send admin invitation. Please try again.",
          );
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full mb-1">
      <div className="w-full max-w-full bg-slate-800 p-4 rounded-lg text-slate-200">
        <h2 className="text-xl mb-4 font-serif">Create Admin</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && (
          <p className="text-green-500 mb-4">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full rounded-md bg-slate-700 border-gray-600 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 text-white"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {loading ? "Sending Invitation..." : "Send Admin Invitation"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateAdmin;
