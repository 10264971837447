import React, { useState, useMemo } from "react";
import { Calendar, X, MessageCircle, Clock, Users } from "lucide-react";
import axios from "axios";
import ResponseDetailsModal from "./ResponseDetailsModal";

const MyResponses = ({ responses, username, businessName, setResponses }) => {
  // Filter for user's responses and limit to 8 weeks (2 months)
  const myResponses = useMemo(() => {
    // Get current date
    const today = new Date();

    // Calculate date 8 weeks ago
    const eightWeeksAgo = new Date();
    eightWeeksAgo.setDate(today.getDate() - 56); // 8 weeks * 7 days

    return responses
      .filter((response) => response.username === username)
      .filter((response) => {
        const responseDate = new Date(response.date_answered);
        return responseDate >= eightWeeksAgo;
      })
      .sort((a, b) => new Date(b.date_answered) - new Date(a.date_answered));
  }, [responses, username]);

  const [selectedResponse, setSelectedResponse] = useState(null);

  const handleCommentSubmit = async (commentText, parentTimestamp) => {
    try {
      const token = localStorage.getItem("access_token");
      const formattedTimestamp = parentTimestamp
        ? parentTimestamp.toString()
        : null;

      console.log("Sending data:", {
        user_id: selectedResponse.user_id,
        date_answered: selectedResponse.date_answered,
        comment: commentText,
        parent_timestamp: parentTimestamp,
      });

      await axios.post(
        `/api/v1/businesses/${businessName}/submit-comment`,
        {
          user_id: selectedResponse.user_id,
          date_answered: selectedResponse.date_answered,
          comment: commentText,
          parent_timestamp: formattedTimestamp,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      const updatedResponseData = await axios.get(
        `/api/v1/businesses/${businessName}/responses/${selectedResponse.user_id}/${selectedResponse.date_answered}`,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setResponses((prevResponses) =>
        prevResponses.map((response) => {
          if (
            response.user_id === selectedResponse.user_id &&
            response.date_answered === selectedResponse.date_answered
          ) {
            return updatedResponseData.data;
          }
          return response;
        }),
      );

      setSelectedResponse(updatedResponseData.data);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleCloseModal = () => {
    setSelectedResponse(null);
  };

  return (
    <div>
      {myResponses.length === 0 ? (
        <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
            <MessageCircle className="h-3 w-3 text-gray-700" />
          </div>
          <p>No responses in the last 8 weeks</p>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calendar className="w-3 h-3 text-gray-500" />
              <h3 className="text-xs text-gray-400">Last 8 Weeks</h3>
            </div>
            <div className="text-xs text-gray-600">
              {myResponses.length} response{myResponses.length !== 1 ? "s" : ""}
            </div>
          </div>

          <div className="space-y-2">
            {myResponses.map((response, index) => (
              <div
                key={index}
                onClick={() => setSelectedResponse(response)}
                className="p-3 bg-gray-900 border-l border-gray-800 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600 cursor-pointer animate-fadeIn"
                style={{ animationDelay: `${index * 0.05}s` }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <Clock className="w-3 h-3 text-amber-500" />
                    <span className="text-xs text-gray-400">
                      {new Date(response.date_answered).toLocaleDateString()} ·{" "}
                      {new Date(response.date_answered).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                  <div className="flex items-center opacity-70 group-hover:opacity-100 transition-opacity duration-300">
                    <Users className="w-3 h-3 text-slate-500" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedResponse && (
        <ResponseDetailsModal
          selectedResponse={selectedResponse}
          handleCloseModal={handleCloseModal}
          onReply={handleCommentSubmit}
        />
      )}
    </div>
  );
};

export default MyResponses;
