import React, { useState, useEffect } from "react";
import axios from "axios";

const TeamReviewPage = ({ businessName, onReviewSelect }) => {
  const [pendingReviews, setPendingReviews] = useState([]);
  const [completedReviews, setCompletedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedReview, setExpandedReview] = useState(null);

  useEffect(() => {
    fetchTeamReviews();
  }, [businessName]);

  const fetchTeamReviews = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/team-reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setPendingReviews(response.data.pending_reviews);
      setCompletedReviews(response.data.completed_reviews);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching team reviews:", err);
      setError("Failed to fetch team reviews. Please try again.");
      setLoading(false);
    }
  };

  const toggleExpandReview = (reviewId) => {
    setExpandedReview(expandedReview === reviewId ? null : reviewId);
  };

  if (loading)
    return (
      <div className="font-serif text-slate-300">Loading team reviews...</div>
    );
  if (error) return <div className="text-red-500 font-serif">{error}</div>;

  return (
    <div className="space-y-4">
      {/* Pending Reviews */}
      <div>
        <h5 className="text-sm font-serif text-cyan-400/90 mb-2">
          Pending Reviews
        </h5>
        <div className="space-y-2 max-h-[25vh] overflow-y-auto custom-scrollbar pr-2">
          {pendingReviews.map((review) => (
            <div
              key={review.id}
              className="bg-slate-900/80 p-3 rounded-md border border-slate-700/50 shadow-sm backdrop-blur-sm transition-all duration-200 cursor-pointer hover:border-cyan-500/50 group"
              onClick={() => onReviewSelect(review)}
            >
              <p className="text-sm font-serif text-slate-200 group-hover:text-slate-100">
                {review.reviews_text}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Completed Reviews */}
      <div>
        <h5 className="text-sm font-serif text-cyan-400/90 mb-2">
          Completed Reviews
        </h5>
        <div className="space-y-2 max-h-[25vh] overflow-y-auto custom-scrollbar pr-2">
          {completedReviews.map((review) => (
            <div
              key={review.id}
              className="bg-slate-900/80 p-3 rounded-md border border-slate-700/50 shadow-sm backdrop-blur-sm transition-all duration-200"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleExpandReview(review.id)}
              >
                <p className="text-sm font-serif text-slate-200 pr-2">
                  {review.reviews_text}
                </p>
                <span className="text-cyan-400/90 text-xs">
                  {expandedReview === review.id ? "▲" : "▼"}
                </span>
              </div>
              {expandedReview === review.id && (
                <div className="mt-3 space-y-2 border-t border-slate-700/50 pt-2">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(
                    (num) =>
                      review[`question_${num}`] && (
                        <div key={num} className="space-y-1">
                          <p className="text-xs text-slate-400 font-serif">
                            {review[`question_${num}`]}
                          </p>
                          <p className="text-sm text-slate-300 font-serif pl-2">
                            {review[`answer_${num}`] || "No answer provided"}
                          </p>
                        </div>
                      ),
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamReviewPage;
