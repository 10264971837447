import React, { useState } from "react";
import { MessageSquare, Share2, Users } from "lucide-react";
import TeamReviewPage from "../Teams/TeamReviewPage";
import SharedWithMe from "../Shared/SharedWithMe";
import ReviewModal from "./ReviewModal";
import AnsweredReviewsSection from "./AnsweredReviewsSection";
import ReviewsToAnswerSection from "./ReviewsToAnswerSection";
import { useReviews } from "./hooks/useReviews";

const MyReviews = ({ businessName }) => {
  const {
    answeredReviews,
    unansweredReviews,
    loading,
    error,
    users,
    handleRecordClick,
    handleSubmitAnswers,
  } = useReviews(businessName);

  const ITEMS_PER_SECTION = 3;
  const [expandedSections, setExpandedSections] = useState({
    answered: false,
    unanswered: false,
    shared: false,
    team: false,
  });

  const [selectedReview, setSelectedReview] = useState(null);
  const [answers, setAnswers] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModalType, setActiveModalType] = useState("");
  const [showUserDropdown, setShowUserDropdown] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  const handleReviewSelect = (review, modalType) => {
    setSelectedReview(review);
    setAnswers({});
    if (modalType === "sharedWithMe") {
      const prefilledAnswers = {};
      review.questions.forEach((question, index) => {
        prefilledAnswers[`question_${index + 1}`] = question;
        prefilledAnswers[`answer_${index + 1}`] = review.answers[index] || "";
      });
      setAnswers(prefilledAnswers);
    }
    setModalOpen(true);
    setActiveModalType(modalType);
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-96">
        <div className="w-8 h-8 border-t-2 border-l-2 border-amber-500 animate-spin rounded-full"></div>
      </div>
    );

  if (error)
    return (
      <div className="text-amber-400 p-3 border-l-2 border-amber-600 bg-gray-900 rounded-md text-xs">
        {error}
      </div>
    );

  return (
    <div className="min-h-screen bg-gray-900 text-gray-300 mt-24 rounded p-2">
      <div className="max-w-7xl mx-auto p-4">
        {/* Header */}
        <div className="relative mb-6">
          <div className="flex items-center p-3 bg-gray-900 border-l border-amber-500 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
            <h1 className="text-sm uppercase tracking-wider text-gray-300">
              Reviews <span className="text-amber-500">Dashboard</span>
            </h1>
            <div className="flex items-center space-x-4 ml-4">
              <MessageSquare className="w-3 h-3 text-amber-500 transition-transform duration-700 group-hover:rotate-12" />
            </div>
          </div>
        </div>

        {/* Primary Reviews Section */}
        <div className="space-y-4 mb-4">
          {/* Unanswered Reviews */}
          <div className="group">
            <div className="bg-gray-900 border-l border-gray-200 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
              <div className="p-4">
                <h2 className="flex items-center text-xs uppercase tracking-wider mb-3 text-gray-400 border-b border-gray-600 pb-2">
                  <span className="mr-2">Pending Reviews</span>
                </h2>
                <ReviewsToAnswerSection
                  unansweredReviews={
                    expandedSections.unanswered
                      ? unansweredReviews
                      : unansweredReviews.slice(0, ITEMS_PER_SECTION)
                  }
                  selectedReview={selectedReview}
                  handleReviewSelect={handleReviewSelect}
                />
                {unansweredReviews.length > ITEMS_PER_SECTION && (
                  <button
                    onClick={() =>
                      setExpandedSections((prev) => ({
                        ...prev,
                        unanswered: !prev.unanswered,
                      }))
                    }
                    className="w-full mt-3 py-1.5 border border-gray-800 bg-gray-800 hover:bg-gray-700 transition-colors duration-300 text-xs text-gray-400 rounded"
                  >
                    {expandedSections.unanswered
                      ? "Show Less"
                      : `View ${unansweredReviews.length - ITEMS_PER_SECTION} More`}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Answered Reviews */}
          <div className="group">
            <div className="bg-gray-900 border-l border-gray-200 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
              <div className="p-4">
                <h2 className="flex items-center text-xs uppercase tracking-wider mb-3 text-gray-400 border-b border-gray-600 pb-2">
                  <span className="mr-2">Completed Reviews</span>
                </h2>
                <AnsweredReviewsSection
                  answeredReviews={
                    expandedSections.answered
                      ? answeredReviews
                      : answeredReviews.slice(0, ITEMS_PER_SECTION)
                  }
                  handleReviewSelect={handleReviewSelect}
                  businessName={businessName}
                  showUserDropdown={showUserDropdown}
                  setShowUserDropdown={setShowUserDropdown}
                  selectedUser={selectedUser}
                  handleUserSelect={handleUserSelect}
                  handleRecordClick={handleRecordClick}
                  users={users}
                />
                {answeredReviews.length > ITEMS_PER_SECTION && (
                  <button
                    onClick={() =>
                      setExpandedSections((prev) => ({
                        ...prev,
                        answered: !prev.answered,
                      }))
                    }
                    className="w-full mt-3 py-1.5 border border-gray-800 bg-gray-800 hover:bg-gray-700 transition-colors duration-300 text-xs text-gray-400 rounded"
                  >
                    {expandedSections.answered
                      ? "Show Less"
                      : `View ${answeredReviews.length - ITEMS_PER_SECTION} More`}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Secondary Reviews Section - Side by Side */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Shared Reviews */}
          <div className="group h-[500px] overflow-auto custom-scrollbar">
            <div className="bg-gray-900 border-l border-gray-200 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
              <div className="p-4">
                <h2 className="flex items-center text-xs uppercase tracking-wider mb-3 text-gray-400 border-b border-gray-600 pb-2 sticky top-0 bg-gray-900 z-10">
                  <Share2 className="w-3 h-3 mr-2 text-amber-500" />
                  <span>Shared Reviews</span>
                </h2>
                <SharedWithMe
                  businessName={businessName}
                  onReviewSelect={(review) =>
                    handleReviewSelect(review, "sharedWithMe")
                  }
                  limit={
                    expandedSections.shared ? undefined : ITEMS_PER_SECTION
                  }
                />
                <button
                  onClick={() =>
                    setExpandedSections((prev) => ({
                      ...prev,
                      shared: !prev.shared,
                    }))
                  }
                  className="w-full mt-3 py-1.5 border border-gray-800 bg-gray-800 hover:bg-gray-700 transition-colors duration-300 text-xs text-gray-400 rounded"
                >
                  {expandedSections.shared ? "Show Less" : "View More"}
                </button>
              </div>
            </div>
          </div>

          {/* Team Reviews */}
          <div className="group h-[500px] overflow-auto custom-scrollbar">
            <div className="bg-gray-900 border-l border-gray-200 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600">
              <div className="p-4">
                <h2 className="flex items-center text-xs uppercase tracking-wider mb-3 text-gray-400 border-b border-gray-600 pb-2 sticky top-0 bg-gray-900 z-10">
                  <Users className="w-3 h-3 mr-2 text-amber-500" />
                  <span>Team Reviews</span>
                </h2>
                <TeamReviewPage
                  businessName={businessName}
                  onReviewSelect={(review) =>
                    handleReviewSelect(review, "teamReviews")
                  }
                  limit={expandedSections.team ? undefined : ITEMS_PER_SECTION}
                />
                <button
                  onClick={() =>
                    setExpandedSections((prev) => ({
                      ...prev,
                      team: !prev.team,
                    }))
                  }
                  className="w-full mt-3 py-1.5 border border-gray-800 bg-gray-800 hover:bg-gray-700 transition-colors duration-300 text-xs text-gray-400 rounded"
                >
                  {expandedSections.team ? "Show Less" : "View More"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <ReviewModal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedReview(null);
            setActiveModalType("");
          }}
          selectedReview={selectedReview}
          activeModalType={activeModalType}
          answers={answers}
          handleAnswerChange={(questionKey, value) =>
            setAnswers({ ...answers, [questionKey]: value })
          }
          handleSubmitAnswers={async () => {
            const success = await handleSubmitAnswers(
              selectedReview.id,
              answers,
            );
            if (success) {
              setSelectedReview(null);
              setAnswers({});
              setModalOpen(false);
            }
          }}
        />
      </div>

      {/* Custom Scrollbar Styling */}
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(4px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 0.4s ease-out forwards;
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #1f2937;
          border-radius: 2px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #374151;
          border-radius: 2px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #4b5563;
        }
      `}</style>
    </div>
  );
};

export default MyReviews;
