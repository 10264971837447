import React from "react";
import { CheckCircle } from "lucide-react";

const UpdateStatusMessage = ({ updateStatus, setUpdateStatus, formatDate }) => {
  if (!updateStatus.success) return null;

  return (
    <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <CheckCircle size={18} className="mr-2" />
          <span>{updateStatus.message}</span>
        </div>
        {updateStatus.details && (
          <button
            onClick={() =>
              setUpdateStatus((prev) => ({
                ...prev,
                showDetails: !prev.showDetails,
              }))
            }
            className="text-green-700 hover:text-green-900 text-sm underline"
          >
            {updateStatus.showDetails ? "Hide Details" : "Show Details"}
          </button>
        )}
      </div>

      {updateStatus.showDetails && updateStatus.details && (
        <div className="mt-3 text-sm bg-white p-3 rounded-lg border border-green-100">
          <p>
            <span className="font-medium">Updated at:</span>{" "}
            {formatDate(updateStatus.details.updated_at)}
          </p>
          <p>
            <span className="font-medium">Users processed:</span>{" "}
            {updateStatus.details.total_users}
          </p>
          {updateStatus.details.entries_updated?.length > 0 && (
            <div className="mt-2">
              <p className="font-medium">Top updates:</p>
              <ul className="mt-1 space-y-1">
                {updateStatus.details.entries_updated
                  .slice(0, 5)
                  .map((entry, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-amber-500 mr-1">•</span>
                      {entry.username}: Rank #{entry.rank} (
                      {entry.total_activity} activities)
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UpdateStatusMessage;
