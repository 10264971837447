import React, { useState, useEffect } from "react";
import axios from "axios";

const PushedReviewsUsers = ({ businessName }) => {
  const [activeReviews, setActiveReviews] = useState([]);
  const [expiredReviews, setExpiredReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  // Replace showAllActive with a state to track how many active reviews to show
  const [activeReviewsToShow, setActiveReviewsToShow] = useState(4); // Initialize to show 4 reviews

  useEffect(() => {
    fetchPushedReviews();
  }, [businessName]);

  const fetchPushedReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/pushed-reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // **Sort active reviews from newest to oldest based on expiration_date**
      const sortedActiveReviews = (response.data.active_reviews || []).sort(
        (a, b) => new Date(b.expiration_date) - new Date(a.expiration_date),
      );
      setActiveReviews(sortedActiveReviews);

      // **Sort expired reviews from newest to oldest as well (optional)**
      const sortedExpiredReviews = (response.data.expired_reviews || []).sort(
        (a, b) => new Date(b.expiration_date) - new Date(a.expiration_date),
      );
      setExpiredReviews(sortedExpiredReviews);

      setError(null);
      setActiveReviewsToShow(4); // Reset the number of reviews to show when fetching new data
    } catch (err) {
      console.error("Error fetching pushed reviews:", err);
      setError("Failed to fetch pushed reviews. Please try again.");
    }
  };

  const handleReviewClick = async (review) => {
    setSelectedReview(review);
    setAnswers({});
    setError(null);
    setSuccessMessage("");

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/review-answers/${review.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (response.data.answers) {
        setAnswers(response.data.answers);
      }
    } catch (err) {
      console.error("Error fetching review answers:", err);
      setError("Failed to fetch review answers. Please try again.");
    }
  };

  const handleAnswerChange = (questionKey, value) => {
    setAnswers({ ...answers, [questionKey]: value });
  };

  const handleSubmitAnswers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.post(
        `/api/v1/businesses/${businessName}/submit-review-answer`,
        {
          review_id: selectedReview.id,
          answers: answers,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSuccessMessage("Answers submitted successfully!");
      fetchPushedReviews();
    } catch (err) {
      console.error("Error submitting answers:", err);
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data.detail.includes("expired")
      ) {
        setError("This review has expired and can no longer be answered.");
      } else {
        setError("Failed to submit answers. Please try again.");
      }
    }
  };

  // **Determine how many active reviews to display based on activeReviewsToShow**
  const displayedActiveReviews = activeReviews.slice(0, activeReviewsToShow);

  // **Determine if there are more reviews to show**
  const hasMoreActiveReviews = activeReviewsToShow < activeReviews.length;

  return (
    <div className="bg-slate-800 font-serif tracking-wide rounded-lg shadow-lg p-6">
      {/* Title with light horizontal line */}
      <h3 className="text-3xl font-semibold mb-3 text-indigo-500 text-right border-b border-slate-400 pb-2">
        Answer Reviews
      </h3>

      <div className="grid grid-cols-2 gap-4">
        {/* First column: Active and Expired reviews */}
        <div className="space-y-4 overflow-y-auto max-h-[calc(100vh-200px)]">
          <div>
            <h4 className="text-2xl font-semibold mb-4">Active Reviews</h4>
            {activeReviews.length === 0 ? (
              <p>No active reviews available.</p>
            ) : (
              <>
                {displayedActiveReviews.map((review) => (
                  <div
                    key={review.id}
                    className="bg-gray-700 p-2 rounded-lg cursor-pointer hover:bg-gray-600 mb-2"
                    onClick={() => handleReviewClick(review)}
                  >
                    <h4 className="font-semibold mb-1">
                      {review.reviews_text}
                    </h4>
                    <p className="text-xs text-gray-400">
                      expires:{" "}
                      {new Date(review.expiration_date).toLocaleString()}
                    </p>
                  </div>
                ))}
                {hasMoreActiveReviews && (
                  <button
                    className="mt-2 px-3 py-2 bg-indigo-800 text-white rounded hover:bg-blue-700"
                    onClick={() => setActiveReviewsToShow((prev) => prev + 4)}
                  >
                    Show More (
                    {activeReviewsToShow + 4 > activeReviews.length
                      ? activeReviews.length - activeReviewsToShow
                      : "4"}{" "}
                    more)
                  </button>
                )}
              </>
            )}
          </div>
          <div>
            <h4 className="text-2xl font-semibold mb-2">Expired Reviews</h4>
            {expiredReviews.length === 0 ? (
              <p>No expired reviews.</p>
            ) : (
              expiredReviews.map((review) => (
                <div
                  key={review.id}
                  className="bg-gray-700 p-4 rounded-lg opacity-50 mb-2"
                >
                  <h4 className="font-semibold mb-2">{review.reviews_text}</h4>
                  <p className="text-sm text-gray-400">
                    Expired: {new Date(review.expiration_date).toLocaleString()}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Second column with vertical line */}
        <div className="border-l border-slate-400 pl-4">
          {selectedReview && (
            <div className="bg-gray-700 p-4 rounded-lg">
              <h4 className="font-semibold mb-4">
                {selectedReview.reviews_text}
              </h4>
              <div className="space-y-4">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                  <div key={num} className="space-y-2">
                    <p>
                      <strong>Question {num}:</strong>{" "}
                      {selectedReview[`question_${num}`]}
                    </p>
                    <textarea
                      className="w-full p-2 bg-gray-600 rounded"
                      value={answers[`answer_${num}`] || ""}
                      onChange={(e) =>
                        handleAnswerChange(`answer_${num}`, e.target.value)
                      }
                      placeholder="Your answer..."
                      readOnly={
                        new Date(selectedReview.expiration_date) <= new Date()
                      }
                    />
                  </div>
                ))}
                {new Date(selectedReview.expiration_date) > new Date() && (
                  <button
                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    onClick={handleSubmitAnswers}
                  >
                    Submit Answers
                  </button>
                )}
                {new Date(selectedReview.expiration_date) <= new Date() && (
                  <p className="text-red-400 mt-4">
                    This review has expired and can no longer be answered.
                  </p>
                )}
              </div>
            </div>
          )}
          {successMessage && (
            <div className="mt-4 p-2 bg-green-600 text-white rounded">
              {successMessage}
            </div>
          )}
          {error && (
            <div className="mt-4 p-2 bg-red-600 text-white rounded">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PushedReviewsUsers;
