import React from "react";

const ReviewCard = ({ review, onReviewClick, onDeleteReview }) => {
  return (
    <div className="bg-gray-200 rounded-md border border-zinc-200 shadow-md overflow-hidden">
      <div
        className="p-2 cursor-pointer transition-colors hover:bg-gray-50"
        onClick={() => onReviewClick(review)}
      >
        <h4 className="text-zinc-800 font-medium mb-2 font-grotesk tracking-wide">
          Review {review.id}
        </h4>
        <p className="text-sm text-zinc-600 line-clamp-2 font-grotesk">
          {review.reviews_text.substring(0, 100)}...
        </p>
      </div>
      <div className="bg-gray-50 px-4 py-2 border-t border-zinc-100 flex justify-end">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDeleteReview(review.id);
          }}
          className="px-3 py-1 font-grotesk bg-gray-800 border border-zinc-200 text-zinc-100 text-xs rounded hover:bg-zinc-100 transition-colors"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ReviewCard;
