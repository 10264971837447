import React from "react";
import {
  Clock,
  Link as LinkIcon,
  MessageCircle,
  Edit,
  Save,
  X,
} from "lucide-react";

const MeetingCard = ({
  meeting,
  isAttendant,
  notes,
  editingNotes,
  onNoteChange,
  onUpdateNotes,
  onEditingNotesChange,
}) => {
  const meetingId = meeting.id;
  const isEditing = editingNotes[meetingId] || false;
  const localMeetingDate = new Date(meeting.meeting_date);

  return (
    <div className="bg-neutral-300 rounded-lg hover:border-cyan-500/30 transition-all duration-300 shadow-2xl">
      <div className="p-4 border-b border-neutral-400">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <Clock className="w-4 h-4 text-neutral-800" strokeWidth={1.5} />
            <span className="text-neutral-800 text-xs">
              {localMeetingDate.toLocaleString()}
            </span>
          </div>
          <a
            href={meeting.meeting_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 text-[#4f5b32] hover:text-cyan-500 transition-colors duration-200"
          >
            <LinkIcon className="w-4 h-4" strokeWidth={1.5} />
            <span className="text-xs tracking-wide">Join</span>
          </a>
        </div>
      </div>

      <div className="p-4 space-y-4">
        <div>
          <div className="flex items-center space-x-3 mb-3">
            <div className="h-[1px] w-4 bg-gradient-to-r from-neutral-300/40 to-transparent" />
            <span className="text-neutral-800 text-xs uppercase tracking-wider">
              {isAttendant ? "My Notes" : "My Notes"}
            </span>
          </div>
          {isEditing ? (
            <div className="space-y-3">
              <textarea
                value={notes[meetingId]}
                onChange={(e) => onNoteChange(meetingId, e.target.value)}
                className="w-full p-3 bg-[#0A0A0B] text-[#9A9A9D] rounded-lg border border-[#1F1F23] focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20 text-xs leading-relaxed resize-none"
                rows="4"
                placeholder="Enter your notes..."
              />
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => onUpdateNotes(meetingId, isAttendant)}
                  className="px-3 py-1.5 bg-slate-950 text-neutral-200 rounded-lg flex items-center space-x-2 text-xs transition-colors duration-200 hover:bg-cyan-500/20"
                >
                  <Save className="w-3.5 h-3.5" strokeWidth={1.5} />
                  <span>Save</span>
                </button>
                <button
                  onClick={() => onEditingNotesChange(meetingId, false)}
                  className="px-3 py-1.5 bg-[#1F1F23] text-slate-300 rounded-lg flex items-center space-x-2 text-xs transition-colors duration-200 hover:bg-[#2F2F33]"
                >
                  <X className="w-3.5 h-3.5" strokeWidth={1.5} />
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              <p className="p-3 bg-gray-800 text-gray-100 rounded-lg border border-[#1F1F23] text-xs leading-relaxed min-h-[60px]">
                {isAttendant
                  ? meeting.attendant_notes || "No notes yet"
                  : meeting.user_notes || "No notes yet"}
              </p>
              <button
                onClick={() => {
                  onNoteChange(
                    meetingId,
                    (isAttendant
                      ? meeting.attendant_notes
                      : meeting.user_notes) || "",
                  );
                  onEditingNotesChange(meetingId, true);
                }}
                className="px-3 py-1.5 bg-slate-800 text-neutral-100 rounded-lg flex items-center space-x-2 text-xs transition-colors duration-200 hover:bg-[#2F2F33]"
              >
                <Edit
                  className="w-3.5 h-3.5 text-neutral-100"
                  strokeWidth={1.5}
                />
                <span>Edit</span>
              </button>
            </div>
          )}
        </div>

        <div>
          <div className="flex items-center space-x-3 mb-3">
            <div className="h-[1px] w-4 bg-gradient-to-r from-cyan-500/40 to-transparent" />
            <span className="text-neutral-700 text-xs uppercase tracking-wider">
              {isAttendant ? "Invitee Notes" : "Attendant Notes"}
            </span>
          </div>
          <p className="p-3 bg-gray-800 text-[#9A9A9D] rounded-lg text-xs leading-relaxed min-h-[60px]">
            {isAttendant
              ? meeting.user_notes || "No notes yet"
              : meeting.attendant_notes || "No notes yet"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;
