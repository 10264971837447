import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const { token } = useParams();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/v1/verify-invite/${token}`);
        if (response.data.tenant_id) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Invalid token:", error);
        setError("Invalid or expired invitation token");
        setIsLoading(false);
      }
    };
    if (token) {
      verifyToken();
    } else {
      setError("No invitation token provided");
      setIsLoading(false);
    }
  }, [token]);

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;
    return regex.test(password);
  };

  const checkPasswordStrength = (password) => {
    if (password.length < 8) {
      setPasswordStrength("Too short");
    } else if (password.length < 12) {
      setPasswordStrength("Weak");
    } else if (password.length < 16) {
      setPasswordStrength("Medium");
    } else {
      setPasswordStrength("Strong");
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (value.includes(" ")) {
      setUsernameError("Username cannot contain spaces");
    } else {
      setUsernameError("");
    }
    setUsername(value.replace(/\s/g, "")); // Remove any spaces automatically
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value.replace(/\s/g, "")); // Remove all whitespace
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value.replace(/\s/g, ""); // Remove all whitespace
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value.replace(/\s/g, ""); // Remove all whitespace
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (usernameError) {
      setError("Please fix username errors before submitting");
      return;
    }

    if (!passwordsMatch) {
      setError("Passwords do not match");
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long, contain at least one number, one uppercase letter, and no spaces",
      );
      return;
    }

    try {
      const normalizedUsername = username.toLowerCase();
      const normalizedEmail = email.toLowerCase();

      await axios.post(
        `/api/v1/signup?username=${normalizedUsername}&email=${normalizedEmail}&password=${password}&token=${token}`,
      );
      window.location.href = "https://itsmatcha.net";
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  if (isLoading) {
    return <div>Verifying invitation...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-slate-800 justify-center items-center">
      <header className="bg-slate-950 w-full py-3">
        <div className="flex items-center justify-center">
          <h1 className="text-4xl font-bold text-teal-600 font-serif">
            matcha
          </h1>
        </div>
      </header>

      <main className="w-full flex-1 flex justify-center items-center">
        <div className="mt-1 w-full max-w-xl bg-slate-900 font-serif shadow-2xl p-5 rounded-lg">
          <h2 className="text-2xl mb-10 mt-5 font-semibold font-serif text-slate-200">
            Create Your Account
          </h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4 text-slate-200">
            <div className="">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={handleUsernameChange}
                required
                className="mt-1 p-1 block w-full bg-indigo-950 rounded-md border-gray-300 shadow-xl focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              {usernameError && (
                <p className="text-sm mt-1 text-red-500">{usernameError}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
                className="mt-1 block p-1 w-full rounded-md bg-indigo-950 border-gray-300 shadow-xl focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
                className="mt-1 p-1 block w-full bg-indigo-950 rounded-md border-gray-300 shadow-xl focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              {passwordStrength && (
                <p
                  className={`text-sm mt-1 ${
                    passwordStrength === "Strong"
                      ? "text-green-500"
                      : passwordStrength === "Medium"
                        ? "text-yellow-500"
                        : "text-red-500"
                  }`}
                >
                  Password strength: {passwordStrength}
                </p>
              )}
              <p className="text-xs text-gray-400 mt-2">
                Password must be at least 8 characters long, contain at least
                one number, one uppercase letter, and no spaces
              </p>
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                className="mt-1 p-1 block w-full bg-indigo-950 rounded-md border-gray-300 shadow-xl focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              {!passwordsMatch && confirmPassword && (
                <p className="text-sm mt-1 text-red-500">
                  Passwords do not match
                </p>
              )}
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              sign-up
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default SignUp;
