import React from "react";
import { Link } from "react-router-dom";
import {
  Users,
  HelpCircle,
  Star,
  BarChart,
  Shield,
  UserCircle,
  UserPlus,
  Home,
  TrendingUp,
  MessageSquare,
  SettingsIcon,
  Award,
  Trophy,
} from "lucide-react";

const Sidebar = ({
  activeSection,
  setActiveSection,
  isAdmin,
  isOpen,
  toggleSidebar,
  user,
}) => {
  if (!user) return null;

  const hasAnalyticsAccess = user.is_admin || user.is_verified;

  const sections = [
    { name: "Dashboard", icon: Home, path: "/dashboard" },
    { name: "Vibe Checks", icon: MessageSquare, path: "/vibe-checks" },
    { name: "Reviews", icon: BarChart, path: "/my-reviews" },
    { name: "One-on-One", icon: UserPlus, path: "/one-on-one" },
    { name: "Leader Board", icon: Award, path: "/leader-board" },
    {
      name: "Quarterly Nominations",
      icon: Trophy,
      path: "/quarterly-nominations",
    },
  ];

  if (hasAnalyticsAccess) {
    sections.push({
      name: "Analytics",
      icon: BarChart,
      path: "/analytics",
    });
  }

  if (isAdmin) {
    sections.push(
      { name: "eNPS", icon: TrendingUp, path: "/enps" },
      { name: "Users", icon: UserCircle, path: "/users" },
      { name: "Create Reviews", icon: Star, path: "/create-reviews" },
      { name: "Questions", icon: HelpCircle, path: "/questions" },
      { name: "User Permissions", icon: Shield, path: "/user-permissions" },
      { name: "Settings", icon: SettingsIcon, path: "/settings" },
    );
  }

  return (
    <div
      className={`mt-12 bg-zinc-950 z-50 text-xs font-light tracking-wider text-gray-400 py-4 w-60 md:w-54 fixed inset-y-0 left-0 transform transition-transform duration-200 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:relative md:translate-x-0`}
    >
      <div className="h-full flex flex-col py-6 px-2">
        {/* Navigation links */}
        <div className="flex-grow space-y-1">
          {sections.map((section) => (
            <Link
              key={section.name}
              to={section.path}
              className={`flex items-center space-x-2 py-3 px-3 rounded transition-all duration-300 ${
                activeSection === section.name
                  ? "bg-zinc-800 border-l-2 border-amber-500 text-gray-100 uppercase"
                  : "hover:bg-gray-900 hover:text-gray-300 border-l border-transparent hover:border-gray-800"
              }`}
              onClick={() => {
                setActiveSection(section.name);
                toggleSidebar();
              }}
            >
              <section.icon
                className={`h-3.5 w-3.5 ${activeSection === section.name ? "text-amber-500" : "text-gray-500"}`}
              />
              <span className="text-xs">{section.name}</span>
            </Link>
          ))}
        </div>

        {/* Image at bottom */}
        <div className="mt-4 px-2 opacity-30 hover:opacity-50 transition-opacity duration-300">
          <img
            src="/8bit-m-2.svg"
            alt="chubs character"
            className="w-full h-20 object-contain invert"
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
