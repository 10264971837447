import React, { useState, useEffect, useRef } from "react";

const RetroAnalyticsWelcome = ({ businessName, onComplete }) => {
  const [animationStage, setAnimationStage] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [playerPosition, setPlayerPosition] = useState(50);
  const [invaders, setInvaders] = useState([]);
  const [audioStarted, setAudioStarted] = useState(false);
  // Audio-related states
  const [audioAvailable, setAudioAvailable] = useState(false);
  const audioContextRef = useRef(null);
  const oscillatorRef = useRef(null);

  // Create invaders
  useEffect(() => {
    const newInvaders = [];
    for (let i = 0; i < 18; i++) {
      newInvaders.push({
        id: i,
        x: (i % 6) * 15 + 10,
        y: Math.floor(i / 6) * 10 + 10,
        type: i % 3,
        visible: true,
      });
    }
    setInvaders(newInvaders);
  }, []);

  // Check if Web Audio API is available (simpler alternative to Tone.js)
  useEffect(() => {
    try {
      if (
        typeof AudioContext !== "undefined" ||
        typeof webkitAudioContext !== "undefined"
      ) {
        setAudioAvailable(true);
      }
    } catch (e) {
      console.log("Web Audio API not supported in this browser");
    }
  }, []);

  // Simple sound effect function using Web Audio API
  const playSound = (frequency, duration = 0.1, type = "square") => {
    if (!audioAvailable || !audioStarted) return;

    try {
      if (!audioContextRef.current) {
        const AudioCtx = window.AudioContext || window.webkitAudioContext;
        audioContextRef.current = new AudioCtx();
      }

      const ctx = audioContextRef.current;
      const oscillator = ctx.createOscillator();
      const gainNode = ctx.createGain();

      oscillator.type = type;
      oscillator.frequency.value = frequency;

      gainNode.gain.value = 0.05;

      oscillator.connect(gainNode);
      gainNode.connect(ctx.destination);

      oscillator.start();

      setTimeout(() => {
        oscillator.stop();
      }, duration * 1000);
    } catch (e) {
      console.log("Error playing sound:", e);
    }
  };

  // Start audio when user interacts
  const startAudio = () => {
    if (!audioStarted && audioAvailable) {
      try {
        if (!audioContextRef.current) {
          const AudioCtx = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioCtx();
        }

        // Play startup sound
        playSound(440, 0.1);
        setTimeout(() => playSound(880, 0.2), 100);

        setAudioStarted(true);
      } catch (e) {
        console.log("Error starting audio:", e);
      }
    }
  };

  // Animation sequence
  useEffect(() => {
    if (!showContent) return;

    const timer1 = setTimeout(() => setAnimationStage(1), 500);
    const timer2 = setTimeout(() => setAnimationStage(2), 1000);
    const timer3 = setTimeout(() => setAnimationStage(3), 1500);

    // Invader movement
    let direction = 1;
    let step = 0;

    const moveInvaders = setInterval(() => {
      setInvaders((prev) => {
        let needsToChangeDirection = false;
        prev.forEach((invader) => {
          if (
            (invader.x >= 85 && direction > 0) ||
            (invader.x <= 5 && direction < 0)
          ) {
            needsToChangeDirection = true;
          }
        });
        return prev.map((invader) => ({
          ...invader,
          x: invader.x + (needsToChangeDirection ? 0 : direction * 2),
          y: invader.y + (needsToChangeDirection ? 2 : 0),
          frame: step % 2,
        }));
      });

      // Simple space invader sound
      if (step % 4 === 0 && audioStarted && audioAvailable) {
        const frequencies = [130.81, 65.41, 98, 82.41]; // C3, C2, G2, E2
        const frequency =
          frequencies[Math.floor(Math.random() * frequencies.length)];
        playSound(frequency, 0.05);
      }

      step++;
      if (step % 16 === 0) {
        direction *= -1;
      }
    }, 500);

    // Player movement
    const movePlayer = () => {
      setPlayerPosition((prev) => {
        const newPos = prev + (Math.random() > 0.5 ? 1 : -1) * 3;
        return Math.max(5, Math.min(95, newPos));
      });
    };

    const playerInterval = setInterval(movePlayer, 1000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearInterval(moveInvaders);
      clearInterval(playerInterval);
    };
  }, [showContent, audioStarted, audioAvailable]);

  useEffect(() => {
    // Start the animation sequence
    const timer = setTimeout(() => setShowContent(true), 300);
    return () => {
      clearTimeout(timer);
    };
  }, [onComplete]);

  // Custom 8-bit audio sequence (simpler than Tone.js)
  useEffect(() => {
    if (!audioStarted || !audioAvailable) return;

    // 8-bit style background music sequence
    const notes = [
      { freq: 261.63, duration: 0.2 }, // C4
      { freq: 329.63, duration: 0.2 }, // E4
      { freq: 392.0, duration: 0.2 }, // G4
      { freq: 440.0, duration: 0.2 }, // A4
      { freq: 392.0, duration: 0.2 }, // G4
      { freq: 329.63, duration: 0.2 }, // E4
    ];

    let noteIndex = 0;
    const playSequence = () => {
      const note = notes[noteIndex];
      playSound(note.freq, note.duration);

      noteIndex = (noteIndex + 1) % notes.length;
    };

    const sequenceInterval = setInterval(playSequence, 400);

    return () => {
      clearInterval(sequenceInterval);

      // Clean up audio context if it exists
      if (
        audioContextRef.current &&
        typeof audioContextRef.current.close === "function"
      ) {
        audioContextRef.current.close();
      }
    };
  }, [audioStarted, audioAvailable]);

  // Pixel art invader renderer
  const renderInvader = (type, frame) => {
    const invaders = [
      // Invader type 1
      ["  ■■  ", " ■■■■ ", "■■■■■■", "■ ■■ ■", "■■■■■■"],
      // Invader type 2
      [" ■  ■ ", "  ■■  ", " ■■■■ ", "■ ■■ ■", "■    ■"],
      // Invader type 3
      ["  ■■  ", "■■■■■■", "■■  ■■", "  ■■  ", " ■  ■ "],
    ];

    return (
      <div className="flex flex-col items-center">
        {invaders[type].map((row, i) => (
          <div key={i} className="flex">
            {row.split("").map((cell, j) => (
              <div
                key={j}
                className={`h-1 w-1 ${
                  cell === "■" ? "bg-lime-400" : "bg-transparent"
                }`}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  // Player ship hit animation
  const [isHit, setIsHit] = useState(false);
  useEffect(() => {
    const hitInterval = setInterval(() => {
      if (Math.random() > 0.9) {
        setIsHit(true);
        if (audioStarted && audioAvailable) {
          playSound(100, 0.2, "sawtooth");
        }
        setTimeout(() => setIsHit(false), 300);
      }
    }, 2000);

    return () => clearInterval(hitInterval);
  }, [audioStarted, audioAvailable]);

  return (
    <div
      className="min-h-screen w-full flex items-center justify-center bg-black overflow-hidden"
      onClick={startAudio}
    >
      {/* CRT scan effect */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-teal-900/10 to-transparent opacity-30 animate-scan"></div>

      {/* Pixel grid background */}
      <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>

      <div
        className={`transition-all duration-500 transform z-10 w-full px-8 ${
          showContent ? "scale-100 opacity-100" : "scale-90 opacity-0"
        }`}
      >
        <div className="border-4 border-lime-500 p-8 w-full mx-auto max-w-6xl text-center relative overflow-hidden bg-black">
          {/* Game title */}
          <div className="absolute top-0 left-0 w-full text-center">
            <div className="inline-block bg-black px-6 py-1 -mt-4 border-2 border-lime-500">
              <span className="text-lime-500 font-pixel text-xl">
                ANALYTICS INVADERS
              </span>
            </div>
          </div>

          {/* Space invaders game board */}
          <div className="relative h-64 mb-8 border-b-4 border-lime-500 mt-6">
            {/* Invaders */}
            {invaders.map((invader) => (
              <div
                key={invader.id}
                className="absolute transform scale-150"
                style={{
                  left: `${invader.x}%`,
                  top: `${invader.y}%`,
                }}
              >
                {renderInvader(invader.type, invader.frame)}
              </div>
            ))}

            {/* Player ship */}
            <div
              className={`absolute bottom-4 transition-colors ${isHit ? "bg-red-500" : ""}`}
              style={{
                left: `${playerPosition}%`,
                transform: "translateX(-50%)",
              }}
            >
              <div
                className={`w-12 h-6 ${isHit ? "bg-red-500" : "bg-lime-500"}`}
              ></div>
              <div
                className={`w-4 h-4 ${isHit ? "bg-red-500" : "bg-lime-500"} mx-auto`}
              ></div>
            </div>

            {/* Score display */}
            <div className="absolute top-2 left-4 text-lime-500 font-pixel">
              SCORE: 0000{animationStage * 250}
            </div>

            {/* Lives display */}
            <div className="absolute top-2 right-4 text-lime-500 font-pixel">
              LIVES: 03
            </div>

            {/* Random lasers */}
            {Array.from({ length: 3 }).map((_, i) => {
              const randomLeft = 10 + Math.random() * 80;
              const randomHeight = 20 + Math.random() * 40;
              return (
                <div
                  key={i}
                  className="absolute w-1 bg-lime-400 animate-laser"
                  style={{
                    left: `${randomLeft}%`,
                    height: `${randomHeight}px`,
                    top: `${30 + i * 20}%`,
                    animationDelay: `${i * 0.3}s`,
                  }}
                ></div>
              );
            })}
          </div>

          {/* Content that animates in */}
          <h1
            className={`text-5xl font-pixel text-lime-500 mb-8 transition-all duration-500 ${
              animationStage >= 1 ? "opacity-100" : "opacity-0"
            }`}
          >
            ANALYTICS PORTAL
          </h1>

          <p
            className={`text-lime-300 mb-10 text-2xl font-pixel mx-auto leading-relaxed transition-all duration-500 ${
              animationStage >= 2 ? "opacity-100" : "opacity-0"
            }`}
          >
            MISSION: TRACK PERFORMANCE AND DEFEAT BAD-VIBES
          </p>

          <div
            className={`flex justify-center transition-all duration-500 ${
              animationStage >= 3 ? "opacity-100" : "opacity-0"
            }`}
          >
            <button
              onClick={() => {
                if (audioStarted && audioAvailable) {
                  playSound(660, 0.2);
                  setTimeout(() => playSound(880, 0.3), 200);
                }
                if (onComplete) onComplete();
              }}
              className="bg-lime-500 text-black py-3 px-8 font-pixel text-xl border-4 border-lime-700 hover:bg-lime-400 transition-all"
            >
              PRESS START
            </button>
          </div>

          <div className="mt-8 text-lime-500 font-pixel text-sm animate-blink">
            INSERT COIN TO CONTINUE
          </div>

          {audioAvailable && !audioStarted && (
            <div className="mt-4 text-yellow-400 font-pixel animate-blink">
              CLICK ANYWHERE TO ENABLE SOUND
            </div>
          )}

          {/* High score table */}
          <div className="mt-10 border-2 border-lime-500 p-4 mx-auto max-w-xs">
            <div className="text-lime-500 font-pixel text-sm mb-2">
              HIGH SCORES
            </div>
            <div className="text-lime-400 font-pixel text-xs flex justify-between">
              <span>DATA-MSTR</span>
              <span>15000</span>
            </div>
            <div className="text-lime-400 font-pixel text-xs flex justify-between">
              <span>ANALYST-X</span>
              <span>12750</span>
            </div>
            <div className="text-lime-400 font-pixel text-xs flex justify-between">
              <span>DASH-LORD</span>
              <span>10500</span>
            </div>
          </div>
        </div>
      </div>

      {/* Add keyframes for CRT scan and other effects */}
      <style jsx>{`
        @keyframes scan {
          0% {
            transform: translateY(-100%);
          }
          100% {
            transform: translateY(100%);
          }
        }
        @keyframes blink {
          0%,
          49% {
            opacity: 1;
          }
          50%,
          100% {
            opacity: 0;
          }
        }
        @keyframes laser {
          0% {
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            transform: translateY(300%);
            opacity: 0;
          }
        }
        .animate-scan {
          animation: scan 2s linear infinite;
        }
        .animate-blink {
          animation: blink 1s step-end infinite;
        }
        .animate-laser {
          animation: laser 1.5s linear infinite;
        }
        .font-pixel {
          font-family: "Press Start 2P", monospace;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        .bg-grid-pattern {
          background-image: linear-gradient(
              to right,
              rgba(30, 255, 0, 0.1) 1px,
              transparent 1px
            ),
            linear-gradient(
              to bottom,
              rgba(30, 255, 0, 0.1) 1px,
              transparent 1px
            );
          background-size: 8px 8px;
        }
      `}</style>
    </div>
  );
};

export default RetroAnalyticsWelcome;
