import React from "react";
import { MessageSquare, Clock } from "lucide-react";

const ReviewsToAnswerSection = ({
  unansweredReviews,
  selectedReview,
  handleReviewSelect,
}) => {
  return (
    <div className="bg-slate-800/60 rounded-lg p-4 border border-slate-700/50 shadow-lg backdrop-blur-sm">
      <h2 className="text-base sm:text-lg font-serif mb-3 text-slate-200 flex items-center gap-2">
        <MessageSquare className="w-4 h-4 text-teal-400" />
        Reviews to Answer
      </h2>

      {unansweredReviews.length === 0 ? (
        <p className="text-gray-400 text-center py-3 font-serif">
          No reviews to answer at the moment.
        </p>
      ) : (
        <div className="space-y-2 max-h-[30vh] overflow-y-auto custom-scrollbar pr-2">
          {unansweredReviews.map((review) => (
            <div
              key={review.id}
              className={`bg-slate-900/80 p-3 rounded-md border transition-all duration-200 cursor-pointer hover:shadow-lg
                ${
                  selectedReview?.id === review.id
                    ? "border-cyan-500/70"
                    : "border-slate-700/50 hover:border-cyan-500/50"
                }`}
              onClick={() => handleReviewSelect(review, "reviewsToAnswer")}
            >
              <div className="space-y-1.5">
                <h3 className="text-sm sm:text-base font-serif text-slate-200">
                  {review.reviews_text}
                </h3>
                <p className="text-xs text-cyan-400/90 flex items-center gap-1.5">
                  <Clock className="w-3 h-3" />
                  Expires on:{" "}
                  {new Date(review.expiration_date).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReviewsToAnswerSection;
