import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Users, Clock, Link as LinkIcon, Calendar } from "lucide-react";

const CreateMeetingForm = ({
  users,
  selectedUser,
  meetingDate,
  meetingLink,
  setSelectedUser,
  setMeetingDate,
  setMeetingLink,
  handleCreateMeeting,
}) => {
  return (
    <div className=" rounded-lg shadow-2xl">
      <div className="p-2 border-b border-[#1F1F23]">
        <div className="flex items-center space-x-3">
          <div className="h-[1px] w-8 bg-gradient-to-r from-yellow-500/40 to-transparent" />
          <span className="text-slate-300 text-sm uppercase tracking-wider">
            New Meeting
          </span>
        </div>
      </div>

      <form onSubmit={handleCreateMeeting} className="p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Users className="w-4 h-4 text-yellow-400" strokeWidth={1.5} />
              <label className="text-slate-300 text-xs tracking-wider">
                Select User
              </label>
            </div>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              className="w-full px-3 py-2 bg-[#0A0A0B] rounded-lg border border-[#1F1F23] focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20 text-[#9A9A9D] text-sm transition-colors duration-200"
              required
            >
              <option value="">Choose a user</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Calendar className="w-4 h-4 text-yellow-400" strokeWidth={1.5} />
              <label className="text-slate-200 text-xs tracking-wider">
                Date & Time
              </label>
            </div>
            <DatePicker
              selected={meetingDate}
              onChange={(date) => setMeetingDate(date)}
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              timeFormat="h:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              className="w-full px-3 py-2 bg-[#0A0A0B] rounded-lg border border-[#1F1F23] focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20 text-[#9A9A9D] text-sm transition-colors duration-200"
              required
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <LinkIcon className="w-4 h-4 text-yellow-400" strokeWidth={1.5} />
              <label className="text-slate-200 text-xs tracking-wider">
                Meeting Link
              </label>
            </div>
            <input
              type="text"
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              placeholder="Enter meeting URL"
              className="w-full px-3 py-2 bg-[#0A0A0B] rounded-lg border border-[#1F1F23] focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20 text-[#9A9A9D] text-sm transition-colors duration-200"
            />
          </div>

          <div className="flex items-end">
            <button
              type="submit"
              className="w-full px-4 py-2 bg-slate-400/10 border border-slate-600 hover:bg-slate-200/20 text-slate-200 rounded-lg flex items-center justify-center space-x-2 transition-all duration-200 text-xs"
            >
              <Clock className="w-4 h-4" strokeWidth={1.5} />
              <span>Schedule Meeting</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMeetingForm;
