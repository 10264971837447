import React from "react";
import QuestionSlider from "./QuestionSlider";

const QuestionForm = ({
  questions,
  answers,
  handleInputChange,
  sliderValue,
  setSliderValue,
  sliderConfirmed,
  handleSliderConfirm,
  handleSubmit,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit} className="mt-10">
      {questions.map((question, index) => (
        <div key={question.id} className="mb-2">
          <p className="text-md mb-2 text-gray-100">{question.question_text}</p>
          {index === 0 ? (
            <QuestionSlider
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              sliderConfirmed={sliderConfirmed}
              handleSliderConfirm={handleSliderConfirm}
            />
          ) : (
            <input
              type="text"
              value={answers[question.id] || ""}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              className="w-full p-1 bg-zinc-400 rounded text-slate-200"
              required
            />
          )}
        </div>
      ))}
      <button
        type="submit"
        disabled={submitting}
        className="w-full mt-10 py-2 px-4 bg-gradient-to-b from-amber-800 to-amber-600  border border-amber-600 text-zinc-800 font-grotesk tracking-widest rounded hover:bg-yellow-600 disabled:opacity-50"
      >
        {submitting ? "Submitting..." : "submit answers"}
      </button>
    </form>
  );
};

export default QuestionForm;
