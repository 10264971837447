import React from "react";

const NominationForm = ({
  activeUsers,
  selectedUser,
  setSelectedUser,
  reason,
  setReason,
  submitting,
  error,
  successMessage,
  handleSubmit,
  currentQuarter,
  currentYear,
  getQuarterDateRange,
}) => {
  // Calculate the previous quarter and year for nominations
  const getPreviousQuarter = (quarter, year) => {
    if (!quarter || !year) {
      return { quarter: 1, year: new Date().getFullYear() }; // Default fallback
    }
    if (quarter === 1) {
      return { quarter: 4, year: year - 1 };
    } else {
      return { quarter: quarter - 1, year };
    }
  };

  const nominationPeriod = getPreviousQuarter(currentQuarter, currentYear);
  const nominationQuarter = nominationPeriod.quarter;
  const nominationYear = nominationPeriod.year;

  // Implement a local fallback for getQuarterDateRange if it's not provided as a prop
  const getQuarterDateRangeLocal = (quarter, year) => {
    const startMonth = (quarter - 1) * 3 + 1;
    const endMonth = quarter * 3;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${monthNames[startMonth - 1]} - ${monthNames[endMonth - 1]} ${year}`;
  };

  // Use the prop if available, otherwise use the local implementation
  const displayDateRange = getQuarterDateRange || getQuarterDateRangeLocal;

  return (
    <div className="bg-gray-950 border-l border-amber-500/30 p-6 my-4 rounded-xl shadow-2xl backdrop-blur-sm relative overflow-hidden">
      {/* Decorative elements for futuristic look */}
      <div className="absolute -top-10 -right-10 w-40 h-40 bg-amber-500/20 rounded-full blur-xl"></div>
      <div className="absolute -bottom-8 -left-8 w-32 h-32 bg-amber-950/20 rounded-full blur-lg"></div>

      {/* Header with glowing accent */}
      <div className="relative z-10">
        <h3 className="text-2xl font-bold text-amber-400 mb-1 flex items-center">
          <span className="mr-2 text-sm bg-amber-500/20 px-2 py-1 rounded-md">
            Q{nominationQuarter}
          </span>
          <span className="text-gray-50">{nominationYear} Peer Nomination</span>
        </h3>

        <div className="text-xs text-amber-300/80 mb-6 font-mono">
          {displayDateRange(nominationQuarter, nominationYear)}
        </div>

        <p className="mb-6 text-gray-300 font-light">
          Recognize an outstanding peer who demonstrated excellence during the
          previous quarter.
        </p>
      </div>

      {/* Notification messages */}
      {error && (
        <div className="bg-red-900/30 border-l-4 border-red-500 text-red-200 p-4 mb-6 rounded-r-md backdrop-blur-sm">
          {error}
        </div>
      )}

      {successMessage && (
        <div className="bg-green-900/30 border-l-4 border-green-500 text-green-200 p-4 mb-6 rounded-r-md backdrop-blur-sm">
          {successMessage}
        </div>
      )}

      {/* Form with sleek inputs */}
      <form onSubmit={handleSubmit} className="relative z-10 space-y-6">
        <div>
          <label
            className="block text-amber-300 text-sm font-medium mb-2"
            htmlFor="nominee"
          >
            SELECT NOMINEE{" "}
            <span className="text-gray-400 ml-2 text-xs">
              ({activeUsers.length} eligible)
            </span>
          </label>
          <select
            id="nominee"
            className="w-full px-4 py-3 bg-neutral-800 border border-amber-500/30 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent transition-all duration-200"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            disabled={submitting}
            required
          >
            <option value="">Select a peer</option>
            {[...activeUsers]
              .sort((a, b) => a.username.localeCompare(b.username))
              .map((user) => (
                <option
                  key={user.id}
                  value={user.id}
                  className="bg-neutral-800"
                >
                  {user.username}
                </option>
              ))}
          </select>
        </div>

        <div>
          <label
            className="block text-amber-300 text-sm font-medium mb-2"
            htmlFor="reason"
          >
            REASON FOR NOMINATION
          </label>
          <textarea
            id="reason"
            className="w-full px-4 py-3 bg-neutral-800 border border-amber-500/30 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent transition-all duration-200 resize-none"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows="2"
            placeholder={`Why does this person deserve recognition for Q${nominationQuarter}?`}
            disabled={submitting}
            required
          ></textarea>
        </div>

        <div className="flex justify-end pt-2">
          <button
            type="submit"
            className="px-6 py-2 bg-gradient-to-r from-amber-600 to-amber-500 text-sm text-neutral-900 font-medium rounded-lg hover:from-amber-500 hover:to-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-neutral-900 disabled:opacity-50 transition-all duration-200 shadow-lg shadow-amber-500/20"
            disabled={submitting}
          >
            {submitting ? (
              <span className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-neutral-900"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing
              </span>
            ) : (
              "Submit Nomination"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NominationForm;
