import React, { useState } from "react";
import { Award, ChevronDown, ChevronUp } from "lucide-react";

const PastWinnersTable = ({ winners }) => {
  const [showWinners, setShowWinners] = useState(true);

  return (
    <div>
      <div
        className="flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer"
        onClick={() => setShowWinners(!showWinners)}
      >
        <h3 className="text-md font-medium font-grotesk text-gray-800">
          Past Quarterly Winners
        </h3>
        {showWinners ? (
          <ChevronUp className="w-5 h-5 text-gray-600" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-600" />
        )}
      </div>

      {showWinners && (
        <div className="mt-4">
          {winners.length === 0 ? (
            <p className="text-gray-500 italic text-center py-4">
              No winners calculated yet.
            </p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Quarter
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Winner
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Votes
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date Calculated
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {winners.map((winner) => (
                    <tr
                      key={`${winner.year}-${winner.quarter}`}
                      className="hover:bg-gray-50"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="font-medium text-gray-900">
                          Q{winner.quarter} {winner.year}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Award className="w-5 h-5 text-yellow-500 mr-2" />
                          <span className="font-medium text-gray-900">
                            {winner.winner_username}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-gray-900">{winner.vote_count}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(winner.calculated_at).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PastWinnersTable;
