import React from "react";
import { Building, User, Mail, Key } from "lucide-react";

const TenantForm = ({ newTenant, handleTenantInputChange, isCreating }) => {
  return (
    <div className="space-y-4">
      {/* Business Name */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Business Name
        </label>
        <div className="relative">
          <Building className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="text"
            name="businessName"
            value={newTenant.businessName}
            onChange={handleTenantInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="e.g., acme-corp"
            disabled={isCreating}
          />
        </div>
        <p className="text-neutral-500 text-xs">
          Lowercase, no spaces. Will be used as database schema prefix.
        </p>
      </div>

      {/* Admin Username */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Admin Username
        </label>
        <div className="relative">
          <User className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="text"
            name="adminUsername"
            value={newTenant.adminUsername}
            onChange={handleTenantInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="e.g., admin"
            disabled={isCreating}
          />
        </div>
      </div>

      {/* Admin Email */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Admin Email
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="email"
            name="adminEmail"
            value={newTenant.adminEmail}
            onChange={handleTenantInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="e.g., admin@example.com"
            disabled={isCreating}
          />
        </div>
      </div>

      {/* Admin Password */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Admin Password
        </label>
        <div className="relative">
          <Key className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="password"
            name="adminPassword"
            value={newTenant.adminPassword}
            onChange={handleTenantInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="Secure password"
            disabled={isCreating}
          />
        </div>
        <p className="text-neutral-500 text-xs">
          Must be at least 8 characters.
        </p>
      </div>
    </div>
  );
};

export default TenantForm;
