import React, { useState, useEffect } from "react";
import { Check } from "lucide-react";

const QuestionSlider = ({
  sliderValue,
  setSliderValue,
  sliderConfirmed,
  handleSliderConfirm,
}) => {
  const [hoverValue, setHoverValue] = useState(null);

  // Generate color based on value - using a more modern gradient palette
  const getSliderColor = (value) => {
    switch (value) {
      case 1:
        return "#FF4B6E"; // Futuristic red
      case 2:
        return "#FF8F59"; // Vibrant orange
      case 3:
        return "#FFD166"; // Bright yellow
      case 4:
        return "#06D6A0"; // Turquoise teal
      case 5:
        return "#3DD5F3"; // Cyan blue
      default:
        return "#6E7DAB"; // Neutral color
    }
  };

  // Create dynamic track background
  const getSliderBackground = () => {
    const percentage = ((sliderValue - 1) / 4) * 100;
    const color = getSliderColor(sliderValue);
    // Create gradient with glow effect
    return `linear-gradient(to right, 
      ${color} ${percentage}%, 
      rgba(30, 41, 59, 0.8) ${percentage}%)`;
  };

  // Value label animation
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    setAnimating(true);
    const timer = setTimeout(() => setAnimating(false), 300);
    return () => clearTimeout(timer);
  }, [sliderValue]);

  return (
    <div className="flex flex-col p-6 bg-gray-800/50 rounded-xl shadow-2xl border border-slate-700 m-2 mb-6 mt-2">
      <div className="w-full mb-6 relative">
        {/* Custom track with glow effect */}
        <div
          className="absolute w-full h-3 rounded-full bg-gray-400 overflow-hidden"
          style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="h-full transition-all duration-300 ease-out"
            style={{
              width: `${((sliderValue - 1) / 4) * 100}%`,
              background: `${getSliderColor(sliderValue)}`,
              boxShadow: `0 0 10px ${getSliderColor(sliderValue)}`,
            }}
          />
        </div>

        {/* Actual input slider (invisible but functional) */}
        <input
          type="range"
          min="1"
          max="5"
          step="1"
          value={sliderValue}
          onChange={(e) => setSliderValue(parseInt(e.target.value))}
          className="w-full h-3 absolute z-10 opacity-0 cursor-pointer"
          disabled={sliderConfirmed}
          required
        />

        {/* Custom tick marks with hover effects */}
        <div className="flex justify-between relative mt-8">
          {[1, 2, 3, 4, 5].map((num) => (
            <button
              key={num}
              type="button"
              disabled={sliderConfirmed}
              onClick={() => setSliderValue(num)}
              onMouseEnter={() => setHoverValue(num)}
              onMouseLeave={() => setHoverValue(null)}
              className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 -mt-5 ${
                sliderValue >= num
                  ? "bg-gradient-to-r from-slate-400 to-slate-950 shadow-lg"
                  : "bg-neutral-500"
              } ${
                hoverValue === num && !sliderConfirmed
                  ? "scale-125 shadow-md shadow-amber-500/30"
                  : ""
              }`}
              style={{
                transform: sliderValue === num ? "scale(1.2)" : "",
                boxShadow:
                  sliderValue === num ? `0 0 15px ${getSliderColor(num)}` : "",
              }}
            >
              <span className="text-white font-medium">{num}</span>
            </button>
          ))}
        </div>

        {/* Value labels */}
        <div className="flex justify-between mt-8 text-sm text-slate-400">
          <span>Poor</span>
          <span>Just-ok</span>
          <span>Good</span>
          <span>Great</span>
          <span>Excellent</span>
        </div>
      </div>

      {/* Value display and confirmation */}
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center">
          <div
            className={`text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-amber-700 to-amber-500 ${
              animating ? "scale-110" : ""
            }`}
            style={{
              transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
            }}
          >
            {sliderValue}
          </div>
          <div className="ml-3 text-slate-200 text-lg">
            {["", "Poor", "Just-ok", "Good", "Great", "Excellent"][sliderValue]}
          </div>
        </div>

        <button
          type="button"
          onClick={handleSliderConfirm}
          disabled={sliderConfirmed}
          className={`flex items-center px-5 py-3 rounded-full text-amber-400 font-medium text-sm transition-all duration-300 ${
            sliderConfirmed
              ? "bg-slate-700 opacity-50 cursor-not-allowed"
              : "bg-gradient-to-r from-gray-800 to-gray-600 hover:shadow-lg hover:shadow-amber-500/30 hover:scale-105"
          }`}
        >
          <Check className="h-5 w-5 mr-2" />
          {sliderConfirmed ? "Confirmed" : "Confirm"}
        </button>
      </div>
    </div>
  );
};

export default QuestionSlider;
