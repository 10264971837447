import React, { useState, useEffect } from "react";
import axios from "axios";

const SharedWithMe = ({ businessName, onReviewSelect }) => {
  const [sharedReviews, setSharedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSharedReviews();
  }, [businessName]);

  const fetchSharedReviews = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/shared-reviews`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSharedReviews(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching shared reviews:", err);
      setError("Failed to fetch shared reviews. Please try again.");
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="font-serif text-slate-300">Loading shared reviews...</div>
    );
  if (error) return <div className="text-red-500 font-serif">{error}</div>;

  return (
    <div className="space-y-2">
      {sharedReviews.length === 0 ? (
        <p className="text-slate-400 font-serif text-center py-3">
          No reviews have been shared with you yet.
        </p>
      ) : (
        sharedReviews.map((review) => (
          <div
            key={review.id}
            className="bg-slate-900/80 p-3 rounded-md border border-slate-700/50 shadow-lg backdrop-blur-sm transition-all duration-200 hover:border-cyan-500/50 group"
          >
            <div className="flex justify-between items-center gap-4">
              <div className="flex-1 min-w-0">
                <h4 className="text-sm sm:text-base font-serif text-slate-200 truncate">
                  {review.review_text}
                </h4>
                <p className="text-xs text-cyan-400/90 mt-1 font-serif">
                  Shared by: {review.shared_by_username} ·{" "}
                  <span className="text-slate-400">
                    {new Date(review.shared_at).toLocaleDateString()}
                  </span>
                </p>
              </div>
              <button
                onClick={() => onReviewSelect(review)}
                className="px-3 py-1.5 bg-slate-800 text-xs font-serif text-cyan-400 rounded-md border border-cyan-500/50 hover:bg-slate-700 transition-colors duration-200 whitespace-nowrap flex-shrink-0"
              >
                View Answers
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SharedWithMe;
