import React, { useState, useEffect } from "react";
import axios from "axios";
import { Users, Shield, Check, UserPlus } from "lucide-react";

const ManageUserPermissions = ({ businessName }) => {
  const [users, setUsers] = useState([]);
  const [selectedViewer, setSelectedViewer] = useState("");
  const [selectedViewees, setSelectedViewees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users-with-ids`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
      setLoading(false);
    }
  };

  const handleViewerChange = (e) => {
    const username = e.target.value;
    setSelectedViewer(username);
    if (username) {
      const selectedUser = users.find((user) => user.username === username);
      setSelectedViewees(selectedUser ? selectedUser.view_questions_ids : []);
    } else {
      setSelectedViewees([]);
    }
  };

  const handleVieweeChange = (mainUserId) => {
    setSelectedViewees((prev) =>
      prev.includes(mainUserId)
        ? prev.filter((id) => id !== mainUserId)
        : [...prev, mainUserId],
    );
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${selectedViewer}/set-viewable-users`,
        { viewable_user_ids: selectedViewees },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      const viewableUsernames = users
        .filter((user) => selectedViewees.includes(user.main_id))
        .map((user) => user.username)
        .join(", ");
      setSuccessMessage(
        `${selectedViewer} can now view information from ${viewableUsernames}`,
      );
      setSelectedViewer("");
      setSelectedViewees([]);
      fetchUsers();
    } catch (err) {
      console.error("Error updating permissions:", err);
      setError("Failed to update permissions. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-neutral-50 flex items-center justify-center">
        <div className="w-12 h-12 border-t-2 border-l-2 border-zinc-400 animate-spin rounded-full"></div>
      </div>
    );
  }

  // Filter users who have view permissions (those who can see others' info)
  const usersWithViewPermissions = users.filter(
    (user) => user.view_questions_ids && user.view_questions_ids.length > 0,
  );

  return (
    <div className="bg-gray-100 text-zinc-800 border border-neutral-50 mt-24 rounded-lg">
      <div className="max-w-6xl mx-auto px-6 py-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-xl font-grotesk font-light tracking-wide text-zinc-700 pb-4">
            Manage Permissions
          </h1>
          <Users className="w-5 h-5 text-gray-500" />
        </div>

        {/* Main Content Card */}
        <div className="bg-gray-100 p-6 border-l border-zinc-200 shadow-lg">
          {error && (
            <div className="mb-6 p-4 bg-rose-50 border-l border-rose-500 text-rose-600">
              {error}
            </div>
          )}

          {successMessage && (
            <div className="mb-6 p-4 bg-emerald-50 border-l border-emerald-500 text-emerald-600 flex items-center gap-2">
              <Check className="w-5 h-5" />
              {successMessage}
            </div>
          )}

          {/* Viewer Selection */}
          <div className="">
            <label className="text-md font-light font-grotesk mb-4 flex items-center gap-2 text-zinc-700">
              <Shield className="w-5 h-5 text-amber-400" />
              Select User to Configure
            </label>
            <select
              value={selectedViewer}
              onChange={handleViewerChange}
              className="w-full px-4 py-3 font-grotesk bg-neutral-100 border border-gray-100 focus:border-zinc-400 transition-colors duration-200 text-zinc-700"
            >
              <option value="">Choose a user</option>
              {users.map((user) => (
                <option key={user.id} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>

          {/* Viewee Selection */}
          {selectedViewer && (
            <div className="mb-8">
              <label className="block text-lg font-light mb-4 flex items-center gap-2 text-zinc-700">
                <UserPlus className="w-5 h-5 text-zinc-500" />
                Grant View Access To
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {users.map((user) => (
                  <div
                    key={user.main_id}
                    className="flex items-center p-4 bg-neutral-50 border-l border-zinc-200 hover:border-zinc-400 transition-colors duration-200 font-grotesk"
                  >
                    <input
                      type="checkbox"
                      id={`user-${user.main_id}`}
                      checked={selectedViewees.includes(user.main_id)}
                      onChange={() => handleVieweeChange(user.main_id)}
                      className="w-4 h-4 border-zinc-300 text-zinc-600"
                    />
                    <label
                      htmlFor={`user-${user.main_id}`}
                      className="ml-3 text-zinc-700 font-grotesk"
                    >
                      {user.username}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Submit Button */}
          {selectedViewer && (
            <button
              onClick={handleSubmit}
              className="px-6 py-3 bg-zinc-200 hover:bg-zinc-300 text-zinc-700 flex items-center gap-2 transition-all duration-200 shadow-2xl"
            >
              <Shield className="w-4 h-4" />
              Update Permissions
            </button>
          )}
        </div>

        {/* Current Permissions Overview - Only showing users with view permissions */}
        <div className="mt-8 bg-white p-6 border-l border-zinc-200 shadow-2xl">
          <h2 className="text-md font-grotesk font-light mb-6 flex items-center gap-2 text-gray-700">
            <Users className="w-5 h-5 text-amber-400" />
            Current Access Overview
          </h2>

          {usersWithViewPermissions.length === 0 ? (
            <div className="p-4 bg-neutral-50 text-zinc-500 border-l border-zinc-200">
              No users currently have view access to others' information.
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {usersWithViewPermissions.map((user) => {
                // Get the usernames of users that this user can view
                const viewableUsernames = users
                  .filter((u) => user.view_questions_ids.includes(u.main_id))
                  .map((u) => u.username)
                  .join(", ");

                return (
                  <div
                    key={user.main_id}
                    className="p-4 bg-neutral-50 border-l border-slate-400"
                  >
                    <span className="font-medium text-zinc-700 font-grotesk">
                      {user.username}
                    </span>
                    <div className="mt-2 text-sm text-zinc-500 font-grotesk">
                      Can view: {viewableUsernames || "None"}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageUserPermissions;
