import React from "react";
import { LogOut, Menu, X } from "lucide-react";

const DashboardHeader = ({
  user,
  handleLogout,
  isSidebarOpen,
  toggleSidebar,
  getDisplayName,
}) => (
  <header className="fixed top-0 left-0 right-0 z-50 bg-zinc-950 border-b border-zinc-800 px-3">
    <div className="flex items-center justify-between py-3 relative">
      <div className="flex items-center">
        <button
          onClick={toggleSidebar}
          className="md:hidden text-gray-400 p-1 hover:text-amber-500 transition-colors duration-300"
        >
          {isSidebarOpen ? (
            <X className="h-4 w-4" />
          ) : (
            <Menu className="h-4 w-4" />
          )}
        </button>

        {/* Mobile: combine business name and MATCHA side by side */}
        <div className="flex items-center space-x-2 md:hidden">
          <h1 className="text-xs text-gray-400 font-light">
            {getDisplayName(user.business_name)}
          </h1>
          <h1 className="text-sm font-medium text-amber-500 tracking-wider">
            MATCHA
          </h1>
        </div>

        {/* Desktop: display only business name on the left */}
        <h1 className="hidden md:block text-xs text-gray-400 font-light ml-2">
          {getDisplayName(user.business_name)}
        </h1>
      </div>

      {/* Desktop: display MATCHA in the center */}
      <h1 className="hidden md:block absolute left-1/2 transform -translate-x-1/2 text-md uppercase font-grotesk font-light tracking-wider text-amber-500">
        MATCHA
      </h1>

      <button
        onClick={handleLogout}
        className="flex items-center px-2 py-1 text-xs text-gray-500 hover:text-amber-500 transition-colors duration-300 focus:outline-none"
      >
        <LogOut className="h-3 w-3" />
      </button>
    </div>
  </header>
);

export default DashboardHeader;
