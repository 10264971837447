import React from "react";

const QuestionSlider = ({
  sliderValue,
  setSliderValue,
  sliderConfirmed,
  handleSliderConfirm,
}) => {
  const getSliderBackground = () => {
    const percentage = ((sliderValue - 1) / 4) * 100;
    return `linear-gradient(to right, #FFB029 ${percentage}%, #374151 ${percentage}%)`;
  };

  return (
    <div className="flex flex-col sm:flex-row items-center">
      <div className="w-full sm:w-1/2">
        <input
          type="range"
          min="1"
          max="5"
          step="1"
          value={sliderValue}
          onChange={(e) => setSliderValue(parseInt(e.target.value))}
          className="w-full h-2 rounded appearance-none cursor-pointer mb-2"
          style={{ background: getSliderBackground() }}
          disabled={sliderConfirmed}
          required
        />
        <div className="flex justify-between text-sm text-gray-400">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
        </div>
      </div>
      <div className="flex items-center mt-4 sm:mt-0 sm:ml-4">
        <span className="text-4xl ml-5 font-medium text-slate-300 mr-10">
          {sliderValue}
        </span>
        <button
          type="button"
          onClick={handleSliderConfirm}
          disabled={sliderConfirmed}
          className={`flex items-center px-4 text-sm py-1 bg-gradient-to-b from-yellow-600 to-yellow-950 text-white rounded hover:bg-yellow-500 focus:outline-none ${
            sliderConfirmed ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
              clipRule="evenodd"
            />
          </svg>
          {sliderConfirmed ? "Confirmed" : "Confirm"}
        </button>
      </div>
    </div>
  );
};

export default QuestionSlider;
