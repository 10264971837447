import React from "react";
import { MessageSquare, Clock } from "lucide-react";

const ReviewsToAnswerSection = ({
  unansweredReviews,
  selectedReview,
  handleReviewSelect,
}) => {
  return (
    <div>
      {unansweredReviews.length === 0 ? (
        <div className="py-4 text-center text-gray-600 text-xs flex flex-col items-center">
          <div className="w-6 h-6 rounded-full border border-gray-800 flex items-center justify-center mb-2 opacity-50">
            <MessageSquare className="h-3 w-3 text-gray-700" />
          </div>
          <p>No pending reviews to answer</p>
        </div>
      ) : (
        <div className="space-y-2 max-h-60 overflow-y-auto pr-1 custom-scrollbar">
          {unansweredReviews.map((review, index) => (
            <div
              key={review.id}
              className={`p-3 rounded bg-gray-900 border-l transition-all duration-300 cursor-pointer hover:border-l-2 animate-fadeIn ${
                selectedReview?.id === review.id
                  ? "border-l-2 border-amber-500"
                  : "border-gray-800 hover:border-amber-600"
              }`}
              onClick={() => handleReviewSelect(review, "reviewsToAnswer")}
              style={{ animationDelay: `${index * 0.05}s` }}
            >
              <div className="space-y-1">
                <h3 className="text-xs text-gray-300 font-light">
                  {review.reviews_text}
                </h3>
                <div className="flex items-center gap-1 mt-2 opacity-70 group-hover:opacity-100 transition-opacity duration-300">
                  <span className="inline-block w-1 h-1 bg-amber-500 rounded-full"></span>
                  <p className="text-xs text-gray-600 flex items-center gap-1">
                    <Clock className="w-3 h-3 text-gray-600" />
                    {new Date(review.expiration_date).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReviewsToAnswerSection;
