import React, { useState, useEffect } from "react";
import {
  Key,
  User,
  AlertTriangle,
  CheckCircle,
  Shield,
  Mail,
} from "lucide-react";
import axios from "axios";

const Settings = ({ businessName }) => {
  // State variables for password change
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // State variables for profile update
  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");

  // Error and success state
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Helper function to parse error messages
  const parseErrorMessage = (error) => {
    if (typeof error === "string") return error;
    if (Array.isArray(error))
      return error.map((e) => e.msg || String(e)).join(", ");
    if (typeof error === "object") {
      if (error.msg) return error.msg;
      return Object.values(error).map(parseErrorMessage).join(", ");
    }
    return "An unknown error occurred";
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSuccess(response.data.message || "Password changed successfully");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      console.error("Error response:", err.response?.data);
      setError(
        parseErrorMessage(err.response?.data?.detail) ||
          "Failed to change password",
      );
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const updatedFields = {};
    if (newUsername) updatedFields.new_username = newUsername;
    if (newEmail) updatedFields.new_email = newEmail;

    if (Object.keys(updatedFields).length === 0) {
      setError("No fields to update");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/update-profile`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSuccess(response.data.message || "Profile updated successfully");
      setNewUsername("");
      setNewEmail("");
    } catch (err) {
      console.error("Profile update error:", err.response?.data);
      setError(
        parseErrorMessage(err.response?.data?.detail) ||
          "Failed to update profile",
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 mt-24">
      {/* Main container with gradient background */}
      <div className="bg-gradient-to-t from-gray-800 via-gray-900 to-gray-800 rounded p-14 shadow-2xl border border-gray-800">
        {/* Header section */}
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-2">
            <h2 className="text-2xl font-grotesk font-medium tracking-wide text-gray-100">
              Account Settings
            </h2>
          </div>
        </div>

        {/* Error and Success Messages with improved styling */}
        {error && (
          <div className="bg-red-900 border border-red-800 text-red-200 px-4 py-3 rounded-lg mb-6 flex items-center">
            <AlertTriangle size={18} className="mr-2" />
            {error}
          </div>
        )}
        {success && (
          <div className="bg-emerald-900/50 border border-emerald-800 text-emerald-300 px-4 py-3 rounded-lg mb-6 flex items-center">
            <CheckCircle size={18} className="mr-2" />
            {success}
          </div>
        )}

        <div className="space-y-8">
          {/* Password Change Section */}
          <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
            <h3 className="text-lg font-medium tracking-wide font-grotesk text-gray-100 mb-6 flex items-center">
              <Key size={18} className="mr-2 text-amber-500" />
              Change Password
            </h3>

            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2.5 
                    text-gray-200 placeholder-gray-500
                    focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent
                    transition-all duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2.5 
                    text-gray-200 placeholder-gray-500
                    focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent
                    transition-all duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2.5 
                    text-gray-200 placeholder-gray-500
                    focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent
                    transition-all duration-200"
                />
              </div>

              <button
                type="submit"
                className="mt-6 w-full bg-gray-700 hover:bg-amber-500 text-gray-200 hover:text-gray-900 px-6 py-3 rounded-lg
                  font-medium transition-all duration-200 
                  focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:ring-offset-2 focus:ring-offset-gray-900
                  active:scale-[0.98] font-grotesk"
              >
                Update Password
              </button>
            </form>
          </div>

          {/* Profile Update Section */}
          <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
            <h3 className="text-lg font-medium font-grotesk text-gray-100 mb-6 flex items-center">
              <User size={18} className="mr-2 text-amber-500" />
              Update Profile
            </h3>

            <form onSubmit={handleProfileUpdate} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  New Username
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User size={16} className="text-gray-500" />
                  </div>
                  <input
                    type="text"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    className="w-full bg-gray-900 border border-gray-700 rounded-lg pl-10 pr-4 py-2.5 
                      text-gray-200 placeholder-gray-500
                      focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent
                      transition-all duration-200"
                    placeholder="Enter new username"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-grotesk font-medium text-gray-400 mb-2">
                  New Email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail size={16} className="text-gray-500" />
                  </div>
                  <input
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    className="w-full bg-gray-900 border border-gray-700 rounded-lg pl-10 pr-4 py-2.5 
                      text-gray-200 placeholder-gray-500
                      focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent
                      transition-all duration-200"
                    placeholder="Enter new email"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="mt-6 w-full bg-gray-700 font-grotesk hover:bg-amber-500 text-gray-200 hover:text-gray-900 px-6 py-3 rounded-lg
                  font-medium transition-all duration-200 
                  focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:ring-offset-2 focus:ring-offset-gray-900
                  active:scale-[0.98]"
              >
                Update Profile
              </button>
            </form>
          </div>

          {/* Security Tips Section - Added to match the informative style of the leaderboard */}
          <div className="p-4 bg-gray-800 rounded-lg border border-gray-700 text-sm text-gray-300">
            <h3 className="font-medium text-gray-100 mb-2 flex items-center">
              <Shield size={16} className="mr-2 text-amber-500" />
              Security Tips
            </h3>
            <p className="mb-2">
              Keep your account secure with these best practices:
            </p>
            <div className="space-y-2 mt-3">
              <div className="flex items-start">
                <div className="w-2 h-2 bg-amber-500 rounded-full mt-1.5 mr-2"></div>
                <span>
                  Use a strong, unique password with a mix of characters
                </span>
              </div>
              <div className="flex items-start">
                <div className="w-2 h-2 bg-amber-500 rounded-full mt-1.5 mr-2"></div>
                <span>Enable two-factor authentication if available</span>
              </div>
              <div className="flex items-start">
                <div className="w-2 h-2 bg-amber-500 rounded-full mt-1.5 mr-2"></div>
                <span>Update your password regularly</span>
              </div>
              <div className="flex items-start">
                <div className="w-2 h-2 bg-amber-500 rounded-full mt-1.5 mr-2"></div>
                <span>Never share your login credentials with others</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
