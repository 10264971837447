import React from "react";
import { AlertCircle } from "lucide-react";

/**
 * Component for handling loading and error states in the ENPS dashboard
 * Displays either a loading spinner or an error message
 */
const ENPSLoadingErrorStates = ({ loading, error }) => {
  if (loading) {
    return (
      <div className="min-h-64 flex items-center justify-center py-12 px-4 bg-gradient-to-br from-zinc-50 to-gray-100">
        <div className="relative flex">
          <div className="h-12 w-12 rounded-full border-t-2 border-b-2 border-zinc-300 animate-spin"></div>
          <div className="h-12 w-12 rounded-full border-r-2 border-l-2 border-zinc-400 animate-spin animation-delay-150 absolute inset-0"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-3 w-3 bg-zinc-500 rounded-full animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-rose-50 p-6 rounded-xl shadow-lg text-center backdrop-blur-sm border border-rose-100">
        <div className="flex items-center justify-center gap-3 mb-3">
          <AlertCircle className="h-6 w-6 text-rose-500" />
          <h3 className="text-lg font-medium text-rose-700">
            Data Unavailable
          </h3>
        </div>
        <p className="text-rose-600 max-w-md mx-auto">{error}</p>
      </div>
    );
  }

  return null;
};

export default ENPSLoadingErrorStates;
