import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { X, ArrowRight, Lock, User } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const Login = ({ onLoginSuccess, onClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Username cannot contain spaces");
    } else {
      setError("");
    }
    setUsername(value.replace(/\s/g, "").toLowerCase());
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Password cannot contain spaces");
    } else {
      setError("");
    }
    setPassword(value.replace(/\s/g, ""));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!username.trim() || !password.trim()) {
      setError("Username and Password cannot be empty or contain only spaces.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "/api/v1/login",
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      // Store token
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.removeItem("is_master_admin");
      onLoginSuccess(response.data.user_info);
    } catch (err) {
      setError(
        err.response?.data?.detail ||
          "Login failed. Please check your credentials.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    navigate("/");
    if (onClose) onClose();
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {/* Backdrop */}
        <motion.div
          className="absolute inset-0 bg-neutral-950/90 backdrop-blur-md"
          onClick={handleClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        />
        {/* Modal Content */}
        <motion.div
          className="relative w-11/12 max-w-md"
          initial={{ scale: 0.95, y: 20, opacity: 0 }}
          animate={{ scale: 1, y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 20,
            delay: 0.1,
          }}
          onClick={(e) => e.stopPropagation()} // Prevent clicks from closing the modal
        >
          {/* Main Content */}
          <div className="bg-neutral-900 border border-amber-700/30 p-8 rounded-xl shadow-2xl backdrop-blur-sm relative overflow-hidden">
            {/* Decorative elements for futuristic look */}
            <div className="absolute -top-10 -right-10 w-40 h-40 bg-amber-700/10 rounded-full blur-xl"></div>
            <div className="absolute -bottom-8 -left-8 w-32 h-32 bg-amber-600/5 rounded-full blur-lg"></div>

            <motion.button
              onClick={handleClose}
              className="absolute top-4 right-4 p-2 text-amber-400 hover:text-amber-300 transition-colors z-10"
              whileHover={{ scale: 1.1, rotate: 90 }}
              whileTap={{ scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              <X className="w-5 h-5" />
            </motion.button>

            <motion.div
              className="space-y-6 relative z-10"
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.1,
                    delayChildren: 0.2,
                  },
                },
              }}
            >
              <motion.div
                className="text-left space-y-2"
                variants={{
                  hidden: { y: -20, opacity: 0 },
                  visible: { y: 0, opacity: 1 },
                }}
              >
                <h2 className="text-2xl font-bold text-amber-400 mb-1 flex items-center">
                  <span className="text-gray-50">Login to Your Account</span>
                </h2>
                <div className="text-xs text-amber-300/80 mb-6 font-mono">
                  Enter your credentials to continue
                </div>
              </motion.div>

              {/* Error message */}
              <AnimatePresence>
                {error && (
                  <motion.div
                    className="bg-red-900/30 border-l-4 border-red-500 text-red-200 p-4 mb-6 rounded-r-md backdrop-blur-sm"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    {error}
                  </motion.div>
                )}
              </AnimatePresence>

              <motion.form
                onSubmit={handleSubmit}
                className="space-y-6"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <div className="space-y-6">
                  <motion.div
                    className="space-y-2"
                    variants={{
                      hidden: { y: 20, opacity: 0 },
                      visible: { y: 0, opacity: 1 },
                    }}
                  >
                    <label
                      htmlFor="username"
                      className="block text-amber-300 text-sm font-medium mb-2"
                    >
                      USERNAME
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        className="w-full px-4 py-3 bg-neutral-800 border border-amber-500/30 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent transition-all duration-200"
                        required
                      />
                      <User className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-amber-500/50" />
                    </div>
                  </motion.div>

                  <motion.div
                    className="space-y-2"
                    variants={{
                      hidden: { y: 20, opacity: 0 },
                      visible: { y: 0, opacity: 1 },
                    }}
                  >
                    <label
                      htmlFor="password"
                      className="block text-amber-300 text-sm font-medium mb-2"
                    >
                      PASSWORD
                    </label>
                    <div className="relative">
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="w-full px-4 py-3 bg-neutral-800 border border-amber-500/30 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500/50 focus:border-transparent transition-all duration-200"
                        required
                      />
                      <Lock className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-amber-500/50" />
                    </div>
                  </motion.div>
                </div>

                <div className="flex justify-end pt-2">
                  <motion.button
                    type="submit"
                    disabled={loading}
                    className="px-6 py-3 bg-gradient-to-r from-amber-700 to-amber-600 text-sm text-neutral-900 font-medium rounded-lg hover:from-amber-500 hover:to-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-neutral-900 disabled:opacity-50 transition-all duration-200 shadow-lg shadow-amber-500/20"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    variants={{
                      hidden: { y: 20, opacity: 0 },
                      visible: { y: 0, opacity: 1 },
                    }}
                  >
                    {loading ? (
                      <span className="flex items-center">
                        <svg
                          className="animate-spin -ml-1 mr-2 h-4 w-4 text-neutral-900"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Processing
                      </span>
                    ) : (
                      <span className="flex items-center">
                        Login to Account
                        <ArrowRight className="ml-2 w-4 h-4" />
                      </span>
                    )}
                  </motion.button>
                </div>
              </motion.form>

              <motion.div
                className="text-center pt-4"
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: { y: 0, opacity: 1 },
                }}
              >
                <Link
                  to="/forgot-password"
                  className="text-sm text-amber-300/80 hover:text-amber-300 transition-colors"
                >
                  Forgot your password?
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Login;
