import React from "react";
import { MessageCircle, Clock, Calendar } from "lucide-react";
import MeetingCard from "./MeetingCard";

const MeetingsListOneonOne = ({
  selectedUserMeetings,
  notes,
  editingNotes,
  onNoteChange,
  onUpdateNotes,
  onEditingNotesChange,
}) => {
  return (
    <div className="bg-gray-800 rounded-lg divide-y divide-gray-700">
      <div className="p-3 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Calendar className="w-5 h-5 text-amber-500" strokeWidth={1.5} />
          <span className="text-gray-100 font-grotesk text-md tracking-wider">
            Scheduled Meetings
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="w-4 h-4 text-amber-500" strokeWidth={1.5} />
          <span className="text-gray-300 font-grotesk text-xs">
            {selectedUserMeetings.length} Total
          </span>
        </div>
      </div>
      <div className="p-4">
        {selectedUserMeetings.length === 0 ? (
          <div className="py-12 flex flex-col items-center justify-center">
            <MessageCircle
              className="w-8 h-8 text-gray-600 mb-3"
              strokeWidth={1.5}
            />
            <p className="text-gray-400 font-grotesk text-sm">
              No meetings scheduled
            </p>
          </div>
        ) : (
          <div className="space-y-3">
            {selectedUserMeetings.map((meeting) => (
              <MeetingCard
                key={meeting.id}
                meeting={meeting}
                isAttendant={meeting.is_attendant}
                notes={notes}
                editingNotes={editingNotes}
                onNoteChange={onNoteChange}
                onUpdateNotes={onUpdateNotes}
                onEditingNotesChange={(meetingId, value) =>
                  onEditingNotesChange(meetingId, value)
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingsListOneonOne;
