import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminReviews = ({ businessName }) => {
  const [reviews, setReviews] = useState([]);
  const [pushedReviews, setPushedReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [total, setTotal] = useState(0);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    fetchReviews();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/admin-reviews-and-pushed?limit=${showAll ? 1000 : 5}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data.reviews);
      setPushedReviews(response.data.pushed_reviews);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
      setLoading(false);
    }
  };

  const handleReviewSelect = (review) => {
    setSelectedReview(review);
    setAnswers({});
  };

  const handleAnswerChange = (questionKey, value) => {
    setAnswers({ ...answers, [questionKey]: value });
  };

  const handleSubmitAnswers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.post(
        `/api/v1/businesses/${businessName}/submit-review-answer`,
        {
          review_id: selectedReview.id,
          answers: answers,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      alert("Answers submitted successfully!");
      fetchReviews();
      setSelectedReview(null);
      setAnswers({});
    } catch (err) {
      console.error("Error submitting answers:", err);
      alert("Failed to submit answers. Please try again.");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No expiration date";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="bg-slate-950 rounded-lg shadow-2xl p-6">
      <h4 className="text-4xl font-semibold shadow-2xl mb-4 font-serif text-indigo-400">
        my reviews
      </h4>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h5 className="text-lg mb-2 font-serif text-slate-100">
            available reviews
          </h5>
          {reviews.map((review) => (
            <div
              key={review.id}
              className={`p-2 font-serif text-md shadow-2xl mb-2 rounded cursor-pointer ${
                selectedReview?.id === review.id
                  ? "bg-teal-600"
                  : "bg-slate-900 hover:bg-indigo-900"
              }`}
              onClick={() => handleReviewSelect(review)}
            >
              {review.reviews_text}
              <div className="text-sm text-gray-400">
                expires: {formatDate(review.expiration_date)}
              </div>
            </div>
          ))}
          {!showAll && total > 5 && (
            <button
              onClick={() => {
                setShowAll(true);
                fetchReviews();
              }}
              className="mt-2 px-4 py-2 bg-indigo-900 text-white rounded hover:bg-blue-700"
            >
              Show All ({total})
            </button>
          )}
          {showAll && (
            <button
              onClick={() => {
                setShowAll(false);
                fetchReviews();
              }}
              className="mt-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Show Less
            </button>
          )}
        </div>
        <div>
          {selectedReview && (
            <>
              <h5 className="text-xl p-3 rounded-md font-serif bg-indigo-900 font-semibold mb-2">
                Review: {selectedReview.reviews_text}
              </h5>
              <div className="mb-4 ml-20">
                <strong>expiration Date:</strong>{" "}
                {formatDate(selectedReview.expiration_date)}
              </div>
              {selectedReview.questions.map((question, index) => (
                <div
                  key={index}
                  className="mb-4 text-sm bg-slate-900 rounded-lg p-3"
                >
                  <p className="font-semibold">{question}</p>
                  {selectedReview.answers.map((answer, answerIndex) => (
                    <div key={answerIndex} className="ml-4 mt-2">
                      <p>
                        <strong>User {answer.user_id}:</strong>{" "}
                        {answer.answers[index]}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <h4 className="text-4xl font-semibold shadow-2xl mt-8 mb-4 font-serif text-indigo-400">
        answer reviews
      </h4>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h5 className="text-lg mb-2 font-serif text-slate-100">
            reviews to answer
          </h5>
          {pushedReviews.map((review) => (
            <div
              key={review.id}
              className={`p-2 font-serif text-md shadow-2xl mb-2 rounded cursor-pointer ${
                selectedReview?.id === review.id
                  ? "bg-teal-600"
                  : "bg-slate-900 hover:bg-indigo-900"
              }`}
              onClick={() => handleReviewSelect(review)}
            >
              {review.reviews_text}
              <div className="text-sm text-gray-400">
                expires: {formatDate(review.expiration_date)}
              </div>
            </div>
          ))}
        </div>
        <div>
          {selectedReview && (
            <>
              <h5 className="text-xl p-3 rounded-md font-serif bg-indigo-900 font-semibold mb-2">
                Review: {selectedReview.reviews_text}
              </h5>
              <div className="mb-4 ml-20">
                <strong>expiration Date:</strong>{" "}
                {formatDate(selectedReview.expiration_date)}
              </div>
              {selectedReview.questions.map((question, index) => (
                <div
                  key={index}
                  className="mb-4 text-sm bg-slate-900 rounded-lg p-3"
                >
                  <p className="font-semibold">{question}</p>
                  <textarea
                    className="w-full p-2 mt-2 bg-slate-800 rounded"
                    value={answers[`answer_${index + 1}`] || ""}
                    onChange={(e) =>
                      handleAnswerChange(`answer_${index + 1}`, e.target.value)
                    }
                    placeholder="Your answer..."
                  />
                </div>
              ))}
              <button
                onClick={handleSubmitAnswers}
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Submit Answers
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminReviews;
