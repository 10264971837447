import React, { useState, useEffect } from "react";
import axios from "axios";

const Analytics = ({ businessName }) => {
  const [analytics, setAnalytics] = useState(null);
  const [sentimentAnalysis, setSentimentAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sentimentLoading, setSentimentLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sentimentError, setSentimentError] = useState(null);
  const [emails, setEmails] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState("");

  useEffect(() => {
    fetchAnalytics();
    fetchSentimentAnalysis();
  }, [businessName]);

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/analytics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAnalytics(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching analytics:", err);
      setError("Failed to fetch analytics. Please try again.");
      setLoading(false);
    }
  };

  const fetchSentimentAnalysis = async () => {
    setSentimentLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/sentiment-analysis`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSentimentAnalysis(response.data);
      setSentimentLoading(false);
    } catch (err) {
      console.error("Error fetching sentiment analysis:", err);
      setSentimentError("Failed to fetch sentiment analysis.");
      setSentimentLoading(false);
    }
  };

  const generateNewSentimentAnalysis = async () => {
    setSentimentLoading(true);
    setSentimentError(null);
    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/generate-sentiment-analysis`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSentimentAnalysis(response.data);
    } catch (err) {
      console.error("Error generating new sentiment analysis:", err);
      setSentimentError("Failed to generate new sentiment analysis.");
    } finally {
      setSentimentLoading(false);
    }
  };

  const sendAnalysisEmail = async () => {
    if (!emails.trim()) {
      setEmailSentMessage("Please enter at least one email address.");
      return;
    }
    setSendingEmail(true);
    setEmailSentMessage("");
    try {
      const emailList = emails
        .split(/[ ,]+/)
        .filter((email) => email.trim() !== "");
      await axios.post(
        `/api/v1/businesses/${businessName}/send-sentiment-analysis`,
        { emails: emailList },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setEmailSentMessage("Sentiment analysis sent successfully!");
      setEmails(""); // Clear the email input after successful send
    } catch (err) {
      console.error("Error sending sentiment analysis email:", err);
      setEmailSentMessage(
        "Failed to send sentiment analysis. Please try again.",
      );
    } finally {
      setSendingEmail(false);
    }
  };

  if (loading) return <div>Loading analytics...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-6">
      <h3 className="text-3xl font-semibold mb-4 text-indigo-400 font-serif">
        Analytics
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-xl font-semibold mb-2 text-teal-400">
            eNPS Score
          </h4>
          <p className="text-2xl text-white">
            {analytics.enps_score !== null
              ? analytics.enps_score.toFixed(2)
              : "N/A"}
          </p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-xl font-semibold mb-2 text-teal-400">
            Vibe Check Score
          </h4>
          <p className="text-2xl text-white">
            {analytics.vibe_score !== null
              ? analytics.vibe_score.toFixed(2)
              : "N/A"}
          </p>
        </div>
      </div>
      {/* Sentiment Analysis Section */}
      <div className="mt-6 bg-gray-800 p-4 rounded-lg">
        <h4 className="text-xl font-semibold mb-2 text-teal-400">
          Sentiment Analysis
        </h4>
        {sentimentLoading ? (
          <p>Loading sentiment analysis...</p>
        ) : sentimentError ? (
          <p className="text-red-500">{sentimentError}</p>
        ) : (
          <>
            {sentimentAnalysis && (
              <div className="mb-2 text-sm text-gray-400">
                <p>Index: {sentimentAnalysis.id}</p>
                <p>
                  Generated at:{" "}
                  {new Date(sentimentAnalysis.created_at).toLocaleString()}
                </p>
              </div>
            )}
            <p className="text-gray-300 whitespace-pre-line">
              {sentimentAnalysis.sentiment_analysis}
            </p>
          </>
        )}
        {/*
<button
          onClick={generateNewSentimentAnalysis}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          disabled={sentimentLoading}
        >
          {sentimentLoading ? "Generating..." : "Generate New Analysis"}
        </button>

        */}{" "}
        <div className="mt-4">
          <input
            type="text"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder="Enter emails (comma or space separated)"
            className="w-full p-2 bg-gray-700 text-white rounded"
          />
          <button
            onClick={sendAnalysisEmail}
            className="mt-2 px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
            disabled={sendingEmail}
          >
            {sendingEmail ? "Sending..." : "Send Analysis"}
          </button>
          {emailSentMessage && (
            <p
              className={`mt-2 ${emailSentMessage.includes("success") ? "text-green-500" : "text-red-500"}`}
            >
              {emailSentMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
