import React from "react";
import { Users, ChevronRight } from "lucide-react";
import CreateMeetingForm from "./CreateMeetingFrom";
import UserListOneonOne from "./UserListOneonOne";
import MeetingsListOneonOne from "./MeetingsListOneonOne";
import useOneOnOne from "./useOneonOne";

const OneOnOne = ({ businessName }) => {
  const {
    users,
    selectedUser,
    meetingDate,
    meetingLink,
    error,
    meetings,
    selectedUserMeetings,
    notes,
    editingNotes,
    selectedUsername,
    setSelectedUser,
    setMeetingDate,
    setMeetingLink,
    setEditingNotes,
    handleCreateMeeting,
    handleUpdateNotes,
    handleUserSelect,
    handleNoteChange,
  } = useOneOnOne(businessName);

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-800 border border-slate-800 rounded-xl shadow-2xl">
      <div className="max-w-[1400px] mx-auto bg-dotted-pattern px-4 sm:px-6">
        <div className="p-2">
          {/* Header */}
          <div className="flex mt-2 justify-center space-x-3 mb-4 bg-gradient-to-t from-slate-950 to-slate-800 border border-slate-900 rounded p-1">
            <div className="h-[2px] mt-3 w-8 bg-gradient-to-r from-yellow-400/40 to-transparent" />
            <h1 className="text-slate-300 mt-1 font-serif text-md tracking-wider uppercase">
              One-on-Ones
            </h1>
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            {/* Form Section - Spans full width */}
            <div className="col-span-1 lg:col-span-12">
              <div className="bg-slate-900 bg-dotted-pattern border border-slate-800 rounded-lg p-3">
                <CreateMeetingForm
                  users={users}
                  selectedUser={selectedUser}
                  meetingDate={meetingDate}
                  meetingLink={meetingLink}
                  setSelectedUser={setSelectedUser}
                  setMeetingDate={setMeetingDate}
                  setMeetingLink={setMeetingLink}
                  handleCreateMeeting={handleCreateMeeting}
                />
              </div>
            </div>

            {error && (
              <div className="col-span-1 lg:col-span-12">
                <div className="px-4 py-3 bg-red-500/5 border border-red-500/10 rounded-lg">
                  <p className="text-[#9A9A9D] text-sm">{error}</p>
                </div>
              </div>
            )}

            {/* Mobile User Selection */}
            <div className="lg:hidden col-span-1">
              <select
                value={selectedUsername || ""}
                onChange={(e) => handleUserSelect(e.target.value)}
                className="w-full px-3 py-2 bg-[#111113] border border-[#1F1F23] rounded-lg text-[#9A9A9D] text-sm focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20"
              >
                <option value="">Select Team Member</option>
                {Object.keys(meetings).map((username) => (
                  <option key={username} value={username}>
                    {username}
                  </option>
                ))}
              </select>
            </div>

            {/* Desktop User List */}
            <div className="hidden lg:block lg:col-span-3">
              <div className="bg-slate-900/20 border border-[#1F1F23] rounded-lg overflow-hidden sticky top-4">
                <div className="p-1 border-b border-[#1F1F23]">
                  <div className="flex items-center justify-between">
                    <span className="text-[#9A9A9D] text-xs uppercase tracking-wider"></span>
                    <ChevronRight className="w-4 h-4 text-[#2F2F33]" />
                  </div>
                </div>
                <UserListOneonOne
                  meetings={meetings}
                  selectedUsername={selectedUsername}
                  onUserSelect={handleUserSelect}
                />
              </div>
            </div>

            {/* Meetings List */}
            <div className="col-span-1 lg:col-span-9">
              <div className="bg-[#111113] border border-[#1F1F23] rounded-lg">
                <MeetingsListOneonOne
                  selectedUserMeetings={selectedUserMeetings}
                  notes={notes}
                  editingNotes={editingNotes}
                  onNoteChange={handleNoteChange}
                  onUpdateNotes={handleUpdateNotes}
                  onEditingNotesChange={(meetingId, value) =>
                    setEditingNotes((prev) => ({
                      ...prev,
                      [meetingId]: value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneOnOne;
