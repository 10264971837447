import React from "react";

const AboutENPSSection = () => {
  return (
    <div className="mt-8 bg-slate-50 p-6 rounded-lg border border-slate-100">
      <div className="flex items-center mb-3">
        <svg
          className="w-5 h-5 text-blue-500 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <h3 className="font-medium text-slate-800">About eNPS Scoring</h3>
      </div>
      <p className="text-slate-600 text-sm mb-4">
        eNPS (Employee Net Promoter Score) measures employee loyalty by
        subtracting the percentage of Detractors (scores 0-6) from Promoters
        (scores 9-10). Scores range from -100 to 100.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
        <div className="bg-white p-3 rounded-lg shadow-sm border border-slate-100">
          <div className="flex items-center mb-1">
            <span className="w-3 h-3 bg-emerald-500 rounded-full mr-2"></span>
            <span className="font-medium text-slate-800">Promoters (9-10)</span>
          </div>
          <p className="text-slate-500 text-xs ml-5">
            Enthusiastic, loyal employees
          </p>
        </div>
        <div className="bg-white p-3 rounded-lg shadow-sm border border-slate-100">
          <div className="flex items-center mb-1">
            <span className="w-3 h-3 bg-amber-500 rounded-full mr-2"></span>
            <span className="font-medium text-slate-800">Passives (7-8)</span>
          </div>
          <p className="text-slate-500 text-xs ml-5">
            Satisfied but not committed
          </p>
        </div>
        <div className="bg-white p-3 rounded-lg shadow-sm border border-slate-100">
          <div className="flex items-center mb-1">
            <span className="w-3 h-3 bg-red-500 rounded-full mr-2"></span>
            <span className="font-medium text-slate-800">Detractors (0-6)</span>
          </div>
          <p className="text-slate-500 text-xs ml-5">Unhappy employees</p>
        </div>
      </div>
    </div>
  );
};

export default AboutENPSSection;
