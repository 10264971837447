import React from "react";
import { Calendar } from "lucide-react";

/**
 * Component for selecting which quarter's data to display/export
 *
 * @param {Object} props Component props
 * @param {number} props.selectedYear Currently selected year
 * @param {number} props.selectedQuarter Currently selected quarter
 * @param {Array<number>} props.availableYears List of available years to select from
 * @param {Array<Object>} props.quarterlyData Array of quarterly data objects
 * @param {Function} props.onChange Callback when selection changes (year, quarter) => void
 * @param {boolean} props.disabled Whether the selectors should be disabled
 */
const QuarterSelector = ({
  selectedYear,
  selectedQuarter,
  availableYears,
  quarterlyData,
  onChange,
  disabled,
}) => {
  // Get unique available quarters based on actual data
  const getAvailableQuarters = () => {
    const quartersForYear = quarterlyData
      .filter((q) => q.year === selectedYear && q.total_responses > 0)
      .map((q) => q.quarter);

    // Return sorted, unique quarters
    return [...new Set(quartersForYear)].sort((a, b) => a - b);
  };

  const availableQuarters = getAvailableQuarters();

  // If no quarters with responses found, show all quarters
  const quarters =
    availableQuarters.length > 0 ? availableQuarters : [1, 2, 3, 4];

  // Get unique years from the quarterly data that have responses
  const yearsWithData = [
    ...new Set(
      quarterlyData.filter((q) => q.total_responses > 0).map((q) => q.year),
    ),
  ].sort((a, b) => b - a); // Sort descending

  // Use years with data, or fall back to available years prop
  const years = yearsWithData.length > 0 ? yearsWithData : availableYears;

  return (
    <div className="flex items-center gap-2">
      <div className="p-2 bg-zinc-100 rounded-lg shadow-sm">
        <Calendar className="h-4 w-4 text-zinc-600" />
      </div>
      <div className="flex items-center gap-2">
        <select
          value={selectedYear}
          onChange={(e) => {
            const newYear = parseInt(e.target.value);
            // Reset to first quarter when changing year
            const newQuarterData = quarterlyData
              .filter((q) => q.year === newYear && q.total_responses > 0)
              .sort((a, b) => a.quarter - b.quarter);

            const newQuarter =
              newQuarterData.length > 0 ? newQuarterData[0].quarter : 1;

            onChange(newYear, newQuarter);
          }}
          disabled={disabled}
          className="bg-zinc-50 border border-zinc-200 text-zinc-700 text-xs rounded-lg focus:ring-amber-500 focus:border-amber-500 p-1.5 disabled:bg-zinc-100 disabled:text-zinc-400"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          value={selectedQuarter}
          onChange={(e) => onChange(selectedYear, parseInt(e.target.value))}
          disabled={disabled}
          className="bg-zinc-50 border border-zinc-200 text-zinc-700 text-xs rounded-lg focus:ring-amber-500 focus:border-amber-500 p-1.5 disabled:bg-zinc-100 disabled:text-zinc-400"
        >
          {quarters.map((quarter) => (
            <option key={quarter} value={quarter}>
              Q{quarter}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default QuarterSelector;
