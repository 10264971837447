import React from "react";
import { Bell, MessageCircle } from "lucide-react";

const NotificationSection = ({ readResponses, responses, currentUsername }) => {
  // Process recent comments
  const recentComments = responses
    ?.reduce((acc, response) => {
      if (response.username === currentUsername && response.comment) {
        const comments = Array.isArray(response.comment)
          ? response.comment
          : [response.comment];

        comments.forEach((comment) => {
          if (typeof comment === "string") {
            // Handle plain string comments - no user attribution
            acc.push({
              text: comment,
              timestamp: response.date_answered,
              username: "comment", // Will show as just "comment"
              responseUsername: response.username,
              responseDate: response.date_answered,
            });
          } else if (typeof comment === "object" && comment !== null) {
            if (Array.isArray(comment.text)) {
              // Handle nested text array format
              comment.text.forEach((nestedComment) => {
                acc.push({
                  text: nestedComment.text || "Invalid comment",
                  timestamp: nestedComment.timestamp || response.date_answered,
                  // Only use username if commenter_id exists
                  username: nestedComment.commenter_id
                    ? nestedComment.commenter_username
                    : "comment",
                  responseUsername: response.username,
                  responseDate: response.date_answered,
                });
              });
            } else {
              // Handle direct object format
              acc.push({
                text: comment.text || "Invalid comment",
                timestamp: comment.timestamp || response.date_answered,
                // Only use username if commenter_id exists
                username: comment.commenter_id
                  ? comment.commenter_username
                  : "comment",
                responseUsername: response.username,
                responseDate: response.date_answered,
              });
            }
          }
        });
      }
      return acc;
    }, [])
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    .slice(0, 10);

  // Safely render read responses based on DB structure
  const renderReadResponse = (response) => {
    try {
      const date = new Date(response.date_answered);

      return (
        <div
          key={response.id}
          className="p-3 rounded-md transition-all duration-200 hover:bg-slate-800/50"
        >
          <p className="text-slate-200 text-xs">"{response.question_text}"</p>
          <p className="text-xs text-slate-400 mt-1">
            Your answer: {response.answer}
          </p>
          <p className="text-xs text-slate-500 mt-1">
            {date.toLocaleDateString()} at{" "}
            {date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </div>
      );
    } catch (error) {
      console.error("Error rendering response:", error, response);
      return null;
    }
  };

  return (
    <div className="space-y-6">
      {/* Comments Section */}
      <div className="mt-4 bg-gradient-to-b from-[#070707] to-slate-800 rounded-lg shadow-2xl p-5 border border-slate-700">
        <div className="flex items-center justify-center mb-3 bg-gradient-to-t from-gray-950 to bg-gray-900 border border-slate-700 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <MessageCircle className="h-4 w-4 text-yellow-400" />
            <h2 className="mt-1 text-lg font-serif text-slate-300">
              Comments on Your Responses
            </h2>
          </div>
        </div>
        {!recentComments || recentComments.length === 0 ? (
          <p className="text-slate-400">No comments on your responses yet.</p>
        ) : (
          <div className="space-y-2">
            {recentComments.map((comment, index) => (
              <div
                key={index}
                className="p-2 rounded-md transition-all duration-200 hover:bg-slate-800/50"
              >
                <div className="flex justify-between">
                  <p className="text-slate-200 text-sm">
                    {comment.username !== "comment" ? (
                      <span className="text-blue-400 font-semibold">
                        {comment.username}
                      </span>
                    ) : (
                      <span className="text-blue-400 font-semibold">
                        comment
                      </span>
                    )}
                  </p>
                  <p className="text-sm text-slate-400">
                    on response{" "}
                    {new Date(comment.responseDate).toLocaleDateString()}
                  </p>
                </div>
                <p className="text-sm text-slate-300 mt-2">"{comment.text}"</p>
                <p className="text-xs text-slate-500 mt-2">
                  {new Date(comment.timestamp).toLocaleDateString()} at{" "}
                  {new Date(comment.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            ))}
            {recentComments.length === 4 && (
              <p className="text-xs text-slate-400 mt-2 text-center">
                Showing 10 most recent comments
              </p>
            )}
          </div>
        )}
      </div>

      {/* Read Responses Section */}
      <div className="mt-4 bg-gradient-to-b from-slate-800 to-[#070707] rounded-lg shadow-2xl p-4 border border-slate-900">
        <div className="flex items-center justify-center mb-5 bg-gradient-to-b from-gray-950 to bg-gray-900 p-2 rounded-lg ">
          <div className="flex items-center gap-2">
            <Bell className="h-5 w-5 text-yellow-400" />
            <h2 className="text-lg font-serif text-slate-300">
              Recently Read Responses
            </h2>
          </div>
        </div>
        {!readResponses || readResponses.length === 0 ? (
          <p className="text-slate-400">No responses have been read yet.</p>
        ) : (
          <div className="space-y-2">
            {readResponses
              .map((response) => renderReadResponse(response))
              .filter(Boolean)}
            {readResponses.length === 4 && (
              <p className="text-xs text-slate-400 mt-2 text-center">
                Showing 4 most recent read responses
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationSection;
