import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Calendar,
  ArrowLeft,
  ArrowRight,
  AlertTriangle,
  RefreshCw,
} from "lucide-react";

const WeeklyVibeAverages = ({ businessName }) => {
  const [weeklyData, setWeeklyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const itemsPerPage = 8;

  const CACHE_KEY = `weeklyVibeData_${businessName}`;
  const CACHE_EXPIRY = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

  // Function to fetch weekly data using axios
  const fetchWeeklyAverages = async (forceRefresh = false) => {
    try {
      // Check if we have cached data and it's still valid
      if (!forceRefresh) {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          const now = new Date().getTime();

          // If cache is still valid (less than CACHE_EXPIRY old)
          if (now - timestamp < CACHE_EXPIRY && data && data.length > 0) {
            console.log("Using cached weekly vibe data");
            setWeeklyData(data);
            setLastUpdated(new Date(timestamp));
            setLoading(false);
            setError(null);
            return;
          }
        }
      }

      // Fetch fresh data if cache is invalid or forceRefresh is true
      setLoading(true);

      // Add timestamp for cache busting
      const timestamp = new Date().getTime();
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/weekly-vc-scores?limit=20&_=${timestamp}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            Expires: "0",
          },
        },
      );

      // Handle and process the data
      const responseData = response.data;
      if (responseData && Array.isArray(responseData.weekly_averages)) {
        // Sort by date (newest first)
        const sortedData = [...responseData.weekly_averages].sort((a, b) => {
          return new Date(b.week_start) - new Date(a.week_start);
        });

        // Store in state
        setWeeklyData(sortedData);

        // Save to localStorage cache with timestamp
        const cacheData = {
          data: sortedData,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
        setLastUpdated(new Date());
        setError(null);
      } else {
        console.warn("Unexpected data format:", responseData);
        setWeeklyData([]);
        setError("Received unexpected data format");
      }
    } catch (err) {
      console.error("Error fetching weekly averages:", err);
      setError("Failed to fetch weekly data");

      // Try to recover from cache even if it's expired
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data } = JSON.parse(cachedData);
        if (data && data.length > 0) {
          console.log("Using expired cache data after fetch error");
          setWeeklyData(data);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle refresh button click
  const handleRefresh = () => {
    fetchWeeklyAverages(true);
  };

  // Initial fetch
  useEffect(() => {
    fetchWeeklyAverages();

    // Set up a timer to refresh the data every 12 hours
    const refreshInterval = setInterval(() => {
      fetchWeeklyAverages();
    }, CACHE_EXPIRY);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [businessName]);

  // Function to check if a date is from the current week
  const isCurrentWeek = (weekStart) => {
    const currentDate = new Date();
    const date = new Date(weekStart);

    // Get the start of the current week (Monday)
    const currentWeekStart = new Date(currentDate);
    currentWeekStart.setDate(
      currentDate.getDate() -
        currentDate.getDay() +
        (currentDate.getDay() === 0 ? -6 : 1),
    );
    currentWeekStart.setHours(0, 0, 0, 0);

    // Get the end of the current week (Sunday)
    const currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
    currentWeekEnd.setHours(23, 59, 59, 999);

    return date >= currentWeekStart && date <= currentWeekEnd;
  };

  // Pagination logic
  const startIndex = currentPage * itemsPerPage;
  const displayedData = weeklyData.slice(startIndex, startIndex + itemsPerPage);

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (startIndex + itemsPerPage < weeklyData.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  if (loading && !weeklyData.length) {
    return <div className="text-center py-4">Loading weekly data...</div>;
  }

  if (error && !weeklyData.length) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
        <div className="flex items-center">
          <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
          <p className="text-sm text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  if (!weeklyData || weeklyData.length === 0) {
    return (
      <div className="text-center text-neutral-400 py-4">
        No weekly data available yet.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-sm text-red-600">{error}</p>
          </div>
        </div>
      )}
      <div className="bg-gray-700 rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center p-3">
            <Calendar className="w-5 h-5 mr-2 text-amber-500" />
            <h3 className="text-lg font-grotesk text-amber-50">
              Weekly Vibe Check History
            </h3>
            {lastUpdated && (
              <span className="text-xs text-gray-400 ml-2">
                Last updated: {lastUpdated.toLocaleTimeString()}
              </span>
            )}
          </div>
          <div className="flex space-x-2 mr-2">
            <button
              onClick={handleRefresh}
              className="p-2 rounded hover:bg-neutral-600 transition-colors"
              title="Refresh data"
            >
              <RefreshCw className="w-4 h-4 text-gray-300" />
            </button>
            <button
              onClick={handlePrev}
              disabled={currentPage === 0}
              className="p-2 rounded hover:bg-neutral-600 disabled:opacity-50 transition-colors"
            >
              <ArrowLeft className="w-5 h-5 text-amber-500" />
            </button>
            <button
              onClick={handleNext}
              disabled={startIndex + itemsPerPage >= weeklyData.length}
              className="p-2 rounded hover:bg-neutral-600 disabled:opacity-50 transition-colors"
            >
              <ArrowRight className="w-5 h-5 text-amber-600" />
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-neutral-200">
            <thead className="bg-neutral-50">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                  Week
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                  Average Score
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                  Responses
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-neutral-200">
              {displayedData.map((week, index) => (
                <tr
                  key={index}
                  className="hover:bg-neutral-100 transition-colors"
                >
                  <td className="px-4 py-3 whitespace-nowrap">
                    <div className="text-sm text-zinc-600">
                      {new Date(week.week_start).toLocaleDateString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "America/Chicago",
                      })}{" "}
                      -{" "}
                      {new Date(week.week_end).toLocaleDateString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "America/Chicago",
                      })}
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap">
                    <div className="text-sm font-medium text-zinc-600">
                      {isCurrentWeek(week.week_start) ? (
                        <span className="text-amber-600 italic">
                          Week not posted yet
                        </span>
                      ) : typeof week.average_score === "number" ? (
                        week.average_score.toFixed(2)
                      ) : (
                        parseFloat(week.average_score).toFixed(2)
                      )}
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap">
                    <div className="text-sm text-neutral-500">
                      {isCurrentWeek(week.week_start)
                        ? "-"
                        : week.response_count}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WeeklyVibeAverages;
