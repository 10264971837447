import React from "react";
import DebugPanel from "./DebugPanel";

const NoUsersAvailableView = ({
  currentQuarter,
  currentYear,
  getQuarterDateRange,
  error,
  debugInfo,
}) => {
  return (
    <div className="bg-gray-100 p-4 my-4 rounded-md">
      <h3 className="text-xl font-semibold text-gray-800 mb-3">
        Q{currentQuarter} {currentYear} Employee Nomination
        <span className="ml-2 text-sm text-blue-600">
          ({getQuarterDateRange(currentQuarter, currentYear)})
        </span>
      </h3>
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-800 p-4">
        <p className="font-medium">No users available for nomination</p>
        <p className="mt-1">
          There are currently no other active users in the system that you can
          nominate. Please try again later.
        </p>
      </div>

      {/* Authentication Error Message */}
      {error && error.includes("Authentication") && (
        <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-3">
          <p className="font-medium">Authentication Error</p>
          <p>{error}</p>
          <p className="mt-2 text-sm">
            Please try refreshing the page or logging out and back in.
          </p>
        </div>
      )}

      {/* Use the DebugPanel component, only in non-production */}
      {/*
  {process.env.NODE_ENV !== "production" && <DebugPanel data={debugInfo} />}
     
      */}
    </div>
  );
};

export default NoUsersAvailableView;
