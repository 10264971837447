import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MasterAdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  // This is important - we're always in master admin mode at this URL
  const isMasterAdmin = true;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!username.trim() || !password.trim()) {
      setError("Username and Password cannot be empty");
      setLoading(false);
      return;
    }

    try {
      // Use the same endpoint as your original login, but always treating this as master admin
      const endpoint = isMasterAdmin ? "/api/v1/master/token" : "/api/v1/login";

      const response = await axios.post(
        endpoint,
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      if (isMasterAdmin) {
        // Store token as master_token for master admin
        localStorage.setItem("master_token", response.data.access_token);
        localStorage.setItem("is_master_admin", "true");

        // Redirect to master dashboard
        navigate("/master-admin/dashboard");
      } else {
        // Regular user login (this won't happen in this component but keeping for consistency)
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.removeItem("is_master_admin");
      }
    } catch (err) {
      setError(
        err.response?.data?.detail ||
          "Login failed. Please check your credentials.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-neutral-900">
      <div className="w-full max-w-md p-6 bg-neutral-800 rounded-md shadow-md">
        <h2 className="text-xl font-bold text-white mb-6">
          Master Admin Login
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-neutral-300 mb-1">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
              className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 text-white rounded-md focus:outline-none focus:border-amber-500"
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-neutral-300 mb-1">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 text-white rounded-md focus:outline-none focus:border-amber-500"
              required
            />
          </div>

          {error && (
            <div className="mb-4 p-2 bg-red-900/50 border border-red-800 text-red-200 text-sm rounded-md">
              {error}
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 px-4 bg-amber-700 hover:bg-amber-800 text-white font-medium rounded-md focus:outline-none transition-colors"
          >
            {loading ? "Authenticating..." : "Login"}
          </button>
        </form>

        <div className="mt-4 text-xs text-neutral-500 text-center">
          Restricted access - Authorized personnel only
        </div>
      </div>
    </div>
  );
};

export default MasterAdminLogin;
