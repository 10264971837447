import React from "react";
import {
  Search,
  RefreshCw,
  AlertCircle,
  UserPlus,
  UserCheck,
  Shield,
  Trash2,
} from "lucide-react";

const MasterAdminsList = ({
  adminLoading,
  adminError,
  masterAdmins,
  searchTerm,
  setSearchTerm,
  fetchMasterAdmins,
  setShowAdminModal,
  handleRevokeMasterAdmin,
}) => {
  const filteredAdmins = masterAdmins.filter(
    (admin) =>
      admin.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admin.email?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      {/* Actions Bar */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
        <div className="relative w-full md:w-auto">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-4 w-4" />
          <input
            type="text"
            placeholder="Search master admins..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 bg-neutral-800 border border-neutral-700 rounded-lg focus:outline-none focus:border-amber-500 w-full md:w-64 text-sm"
          />
        </div>

        <div className="flex gap-3 w-full md:w-auto">
          <button
            className="flex items-center gap-2 bg-amber-700 hover:bg-amber-600 px-4 py-2 rounded-lg text-sm font-medium transition-colors w-full md:w-auto justify-center"
            onClick={() => setShowAdminModal(true)}
          >
            <UserPlus className="h-4 w-4" />
            Add Master Admin
          </button>

          <button
            className="flex items-center justify-center bg-neutral-800 hover:bg-neutral-700 p-2 rounded-lg text-neutral-400 hover:text-white transition-colors"
            onClick={fetchMasterAdmins}
            title="Refresh"
          >
            <RefreshCw className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Master Admins List */}
      <div className="bg-neutral-800 border border-neutral-700 rounded-lg overflow-hidden">
        <div className="p-4 border-b border-neutral-700">
          <h2 className="text-lg font-medium flex items-center gap-2">
            <Shield className="h-5 w-5 text-amber-400" />
            Master Administrators
          </h2>
        </div>

        {adminLoading ? (
          <div className="p-8 flex flex-col items-center justify-center">
            <RefreshCw className="h-8 w-8 text-amber-500 mb-3 animate-spin" />
            <p className="text-neutral-400">Loading master admins...</p>
          </div>
        ) : adminError ? (
          <div className="p-8 flex flex-col items-center justify-center text-center">
            <AlertCircle className="h-8 w-8 text-red-500 mb-3" />
            <p className="text-neutral-300 mb-1">{adminError}</p>
            <p className="text-neutral-500 text-sm">
              Make sure your backend API is properly configured
            </p>
            <button
              onClick={fetchMasterAdmins}
              className="mt-4 px-4 py-2 bg-neutral-700 hover:bg-neutral-600 rounded-lg text-sm transition-colors"
            >
              Try Again
            </button>
          </div>
        ) : filteredAdmins.length === 0 ? (
          <div className="p-8 flex flex-col items-center justify-center text-center">
            <UserCheck className="h-8 w-8 text-neutral-500 mb-3" />
            <p className="text-neutral-300 mb-1">No master admins found</p>
            {searchTerm ? (
              <p className="text-neutral-500 text-sm">
                Try a different search term or clear the search
              </p>
            ) : (
              <p className="text-neutral-500 text-sm">
                Add your first master admin to get started
              </p>
            )}
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="mt-4 px-4 py-2 bg-neutral-700 hover:bg-neutral-600 rounded-lg text-sm transition-colors"
              >
                Clear Search
              </button>
            )}
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead className="bg-neutral-700/30">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-neutral-400 uppercase tracking-wider">
                    Username
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-neutral-400 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-neutral-400 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {filteredAdmins.map((admin, index) => (
                  <tr
                    key={admin.id || index}
                    className="hover:bg-neutral-700/20"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-amber-400">
                      {admin.username}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-300">
                      {admin.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                      <button
                        onClick={() => handleRevokeMasterAdmin(admin.username)}
                        className="inline-flex items-center justify-center p-2 bg-red-900/30 hover:bg-red-900/50 text-red-400 rounded-lg transition-colors"
                        title="Revoke master admin"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default MasterAdminsList;
