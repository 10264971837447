import React from "react";
import MyReviews from "../MyReviews";
import Responses from "../Responses";
import OneOnOne from "../OneonOne/OneOnOne";
import Analytics from "../Analytics";
import MyResponses from "../VibeCheck/MyResponses";
import ViewUserResponses from "../VibeCheck/ViewUserResponses";
import UserList from "../UserList";
import ENPS from "../ENPS";
import QuestionList from "../QuestionList";
import QReviews from "../QReviews";
import PushedReviewsUsers from "../PushedReviewsUsers";
import TeamReviewPage from "../TeamReviewPage";
import ManageUserPermissions from "../ManageUserPermissions";
import Teams from "../Teams";
import AdminReviews from "../AdminReviews";
import ViewAnsweredReviews from "../ViewAnsweredReviews";
import Settings from "../Settings";
import DashboardContent from "./DashboardContent";
import NotificationSection from "./NotificationSection";

const MainContentRenderer = ({
  activeSection,
  loading,
  error,
  user,
  responses,
  setResponses,
  users,
  setUsers,
  showReadNotification,
  hasReadResponses,
  setShowReadNotification,
  latestSurveyResponse,
  averageScore,
  lastCalculated,
  averageScoreError,
  getDisplayName,
  readResponses,
}) => {
  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  switch (activeSection) {
    case "Dashboard":
      console.log("READ Responses", readResponses);
      return (
        <>
          <DashboardContent
            user={user}
            showReadNotification={showReadNotification}
            hasReadResponses={hasReadResponses}
            setShowReadNotification={setShowReadNotification}
            latestSurveyResponse={latestSurveyResponse}
            averageScore={averageScore}
            lastCalculated={lastCalculated}
            averageScoreError={averageScoreError}
            getDisplayName={getDisplayName}
          />
          <NotificationSection
            readResponses={readResponses}
            responses={responses}
            currentUsername={user.username}
          />
        </>
      );
    case "Reviews":
      return <MyReviews businessName={user.business_name} user={user} />;
    case "Vibe Checks":
      return (
        <Responses
          responses={responses}
          setResponses={setResponses}
          businessName={user.business_name}
          currentUsername={user.username}
        />
      );
    case "One-on-One":
      return (
        <OneOnOne
          businessName={user.business_name}
          currentUser={user.main_user}
        />
      );
    case "Analytics":
      return user.is_admin ? (
        <Analytics businessName={user.business_name} />
      ) : null;
    case "My Responses":
      return <MyResponses responses={responses} username={user.username} />;
    case "View Responses":
      return (
        <ViewUserResponses
          responses={responses}
          setResponses={setResponses}
          businessName={user.business_name}
          currentUsername={user.username}
        />
      );
    case "Users":
      return user.is_admin ? (
        <>
          <UserList
            businessName={user.business_name}
            currentUser={user}
            users={users}
            setUsers={setUsers}
          />
        </>
      ) : null;
    case "eNPS":
      return <ENPS businessName={user.business_name} />;
    case "Questions":
      return user.is_admin ? (
        <QuestionList businessName={user.business_name} />
      ) : null;
    case "Create Reviews":
      return user.is_admin ? (
        <QReviews businessName={user.business_name} />
      ) : (
        <PushedReviewsUsers businessName={user.business_name} />
      );
    case "Answer Reviews":
      return !user.is_admin ? (
        <PushedReviewsUsers businessName={user.business_name} />
      ) : null;
    case "Team Reviews":
      return <TeamReviewPage businessName={user.business_name} />;
    case "User Permissions":
      return user.is_admin ? (
        <>
          <ManageUserPermissions businessName={user.business_name} />
          <Teams businessName={user.business_name} />
        </>
      ) : null;
    case "My Reviews":
      return user.is_admin ? (
        <AdminReviews businessName={user.business_name} />
      ) : (
        <ViewAnsweredReviews businessName={user.business_name} />
      );
    case "Settings":
      return <Settings businessName={user.business_name} />;
    default:
      return null;
  }
};

export default MainContentRenderer;
