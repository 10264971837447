import React from "react";

/**
 * Displays a single quarter's eNPS card with:
 *  - Quarter name (e.g., "Q4 2024")
 *  - eNPS score
 *  - Promoters/Passives/Detractors bars
 */
const QuarterlyENPSCard = ({ quarterData, selectedYear }) => {
  const {
    name,
    enpsScore,
    promotersPercentage,
    passivesPercentage,
    detractorsPercentage,
    totalResponses,
  } = quarterData;

  // Local helper to color the numeric eNPS score
  const getScoreColor = (score) => {
    if (score >= 50) return "#10b981"; // emerald-500
    if (score >= 30) return "#34d399"; // emerald-400
    if (score >= 10) return "#a3e635"; // lime-500
    if (score >= 0) return "#fbbf24"; // amber-400
    if (score >= -30) return "#f97316"; // orange-500
    return "#ef4444"; // red-500
  };

  // We can also give a label like "Excellent" / "Needs Work"
  const getScoreBackgroundColor = (score) => {
    if (score >= 50) return "rgba(16, 185, 129, 0.1)";
    if (score >= 30) return "rgba(52, 211, 153, 0.1)";
    if (score >= 10) return "rgba(163, 230, 53, 0.1)";
    if (score >= 0) return "rgba(251, 191, 36, 0.1)";
    if (score >= -30) return "rgba(249, 115, 22, 0.1)";
    return "rgba(239, 68, 68, 0.1)";
  };

  const getScoreLabel = (score) => {
    if (score >= 50) return "Excellent";
    if (score >= 30) return "Great";
    if (score >= 10) return "Good";
    if (score >= 0) return "Adequate";
    if (score >= -30) return "Needs Work";
    return "Critical";
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-slate-100 hover:shadow-md transition-shadow duration-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-medium text-slate-800">{name}</h3>
        <div
          className="text-xs font-semibold px-2 py-1 rounded-full"
          style={{
            backgroundColor: getScoreBackgroundColor(enpsScore),
            color: getScoreColor(enpsScore),
          }}
        >
          {getScoreLabel(enpsScore)}
        </div>
      </div>

      <div className="flex items-baseline">
        <div
          className="text-4xl font-bold"
          style={{ color: getScoreColor(enpsScore) }}
        >
          {typeof enpsScore === "number" ? enpsScore.toFixed(1) : "0.0"}
        </div>
        <div className="text-slate-400 ml-1 text-sm">/ 100</div>
      </div>

      <div className="text-xs text-slate-500 mt-1 mb-4">
        {totalResponses} {totalResponses === 1 ? "response" : "responses"}
      </div>

      <div className="space-y-2">
        {/* Promoters bar */}
        <div className="flex flex-col">
          <div className="flex justify-between items-center text-xs mb-1">
            <span className="text-emerald-600 font-medium">Promoters</span>
            <span className="font-semibold">
              {typeof promotersPercentage === "number"
                ? promotersPercentage.toFixed(1)
                : "0.0"}
              %
            </span>
          </div>
          <div className="w-full bg-slate-100 rounded-full h-2">
            <div
              className="bg-emerald-500 h-2 rounded-full"
              style={{ width: `${promotersPercentage || 0}%` }}
            ></div>
          </div>
        </div>

        {/* Passives bar */}
        <div className="flex flex-col">
          <div className="flex justify-between items-center text-xs mb-1">
            <span className="text-amber-600 font-medium">Passives</span>
            <span className="font-semibold">
              {typeof passivesPercentage === "number"
                ? passivesPercentage.toFixed(1)
                : "0.0"}
              %
            </span>
          </div>
          <div className="w-full bg-slate-100 rounded-full h-2">
            <div
              className="bg-amber-500 h-2 rounded-full"
              style={{ width: `${passivesPercentage || 0}%` }}
            ></div>
          </div>
        </div>

        {/* Detractors bar */}
        <div className="flex flex-col">
          <div className="flex justify-between items-center text-xs mb-1">
            <span className="text-red-600 font-medium">Detractors</span>
            <span className="font-semibold">
              {typeof detractorsPercentage === "number"
                ? detractorsPercentage.toFixed(1)
                : "0.0"}
              %
            </span>
          </div>
          <div className="w-full bg-slate-100 rounded-full h-2">
            <div
              className="bg-red-500 h-2 rounded-full"
              style={{ width: `${detractorsPercentage || 0}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuarterlyENPSCard;
