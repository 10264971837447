import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Users,
  Crown,
  Shield,
  Group,
  Check,
  Edit,
  Trash,
  Save,
  X,
} from "lucide-react";
import UserSelection from "./Teams/UserSelection"; // Import the user selection component

const Teams = ({ businessName }) => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [leaderId, setLeaderId] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [editingTeamId, setEditingTeamId] = useState(null);
  const [editingTeamName, setEditingTeamName] = useState("");
  const [editingLeaderId, setEditingLeaderId] = useState("");
  const [editingSelectedUsers, setEditingSelectedUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchTeams();
  }, [businessName]);

  const fetchUsers = () => {
    const token = localStorage.getItem("access_token");
    axios
      .get(`/api/v1/businesses/${businessName}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setError("Failed to fetch users. Please try again.");
      });
  };

  const fetchTeams = () => {
    const token = localStorage.getItem("access_token");
    axios
      .get(`/api/v1/businesses/${businessName}/teams`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setTeams(response.data);
      })
      .catch((error) => {
        console.error("Error fetching teams:", error);
        setError("Failed to fetch teams. Please try again.");
      });
  };

  const handleCreateTeam = () => {
    if (!teamName.trim()) {
      setError("Please enter a team name");
      return;
    }

    if (!leaderId) {
      setError("Please select a team leader");
      return;
    }

    if (selectedUsers.length === 0) {
      setError("Please select at least one team member");
      return;
    }

    const token = localStorage.getItem("access_token");
    if (!token) {
      setError("No authentication token found. Please log in again.");
      return;
    }

    axios
      .post(
        `/api/v1/businesses/${businessName}/teams`,
        {
          team_name: teamName,
          member_ids: selectedUsers.map((id) => parseInt(id)),
          leader_id: parseInt(leaderId),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then(() => {
        setSuccessMessage("Team created successfully!");
        setTeamName("");
        setSelectedUsers([]);
        setLeaderId("");
        fetchTeams();
      })
      .catch((error) => {
        console.error("Error creating team:", error);
        setError(
          error.response?.data?.detail ||
            "Failed to create team. Please try again.",
        );
      });
  };

  // Function to handle starting edit mode for a team
  const startEditTeam = (team) => {
    console.log("Starting edit mode for team:", team);

    // Convert numeric IDs to strings for form controls
    const leaderIdStr = team.leader_id ? team.leader_id.toString() : "";
    const memberIdsStr = team.member_ids
      ? team.member_ids.map((id) => id.toString())
      : [];

    console.log("Setting editing state with:");
    console.log("- Team ID:", team.id);
    console.log("- Team Name:", team.team_name);
    console.log("- Leader ID (string):", leaderIdStr);
    console.log("- Member IDs (strings):", memberIdsStr);

    setEditingTeamId(team.id);
    setEditingTeamName(team.team_name);
    setEditingLeaderId(leaderIdStr);
    setEditingSelectedUsers(memberIdsStr);

    // Clear any previous error or success message
    setError("");
    setSuccessMessage("");
  };

  // Function to cancel editing
  const cancelEdit = () => {
    setEditingTeamId(null);
    setEditingTeamName("");
    setEditingLeaderId("");
    setEditingSelectedUsers([]);
    setError("");
  };

  // Function to save team edits
  const saveTeamEdits = () => {
    console.log("Starting saveTeamEdits function");

    // Form validation
    if (!editingTeamName.trim()) {
      setError("Please enter a team name");
      return;
    }

    if (!editingLeaderId) {
      setError("Please select a team leader");
      return;
    }

    if (editingSelectedUsers.length === 0) {
      setError("Please select at least one team member");
      return;
    }

    // Get authentication token
    const token = localStorage.getItem("access_token");
    if (!token) {
      setError("No authentication token found. Please log in again.");
      return;
    }

    // Find the original team to compare changes
    const originalTeam = teams.find((t) => t.id === editingTeamId);
    const originalMemberIds = originalTeam
      ? originalTeam.member_ids.map((id) => id.toString())
      : [];

    // Identify added and removed members
    const addedMembers = editingSelectedUsers.filter(
      (id) => !originalMemberIds.includes(id),
    );
    const removedMembers = originalMemberIds.filter(
      (id) => !editingSelectedUsers.includes(id),
    );

    // Log the changes
    console.log("Team ID:", editingTeamId);
    console.log("Team Name:", editingTeamName);
    console.log("Leader ID:", editingLeaderId);
    console.log("Member IDs:", editingSelectedUsers);
    console.log("Added members:", addedMembers);
    console.log("Removed members:", removedMembers);

    // Prepare update data
    const updateData = {
      team_name: editingTeamName,
      leader_id: parseInt(editingLeaderId),
      member_ids: editingSelectedUsers.map((id) => parseInt(id)),
    };

    console.log("Sending update data:", updateData);
    console.log(
      `PUT URL: /api/v1/businesses/${businessName}/teams/${editingTeamId}`,
    );

    // Send update request
    axios
      .put(
        `/api/v1/businesses/${businessName}/teams/${editingTeamId}`,
        updateData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        console.log("Response:", response.data);

        // Get usernames for the success message
        const addedUsernames = addedMembers.map((id) => {
          const user = users.find((u) => u.id.toString() === id);
          return user ? user.username : id;
        });

        const removedUsernames = removedMembers.map((id) => {
          const user = users.find((u) => u.id.toString() === id);
          return user ? user.username : id;
        });

        // Create a detailed success message
        let successMsg = "Team updated successfully!";
        if (addedUsernames.length > 0) {
          successMsg += ` Added: ${addedUsernames.join(", ")}.`;
        }
        if (removedUsernames.length > 0) {
          successMsg += ` Removed: ${removedUsernames.join(", ")}.`;
        }

        setSuccessMessage(successMsg);
        cancelEdit();
        fetchTeams();
      })
      .catch((error) => {
        console.error("Error updating team:", error);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);

          setError(
            error.response.data?.detail ||
              `Server error (${error.response.status}): Failed to update team. Please try again.`,
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error request:", error.request);
          setError(
            "No response received from server. Please check your network connection and try again.",
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
          setError(`Request error: ${error.message}`);
        }
      });
  };

  // Function to delete a team
  const deleteTeam = (teamId) => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      const token = localStorage.getItem("access_token");
      axios
        .delete(`/api/v1/businesses/${businessName}/teams/${teamId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          setSuccessMessage("Team deleted successfully!");
          fetchTeams();
        })
        .catch((error) => {
          console.error("Error deleting team:", error);
          setError(
            error.response?.data?.detail ||
              "Failed to delete team. Please try again.",
          );
        });
    }
  };

  return (
    <div className="min-h-screen bg-neutral-50 text-zinc-700 mt-6 rounded-lg">
      <div className="max-w-6xl mx-auto px-8 py-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8 mt-3">
          <h1 className="text-xl font-light font-grotesk tracking-wide text-gray-700 pb-4">
            Team Management
          </h1>
          <Users className="w-5 h-5 text-slate-500" />
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 p-4 bg-rose-50 border-l border-rose-500 text-rose-600">
            {error}
          </div>
        )}

        {successMessage && (
          <div className="mb-6 p-4 bg-emerald-50 border-l border-emerald-500 text-emerald-600 flex items-center gap-2">
            <Check className="w-5 h-5" />
            {successMessage}
          </div>
        )}

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            {editingTeamId ? (
              <div className="space-y-6">
                <UserSelection
                  users={users}
                  selectedUsers={editingSelectedUsers}
                  setSelectedUsers={setEditingSelectedUsers}
                  title="Edit Team Members"
                />

                {/* Current Team Members Section */}
                <div className="mt-4 p-4 bg-gray-50 border border-gray-200 rounded">
                  <h3 className="text-sm font-medium mb-3 text-zinc-600 font-grotesk">
                    Current Team Members ({editingSelectedUsers.length})
                  </h3>

                  {editingSelectedUsers.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {editingSelectedUsers.map((userId) => {
                        const user = users.find(
                          (u) => u.id.toString() === userId,
                        );
                        return user ? (
                          <div
                            key={`member-${userId}`}
                            className="bg-white flex items-center gap-1 px-3 py-1 rounded border border-zinc-200"
                          >
                            <span className="text-sm text-zinc-700">
                              {user.username}
                            </span>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                console.log("Removing member with ID:", userId);
                                setEditingSelectedUsers((prev) =>
                                  prev.filter((id) => id !== userId),
                                );
                              }}
                              className="flex items-center text-rose-500 hover:text-rose-700 ml-1 py-1 px-2 text-xs hover:bg-rose-50 rounded transition-colors duration-200"
                              title="Remove member"
                            >
                              <X className="w-3 h-3 mr-1" />
                              <span>Remove</span>
                            </button>
                          </div>
                        ) : null;
                      })}
                    </div>
                  ) : (
                    <div className="text-zinc-500 text-sm">
                      No members selected. Please select at least one team
                      member.
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <UserSelection
                users={users}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            )}
          </div>

          {/* Right Column - Team Creation or Editing */}
          <div className="bg-neutral-50 p-6 border-l border-neutral-200 shadow-lg rounded">
            <h2 className="text-md font-light mb-6 flex font-grotesk items-center gap-2 text-zinc-700">
              <Group className="w-5 h-5 text-slate-500" />
              {editingTeamId ? "Edit Team" : "Create New Team"}
            </h2>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2 text-zinc-600 font-grotesk">
                  Team Name
                </label>
                <input
                  type="text"
                  value={editingTeamId ? editingTeamName : teamName}
                  onChange={(e) =>
                    editingTeamId
                      ? setEditingTeamName(e.target.value)
                      : setTeamName(e.target.value)
                  }
                  placeholder="Enter team name"
                  className="w-full px-4 py-3 bg-neutral-50 border border-gray-100 focus:border-zinc-400 transition-colors duration-200 text-zinc-700 font-grotesk"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-3 text-zinc-600 font-grotesk">
                  Team Leader
                </label>
                <select
                  value={editingTeamId ? editingLeaderId : leaderId}
                  onChange={(e) =>
                    editingTeamId
                      ? setEditingLeaderId(e.target.value)
                      : setLeaderId(e.target.value)
                  }
                  className="w-full mb-10 font-grotesk px-4 py-3 bg-neutral-50 border border-zinc-200 focus:border-zinc-400 transition-colors duration-200 text-zinc-700"
                >
                  <option value="">Select team leader</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.username}
                    </option>
                  ))}
                </select>
              </div>

              {editingTeamId ? (
                <div className="flex gap-3">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("Save button clicked with:", {
                        teamId: editingTeamId,
                        teamName: editingTeamName,
                        leaderId: editingLeaderId,
                        memberIds: editingSelectedUsers,
                      });
                      saveTeamEdits();
                    }}
                    disabled={
                      !editingTeamName ||
                      !editingLeaderId ||
                      editingSelectedUsers.length === 0
                    }
                    className={`flex-1 px-6 py-2 rounded bg-amber-700 text-gray-50 font-grotesk flex items-center justify-center gap-2 transition-all duration-200 shadow-lg border border-amber-600 ${
                      editingTeamName &&
                      editingLeaderId &&
                      editingSelectedUsers.length > 0
                        ? "hover:bg-zinc-300"
                        : "opacity-70 cursor-not-allowed"
                    }`}
                  >
                    <Save className="w-5 h-5 text-slate-300" />
                    Save Changes
                    {!editingTeamName && (
                      <span className="text-xs ml-1">(name missing)</span>
                    )}
                    {!editingLeaderId && (
                      <span className="text-xs ml-1">(leader missing)</span>
                    )}
                    {editingSelectedUsers.length === 0 && (
                      <span className="text-xs ml-1">(members missing)</span>
                    )}
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("Cancel button clicked");
                      cancelEdit();
                    }}
                    className="px-6 py-2 rounded bg-slate-200 text-zinc-700 font-grotesk flex items-center justify-center gap-2 transition-all duration-200 border border-zinc-300 hover:bg-slate-300"
                  >
                    <X className="w-5 h-5" />
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleCreateTeam}
                  disabled={
                    !teamName || !leaderId || selectedUsers.length === 0
                  }
                  className={`w-full px-6 py-2 rounded bg-amber-700 text-gray-50 font-grotesk flex items-center justify-center gap-2 transition-all duration-200 shadow-lg border border-amber-600 ${
                    teamName && leaderId && selectedUsers.length > 0
                      ? "hover:bg-zinc-300"
                      : "opacity-70 cursor-not-allowed"
                  }`}
                >
                  <Shield className="w-5 h-5 text-slate-300" />
                  Create Team
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Existing Teams Section */}
        <div className="mt-8 bg-white p-6 border-l border-zinc-200 shadow-xl">
          <h2 className="text-md font-light mb-6 flex font-grotesk items-center gap-2 text-zinc-700">
            <Users className="w-4 h-4 text-slate-500" />
            Existing Teams
          </h2>

          {teams.length === 0 ? (
            <div className="text-center p-8 text-zinc-500 font-grotesk">
              No teams created yet. Create your first team above.
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {teams.map((team) => {
                const leader = users.find((u) => u.id === team.leader_id);
                const members = team.member_ids
                  .map((memberId) => users.find((u) => u.id === memberId))
                  .filter(Boolean);

                return (
                  <div
                    key={team.id}
                    className="p-4 bg-neutral-50 border-l border-zinc-200 hover:border-zinc-400 transition-colors duration-200"
                  >
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="text-md font-medium text-gray-600 font-grotesk">
                        {team.team_name}
                      </h3>
                      <div className="flex gap-2">
                        <button
                          onClick={(e) => {
                            console.log("Edit button clicked for team:", team);
                            e.preventDefault();
                            startEditTeam(team);
                          }}
                          className="p-1 text-amber-600 hover:text-amber-800 transition-colors duration-200"
                          title="Edit team"
                          disabled={editingTeamId !== null}
                        >
                          <Edit className="w-4 h-4" />
                        </button>
                        <button
                          onClick={(e) => {
                            console.log(
                              "Delete button clicked for team:",
                              team,
                            );
                            e.preventDefault();
                            deleteTeam(team.id);
                          }}
                          className="p-1 text-rose-600 hover:text-rose-800 transition-colors duration-200"
                          title="Delete team"
                          disabled={editingTeamId !== null}
                        >
                          <Trash className="w-4 h-4" />
                        </button>
                      </div>
                    </div>

                    <div className="space-y-3">
                      <div className="flex items-center gap-2 text-zinc-600">
                        <Crown className="w-4 h-4 text-amber-500" />
                        <span className="font-grotesk text-md">
                          {leader?.username || "Unknown Leader"}
                        </span>
                      </div>

                      <div>
                        <div className="text-sm text-zinc-600 mb-2 font-grotesk">
                          Members:
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {members.map((member) => (
                            <div
                              key={member.id}
                              className="bg-slate-200 px-2 mb-1 py-1 rounded text-xs text-zinc-700 font-grotesk"
                            >
                              {member.username}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Teams;
