import { useState } from "react";

const useModalManagement = (initialFormState) => {
  const [formData, setFormData] = useState(initialFormState);
  const [showModal, setShowModal] = useState(false);
  const [creationStatus, setCreationStatus] = useState({
    isCreating: false,
    success: false,
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData(initialFormState);
  };

  const resetStatus = () => {
    setCreationStatus({
      isCreating: false,
      success: false,
      message: "",
    });
  };

  const closeModalWithSuccess = (successMessage, onSuccessCallback) => {
    setCreationStatus({
      isCreating: false,
      success: true,
      message: successMessage,
    });

    // Reset form
    resetForm();

    // Close modal after successful creation
    setTimeout(() => {
      setShowModal(false);

      // Reset status message after closing modal
      setTimeout(() => {
        resetStatus();
      }, 300);

      // Run callback if provided
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }, 1500);
  };

  const setError = (errorMessage) => {
    setCreationStatus({
      isCreating: false,
      success: false,
      message: errorMessage,
    });
  };

  const setProcessing = (processingMessage = "Processing...") => {
    setCreationStatus({
      isCreating: true,
      success: false,
      message: processingMessage,
    });
  };

  return {
    formData,
    setFormData,
    showModal,
    setShowModal,
    creationStatus,
    handleInputChange,
    resetForm,
    resetStatus,
    closeModalWithSuccess,
    setError,
    setProcessing,
  };
};

export default useModalManagement;
