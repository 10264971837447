import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PushReview from "./PushReview";
import ReviewCard from "./QReviews/ReviewCard";

const QReviews = ({ businessName }) => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    fetchReviews();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
    }
  };

  const handleReviewClick = (review) => {
    setSelectedReview(review);
  };

  const handleCreateReview = () => {
    navigate(`/businesses/${businessName}/create-review`);
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(
        `/api/v1/businesses/${businessName}/reviews/${reviewId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchReviews(); // Refresh the reviews list
    } catch (err) {
      console.error("Error deleting review:", err);
      setError("Failed to delete review. Please try again.");
    }
  };

  const displayedReviews = showAllReviews ? reviews : reviews.slice(-5);

  return (
    <div className="bg-gray-200/30 text-zinc-800 mt-24">
      <div className="relative">
        <div className="relative flex items-center justify-start p-2 bg-gray-800 shadow-2xl border-l-2 border-gray-50 rounded-md">
          <h1 className="text-md font-light ml-2 tracking-wide font-grotesk text-amber-400">
            Review Management
          </h1>
        </div>
      </div>

      <div className="mt-4 space-y-6">
        {/* Create New Review Section */}
        <div className="bg-gray-200 rounded-md shadow-2xl border-l border-gray-50 transition-all duration-300 hover:border-zinc-400 overflow-hidden relative">
          <div className="relative p-6 mb-2 mt-2">
            <h3 className="flex items-center text-md font-light font-grotesk mb-4 text-zinc-700">
              <span className="mr-2 font-grotesk tracking-wider">
                Create New Review
              </span>
              <div className="h-px flex-grow bg-zinc-200 ml-4"></div>
            </h3>

            <div className="bg-gray-100 p-5 rounded-md border border-zinc-100 shadow-md mb-6">
              <p className="text-zinc-700 text-xs mb-4 font-grotesk">
                Want to create a new review? Click the button below. It will
                make a template and place it in "Review Templates" section. You
                could then select it from the "Push Review" section.
              </p>
              <button
                onClick={handleCreateReview}
                className="w-full py-2 px-4 bg-gray-700 text-sm font-grotesk border border-zinc-200 text-zinc-100 font-light rounded-md hover:bg-neutral-300 hover:text-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
              >
                Create New Review
              </button>
            </div>

            <div className="bg-gray-100 p-5 rounded-md border border-zinc-100 shadow-md">
              <PushReview businessName={businessName} />
            </div>
          </div>
        </div>

        {/* Existing Reviews Section */}
        <div className="bg-gray-200 rounded-md shadow-2xl border-l border-gray-50 transition-all duration-300 hover:border-zinc-400 overflow-hidden relative">
          <div className="relative p-6 mt-2">
            <h3 className="flex items-center text-md font-light font-grotesk mb-6 text-zinc-700">
              <span className="mr-2 font-grotesk tracking-wider">
                Review Templates
              </span>
              <div className="h-px flex-grow bg-zinc-200 ml-4"></div>
            </h3>

            <div className="bg-gray-100 p-5 rounded-md border border-zinc-100 shadow-md">
              <p className="text-zinc-700 text-xs font-grotesk mb-4">
                These are all of your review templates. Delete the ones you
                don't want. Create new ones with the button above.
              </p>

              <div className="space-y-3 max-h-[calc(100vh-300px)] overflow-y-auto mt-4 pr-1">
                {displayedReviews.length === 0 ? (
                  <div className="text-center p-4 bg-gray-50 rounded-md text-zinc-500 font-grotesk">
                    No review templates available. Create one to get started.
                  </div>
                ) : (
                  displayedReviews.map((review) => (
                    <ReviewCard
                      key={review.id}
                      review={review}
                      onReviewClick={handleReviewClick}
                      onDeleteReview={handleDeleteReview}
                    />
                  ))
                )}
              </div>

              {reviews.length > 5 && (
                <button
                  onClick={() => setShowAllReviews(!showAllReviews)}
                  className="mt-4 w-full py-2 px-4 bg-zinc-50 border border-zinc-200 text-zinc-600 text-sm rounded-md hover:bg-zinc-100 transition-colors"
                >
                  {showAllReviews
                    ? "Show Less"
                    : `Show All (${reviews.length})`}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="text-rose-400 p-4 mt-6 border border-rose-600 bg-rose-950/20 rounded-md">
          {error}
        </div>
      )}

      {selectedReview && (
        <ReviewContent
          review={selectedReview}
          onClose={() => setSelectedReview(null)}
        />
      )}
    </div>
  );
};

const ReviewContent = ({ review, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-900/80 flex items-center justify-center z-50 p-4">
      <div className="bg-neutral-100 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-8">
          <h2 className="text-xl font-grotesk font-medium text-zinc-800 mb-6 pb-3 border-b border-zinc-100">
            Review Details
          </h2>

          <div className="mb-4 bg-gray-300 p-4 rounded-md shadow-md">
            <h3 className="text-gray-600 text-sm uppercase mb-2">
              Review Description
            </h3>
            <p className="text-zinc-800">{review.reviews_text}</p>
          </div>

          {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => {
            const question = review[`question_${num}`];
            if (question) {
              return (
                <div
                  key={num}
                  className="mb-4 bg-gray-200 p-4 rounded-md shadow-sm"
                >
                  <h3 className="text-zinc-500 text-sm uppercase mb-2">
                    Question {num}
                  </h3>
                  <p className="text-zinc-800">{question}</p>
                </div>
              );
            }
            return null;
          })}

          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-slate-700 border border-zinc-200 text-zinc-100 font-grotesk rounded-md hover:bg-zinc-100 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QReviews;
