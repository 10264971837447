import React from "react";

/**
 * Debug panel component for displaying debugging information
 * Can be conditionally rendered in development environments
 */
const DebugPanel = ({ data }) => {
  return (
    <div className="mt-4 p-3 bg-gray-800 text-white rounded-md text-xs font-mono overflow-x-auto">
      <details>
        <summary className="cursor-pointer">Debug Information!</summary>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </details>
    </div>
  );
};

export default DebugPanel;
