import React, { useState, useEffect } from "react";
import axios from "axios";

const Settings = ({ businessName }) => {
  // State variables for password change
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // State variables for profile update
  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");

  // Error and success state
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Helper function to parse error messages
  const parseErrorMessage = (error) => {
    if (typeof error === "string") return error;
    if (Array.isArray(error))
      return error.map((e) => e.msg || String(e)).join(", ");
    if (typeof error === "object") {
      if (error.msg) return error.msg;
      return Object.values(error).map(parseErrorMessage).join(", ");
    }
    return "An unknown error occurred";
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSuccess(response.data.message || "Password changed successfully");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      console.error("Error response:", err.response?.data);
      setError(
        parseErrorMessage(err.response?.data?.detail) ||
          "Failed to change password",
      );
    }
  };

  // Handle profile update (username and/or email)
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const updatedFields = {};
    if (newUsername) updatedFields.new_username = newUsername;
    if (newEmail) updatedFields.new_email = newEmail;

    if (Object.keys(updatedFields).length === 0) {
      setError("No fields to update");
      return;
    }

    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/update-profile`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSuccess(response.data.message || "Profile updated successfully");
      setNewUsername("");
      setNewEmail("");
    } catch (err) {
      console.error("Profile update error:", err.response?.data);
      setError(
        parseErrorMessage(err.response?.data?.detail) ||
          "Failed to update profile",
      );
    }
  };
  return (
    <div className="bg-slate-800 p-10 rounded-lg mt-4 shadow-2xl">
      <h2 className="text-xl mb-4 text-white font-serif">My User Settings</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-cyan-300 mb-4 text-right">{success}</p>}

      {/* Password Change Form */}
      <form onSubmit={handlePasswordChange} className="mb-8">
        <h3 className="text-xl mb-4 text-center text-cyan-500 font-serif">
          Change Password
        </h3>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="currentPassword"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              className="mt-1 block p-2 w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="newPassword"
              className="block mb-2 text-sm font-medium text-gray-300"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="mt-1 block p-2 w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block mb-2 text-sm font-medium text-gray-300"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="mt-1 block p-2 w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Change Password
          </button>
        </div>
      </form>

      {/* Profile Update Form */}
      <form onSubmit={handleProfileUpdate}>
        <h3 className="text-xl font-serif mb-4 text-center text-cyan-500">
          Update Profile
        </h3>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="newUsername"
              className="block mb-2 text-sm font-medium text-gray-300"
            >
              New Username
            </label>
            <input
              type="text"
              id="newUsername"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              className="mt-1 block p-2 w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="newEmail"
              className="block mb-2 text-sm font-medium text-gray-300"
            >
              New Email
            </label>
            <input
              type="email"
              id="newEmail"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="mt-1 block p-2 w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default Settings;
