import React, { useState, useEffect } from "react";
import axios from "axios";
import NominationDisplay from "./NominationDisplay";
import NominationForm from "./NominationForm";
import NoUsersAvailableView from "./NoUsersAvailableView";
import userFetchingService from "./userFetchingService";

const QuarterlyNominations = ({
  businessName,
  user,
  onNominationSubmitted,
}) => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [currentNomination, setCurrentNomination] = useState(null);
  const [currentQuarter, setCurrentQuarter] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [debugInfo, setDebugInfo] = useState({});

  // Helper function to calculate previous quarter
  const getPreviousQuarter = (quarter, year) => {
    if (quarter === 1) {
      return { quarter: 4, year: year - 1 };
    } else {
      return { quarter: quarter - 1, year };
    }
  };

  // Set current quarter and year
  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const quarter = Math.floor(month / 3) + 1;

    console.log(
      `Component Date: ${now.toLocaleDateString()}, Detected Quarter: ${quarter}`,
    );

    setCurrentQuarter(quarter);
    setCurrentYear(year);
  }, []);

  // Calculate nomination period (previous quarter)
  const nominationPeriod = currentQuarter
    ? getPreviousQuarter(currentQuarter, currentYear)
    : { quarter: null, year: null };
  const nominationQuarter = nominationPeriod.quarter;
  const nominationYear = nominationPeriod.year;

  useEffect(() => {
    const checkNominationStatus = async () => {
      try {
        setLoading(true);
        console.log(
          `[NOMINATION DEBUG] Checking for business: ${businessName}, User:`,
          user?.username || user?.auth_user?.username,
        );

        // Get the authentication token
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("[NOMINATION DEBUG] No authentication token found");
          setError(
            "Authentication token not found. Please try logging in again.",
          );
          return;
        }

        // Direct API call to check status
        try {
          const statusResponse = await axios.get(
            `/api/v1/businesses/${businessName}/quarterly-nominations/status`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          console.log(
            "[NOMINATION DEBUG] Status API response:",
            statusResponse.data,
          );

          // Make sure we set these correctly
          const hasSubmittedNomination =
            statusResponse.data.has_submitted === true;
          setHasSubmitted(hasSubmittedNomination);
          setCurrentNomination(statusResponse.data.nomination);

          console.log(
            `[NOMINATION DEBUG] Has submitted: ${hasSubmittedNomination}`,
          );
          console.log(
            `[NOMINATION DEBUG] Current nomination:`,
            statusResponse.data.nomination,
          );

          if (statusResponse.data.current_quarter) {
            setCurrentQuarter(statusResponse.data.current_quarter);
            setCurrentYear(statusResponse.data.current_year);
          }

          // Only fetch users if the user hasn't submitted a nomination
          if (!hasSubmittedNomination) {
            const usersResult = await userFetchingService.fetchUsers(
              businessName,
              user,
              token,
            );

            setActiveUsers(usersResult.users);
            console.log(
              `[NOMINATION DEBUG] Fetched ${usersResult.users.length} active users`,
            );

            setDebugInfo({
              ...statusResponse.data,
              usersDebug: usersResult.debug,
            });
          } else {
            setDebugInfo({
              ...statusResponse.data,
            });
          }
        } catch (apiError) {
          console.error(
            "[NOMINATION DEBUG] Error in API call to check status:",
            apiError.response?.status,
            apiError.message,
          );

          // Fallback to the service method
          const statusResult = await userFetchingService.fetchNominationStatus(
            businessName,
            token,
          );

          setHasSubmitted(statusResult.hasSubmitted);
          setCurrentNomination(statusResult.currentNomination);

          if (statusResult.currentQuarter) {
            setCurrentQuarter(statusResult.currentQuarter);
            setCurrentYear(statusResult.currentYear);
          }

          // Only fetch users if needed
          if (!statusResult.hasSubmitted) {
            const usersResult = await userFetchingService.fetchUsers(
              businessName,
              user,
              token,
            );
            setActiveUsers(usersResult.users);
          }

          setDebugInfo({
            ...statusResult.debug,
            apiError: {
              message: apiError.message,
              status: apiError.response?.status,
              data: apiError.response?.data,
            },
          });
        }
      } catch (err) {
        console.error(
          "[NOMINATION DEBUG] General error in nomination component:",
          err,
        );
        setError("Error checking nomination status. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (businessName) {
      checkNominationStatus();
    }
  }, [businessName, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!selectedUser) {
      setError("Please select a user to nominate");
      return;
    }

    if (!reason.trim()) {
      setError("Please provide a reason for your nomination");
      return;
    }

    try {
      setSubmitting(true);

      // Get the authentication token
      const token = localStorage.getItem("access_token");
      if (!token) {
        setError(
          "Authentication token not found. Please try logging in again.",
        );
        return;
      }

      // Perform the API call
      try {
        const response = await axios.post(
          `/api/v1/businesses/${businessName}/quarterly-nominations`,
          {
            nominee_id: parseInt(selectedUser),
            reason: reason.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );

        console.log("[NOMINATION DEBUG] Submission response:", response.data);

        setHasSubmitted(true);
        setCurrentNomination(response.data);
        setSuccessMessage("Nomination submitted successfully!");

        // Call the callback if provided
        if (typeof onNominationSubmitted === "function") {
          onNominationSubmitted();
        }
      } catch (submitError) {
        console.error(
          "[NOMINATION DEBUG] Error submitting nomination:",
          submitError.response?.status,
          submitError.message,
        );
        throw submitError; // Re-throw to be caught by outer catch
      }
    } catch (err) {
      console.error("[NOMINATION DEBUG] Error handling submission:", err);
      setError(err.response?.data?.detail || "Error submitting nomination");
    } finally {
      setSubmitting(false);
    }
  };

  const getQuarterDateRange = (quarter, year) => {
    switch (quarter) {
      case 1:
        return `January 1 - March 31, ${year}`;
      case 2:
        return `April 1 - June 30, ${year}`;
      case 3:
        return `July 1 - September 30, ${year}`;
      case 4:
        return `October 1 - December 31, ${year}`;
      default:
        return "Current quarter";
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        Loading quarterly nomination form...
      </div>
    );
  }

  // Debug output before rendering
  console.log("[NOMINATION DEBUG] Render state:", {
    hasSubmitted,
    currentNomination,
    nominationQuarter,
    nominationYear,
  });

  // Already submitted nomination view - Use the NominationDisplay component
  if (hasSubmitted && currentNomination) {
    console.log(
      "[NOMINATION DEBUG] Showing NominationDisplay for submitted nomination",
    );
    return (
      <NominationDisplay
        nomination={currentNomination}
        quarter={nominationQuarter}
        year={nominationYear}
      />
    );
  }

  // No users available to nominate - Use the NoUsersAvailableView component
  if (activeUsers.length === 0) {
    console.log("[NOMINATION DEBUG] No users available for nomination");
    return (
      <NoUsersAvailableView
        currentQuarter={nominationQuarter}
        currentYear={nominationYear}
        getQuarterDateRange={getQuarterDateRange}
        error={error}
        debugInfo={debugInfo}
      />
    );
  }

  // Use the NominationForm component for the form view
  console.log("[NOMINATION DEBUG] Showing nomination form");
  return (
    <NominationForm
      activeUsers={activeUsers}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      reason={reason}
      setReason={setReason}
      submitting={submitting}
      error={error}
      successMessage={successMessage}
      handleSubmit={handleSubmit}
      currentQuarter={currentQuarter}
      currentYear={currentYear}
      getQuarterDateRange={getQuarterDateRange}
    />
  );
};

export default QuarterlyNominations;
