import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

const FuturisticHeader = ({
  setShowLoginForm,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) => {
  const canvasRef = useRef(null);

  const handleLoginClick = (e) => {
    e.preventDefault();
    setShowLoginForm(true);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 80, // Offset to account for header height
        behavior: "smooth",
      });
      // Close mobile menu if it's open
      if (isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    }
  };

  // Particle animation for header background
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    const parentRect = canvas.parentElement.getBoundingClientRect();
    canvas.width = parentRect.width;
    canvas.height = parentRect.height;

    const particles = [];
    const particleCount = Math.min(Math.floor(canvas.width / 50), 20);
    const connectionDistance = 80;

    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 1 + 0.5,
        speedX: Math.random() * 0.3 - 0.15,
        speedY: Math.random() * 0.3 - 0.15,
        hue: 150 + Math.random() * 50, // cyan-green hues
      });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Update and draw particles
      for (let i = 0; i < particles.length; i++) {
        const p = particles[i];

        p.x += p.speedX;
        p.y += p.speedY;

        if (p.x < 0 || p.x > canvas.width) p.speedX *= -1;
        if (p.y < 0 || p.y > canvas.height) p.speedY *= -1;

        ctx.fillStyle = `hsla(${p.hue}, 100%, 50%, 0.7)`;
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
        ctx.fill();

        for (let j = i + 1; j < particles.length; j++) {
          const p2 = particles[j];
          const dx = p.x - p2.x;
          const dy = p.y - p2.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < connectionDistance) {
            ctx.strokeStyle = `hsla(${p.hue}, 100%, 50%, ${0.2 * (1 - distance / connectionDistance)})`;
            ctx.lineWidth = 0.3;
            ctx.beginPath();
            ctx.moveTo(p.x, p.y);
            ctx.lineTo(p2.x, p2.y);
            ctx.stroke();
          }
        }
      }

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      const parentRect = canvas.parentElement.getBoundingClientRect();
      canvas.width = parentRect.width;
      canvas.height = parentRect.height;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Glitch effect for logo
  const GlitchText = ({ text, className }) => {
    return (
      <div className={`relative ${className}`}>
        <span className="block">{text}</span>
        <span
          className="absolute top-0 left-0 right-0 text-cyan-500 opacity-70"
          style={{
            clipPath: "inset(10% 0 60% 0)",
            animation: "glitch-anim 3s infinite linear alternate-reverse",
            transform: "translateX(-1px)",
          }}
        >
          {text}
        </span>
        <span
          className="absolute top-0 left-0 right-0 text-emerald-500 opacity-70"
          style={{
            clipPath: "inset(40% 0 10% 0)",
            animation: "glitch-anim 2s infinite linear alternate-reverse",
            transform: "translateX(1px)",
          }}
        >
          {text}
        </span>
        <style jsx>{`
          @keyframes glitch-anim {
            0% {
              clip-path: inset(20% 0 30% 0);
              transform: translateX(-1px);
            }
            20% {
              clip-path: inset(40% 0 40% 0);
              transform: translateX(1px);
            }
            40% {
              clip-path: inset(10% 0 50% 0);
              transform: translateX(-0.5px);
            }
            60% {
              clip-path: inset(50% 0 10% 0);
              transform: translateX(0.5px);
            }
            80% {
              clip-path: inset(30% 0 30% 0);
              transform: translateX(1px);
            }
            100% {
              clip-path: inset(20% 0 20% 0);
              transform: translateX(-1px);
            }
          }
        `}</style>
      </div>
    );
  };

  return (
    <header className="fixed w-full top-0 z-50 backdrop-blur-lg bg-black/70 border-b border-cyan-900/50 h-12 md:h-16 overflow-hidden">
      <canvas ref={canvasRef} className="absolute inset-0 z-0" />

      {/* Horizontal animated line */}
      <motion.div
        className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent"
        animate={{
          opacity: [0.2, 0.8, 0.2],
          scaleY: [1, 1.5, 1],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />

      <div className="container mx-auto px-4 flex justify-between items-center h-full relative z-10">
        <div className="flex items-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <GlitchText
              text="MATCHA"
              className="font-grotesk font-semibold ml-4 text-2xl tracking-wider md:text-4xl bg-gradient-to-r from-emerald-300 via-cyan-200 to-emerald-300 bg-clip-text text-transparent"
            />
          </motion.div>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6">
          {/* About link */}
          <button
            onClick={() => scrollToSection("about")}
            className="relative text-neutral-300 transition-colors group font-grotesk"
          >
            <motion.span
              className="relative z-10"
              whileHover={{
                color: "#10b981",
                textShadow: "0 0 8px rgba(16, 185, 129, 0.7)",
              }}
            >
              About
            </motion.span>
            <motion.span
              className="absolute inset-0 bg-emerald-900/0 -z-10 rounded-md"
              initial={{ scale: 1.1, opacity: 0 }}
              whileHover={{
                scale: 1.1,
                opacity: 1,
                backgroundColor: "rgba(16, 185, 129, 0.2)",
              }}
              transition={{ duration: 0.3 }}
            />
          </button>

          {/* Contact link */}
          <button
            onClick={() => scrollToSection("contact")}
            className="relative text-neutral-300 transition-colors group font-grotesk"
          >
            <motion.span
              className="relative z-10"
              whileHover={{
                color: "#10b981",
                textShadow: "0 0 8px rgba(16, 185, 129, 0.7)",
              }}
            >
              Contact
            </motion.span>
            <motion.span
              className="absolute inset-0 bg-emerald-900/0 -z-10 rounded-md"
              initial={{ scale: 1.1, opacity: 0 }}
              whileHover={{
                scale: 1.1,
                opacity: 1,
                backgroundColor: "rgba(16, 185, 129, 0.2)",
              }}
              transition={{ duration: 0.3 }}
            />
          </button>

          {/* Login button */}
          <motion.button
            onClick={handleLoginClick}
            className="px-6 py-2 text-neutral-200 relative group overflow-hidden font-grotesk rounded"
            whileHover={{
              boxShadow: "0 0 15px rgba(16, 185, 129, 0.5)",
            }}
          >
            <motion.span
              className="relative z-10"
              whileHover={{
                color: "#f59e0b",
                textShadow: "0 0 8px rgba(245, 158, 11, 0.7)",
              }}
            >
              Login
            </motion.span>
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-emerald-800/40 to-cyan-800/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              whileHover={{
                opacity: 1,
              }}
            />

            {/* Animated border effect */}
            <motion.div
              className="absolute inset-0 rounded pointer-events-none"
              style={{
                background: "none",
                border: "1px solid transparent",
                borderImageSlice: 1,
                borderImageSource:
                  "linear-gradient(90deg, transparent, rgba(16, 185, 129, 0.5), transparent)",
              }}
              animate={{
                opacity: [0, 1, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
          </motion.button>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden flex items-center space-x-4">
          <motion.button
            onClick={handleLoginClick}
            className="px-4 py-1.5 text-neutral-100 text-sm rounded-lg relative group overflow-hidden font-grotesk"
            whileHover={{
              y: -2,
              boxShadow: "0 0 10px rgba(16, 185, 129, 0.5)",
            }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="relative z-10">Login</span>
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 to-cyan-500/20 opacity-0"
              whileHover={{
                opacity: 1,
              }}
            />
          </motion.button>

          <motion.button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-neutral-300 p-1 rounded-full"
            whileHover={{
              backgroundColor: "rgba(16, 185, 129, 0.2)",
              color: "#10b981",
            }}
            whileTap={{ scale: 0.95 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </motion.button>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            className="md:hidden absolute top-full left-0 right-0 backdrop-blur-xl bg-black/80 border-t border-cyan-900/50"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="py-4 px-4 space-y-4">
              <button
                onClick={() => scrollToSection("about")}
                className="block w-full text-left text-neutral-300 hover:text-emerald-400 font-grotesk relative group overflow-hidden"
              >
                <motion.span
                  className="relative z-10 flex items-center"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  About
                  <motion.span
                    className="ml-2 text-emerald-500 opacity-0 group-hover:opacity-100"
                    initial={{ width: 0 }}
                    whileHover={{ width: "auto" }}
                  >
                    →
                  </motion.span>
                </motion.span>
                <motion.div
                  className="absolute bottom-0 left-0 h-px w-0 bg-gradient-to-r from-emerald-500 to-cyan-500"
                  whileHover={{ width: "100%" }}
                  transition={{ duration: 0.3 }}
                />
              </button>
              <button
                onClick={() => scrollToSection("contact")}
                className="block w-full text-left text-neutral-300 hover:text-emerald-400 font-grotesk relative group overflow-hidden"
              >
                <motion.span
                  className="relative z-10 flex items-center"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  Contact
                  <motion.span
                    className="ml-2 text-emerald-500 opacity-0 group-hover:opacity-100"
                    initial={{ width: 0 }}
                    whileHover={{ width: "auto" }}
                  >
                    →
                  </motion.span>
                </motion.span>
                <motion.div
                  className="absolute bottom-0 left-0 h-px w-0 bg-gradient-to-r from-emerald-500 to-cyan-500"
                  whileHover={{ width: "100%" }}
                  transition={{ duration: 0.3 }}
                />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default FuturisticHeader;
