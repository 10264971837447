import axios from "axios";
import { API_URL } from "../../utils/apiConfig";

/**
 * Service for fetching and managing ENPS data
 */
const ENPSStatsDataService = {
  /**
   * Creates an empty stats object for quarters with no data
   */
  createEmptyStats: (year, quarter) => ({
    enps_score: 0,
    promoters: 0,
    passives: 0,
    detractors: 0,
    promoters_percentage: 0,
    passives_percentage: 0,
    detractors_percentage: 0,
    answered_reviews: 0,
    recent_reviews: 0,
    recent_answered: 0,
    current_quarter: quarter,
    current_year: year,
  }),

  /**
   * Transform quarterly data into the format expected by the UI
   */
  transformQuarterData: (quarterData) => ({
    enps_score: quarterData.enps_score,
    promoters: quarterData.promoters_count,
    passives: quarterData.passives_count,
    detractors: quarterData.detractors_count,
    promoters_percentage: quarterData.promoters_percentage,
    passives_percentage: quarterData.passives_percentage,
    detractors_percentage: quarterData.detractors_percentage,
    answered_reviews: quarterData.total_responses,
    recent_reviews: quarterData.total_responses,
    recent_answered: quarterData.total_responses,
    current_quarter: quarterData.quarter,
    current_year: quarterData.year,
  }),

  /**
   * Fetch the current user
   */
  fetchCurrentUser: async () => {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(`${API_URL}/api/v1/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  /**
   * Fetch all quarterly ENPS data for a business
   */
  fetchQuarterlyData: async (businessName) => {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(
      `${API_URL}/api/v1/businesses/${businessName}/enps-quarterly-stats`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  },

  /**
   * Export ENPS data for a specific quarter
   * @param {string} businessName - The business name
   * @param {number} year - The year to export
   * @param {number} quarter - The quarter to export (1-4)
   * @returns {Blob} - Blob of CSV data for the specified quarter
   */
  exportQuarterData: async (businessName, year, quarter) => {
    const token = localStorage.getItem("access_token");

    // Updated to include year and quarter as query parameters to filter data server-side
    const response = await axios.get(
      `${API_URL}/api/v1/businesses/${businessName}/enps-export`,
      {
        params: {
          year: year,
          quarter: quarter,
        },
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      },
    );
    return response.data;
  },

  /**
   * Get the most recent quarter with data
   */
  getMostRecentQuarterWithData: (quarters) => {
    if (!quarters || quarters.length === 0) {
      return null;
    }

    // Filter quarters with responses
    const quartersWithData = quarters.filter((q) => q.total_responses > 0);

    if (quartersWithData.length === 0) {
      return null;
    }

    // Sort by year and quarter descending
    return [...quartersWithData].sort((a, b) => {
      if (a.year !== b.year) return b.year - a.year;
      return b.quarter - a.quarter;
    })[0];
  },

  /**
   * Get the current year and quarter
   */
  getCurrentYearAndQuarter: () => {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear(),
      quarter: Math.floor(currentDate.getMonth() / 3) + 1,
    };
  },
};

export default ENPSStatsDataService;
