// MobileSidebarOverlay.jsx
const MobileSidebarOverlay = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
      onClick={onClose}
    />
  );
};

export default MobileSidebarOverlay;
