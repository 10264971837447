import React from "react";
import { CheckCircle, XCircle, AlertTriangle } from "lucide-react";

/**
 * Displays a score indicator with visual feedback based on the score value
 *
 * @param {Object} props
 * @param {number} props.score The ENPS score to display
 */
const ScoreIndicator = ({ score }) => {
  let scoreClass = "";
  let scoreIcon = null;
  let scoreLabel = "";

  if (score < 0) {
    scoreClass = "text-rose-500 bg-rose-50";
    scoreIcon = <XCircle className="h-5 w-5 text-rose-500" />;
    scoreLabel = "Needs Improvement";
  } else if (score < 30) {
    scoreClass = "text-amber-500 bg-amber-50";
    scoreIcon = <AlertTriangle className="h-5 w-5 text-amber-500" />;
    scoreLabel = "Concerning";
  } else if (score < 50) {
    scoreClass = "text-amber-500 bg-amber-50";
    scoreIcon = <AlertTriangle className="h-5 w-5 text-amber-400" />;
    scoreLabel = "Average";
  } else {
    scoreClass = "text-emerald-500 bg-emerald-50";
    scoreIcon = <CheckCircle className="h-5 w-5 text-emerald-500" />;
    scoreLabel = "Excellent";
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <div
          className={`text-3xl md:text-4xl font-bold tracking-tight z-10 ${scoreClass} px-4 py-2 rounded-lg`}
        >
          {score}
        </div>
        <div className="flex flex-col">
          <div className="flex items-center gap-1.5">
            {scoreIcon}
            <span className="text-sm font-medium">{scoreLabel}</span>
          </div>
          <span className="text-xs text-zinc-500">out of 100</span>
        </div>
      </div>
      <div className="w-full h-1 bg-gradient-to-r from-rose-500 via-amber-500 to-emerald-500 mt-2 rounded-full overflow-hidden">
        <div
          className="w-3 h-3 bg-white rounded-full shadow-lg relative"
          style={{
            marginLeft: `${Math.max(0, Math.min(100, score))}%`,
            transform: "translateX(-50%) translateY(-33%)",
          }}
        ></div>
      </div>
    </div>
  );
};

/**
 * Displays a percentage bar with appropriate styling based on the theme
 *
 * @param {Object} props
 * @param {string} props.label The label to display (e.g., "Promoters (9-10)")
 * @param {number} props.percentage The percentage value to display and use for bar width
 * @param {string} props.theme Theme name: "promoter", "passive", or "detractor"
 */
const StatBar = ({ label, percentage, theme }) => {
  // Theme presets for different stat types
  const themes = {
    promoter: {
      bgGradient: "from-emerald-400 via-emerald-500 to-teal-600",
      pulseColor: "emerald-300",
      textColor: "emerald-700",
    },
    passive: {
      bgGradient: "from-amber-400 via-amber-500 to-yellow-600",
      pulseColor: "amber-300",
      textColor: "amber-700",
    },
    detractor: {
      bgGradient: "from-rose-400 via-rose-500 to-red-600",
      pulseColor: "rose-300",
      textColor: "rose-700",
    },
  };

  const currentTheme = themes[theme];

  return (
    <div className="space-y-2 group">
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium text-zinc-700 group-hover:text-zinc-900 transition-colors">
          {label}
        </span>
        <div
          className={`font-semibold text-${currentTheme.textColor} bg-zinc-50 px-3 py-1 rounded-full text-xs shadow backdrop-blur-sm`}
        >
          {percentage}%
        </div>
      </div>
      <div className="w-full bg-zinc-100 rounded-full h-2.5 overflow-hidden shadow-inner">
        <div
          className={`h-2.5 rounded-full transition-all duration-700 ease-out bg-gradient-to-r ${currentTheme.bgGradient}`}
          style={{ width: `${percentage}%` }}
        >
          <div className="absolute inset-0 w-full h-full">
            <div className="w-full h-full bg-[radial-gradient(at_right_top,_rgba(255,255,255,0.15),_transparent_70%)]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * ENPS Score Card component that displays the ENPS score and score breakdowns
 *
 * @param {Object} props
 * @param {number} props.score The ENPS score
 * @param {number} props.promotersPercentage Percentage of promoters
 * @param {number} props.passivesPercentage Percentage of passives
 * @param {number} props.detractorsPercentage Percentage of detractors
 * @param {number} props.selectedQuarter Current quarter being displayed
 * @param {number} props.selectedYear Current year being displayed
 */
const ENPSScoreCard = ({
  score,
  promotersPercentage,
  passivesPercentage,
  detractorsPercentage,
  selectedQuarter,
  selectedYear,
}) => {
  return (
    <div className="bg-gray-50/80 backdrop-blur-sm p-6 rounded-xl border border-zinc-100/70 shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="mb-6">
        <p className="text-sm font-medium text-zinc-600 mb-3">
          Q{selectedQuarter} {selectedYear} ENPS
        </p>
        <ScoreIndicator score={score} />
      </div>

      <div className="space-y-5 mt-8">
        <StatBar
          label="Promoters (9-10)"
          percentage={promotersPercentage}
          theme="promoter"
        />
        <StatBar
          label="Passives (7-8)"
          percentage={passivesPercentage}
          theme="passive"
        />
        <StatBar
          label="Detractors (0-6)"
          percentage={detractorsPercentage}
          theme="detractor"
        />
      </div>
    </div>
  );
};

export default ENPSScoreCard;
