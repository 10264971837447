import React from "react";
import { Link } from "react-router-dom";
import SharedWithMe from "./SharedWithMe";

const SharedReviewsPage = ({ businessName }) => {
  return (
    <div className="min-h-screen bg-slate-900 text-white">
      <header className="bg-slate-800 p-4">
        <div className="container flex justify-between items-center">
          <Link
            to="/"
            className="text-teal-500 ml-4 text-4xl font-serif font-bold"
          >
            matcha
          </Link>
        </div>
      </header>
      <div className="container mx-auto">
        <Link
          to="/my-reviews"
          className="p-2 text-indigo-400 hover:text-indigo-300"
        >
          &larr; back to my-reviews
        </Link>
        <div className="rounded">
          <SharedWithMe businessName={businessName} />
        </div>
      </div>
    </div>
  );
};

export default SharedReviewsPage;
