import React, { useState, useEffect } from "react";
import enpsDataService from "./enpsDataService";
import YearlySummary from "./YearlySummary";
import QuarterlyENPSCard from "./QuarterlyENPSCard";
import ENPSTrendChart from "./ENPSTrendChart";
import AboutENPSSection from "./AboutENPSSection";

/**
 * The main parent component that:
 *  - Fetches eNPS data from the service
 *  - Passes data to the child components
 *  - Shows a rolling 5-quarter view across year boundaries
 */
const ENPSQuarterlyStats = ({ businessName }) => {
  // Our chosen default year to display
  const [selectedYear, setSelectedYear] = useState(2024);

  // State for data loading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Raw JSON data if you want debugging
  const [rawData, setRawData] = useState(null);

  // The processed data arrays
  const [quarterlyData, setQuarterlyData] = useState([]);
  const [availableYears, setAvailableYears] = useState([2024]);

  // For the summary
  const [yearlyAverage, setYearlyAverage] = useState(0);
  const [previousYearAverage, setPreviousYearAverage] = useState(null);
  const [trendDirection, setTrendDirection] = useState(null);

  useEffect(() => {
    fetchData();
    // Re-fetch whenever businessName or selectedYear changes
  }, [businessName, selectedYear]);

  // Call the data service
  const fetchData = async () => {
    setLoading(true);

    const result = await enpsDataService.fetchQuarterlyData(
      businessName,
      selectedYear,
    );

    if (result.success) {
      setQuarterlyData(result.data.quarterlyData);
      setYearlyAverage(result.data.yearlyAverage);
      setPreviousYearAverage(result.data.previousYearAverage);
      setTrendDirection(result.data.trendDirection);
      setAvailableYears(result.data.availableYears);
      setRawData(result.rawData);
      setError(null);
    } else {
      setError(result.error);
    }

    setLoading(false);
  };

  // Helper function that sets the color of the numeric eNPS score
  const getScoreColor = (score) => {
    if (score >= 50) return "#10b981"; // emerald-500
    if (score >= 30) return "#34d399"; // emerald-400
    if (score >= 10) return "#a3e635"; // lime-500
    if (score >= 0) return "#fbbf24"; // amber-400
    if (score >= -30) return "#f97316"; // orange-500
    return "#ef4444"; // red-500
  };

  // Helper function for the summary's trend arrow / text
  const getTrendIcon = (direction) => {
    if (direction === "up") {
      return (
        <span className="text-emerald-500 flex items-center text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
              clipRule="evenodd"
            />
          </svg>
          <span>Improved from {previousYearAverage?.toFixed(1)}</span>
        </span>
      );
    } else if (direction === "down") {
      return (
        <span className="text-red-500 flex items-center text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12 13a1 1 0 100 2h5a1 1 0 001-1v-5a1 1 0 10-2 0v2.586l-4.293-4.293a1 1 0 00-1.414 0L8 9.586l-4.293-4.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0L11 9.414 14.586 13H12z"
              clipRule="evenodd"
            />
          </svg>
          <span>Decreased from {previousYearAverage?.toFixed(1)}</span>
        </span>
      );
    } else if (direction === "same") {
      return (
        <span className="text-slate-500 flex items-center text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>Same as previous year</span>
        </span>
      );
    }
    return null;
  };

  // Year dropdown event
  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  // Loading / error states
  if (loading) {
    return (
      <div className="bg-gray-200 p-8 rounded-xl shadow-lg border border-slate-100">
        <h2 className="text-xl font-semibold text-gray-700 mb-6">
          Quarterly eNPS Analysis
        </h2>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-l-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gray-200 p-8 rounded-xl shadow-lg border border-slate-100">
        <h2 className="text-xl font-semibold text-slate-800 mb-6">
          Quarterly eNPS Analysis
        </h2>
        <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  // Main render
  return (
    <div className="bg-gray-100 p-8 rounded-xl shadow-lg border border-slate-100">
      {/* Yearly summary header */}
      <YearlySummary
        yearlyAverage={yearlyAverage}
        previousYearAverage={previousYearAverage}
        trendDirection={trendDirection}
        selectedYear={selectedYear}
        availableYears={availableYears}
        handleYearChange={handleYearChange}
        getScoreColor={getScoreColor}
        getTrendIcon={getTrendIcon}
      />

      {/* Quarterly cards. In rolling view, we can have up to 5 or fewer. */}
      <div className="mb-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
          {quarterlyData.map((quarter) => (
            <QuarterlyENPSCard
              key={quarter.name}
              quarterData={quarter}
              selectedYear={selectedYear} // optional
            />
          ))}
        </div>
      </div>

      {/* eNPS Trend Chart */}
      <ENPSTrendChart
        quarterlyData={quarterlyData}
        selectedYear={selectedYear}
        colors={{
          promoters: "#10b981",
          passives: "#f59e0b",
          detractors: "#ef4444",
          enpsScore: "#3b82f6",
          enpsArea: "rgba(59, 130, 246, 0.1)",
        }}
      />

      {/* Debug panel if you want to see raw data 
      <div className="mt-8 p-4 bg-gray-50 rounded-lg border overflow-auto">
        <pre>{JSON.stringify(rawData, null, 2)}</pre>
      </div>
      */}

      {/* About eNPS Info */}
      <AboutENPSSection />
    </div>
  );
};

export default ENPSQuarterlyStats;
