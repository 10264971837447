import React from "react";
import { Users, ChevronRight } from "lucide-react";
import CreateMeetingForm from "./CreateMeetingFrom";
import UserListOneonOne from "./UserListOneonOne";
import MeetingsListOneonOne from "./MeetingsListOneonOne";
import useOneOnOne from "./useOneonOne";

const OneOnOne = ({ businessName }) => {
  const {
    users,
    selectedUser,
    meetingDate,
    meetingLink,
    error,
    meetings,
    selectedUserMeetings,
    notes,
    editingNotes,
    selectedUsername,
    setSelectedUser,
    setMeetingDate,
    setMeetingLink,
    setEditingNotes,
    handleCreateMeeting,
    handleUpdateNotes,
    handleUserSelect,
    handleNoteChange,
  } = useOneOnOne(businessName);

  return (
    <div className="bg-gradient-to-t from-gray-800 via-gray-900 to-gray-800 rounded-xl p-8 shadow-2xl border border-gray-800 mt-24">
      <div className="max-w-[1400px] mx-auto">
        <div className="p-2 text-center mb-1">
          {/* Header */}
          <div className="flex justify-between items-center mb-12">
            <div className="flex items-center space-x-2">
              <h2 className="text-xl font-grotesk font-medium text-gray-100">
                One-on-One Meetings
              </h2>
            </div>
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            {/* Form Section - Spans full width */}
            <div className="col-span-1 lg:col-span-12">
              <div className="bg-gray-800 rounded-lg border border-gray-800 p-4">
                <CreateMeetingForm
                  users={users}
                  selectedUser={selectedUser}
                  meetingDate={meetingDate}
                  meetingLink={meetingLink}
                  setSelectedUser={setSelectedUser}
                  setMeetingDate={setMeetingDate}
                  setMeetingLink={setMeetingLink}
                  handleCreateMeeting={handleCreateMeeting}
                />
              </div>
            </div>

            {error && (
              <div className="col-span-1 lg:col-span-12">
                <div className="bg-red-900 border border-red-800 text-red-200 px-4 py-3 rounded-lg">
                  <p className="text-sm">{error}</p>
                </div>
              </div>
            )}

            {/* Mobile User Selection */}
            <div className="lg:hidden col-span-1">
              <select
                value={selectedUsername || ""}
                onChange={(e) => handleUserSelect(e.target.value)}
                className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg text-gray-300 text-sm focus:border-amber-500/50 focus:ring-1 focus:ring-amber-500/20"
              >
                <option value="">Select Team Member</option>
                {Object.keys(meetings).map((username) => (
                  <option key={username} value={username}>
                    {username}
                  </option>
                ))}
              </select>
            </div>

            {/* Desktop User List */}
            <div className="hidden lg:block lg:col-span-3">
              <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden sticky top-4">
                <div className="p-3 border-b border-gray-700">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-300 text-sm font-grotesk font-medium tracking-wider">
                      Team Members
                    </span>
                    <ChevronRight className="w-4 h-4 text-amber-500" />
                  </div>
                </div>
                <UserListOneonOne
                  meetings={meetings}
                  selectedUsername={selectedUsername}
                  onUserSelect={handleUserSelect}
                />
              </div>
            </div>

            {/* Meetings List */}
            <div className="col-span-1 lg:col-span-9">
              <div className="bg-gray-800 rounded-lg border border-gray-800">
                <MeetingsListOneonOne
                  selectedUserMeetings={selectedUserMeetings}
                  notes={notes}
                  editingNotes={editingNotes}
                  onNoteChange={handleNoteChange}
                  onUpdateNotes={handleUpdateNotes}
                  onEditingNotesChange={(meetingId, value) =>
                    setEditingNotes((prev) => ({
                      ...prev,
                      [meetingId]: value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneOnOne;
