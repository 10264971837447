// QuestionItem.jsx
import React from "react";
import { useDrag } from "react-dnd";
import { MoreVertical, Trash2 } from "lucide-react";

const QuestionItem = ({ id, text, onDeleteQuestion, isDraggable = true }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "question",
    item: { id, text },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isDraggable,
  });

  return (
    <div
      ref={isDraggable ? drag : null}
      className={`bg-gray-50 border border-gray-100 p-3 rounded-md shadow-md flex justify-between items-center mb-2 ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      <span className="text-zinc-700">{text}</span>
      <div className="flex items-center">
        {isDraggable && (
          <div className="cursor-move mr-2 text-zinc-400">
            <MoreVertical size={18} />
          </div>
        )}
        {onDeleteQuestion && (
          <button
            onClick={() => onDeleteQuestion(id)}
            className="text-slate-400 hover:text-rose-700 transition-colors duration-200"
          >
            <Trash2 size={18} />
          </button>
        )}
      </div>
    </div>
  );
};

export default QuestionItem;
