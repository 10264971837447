import React from "react";
import { MessageCircle, ChevronRight } from "lucide-react";
import UserResponseCard from "../UserResponseCard";

const ResponsesList = ({
  selectedDate,
  groupedResponses,
  activeComment,
  setActiveComment,
  comments,
  setComments,
  handleCommentSubmit,
  handleResponseClick,
}) => {
  if (!selectedDate) return null;

  return (
    <div className="space-y-3 lg:col-span-4">
      <div className="bg-zinc-800 p-3 border-l border-gray-300 rounded group transition-all duration-500 hover:border-l-2 hover:border-amber-600 shadow-lg">
        {/* Header Section */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="flex items-center space-x-2">
              <MessageCircle className="w-3 h-3 text-amber-500" />
              <span className="text-xs uppercase tracking-wider text-gray-400">
                Responses for {new Date(selectedDate).toLocaleDateString()}
              </span>
            </div>
          </div>
          <ChevronRight className="w-3 h-3 text-amber-500 transition-transform duration-300 group-hover:translate-x-1" />
        </div>
      </div>

      {/* Responses Grid */}
      <div className="p-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {groupedResponses[selectedDate]?.map((response) => (
            <UserResponseCard
              key={`${response.user_id}-${response.date_answered}`}
              response={response}
              activeComment={activeComment}
              setActiveComment={setActiveComment}
              comments={comments}
              setComments={setComments}
              handleCommentSubmit={handleCommentSubmit}
              handleResponseClick={handleResponseClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsesList;
