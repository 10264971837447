import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../utils/apiConfig";
import ENPSStats from "./ENPS/ENPSStats";

const ENPS = ({ businessName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [emails, setEmails] = useState("");
  const [sendToAll, setSendToAll] = useState(false);
  const [verifyEmails, setVerifyEmails] = useState(false); // New state for email verification
  const [questions, setQuestions] = useState([
    { id: 1, text: "", required: true },
    { id: 2, text: "", required: true },
    { id: 3, text: "", required: true },
    { id: 4, text: "", required: true },
    { id: 5, text: "", required: false },
  ]);

  const handleQuestionChange = (id, text) => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, text } : q)));
  };

  const handleEmailChange = (e) => {
    setEmails(e.target.value);
  };

  const handleSendToAllChange = (e) => {
    setSendToAll(e.target.checked);
    if (e.target.checked) {
      setVerifyEmails(false); // Reset verify emails when sending to all
    }
  };

  const handleVerifyEmailsChange = (e) => {
    setVerifyEmails(e.target.checked);
    setError(null); // Clear any existing errors when toggling
  };

  const handleSendENPS = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const emailList = sendToAll
      ? []
      : emails
          .split(/[,\s]+/)
          .map((email) => email.trim())
          .filter((email) => email !== "");

    if (
      questions.some((q) => q.required && q.text.trim() === "") ||
      (!sendToAll && emailList.length === 0)
    ) {
      setError(
        'Please fill in all required questions and provide at least one email address (or select "Send to All Users") before sending the survey.',
      );
      setIsLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("access_token");

      // If verifyEmails is true, first validate the emails
      if (verifyEmails && !sendToAll) {
        const validateResponse = await axios.post(
          `${API_URL}/api/v1/businesses/${businessName}/validate-emails`,
          { emails: emailList },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (validateResponse.data.invalidEmails?.length > 0) {
          setError(
            `The following emails are not registered users: ${validateResponse.data.invalidEmails.join(
              ", ",
            )}`,
          );
          setIsLoading(false);
          return;
        }
      }

      // Send the survey
      const response = await axios.post(
        `${API_URL}/api/v1/businesses/${businessName}/create-enps-survey`,
        {
          questions: questions.map((q) => q.text),
          emails: emailList,
          send_to_all: sendToAll,
          verify_emails: verifyEmails,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setSuccess(
        `Survey created and sent to ${
          sendToAll ? "all users" : emailList.length
        } recipient(s)`,
      );
    } catch (err) {
      console.error("Error sending eNPS:", err.response?.data || err.message);
      setError(
        `Failed to send eNPS: ${err.response?.data?.detail || err.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-200 text-gray-600 mt-24 p-6 rounded-lg">
      <div className="relative">
        <div className="relative flex items-center justify-left pl-5 p-2 bg-gray-800 shadow-2xl border-l-2 border-gray-50 rounded-md mb-6">
          <h1 className="text-lg font-light tracking-wider font-grotesk text-gray-50">
            Employee Net Promoter Score
          </h1>
        </div>
      </div>

      <div className="mt-4">
        <div className="bg-gray-200 rounded-md shadow-lg border-l border-gray-300 transition-all duration-300 hover:border-zinc-400 overflow-hidden relative">
          <div className="relative p-6">
            <div className="mb-12">
              <ENPSStats businessName={businessName} />
            </div>

            <form onSubmit={handleSendENPS} className="space-y-6">
              <div className="bg-gray-100 p-5 rounded-md border border-zinc-200 shadow-lg">
                <h3 className="flex items-center text-lg font-light font-grotesk mb-6 text-zinc-700">
                  <span className="mr-2 font-grotesk tracking-wider">
                    Create New Survey
                  </span>
                  <div className="h-px flex-grow bg-neutral-300 ml-4"></div>
                </h3>
                <div className="space-y-4 mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={sendToAll}
                      onChange={handleSendToAllChange}
                      className="mr-2 h-4 w-4 text-amber-500 border-gray-300 rounded focus:ring-amber-500"
                    />
                    <span className="text-zinc-700 text-sm tracking-wide font-grotesk">
                      Send to all users
                    </span>
                  </label>

                  {!sendToAll && (
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={verifyEmails}
                        onChange={handleVerifyEmailsChange}
                        className="mr-2 h-4 w-4 text-amber-500 border-gray-300 rounded focus:ring-amber-500"
                      />
                      <span className="text-zinc-700 text-sm tracking-wide font-grotesk">
                        Verify emails against registered users
                      </span>
                    </label>
                  )}
                </div>

                {!sendToAll && (
                  <div className="mb-4">
                    <label className="block text-sm font-medium font-grotesk text-zinc-700 mb-2">
                      Email addresses (separated by commas or spaces)
                      {verifyEmails && (
                        <span className="text-zinc-500 text-xs ml-2">
                          (must be registered users)
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      value={emails}
                      onChange={handleEmailChange}
                      className="w-full p-1.5 bg-white border border-zinc-300 text-zinc-800 rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
                      placeholder="Enter email addresses"
                    />
                  </div>
                )}
              </div>

              <div className="bg-gray-100 p-5 rounded-md border border-zinc-100 shadow-md">
                <h3 className="flex items-center text-lg font-light font-grotesk mb-6 text-zinc-700">
                  <span className="mr-2 font-grotesk tracking-wider">
                    Survey Questions
                  </span>
                  <div className="h-px flex-grow bg-neutral-300 ml-4"></div>
                </h3>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {questions.map((question, index) => (
                    <div key={question.id} className="mb-2">
                      <label className="block text-sm font-grotesk text-zinc-700 mb-2">
                        Question {index + 1}
                        {!question.required && (
                          <span className="text-zinc-500 text-xs ml-2">
                            (Optional)
                          </span>
                        )}
                      </label>
                      <input
                        type="text"
                        value={question.text}
                        onChange={(e) =>
                          handleQuestionChange(question.id, e.target.value)
                        }
                        className="w-full p-1.5 bg-gray-200 font-grotesk border border-zinc-300 text-zinc-800 rounded-md focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
                        placeholder={`Enter question ${index + 1}`}
                        required={question.required}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-3 px-4 bg-gray-800 border border-zinc-100 text-amber-500 font-grotesk tracking-wider font-light rounded-md hover:bg-zinc-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-l-2 border-zinc-400 animate-spin rounded-full mr-2"></div>
                    Sending...
                  </div>
                ) : (
                  "Send eNPS Survey"
                )}
              </button>
            </form>

            {error && (
              <div className="text-rose-400 p-4 mt-6 border border-rose-600 bg-rose-950/20 rounded-md">
                {error}
              </div>
            )}

            {success && (
              <div className="text-emerald-600 p-4 mt-6 border border-emerald-500 bg-emerald-50 rounded-md">
                {success}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ENPS;
