import React, { useState, useEffect } from "react";
import axios from "axios";

const SentimentAnalysis = ({ businessName }) => {
  const [sentimentAnalysis, setSentimentAnalysis] = useState(null);
  const [sentimentLoading, setSentimentLoading] = useState(true);
  const [sentimentError, setSentimentError] = useState(null);
  const [emails, setEmails] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const [generatingAnalysis, setGeneratingAnalysis] = useState(false);
  const [weekInfo, setWeekInfo] = useState(null);

  useEffect(() => {
    fetchSentimentAnalysis();
    fetchCurrentWeekInfo();
  }, [businessName]);

  const fetchCurrentWeekInfo = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/weekly-vc-scores?limit=1`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );

      if (response.data?.weekly_averages?.length > 0) {
        const currentWeek = response.data.weekly_averages[0];
        setWeekInfo({
          start: new Date(currentWeek.week_start),
          end: new Date(currentWeek.week_end),
        });
      }
    } catch (err) {
      console.error("Error fetching week info:", err);
    }
  };

  const fetchSentimentAnalysis = async () => {
    setSentimentLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/sentiment-analysis`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSentimentAnalysis(response.data);
      setSentimentLoading(false);
    } catch (err) {
      console.error("Error fetching sentiment analysis:", err);
      setSentimentError("Failed to fetch sentiment analysis.");
      setSentimentLoading(false);
    }
  };

  const generateNewAnalysis = async () => {
    setGeneratingAnalysis(true);
    try {
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/generate-sentiment-analysis`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSentimentAnalysis(response.data);
      setEmailSentMessage("New sentiment analysis generated successfully!");
      setTimeout(() => setEmailSentMessage(""), 3000);
    } catch (err) {
      console.error("Error generating sentiment analysis:", err);
      setEmailSentMessage("Failed to generate new analysis. Please try again.");
    } finally {
      setGeneratingAnalysis(false);
    }
  };

  const sendAnalysisEmail = async () => {
    if (!emails.trim()) {
      setEmailSentMessage("Please enter at least one email address.");
      return;
    }
    setSendingEmail(true);
    setEmailSentMessage("");
    try {
      const emailList = emails
        .split(/[ ,]+/)
        .filter((email) => email.trim() !== "");
      await axios.post(
        `/api/v1/businesses/${businessName}/send-sentiment-analysis`,
        { emails: emailList },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setEmailSentMessage("Sentiment analysis sent successfully!");
      setEmails("");
    } catch (err) {
      console.error("Error sending sentiment analysis email:", err);
      setEmailSentMessage(
        "Failed to send sentiment analysis. Please try again.",
      );
    } finally {
      setSendingEmail(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="mt-6 bg-white p-6 border-l border-zinc-200 shadow-2xl rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-lg font-grotesk font-light tracking-wide text-zinc-700">
          Sentiment Analysis
        </h4>
        <button
          onClick={generateNewAnalysis}
          disabled={generatingAnalysis}
          className="px-4 py-2 bg-zinc-100 hover:bg-zinc-200 text-zinc-700 text-sm rounded transition-all duration-200"
        >
          {generatingAnalysis ? "Generating..." : "Generate New Analysis"}
        </button>
      </div>

      {weekInfo && (
        <div className="bg-zinc-50 p-3 rounded mb-4 text-sm">
          <p className="font-medium text-zinc-700">Current Week:</p>
          <p className="text-zinc-500">
            {formatDate(weekInfo.start)} - {formatDate(weekInfo.end)}
          </p>
        </div>
      )}

      {sentimentLoading ? (
        <p className="text-sm text-zinc-500">Loading sentiment analysis...</p>
      ) : sentimentError ? (
        <p className="text-sm text-rose-600">{sentimentError}</p>
      ) : (
        <>
          {sentimentAnalysis && (
            <div className="mb-2 text-sm text-zinc-500">
              <p>
                <span className="font-medium">Generated:</span>{" "}
                {formatDate(sentimentAnalysis.created_at)}
              </p>
            </div>
          )}
          <div className="bg-zinc-50 p-4 rounded-lg border border-zinc-100 mb-4">
            <p className="text-sm text-zinc-600 whitespace-pre-line">
              {sentimentAnalysis?.sentiment_analysis}
            </p>
          </div>
        </>
      )}

      <div className="mt-6 bg-zinc-50 p-4 rounded-lg border border-zinc-100">
        <h5 className="text-sm font-medium text-zinc-700 mb-2">
          Share Analysis
        </h5>
        <input
          type="text"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          placeholder="Enter emails (comma or space separated)"
          className="w-full px-4 py-3 font-grotesk bg-white border border-zinc-200 focus:border-zinc-400 transition-colors duration-200 text-zinc-700 rounded"
        />
        <button
          onClick={sendAnalysisEmail}
          className="mt-4 px-6 py-3 bg-zinc-200 hover:bg-zinc-300 text-zinc-700 rounded transition-all duration-200"
          disabled={sendingEmail}
        >
          {sendingEmail ? "Sending..." : "Send Analysis"}
        </button>
        {emailSentMessage && (
          <p
            className={`mt-2 text-sm ${
              emailSentMessage.includes("success")
                ? "text-green-600"
                : "text-rose-600"
            }`}
          >
            {emailSentMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default SentimentAnalysis;
