import React, { useState, useEffect } from "react";
import { RefreshCw, HelpCircle, ChevronLeft, ChevronRight } from "lucide-react";

// Import our new components
import UpdateStatusMessage from "../components/LeaderBoards/UpdateStatusMessage";
import LeaderboardRow from "../components/LeaderBoards/LeaderboardRow";

const LeaderBoards = ({ businessName, user, isAdmin }) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalUsers: 0,
    limit: 16,
  });
  const [showInfo, setShowInfo] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({
    success: false,
    message: "",
    details: null,
    showDetails: false,
  });

  const fetchLeaderboard = async (page = 1) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");

      if (!token) {
        setError("Authentication token not found. Please log in again.");
        throw new Error("No authentication token");
      }

      const response = await fetch(
        `/api/v1/businesses/${businessName}/leaderboard?page=${page}&limit=${pagination.limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error(
            "Authentication failed. Please try logging out and back in.",
          );
        } else if (response.status === 403) {
          throw new Error(
            "You don't have permission to view the leaderboard. Admin access required.",
          );
        } else {
          throw new Error(
            "Failed to load leaderboard data. Please try again later.",
          );
        }
      }

      const data = await response.json();
      setLeaderboard(data.leaderboard);
      setLastUpdated(data.last_updated);
      setPagination({
        ...pagination,
        currentPage: page,
        totalUsers: data.total_users,
      });
      setError(null);
    } catch (err) {
      console.error("Error fetching leaderboard:", err);
      setError(
        err.message ||
          "Connection error. Please check your network and try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  const updateLeaderboard = async () => {
    setUpdating(true);
    setUpdateStatus({
      success: false,
      message: "",
      details: null,
      showDetails: false,
    });

    try {
      const token = localStorage.getItem("access_token");

      if (!token) {
        setError("Authentication token not found. Please log in again.");
        throw new Error("No authentication token");
      }

      const response = await fetch(
        `/api/v1/businesses/${businessName}/leaderboard/update`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error(
            "Authentication failed. Please try logging out and back in.",
          );
        } else if (response.status === 403) {
          throw new Error(
            "You don't have permission to update the leaderboard. Admin access required.",
          );
        } else {
          throw new Error(
            "Failed to update leaderboard. Please try again later.",
          );
        }
      }

      const data = await response.json();

      setUpdateStatus({
        success: true,
        message: "Leaderboard updated successfully!",
        details: data,
        showDetails: false,
      });

      await fetchLeaderboard(1);
      setError(null);
    } catch (err) {
      console.error("Error updating leaderboard:", err);
      setError(
        err.message ||
          "Connection error. Please check your network and try again.",
      );

      setUpdateStatus({
        success: false,
        message: err.message || "Update failed",
        details: null,
        showDetails: false,
      });
    } finally {
      setUpdating(false);

      if (updateStatus.success) {
        setTimeout(() => {
          setUpdateStatus((prev) => ({ ...prev, showDetails: false }));
        }, 5000);
      }
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [businessName]);

  const handlePageChange = (newPage) => {
    if (
      newPage > 0 &&
      newPage <= Math.ceil(pagination.totalUsers / pagination.limit)
    ) {
      fetchLeaderboard(newPage);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const getRankColor = (rank) => {
    if (rank === 1) return "text-amber-500";
    if (rank === 2) return "text-gray-400";
    if (rank === 3) return "text-amber-700";
    return "";
  };

  return (
    <div className="bg-gradient-to-t from-gray-800 via-gray-900 to-gray-800 rounded-xl p-4 md:p-8 lg:p-14 shadow-2xl border border-gray-800 mt-24">
      {/* Header section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 md:mb-8 space-y-4 md:space-y-0">
        <div className="flex items-center space-x-2">
          <h2 className="text-xl md:text-2xl font-grotesk font-medium text-gray-100">
            Matcha Activity Leaderboard (beta)
          </h2>
          <button
            className="text-gray-500 hover:text-gray-300 transition-colors"
            onClick={() => setShowInfo(!showInfo)}
          >
            <HelpCircle size={16} className="text-amber-400 md:size-18" />
          </button>
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4 w-full md:w-auto">
          {lastUpdated && (
            <span className="text-xs md:text-sm text-gray-400">
              Updated: {formatDate(lastUpdated)}
            </span>
          )}
          {/* Admin only refresh button */}
          {user && user.is_admin ? (
            <button
              onClick={updateLeaderboard}
              disabled={updating}
              className="flex items-center px-3 py-1.5 md:px-4 md:py-2 bg-gray-800 text-gray-100 border border-gray-700 rounded-lg hover:bg-gray-700 transition-colors disabled:opacity-50 text-sm"
            >
              <RefreshCw
                size={14}
                className={`mr-2 ${updating ? "animate-spin" : ""}`}
              />
              {updating ? "Updating..." : "Refresh Data"}
            </button>
          ) : (
            <span className="text-xs md:text-sm text-gray-400">
              data will refresh daily
            </span>
          )}
        </div>
      </div>

      {/* Info tooltip */}
      {showInfo && (
        <div className="mb-6 p-3 md:p-4 bg-gray-800 rounded-lg border border-gray-700 text-xs md:text-sm text-gray-300">
          <h3 className="font-medium text-gray-100 mb-2">
            About the Leaderboard
          </h3>
          <p className="mb-2">
            This leaderboard ranks users based on their platform activity and
            the quality of their contributions.
          </p>
          <div className="flex flex-col md:flex-wrap md:flex-row gap-2 md:gap-4 mt-3">
            <div className="flex items-center">
              <div className="w-3 h-3 md:w-4 md:h-4 bg-amber-500 rounded-full mr-2"></div>
              <span>Vibe Checks: Total responses submitted</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 md:w-4 md:h-4 bg-amber-500 rounded-full mr-2"></div>
              <span>
                Comments: Comments on others' responses (weighted less)
              </span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 md:w-4 md:h-4 bg-amber-500 rounded-full mr-2"></div>
              <span>Replies: Replies to comments</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 md:w-4 md:h-4 bg-amber-500 rounded-full mr-2"></div>
              <span>Depth: Longer Responses (weighted 2x)</span>
            </div>
          </div>
          <div className="mt-3 md:mt-4 text-xs text-gray-400">
            <p>
              Total score formula: Vibe Checks + Comments + Replies + (Depth ×
              2)
            </p>
            <p>
              Thoughtful, in-depth responses are weighted more heavily to
              encourage quality participation.
            </p>
          </div>
        </div>
      )}
      {/* Error message */}
      {error && (
        <div className="bg-red-900 border border-red-800 text-red-200 px-3 py-2 md:px-4 md:py-3 rounded-lg mb-4 md:mb-6 text-sm">
          {error}
        </div>
      )}

      {/* Update status message - extracted to component */}
      <UpdateStatusMessage
        updateStatus={updateStatus}
        setUpdateStatus={setUpdateStatus}
        formatDate={formatDate}
      />

      {/* Loading state */}
      {loading ? (
        <div className="flex justify-center items-center h-40 md:h-64">
          <div className="animate-spin rounded-full h-8 w-8 md:h-10 md:w-10 border-t-2 border-b-2 border-amber-500"></div>
        </div>
      ) : (
        <>
          {/* Leaderboard table */}
          <div className="overflow-x-auto -mx-4 md:mx-0">
            <table className="min-w-full">
              <thead>
                <tr className="border-b border-gray-800">
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Rank
                  </th>
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    User
                  </th>
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Vibe
                  </th>
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Comm
                  </th>
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Rep
                  </th>
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Depth
                  </th>
                  <th className="px-2 md:px-4 py-2 md:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {leaderboard.length === 0 ? (
                  <tr>
                    <td
                      colSpan="7"
                      className="px-2 md:px-4 py-6 md:py-8 text-center text-sm text-gray-400"
                    >
                      No data available. Users need to participate to appear on
                      the leaderboard.
                    </td>
                  </tr>
                ) : (
                  leaderboard.map((entry, index) => (
                    <LeaderboardRow
                      key={entry.id}
                      entry={entry}
                      index={index}
                      getRankColor={getRankColor}
                    />
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination controls */}
          {pagination.totalUsers > pagination.limit && (
            <div className="flex flex-col md:flex-row justify-between items-center mt-4 md:mt-6 space-y-2 md:space-y-0">
              <div className="text-xs md:text-sm text-gray-400">
                {(pagination.currentPage - 1) * pagination.limit + 1} -{" "}
                {Math.min(
                  pagination.currentPage * pagination.limit,
                  pagination.totalUsers,
                )}{" "}
                of {pagination.totalUsers} users
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handlePageChange(pagination.currentPage - 1)}
                  disabled={pagination.currentPage === 1}
                  className="p-1.5 md:p-2 rounded-lg border border-gray-700 text-gray-300 hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft size={12} className="md:size-8" />
                </button>
                <span className="flex items-center px-2 md:px-3 py-1 text-xs md:text-sm text-gray-300">
                  Page {pagination.currentPage}
                </span>
                <button
                  onClick={() => handlePageChange(pagination.currentPage + 1)}
                  disabled={
                    pagination.currentPage ===
                    Math.ceil(pagination.totalUsers / pagination.limit)
                  }
                  className="p-1.5 md:p-2 rounded-lg border border-gray-700 text-gray-300 hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronRight size={12} className="md:size-8" />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LeaderBoards;
