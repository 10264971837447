import React, { useState } from "react";
import { Calendar, X, MessageCircle, Clock, Users } from "lucide-react";
import axios from "axios";
import ResponseDetailsModal from "./ResponseDetailsModal";

const MyResponses = ({ responses, username, businessName, setResponses }) => {
  const myResponses = responses
    .filter((response) => response.username === username)
    .sort((a, b) => new Date(b.date_answered) - new Date(a.date_answered));

  const [selectedResponse, setSelectedResponse] = useState(null);

  const handleCommentSubmit = async (commentText, parentTimestamp) => {
    try {
      const token = localStorage.getItem("access_token");

      const formattedTimestamp = parentTimestamp
        ? parentTimestamp.toString()
        : null;

      console.log("Sending data:", {
        user_id: selectedResponse.user_id,
        date_answered: selectedResponse.date_answered,
        comment: commentText,
        parent_timestamp: parentTimestamp,
      });

      await axios.post(
        `/api/v1/businesses/${businessName}/submit-comment`,
        {
          user_id: selectedResponse.user_id,
          date_answered: selectedResponse.date_answered,
          comment: commentText,
          parent_timestamp: formattedTimestamp,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // Fetch the updated response data
      const updatedResponseData = await axios.get(
        `/api/v1/businesses/${businessName}/responses/${selectedResponse.user_id}/${selectedResponse.date_answered}`,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // Update both the global responses and the selected response
      setResponses((prevResponses) =>
        prevResponses.map((response) => {
          if (
            response.user_id === selectedResponse.user_id &&
            response.date_answered === selectedResponse.date_answered
          ) {
            return updatedResponseData.data;
          }
          return response;
        }),
      );

      // Update the modal content with fresh data
      setSelectedResponse(updatedResponseData.data);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleCloseModal = () => {
    setSelectedResponse(null);
  };

  return (
    <div className="text-slate-200">
      {myResponses.length === 0 ? (
        <div className="flex items-center justify-center p-3 text-slate-300 font-serif">
          <p className="flex items-center gap-1.5 text-sm">
            <MessageCircle className="w-3 h-3" />
            You haven't submitted any responses yet.
          </p>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex items-center gap-1.5">
            <Calendar className="w-3 h-3 text-slate-400" />
            <h3 className="font-serif text-sm text-slate-300">By Date</h3>
          </div>

          <div className="space-y-2 pr-2">
            {myResponses.map((response, index) => (
              <div
                key={index}
                onClick={() => setSelectedResponse(response)}
                className="p-3 rounded-md bg-gradient-to-t from-gray-900 to bg-gray-950 border border-slate-900 hover:border-slate-200 cursor-pointer transition-all duration-200 group backdrop-blur-sm shadow-lg"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1.5 text-slate-200 group-hover:text-slate-500 ">
                    <Clock className="w-3 h-3 text-slate-400" />
                    <span className="text-xs font-serif">
                      {new Date(response.date_answered).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        },
                      )}
                    </span>
                  </div>
                  <Users className="w-3 h-3 text-yellow-400 group-hover:text-indigo-300/90" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedResponse && (
        <ResponseDetailsModal
          selectedResponse={selectedResponse}
          handleCloseModal={handleCloseModal}
          onReply={handleCommentSubmit}
        />
      )}
    </div>
  );
};

export default MyResponses;
