// hooks/useDashboardData.js
import { useState, useEffect } from "react";
import axios from "axios";

const useDashboardData = (user) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState([]);
  const [hasReadResponses, setHasReadResponses] = useState(false);
  const [averageScore, setAverageScore] = useState(null);
  const [lastCalculated, setLastCalculated] = useState(null);
  const [averageScoreError, setAverageScoreError] = useState(null);
  const [showReadNotification, setShowReadNotification] = useState(false);
  const [latestSurveyResponse, setLatestSurveyResponse] = useState(null);
  const [readResponses, setReadResponses] = useState([]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchResponses = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/viewable-responses`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setResponses(response.data);
    } catch (err) {
      console.error("Error fetching responses:", err);
      setError("Failed to fetch responses. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const checkReadResponses = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/user-responses-read-status`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setHasReadResponses(response.data.has_read_responses);
    } catch (err) {
      console.error("Error checking read responses:", err);
    }
  };

  const fetchAverageScore = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/average-vc-score`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setAverageScore(response.data.average_score);
      setLastCalculated(response.data.last_calculated);
    } catch (err) {
      console.error("Error fetching average score:", err);
      setAverageScoreError("Failed to fetch average score. Please try again.");
    }
  };

  const fetchReadResponses = async () => {
    try {
      const token = localStorage.getItem("access_token");
      console.log("Fetching read responses for business:", user.business_name);

      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/read-responses`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      console.log("Read responses data:", response.data);

      // Create test data that matches your DB structure exactly
      const testReadResponses = [
        ...response.data,
        {
          id: "test1",
          question_text: "Test Question 1", // Changed to string
          answer: "Test Answer 1", // Changed to string
          date_answered: new Date().toISOString(), // Valid date string
        },
        {
          id: "test2",
          question_text: "Test Question 2",
          answer: "Test Answer 2",
          date_answered: new Date().toISOString(),
        },
        {
          id: "test3",
          question_text: "Test Question 3",
          answer: "Test Answer 3",
          date_answered: new Date().toISOString(),
        },
      ];

      setReadResponses(response.data || []);
    } catch (err) {
      console.error("Error fetching read responses:", err);
      setReadResponses([]);
    }
  };

  useEffect(() => {
    if (user?.is_admin) {
      fetchUsers();
      fetchAverageScore();
    }
    fetchResponses();
    checkReadResponses();
    fetchReadResponses();

    if (user?.is_admin) {
      const scoreIntervalId = setInterval(fetchAverageScore, 600000);
      const readResponsesInterval = setInterval(fetchReadResponses, 300000);
      return () => {
        clearInterval(scoreIntervalId);
        clearInterval(readResponsesInterval);
      };
    }
  }, [user]);

  useEffect(() => {
    const dismissedNotification = localStorage.getItem(
      "dismissedReadNotification",
    );
    setShowReadNotification(hasReadResponses && !dismissedNotification);
  }, [hasReadResponses]);

  const dismissNotification = () => {
    setShowReadNotification(false);
    localStorage.setItem("dismissedReadNotification", "true");
  };

  return {
    users,
    setUsers,
    loading,
    error,
    responses,
    setResponses,
    hasReadResponses,
    averageScore,
    lastCalculated,
    averageScoreError,
    showReadNotification,
    setShowReadNotification,
    latestSurveyResponse,
    dismissNotification,
    readResponses,
  };
};

export default useDashboardData;
