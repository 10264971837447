import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BarChart, Users, TrendingUp } from "lucide-react";

const Login = ({ onLoginSuccess }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Username cannot contain spaces");
    } else {
      setError("");
    }
    setUsername(value.replace(/\s/g, "").toLowerCase()); // Remove spaces and convert to lowercase
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Password cannot contain spaces");
    } else {
      setError("");
    }
    setPassword(value.replace(/\s/g, "")); // Remove spaces
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Additional validation to ensure no empty fields after trimming
    if (!username.trim() || !password.trim()) {
      setError("Username and Password cannot be empty or contain only spaces.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "/api/v1/login",
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      localStorage.setItem("access_token", response.data.access_token);
      onLoginSuccess(response.data.user_info);
    } catch (err) {
      setError(
        err.response?.data?.detail ||
          "Login failed. Please check your credentials.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-950 to-gray-950  text-white relative">
      <header className="p-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 relative">
        <div className="flex items-end mt-16 md:mt-5 mb-6">
          <sup className="text-xs align-text-bottom text-slate-400 ml-4">
            &copy;
          </sup>
          <h1 className="tracking-wide font-bold text-6xl ml-2 font-serif text-yellow-600 shadow-2xl">
            matcha
          </h1>
        </div>
        <button
          onClick={() => setShowLoginForm(true)}
          className="bg-gradient-to-t from-yellow-600 to-yellow-950 text-sm tracking-widest hover:bg-sky-900 text-slate-300 font-serif  py-2 px-4 rounded absolute top-0 right-0 mt-10 mr-8 shadow-2xl md:static md:mt-0 md:mr-0"
        >
          Login
        </button>
      </header>

      <main className="container mx-auto px-4 py-16 font-serif">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-10 md:mb-0">
            <h2 className="text-xl md:text-2xl font-serif font-semibold mb-4 text-slate-300">
              Ai-driven performance insights for teams that want to grow their
              company culture
            </h2>
            <p className="text-xl md:text-lg mb-8 text-slate-400">
              Identify key talent and increase their success
            </p>
            <div className="grid grid-cols-1 bg-dotted-pattern md:grid-cols-3 gap-6 mt-12">
              <div className="bg-gradient-to-t from-slate-800 to-slate-950  bg-opacity-10 p-4 rounded-lg border border-slate-800 shadow-2xl">
                <BarChart className="w-10 h-10 mb-2 text-yellow-500" />
                <h3 className="text-lg mb-2 text-slate-300">
                  Employee engagement
                </h3>
              </div>
              <div className="bg-gradient-to-t from-slate-800 to-slate-950  bg-opacity-10 border border-slate-800 p-4 rounded-lg shadow-2xl">
                <Users className="w-10 h-10 mb-2 text-yellow-500" />
                <h3 className="text-lg mb-2 text-slate-300">
                  Talent Retention
                </h3>
              </div>
              <div className="bg-gradient-to-t from-slate-800 to-slate-950  bg-opacity-10 p-4 rounded-lg border border-slate-800 shadow-2xl">
                <TrendingUp className="w-10 h-10 mb-2 text-yellow-500" />
                <h3 className="text-lg text-slate-300 mb-2">
                  Productivity Boost
                </h3>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Modal Login Form */}
      {showLoginForm && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ backdropFilter: "blur(8px)" }}
        >
          <div className="bg-gradient-to-t from-slate-800 to-slate-900 border border-slate-800 shadow-2xl bg-opacity-90 p-8 rounded-lg w-11/12 md:w-1/3 relative">
            <button
              onClick={() => setShowLoginForm(false)}
              className="absolute top-4 right-4 text-yellow-500 text-2xl"
            >
              &times;
            </button>
            <h2 className="text-2xl font-serif text-slate-200 font-semibold mb-6 text-center">
              Login to Your Account
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="username" className="block mb-2">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={handleUsernameChange}
                  className="w-full px-3 py-2 bg-slate-800 rounded focus:outline-none focus:ring-1 focus:ring-slate-100"
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block mb-2">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="w-full px-3 py-2 bg-slate-800 rounded focus:outline-none focus:ring-1 focus:ring-slate-300"
                  required
                />
              </div>
              {error && <p className="text-red-300 mb-4">{error}</p>}
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-b from-yellow-600 to-yellow-950 text-slate-100 hover:bg-slate-300 hover:text-slate-950 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {loading ? "Logging in..." : "Submit"}
              </button>
            </form>
            <div className="mt-4 text-center">
              <Link
                to="/forgot-password"
                className="text-slate-300 text-sm hover:text-sky-800"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
