import React from "react";
import { Download, Activity } from "lucide-react";
import QuarterSelector from "../ENPSstats/QuarterSelector";

/**
 * Header component for the ENPS dashboard
 * Includes title, quarter selector and export button
 */
const ENPSHeader = ({
  selectedYear,
  selectedQuarter,
  availableYears,
  quarterlyData,
  hasResponses,
  loading,
  exporting,
  currentUser,
  onQuarterChange,
  onExport,
}) => {
  return (
    <div className="bg-gray-700 backdrop-blur-md p-5 md:p-6 border-b border-zinc-200/50">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-gradient-to-br from-zinc-100 to-gray-200 rounded-xl shadow">
            <Activity className="h-5 w-5 text-zinc-600" />
          </div>
          <div>
            <h3 className="text-lg font-grotesk font-medium tracking-wide text-amber-400 flex items-center gap-2">
              Employee Net Promoter Score
              <span className="bg-zinc-100 text-zinc-600 text-xs py-0.5 px-2 rounded-full shadow-sm">
                BETA
              </span>
            </h3>
            <p className="text-xs text-gray-300 mt-0.5">
              Q{selectedQuarter} {selectedYear} employee satisfaction metrics
            </p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row w-full md:w-auto gap-3">
          {/* Quarter selector */}
          <QuarterSelector
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            availableYears={availableYears}
            quarterlyData={quarterlyData}
            onChange={onQuarterChange}
            disabled={loading}
          />

          {/* Export button - only shown for admins */}
          {currentUser?.is_admin && (
            <button
              onClick={onExport}
              disabled={exporting || !hasResponses}
              className="flex items-center gap-2 px-4 py-2 text-xs 
              bg-gradient-to-br from-zinc-100 to-zinc-200
              hover:from-zinc-200 hover:to-zinc-300
              disabled:from-zinc-50 disabled:to-zinc-100
              disabled:text-zinc-400 text-zinc-700 rounded-lg transition-all duration-300 
              shadow-sm hover:shadow disabled:shadow-none group"
            >
              <Download className="h-4 w-4 group-hover:animate-bounce" />
              {exporting ? "Exporting..." : "Export Data"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ENPSHeader;
