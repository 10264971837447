import React, { useState } from "react";
import axios from "axios";

const CreateAdmin = ({ businessName }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setLoading(true);

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/create-admin`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      console.log("Admin invitation sent:", response.data);
      setSuccessMessage(
        "Admin invitation sent successfully. Check email for further instructions.",
      );
      setEmail("");
    } catch (err) {
      console.error(
        "Error sending admin invitation:",
        err.response?.data || err.message,
      );
      if (err.response?.status === 400 && err.response?.data?.detail) {
        const errorDetail = err.response.data.detail;
        if (
          typeof errorDetail === "object" &&
          errorDetail.code === "EMAIL_ALREADY_EXISTS"
        ) {
          setError(errorDetail.message);
          setEmail("");
        } else {
          setError(
            typeof errorDetail === "string"
              ? errorDetail
              : "Failed to send admin invitation. Please try again.",
          );
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full mb-1 mt-6">
      <div className="w-full bg-gray-100 p-6 border-l border-zinc-200 shadow-lg rounded-lg">
        <h2 className="text-lg font-grotesk font-light tracking-wide text-zinc-700 mb-4">
          Create Admin
        </h2>
        {error && <p className="text-sm text-rose-600 mb-4">{error}</p>}
        {successMessage && (
          <p className="text-sm text-green-600 mb-4">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-grotesk font-medium text-zinc-700 mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full rounded bg-neutral-50 border border-zinc-200 focus:border-zinc-400 transition-colors duration-200 text-zinc-700 px-4 py-3"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-3 px-6 border border-transparent shadow-2xl text-sm font-medium text-amber-300 font-grotesk tracking-wide bg-gray-600 hover:bg-zinc-300 rounded transition-all duration-200 disabled:opacity-50"
          >
            {loading ? "Sending Invitation..." : "Send Admin Invitation"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateAdmin;
