import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("sending");

    console.log("Attempting to submit form to:", "/api/v1/contact");
    console.log("Form data:", formData);

    try {
      const response = await axios.post("/api/v1/contact-form", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setStatus("success");
        setFormData({ name: "", email: "", message: "" });
      }
    } catch (error) {
      console.error("Error:", error);
      console.log("Error response:", error.response);
      console.log("Error request:", error.request);
      setStatus("error");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-neutral-800 to-neutral-950">
      {/* Navigation */}
      <nav className="w-full flex justify-center p-4 bg-transparent">
        <div className="flex space-x-8">
          <Link
            to="/"
            className="text-neutral-800 hover:text-neutral-600 font-serif"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-neutral-800 hover:text-neutral-600 font-serif"
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="text-neutral-800 hover:text-neutral-600 font-serif"
          >
            Contact
          </Link>
        </div>
      </nav>

      {/* Logo Section */}
      <header className="p-8 flex justify-center items-center">
        <div className="flex items-end mt-16 md:mt-5 mb-6">
          <img
            src="/matcha8.svg"
            alt="Matcha Logo"
            className="h-[180px] w-full invert"
          />
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-2xl mx-auto bg-white bg-opacity-90 rounded-lg shadow-xl p-8">
          <h1 className="text-4xl font-serif text-neutral-800 mb-6">
            Contact Us
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-neutral-700 mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-neutral-500"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-neutral-700 mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-neutral-500"
                required
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-neutral-700 mb-2">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                className="w-full px-4 py-2 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-neutral-500"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              disabled={status === "sending"}
              className="w-full bg-gradient-to-b from-neutral-600 to-neutral-950 text-white py-2 px-6 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
            >
              {status === "sending" ? "Sending..." : "Send Message"}
            </button>

            {status === "success" && (
              <p className="text-green-600">
                Thank you for your message. We'll be in touch soon!
              </p>
            )}
            {status === "error" && (
              <p className="text-red-600">
                There was an error sending your message. Please try again later.
              </p>
            )}
          </form>
        </div>
      </main>
    </div>
  );
};

export default Contact;
