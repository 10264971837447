// components/MasterDashboard/MasterAdminForm.jsx
import React from "react";
import { User, Mail, Lock } from "lucide-react";

const MasterAdminForm = ({ newAdmin, handleAdminInputChange, isCreating }) => {
  return (
    <div className="space-y-4">
      {/* Username */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Username
        </label>
        <div className="relative">
          <User className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="text"
            name="username"
            value={newAdmin.username}
            onChange={handleAdminInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="e.g., johndoe"
            disabled={isCreating}
          />
        </div>
      </div>

      {/* Email */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Email
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="email"
            name="email"
            value={newAdmin.email}
            onChange={handleAdminInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="e.g., john@example.com"
            disabled={isCreating}
          />
        </div>
      </div>

      {/* Password */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-neutral-300">
          Password
        </label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 h-5 w-5" />
          <input
            type="password"
            name="password"
            value={newAdmin.password}
            onChange={handleAdminInputChange}
            className="pl-10 pr-4 py-3 bg-neutral-700/50 border border-neutral-600 text-white rounded-lg focus:outline-none focus:border-amber-500 w-full placeholder-neutral-500"
            placeholder="Secure password"
            disabled={isCreating}
          />
        </div>
        <p className="text-neutral-500 text-xs">
          Must be at least 8 characters.
        </p>
      </div>
    </div>
  );
};

export default MasterAdminForm;
