import React, { useState, useEffect, useRef } from "react";
import { ClipboardList } from "lucide-react";
import ENPSScoreCard from "../ENPSstats/ENPSScoreCard";
import ResponseOverviewCard from "../ENPSstats/ResponseOverviewCard";
import ENPSHeader from "../ENPSstats/ENPSHeader";
import ENPSLoadingErrorStates from "../ENPSstats/ENPSLoadingErrorStates";
import ENPSStatsDataService from "../ENPSstats/ENPSStatsDataService";

/**
 * Component to display when there's no ENPS data for the current quarter
 */
const NoENPSData = ({ quarter, year }) => {
  return (
    <div className="flex flex-col items-center justify-center p-6 h-full">
      <ClipboardList className="h-12 w-12 text-zinc-400 mb-4" />
      <h3 className="text-xl font-medium text-zinc-700 mb-2 text-center">
        No ENPS Data Available
      </h3>
      <p className="text-zinc-500 text-center max-w-md">
        eNPS for Q{quarter} {year} has not begun or no responses have been
        collected yet.
      </p>
    </div>
  );
};

/**
 * Main ENPS Statistics component
 */
const ENPSStats = ({ businessName }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Add useRef for tracking initial render
  const isInitialMount = useRef(true);

  // State for quarter selection and available historical data
  const { year: currentYear, quarter: currentQuarter } =
    ENPSStatsDataService.getCurrentYearAndQuarter();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedQuarter, setSelectedQuarter] = useState(currentQuarter);
  const [availableYears, setAvailableYears] = useState([]);
  const [quarterlyData, setQuarterlyData] = useState([]);

  // Track if the current quarter has any data
  const [hasData, setHasData] = useState(false);

  // Initial data load
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);

        // Get the current user
        const user = await ENPSStatsDataService.fetchCurrentUser();
        setCurrentUser(user);

        // Fetch quarterly data
        const quarterlyResponse =
          await ENPSStatsDataService.fetchQuarterlyData(businessName);
        const quarters = quarterlyResponse.quarters || [];
        const years = quarterlyResponse.available_years || [];

        setQuarterlyData(quarters);
        setAvailableYears(years.length > 0 ? years : [currentYear]);

        // If we have quarterly data, set the default to the most recent quarter with data
        if (quarters.length > 0) {
          const latestQuarter =
            ENPSStatsDataService.getMostRecentQuarterWithData(quarters);

          if (latestQuarter) {
            // Set to the most recent quarter with data
            setSelectedYear(latestQuarter.year);
            setSelectedQuarter(latestQuarter.quarter);
            setHasData(true);

            // Transform and set stats
            setStats(ENPSStatsDataService.transformQuarterData(latestQuarter));
          } else {
            // No quarters with data, use current quarter/year
            setSelectedYear(currentYear);
            setSelectedQuarter(currentQuarter);
            setHasData(false);
            setStats(
              ENPSStatsDataService.createEmptyStats(
                currentYear,
                currentQuarter,
              ),
            );
          }
        } else {
          // No quarterly data at all
          setSelectedYear(currentYear);
          setSelectedQuarter(currentQuarter);
          setHasData(false);
          setStats(
            ENPSStatsDataService.createEmptyStats(currentYear, currentQuarter),
          );
        }

        setError(null);
      } catch (err) {
        console.error("Error in initial data fetch:", err);
        setError("Failed to load ENPS data");
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessName]);

  // Fetch stats for a specific quarter
  const fetchQuarterStats = async () => {
    try {
      setLoading(true);

      // Fetch quarterly data
      const quarterlyResponse =
        await ENPSStatsDataService.fetchQuarterlyData(businessName);
      const quarters = quarterlyResponse.quarters || [];

      // Find the specific quarter data from the response
      const currentQuarterData = quarters.find(
        (q) => q.year === selectedYear && q.quarter === selectedQuarter,
      );

      if (currentQuarterData && currentQuarterData.total_responses > 0) {
        setStats(ENPSStatsDataService.transformQuarterData(currentQuarterData));
        setHasData(true);
        setError(null);
      } else {
        // If no data found for this quarter or total_responses is 0
        setHasData(false);
        setStats(
          ENPSStatsDataService.createEmptyStats(selectedYear, selectedQuarter),
        );
      }
    } catch (err) {
      console.error("Error fetching quarter stats:", err);
      setError(
        `Failed to load ENPS statistics for Q${selectedQuarter} ${selectedYear}`,
      );
    } finally {
      setLoading(false);
    }
  };

  // Refresh stats when selected quarter changes, but skip the initial render
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (selectedYear && selectedQuarter) {
      fetchQuarterStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedQuarter]);

  // Handle quarter selection change
  const handleQuarterChange = (year, quarter) => {
    setSelectedYear(year);
    setSelectedQuarter(quarter);
    setLoading(true);
  };

  // Handle export button click
  const handleExport = async () => {
    if (!currentUser?.is_admin) return;

    try {
      setExporting(true);

      // Get the export data
      const blob = await ENPSStatsDataService.exportQuarterData(
        businessName,
        selectedYear,
        selectedQuarter,
      );

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `enps_responses_${businessName}_Q${selectedQuarter}_${selectedYear}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error("Export failed:", err);
      alert("Failed to export data. Please try again.");
    } finally {
      setExporting(false);
    }
  };

  // Check for loading or error states
  const loadingErrorContent = (
    <ENPSLoadingErrorStates loading={loading} error={error} />
  );
  if (loading || error) return loadingErrorContent;

  // Find the current quarter's data in the quarterly data array
  const currentQuarterData = quarterlyData.find(
    (q) => q.year === selectedYear && q.quarter === selectedQuarter,
  );

  // Determine if the current quarter has responses (for export button)
  const hasResponses =
    currentQuarterData && currentQuarterData.total_responses > 0;

  return (
    <div className="bg-gradient-to-br from-gray-100 to-neutral-200 rounded-xl overflow-hidden border border-zinc-300/70 shadow-xl">
      {/* Header component with title and controls */}
      <ENPSHeader
        selectedYear={selectedYear}
        selectedQuarter={selectedQuarter}
        availableYears={availableYears}
        quarterlyData={quarterlyData}
        hasResponses={hasResponses}
        loading={loading}
        exporting={exporting}
        currentUser={currentUser}
        onQuarterChange={handleQuarterChange}
        onExport={handleExport}
      />

      {/* Content with modern glassmorphic cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-5 md:p-6">
        {hasData ? (
          <>
            {/* Score card component */}
            <ENPSScoreCard
              score={stats.enps_score}
              promotersPercentage={stats.promoters_percentage}
              passivesPercentage={stats.passives_percentage}
              detractorsPercentage={stats.detractors_percentage}
              selectedQuarter={selectedQuarter}
              selectedYear={selectedYear}
            />

            {/* Response overview component */}
            <ResponseOverviewCard stats={stats} />
          </>
        ) : (
          // No data message
          <div className="col-span-2 bg-gray-50/80 backdrop-blur-sm py-12 rounded-xl border border-zinc-100/70 shadow-lg">
            <NoENPSData quarter={selectedQuarter} year={selectedYear} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ENPSStats;
