import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PushReview = ({ businessName }) => {
  const [reviews, setReviews] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [expirationDate, setExpirationDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
  );
  const [scheduledPushTime, setScheduledPushTime] = useState(new Date());
  const [isPushImmediate, setIsPushImmediate] = useState(true);
  const [isTeamReview, setIsTeamReview] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchReviews();
    fetchTeams();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
    }
  };

  const fetchTeams = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/teams`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setTeams(response.data);
    } catch (err) {
      console.error("Error fetching teams:", err);
      setError("Failed to fetch teams. Please try again.");
    }
  };

  const handlePushReview = async () => {
    if (!selectedReview) return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/push-review`,
        {
          review_id: parseInt(selectedReview),
          expiration_date: expirationDate.toISOString(),
          scheduled_push_time: isPushImmediate
            ? null
            : scheduledPushTime.toISOString(),
          is_team_review: isTeamReview,
          team_id: isTeamReview ? parseInt(selectedTeam) : null,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      console.log("Push review response:", response.data);
      alert(
        isPushImmediate
          ? `Review ${isTeamReview ? "pushed to team" : "pushed to all users"} successfully!`
          : `Review scheduled to be ${isTeamReview ? "pushed to team" : "pushed to all users"} successfully!`,
      );
    } catch (err) {
      console.error("Error pushing review:", err.response?.data || err.message);
      setError(
        `Failed to push review: ${err.response?.data?.detail || err.message}`,
      );
    }
  };
  return (
    <div className="bg-slate-800 rounded-lg shadow-2xl p-4">
      <h3 className="text-xl text-slate-200 mb-10 font-mono">push review</h3>
      <select
        className="w-full p-2 mb-4 bg-slate-950 text-md rounded text-slate-100"
        onChange={(e) => setSelectedReview(e.target.value)}
        value={selectedReview || ""}
      >
        <option value="">Select a review</option>
        {reviews.map((review) => (
          <option key={review.id} value={review.id}>
            {review.reviews_text.substring(0, 50)}...
          </option>
        ))}
      </select>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Expiration Date
        </label>
        <DatePicker
          selected={expirationDate}
          onChange={(date) => setExpirationDate(date)}
          className="w-full p-2 bg-teal-500 opacity-80 rounded"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={isPushImmediate}
            onChange={() => setIsPushImmediate(!isPushImmediate)}
            className="mr-2"
          />
          Push Immediately
        </label>
      </div>
      {!isPushImmediate && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Scheduled Push Time
          </label>
          <DatePicker
            selected={scheduledPushTime}
            onChange={(date) => setScheduledPushTime(date)}
            className="w-full p-2 bg-gray-700 rounded"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
      )}
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={isTeamReview}
            onChange={() => setIsTeamReview(!isTeamReview)}
            className="mr-2"
          />
          Push to Team
        </label>
      </div>
      {isTeamReview && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Select Team
          </label>
          <select
            className="w-full p-2 bg-slate-950 text-md rounded text-slate-100"
            onChange={(e) => setSelectedTeam(e.target.value)}
            value={selectedTeam || ""}
          >
            <option value="">Select a team</option>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.team_name}
              </option>
            ))}
          </select>
        </div>
      )}
      <button
        onClick={handlePushReview}
        className="w-full font-mono text-sm py-2 px-4 bg-indigo-800 text-white rounded hover:bg-cyan-700"
        disabled={!selectedReview || (isTeamReview && !selectedTeam)}
      >
        {isPushImmediate ? "Push Review" : "Schedule Review Push"}
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default PushReview;
