import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const PriorityCheck = ({
  priority,
  priorityChecked,
  submittingPriority,
  handlePrioritySubmit,
  prioritySubmitError,
  uncompletedPriorities = [],
}) => {
  // Filter out the current priority from uncompleted priorities to avoid duplication
  const otherPriorities = uncompletedPriorities.filter(
    (p) => p.priority !== priority,
  );

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.05, backgroundColor: "#1e293b" },
    tap: { scale: 0.95 },
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: (custom) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: custom * 0.1,
        duration: 0.3,
      },
    }),
  };

  return (
    <AnimatePresence>
      {/* Current user's priority */}
      <motion.div
        className="mb-4 p-4 bg-gradient-to-b from-neutral-600 to bg-stone-950 rounded-lg mt-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, y: -10 }}
      >
        <motion.h4
          className="text-sm text-[#E7EEE7]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          Priority for this week:
        </motion.h4>
        <motion.div
          className="flex mt-1 justify-between items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <motion.p
            className="text-slate-200 text-sm"
            initial={{ x: -5 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.4 }}
            whileHover={{ x: 2 }}
          >
            {priority}
          </motion.p>
          <motion.button
            onClick={() => handlePrioritySubmit()} // Call with no params for current user's priority
            disabled={submittingPriority || priorityChecked}
            className={`text-xs px-2 py-2 bg-neutral-600 text-neutral-100 rounded hover:bg-slate-900 focus:outline-none ml-auto ${
              submittingPriority || priorityChecked
                ? "opacity-60 cursor-not-allowed"
                : ""
            }`}
            variants={buttonVariants}
            initial="rest"
            whileHover={!priorityChecked ? "hover" : "rest"}
            whileTap={!priorityChecked ? "tap" : "rest"}
          >
            {submittingPriority ? (
              <motion.span
                className="flex items-center"
                animate={{ opacity: [0.5, 1, 0.5] }}
                transition={{ repeat: Infinity, duration: 1 }}
              >
                <motion.div
                  className="w-3 h-3 border-2 border-slate-300 border-t-transparent rounded-full mr-1 inline-block"
                  animate={{ rotate: 360 }}
                  transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                />
                Processing...
              </motion.span>
            ) : priorityChecked ? (
              <motion.span
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                Priority Completed
              </motion.span>
            ) : (
              "Mark Completed"
            )}
          </motion.button>
        </motion.div>
        <AnimatePresence>
          {prioritySubmitError && (
            <motion.p
              className="text-red-500 mt-1"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              {prioritySubmitError}
            </motion.p>
          )}
        </AnimatePresence>
      </motion.div>

      {/* Other uncompleted priorities section */}
      {otherPriorities.length > 0 && (
        <motion.div
          className="mb-6 p-4 bg-gradient-to-t from-stone-300 to-zinc-100 rounded-lg border border-zinc-50"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, y: -10 }}
          transition={{ delay: 0.2 }}
        >
          <motion.h4
            className="text-sm text-amber-600 mb-2 tracking-wider font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Previously Uncompleted:
          </motion.h4>
          <motion.div
            className="space-y-2"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {},
              visible: {
                transition: { staggerChildren: 0.1 },
              },
            }}
          >
            {otherPriorities.map((item, index) => (
              <motion.div
                key={index}
                className="flex justify-between items-center p-1 rounded"
                variants={listItemVariants}
                custom={index}
                whileHover={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  transition: { duration: 0.2 },
                }}
              >
                <motion.div className="space-y-1">
                  <motion.p
                    className="text-gray-700 text-sm"
                    whileHover={{ x: 2 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    {item.priority}
                  </motion.p>
                  <motion.p className="text-xs text-zinc-600">
                    Set on: {new Date(item.date).toLocaleString("en-US")}
                  </motion.p>
                </motion.div>
                <motion.button
                  onClick={() =>
                    handlePrioritySubmit({
                      userId: item.user_id,
                      date: item.date,
                    })
                  }
                  className="text-xs px-2 py-1 bg-neutral-800/80 text-white rounded hover:bg-cyan-600 focus:outline-none transition-colors duration-200"
                  variants={buttonVariants}
                  initial="rest"
                  whileHover="hover"
                  whileTap="tap"
                >
                  Mark Complete
                </motion.button>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PriorityCheck;
