import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const CurrentNominationsList = ({ nominations }) => {
  const [showNominations, setShowNominations] = useState(true);

  // Group nominations by nominee for counting
  const getNomineeCounts = () => {
    const counts = {};

    nominations.forEach((nom) => {
      if (!counts[nom.nominee_id]) {
        counts[nom.nominee_id] = {
          nominee_id: nom.nominee_id,
          username: nom.nominee_username,
          count: 0,
          nominations: [],
        };
      }

      counts[nom.nominee_id].count += 1;
      counts[nom.nominee_id].nominations.push(nom);
    });

    // Convert to array and sort by count (highest first)
    return Object.values(counts).sort((a, b) => b.count - a.count);
  };

  const nomineeCount = getNomineeCounts();

  return (
    <div className="mb-6">
      <div
        className="flex items-center justify-between bg-gray-200 p-3 rounded-md cursor-pointer"
        onClick={() => setShowNominations(!showNominations)}
      >
        <h3 className="text-md font-medium text-gray-800 font-grotesk">
          Current Nominations by Employee
        </h3>
        {showNominations ? (
          <ChevronUp className="w-5 h-5 text-gray-600" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-600" />
        )}
      </div>

      {showNominations && (
        <div className="mt-4 space-y-4">
          {nominations.length === 0 ? (
            <p className="text-gray-500 italic text-center py-4">
              No nominations yet for this quarter.
            </p>
          ) : (
            nomineeCount.map((nominee) => (
              <div
                key={nominee.nominee_id}
                className="border rounded-lg overflow-hidden"
              >
                <div className="bg-gray-50 p-3 flex justify-between items-center">
                  <div>
                    <span className="font-medium text-gray-800">
                      {nominee.username}
                    </span>
                    <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full">
                      {nominee.count}{" "}
                      {nominee.count === 1 ? "nomination" : "nominations"}
                    </span>
                  </div>
                </div>
                <div className="space-y-3 p-2 bg-gray-50">
                  {nominee.nominations.map((nom) => (
                    <div key={nom.id} className="bg-gray-200 p-3 rounded">
                      <div className="text-sm text-gray-600 mb-1 font-grotesk">
                        Nominated by {nom.nominator_username}
                      </div>
                      <div className="text-gray-800 font-grotesk">
                        "{nom.reason}"
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default CurrentNominationsList;
