import React, { useState, useEffect } from "react";
import ReviewSubmissionsLists from "./ReviewSubmissionLists";
import { ChevronDown, ChevronRight, ArrowLeft, ArrowRight } from "lucide-react";

const ReviewStats = ({
  reviewStats,
  reviewStatsLoading,
  reviewStatsError,
  selectedQuarter,
  setSelectedQuarter,
  selectedYear,
  setSelectedYear,
}) => {
  const [selectedReview, setSelectedReview] = useState(null);
  const [organizedReviews, setOrganizedReviews] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    if (reviewStats?.submissions_data) {
      const reviews = {};

      reviewStats.submissions_data.forEach((review) => {
        const submissionDate = new Date(
          review.recent_submissions[0]?.submitted_at || Date.now(),
        );
        const year = submissionDate.getFullYear();
        const quarter = Math.floor(submissionDate.getMonth() / 3) + 1;

        if (!reviews[year]) reviews[year] = {};
        if (!reviews[year][quarter]) reviews[year][quarter] = [];
        reviews[year][quarter].push(review);
      });

      setOrganizedReviews(reviews);
    }
  }, [reviewStats]);

  // Reset page when year or quarter selection changes
  useEffect(() => {
    setCurrentPage(0);
  }, [selectedYear, selectedQuarter]);

  if (reviewStatsLoading)
    return (
      <div className="mt-6 bg-white p-6 border-l border-zinc-200 shadow-2xl rounded-lg">
        <p className="text-sm text-zinc-500">Loading review statistics...</p>
      </div>
    );
  if (reviewStatsError)
    return (
      <div className="mt-6 bg-white p-6 border-l border-zinc-200 shadow-2xl rounded-lg">
        <p className="text-sm text-rose-600">{reviewStatsError}</p>
      </div>
    );
  if (!reviewStats?.submissions_data) return null;

  const filterPendingSubmissionsByTeam = (reviewData) => {
    if (reviewData.is_team_review && reviewData.team_members) {
      return reviewData.pending_submissions.filter((submission) =>
        reviewData.team_members.includes(submission.user_id),
      );
    }
    return reviewData.pending_submissions;
  };

  const years = Object.keys(organizedReviews).sort((a, b) => b - a);
  const getQuarterLabel = (q) =>
    `Q${q} (${["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"][q - 1]})`;

  const reviewList =
    (organizedReviews[selectedYear] &&
      organizedReviews[selectedYear][selectedQuarter]) ||
    [];
  const startIndex = currentPage * itemsPerPage;
  const paginatedReviews = reviewList.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (startIndex + itemsPerPage < reviewList.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg font-grotesk">
      <div className="flex items-center justify-between mb-6">
        <h4 className="text-lg font-grotesk text-zinc-700 tracking-wide">
          Review Submissions
        </h4>
        <div className="flex gap-4">
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            className="bg-neutral-50 text-zinc-700 border border-zinc-200 rounded px-3 py-1 focus:border-zinc-400 transition-colors duration-200"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            value={selectedQuarter}
            onChange={(e) => setSelectedQuarter(Number(e.target.value))}
            className="bg-neutral-50 text-zinc-700 border border-zinc-200 rounded px-3 py-1 focus:border-zinc-400 transition-colors duration-200"
          >
            {[1, 2, 3, 4].map((q) => (
              <option key={q} value={q}>
                {getQuarterLabel(q)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="space-y-4">
        {paginatedReviews.length > 0 ? (
          paginatedReviews.map((reviewData, index) => {
            // Use a unique key for the review within the current page
            const globalIndex = startIndex + index;
            return (
              <div
                key={globalIndex}
                className="border border-zinc-200 rounded overflow-hidden"
              >
                <button
                  onClick={() =>
                    setSelectedReview(
                      selectedReview === globalIndex ? null : globalIndex,
                    )
                  }
                  className="w-full p-4 text-left bg-neutral-50 hover:bg-neutral-100 transition-colors duration-200"
                >
                  <h5 className="text-sm text-zinc-700 flex items-center justify-between">
                    {reviewData.review_text}
                    {selectedReview === globalIndex ? (
                      <ChevronDown className="h-4 w-4 text-amber-500" />
                    ) : (
                      <ChevronRight className="h-4 w-4 text-amber-500" />
                    )}
                  </h5>
                </button>

                {selectedReview === globalIndex && (
                  <div className="p-4 space-y-6 bg-neutral-50">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="p-4 bg-white border-l border-zinc-200 shadow-2xl rounded-lg">
                        <p className="text-sm text-zinc-500 mb-1">
                          Total Active Users
                        </p>
                        <p className="text-xl text-zinc-700">
                          {reviewData.total_active_users}
                        </p>
                      </div>
                      <div className="p-4 bg-white border-l border-zinc-200 shadow-2xl rounded-lg">
                        <p className="text-sm text-zinc-500 mb-1">
                          Submissions
                        </p>
                        <p className="text-xl text-zinc-700">
                          {reviewData.submissions_this_week}
                        </p>
                      </div>
                      <div className="p-4 bg-white border-l border-zinc-200 shadow-2xl rounded-lg">
                        <p className="text-sm text-zinc-500 mb-1">
                          Submission Rate
                        </p>
                        <p className="text-xl text-zinc-700">
                          {reviewData.submission_rate}%
                        </p>
                      </div>
                    </div>

                    <ReviewSubmissionsLists
                      recentSubmissions={reviewData.recent_submissions}
                      pendingSubmissions={filterPendingSubmissionsByTeam(
                        reviewData,
                      )}
                    />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="text-center py-8 text-sm text-zinc-500">
            No reviews found for {getQuarterLabel(selectedQuarter)}{" "}
            {selectedYear}
          </div>
        )}

        {/* Pagination Controls */}
        {reviewList.length > itemsPerPage && (
          <div className="flex items-center justify-center mt-4 space-x-4">
            <button
              onClick={handlePrev}
              disabled={currentPage === 0}
              className="p-2 rounded hover:bg-gray-200 disabled:opacity-50"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
            <span className="text-sm text-zinc-600">
              Page {currentPage + 1} of{" "}
              {Math.ceil(reviewList.length / itemsPerPage)}
            </span>
            <button
              onClick={handleNext}
              disabled={startIndex + itemsPerPage >= reviewList.length}
              className="p-2 rounded hover:bg-gray-200 disabled:opacity-50"
            >
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewStats;
