import React, { useState, useEffect } from "react";
import axios from "axios";
import TeamReviewPage from "./TeamReviewPage";
import SharedWithMe from "./SharedWithMe";
import ReviewModal from "./ReviewModal";
import AnsweredReviewsSection from "./AnsweredReviewsSection";
import ReviewsToAnswerSection from "./ReviewsToAnswerSection";
import { MessageSquare, Share2, Users } from "lucide-react";

const MyReviews = ({ businessName }) => {
  const [answeredReviews, setAnsweredReviews] = useState([]);
  const [unansweredReviews, setUnansweredReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);
  const [answers, setAnswers] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModalType, setActiveModalType] = useState("");
  const [users, setUsers] = useState([]);
  const [showUserDropdown, setShowUserDropdown] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    fetchData();
    fetchUsers();
  }, [businessName]);

  const fetchData = async () => {
    try {
      const [answeredResponse, reviewsResponse] = await Promise.all([
        axios.get(`/api/v1/businesses/${businessName}/my-answered-reviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }),
        axios.get(`/api/v1/businesses/${businessName}/pushed-reviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }),
      ]);

      const answered = answeredResponse.data.answered_reviews || [];
      const pushedReviews = reviewsResponse.data.active_reviews || [];

      setAnsweredReviews(answered);

      if (pushedReviews.length > 0) {
        const answeredReviewIds = new Set(answered.map((review) => review.id));
        const filteredReviews = pushedReviews.filter(
          (review) => !answeredReviewIds.has(review.id),
        );
        setUnansweredReviews(filteredReviews);
      } else {
        setUnansweredReviews([]);
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/all-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setUsers(response.data || []);
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  const handleRecordClick = async (reviewId) => {
    if (!selectedUser) {
      alert("Please select a user to share with.");
      return;
    }
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/record-review-click/${reviewId}`,
        { shared_with: parseInt(selectedUser) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Click recorded successfully");
      setShowUserDropdown(null);
      setSelectedUser("");
    } catch (err) {
      console.error("Error recording review click:", err);
    }
  };

  const handleReviewSelect = (review, modalType) => {
    setSelectedReview(review);
    setAnswers({});
    if (modalType === "sharedWithMe") {
      const prefilledAnswers = {};
      review.questions.forEach((question, index) => {
        prefilledAnswers[`question_${index + 1}`] = question;
        prefilledAnswers[`answer_${index + 1}`] = review.answers[index] || "";
      });
      setAnswers(prefilledAnswers);
    }
    setModalOpen(true);
    setActiveModalType(modalType);
  };

  const handleAnswerChange = (questionKey, value) => {
    setAnswers({ ...answers, [questionKey]: value });
  };

  const handleSubmitAnswers = async () => {
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/submit-review-answer`,
        {
          review_id: selectedReview.id,
          answers: answers,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      alert("Answers submitted successfully!");
      setSelectedReview(null);
      setAnswers({});
      setModalOpen(false);

      setLoading(true);
      fetchData();
    } catch (err) {
      console.error("Error submitting answers:", err);
      alert("Failed to submit answers. Please try again.");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedReview(null);
    setActiveModalType("");
  };

  if (loading) return <div className="font-serif">Loading reviews...</div>;
  if (error) return <div className="text-red-500 font-serif">{error}</div>;

  return (
    <div className="bg-slate-950 border border-indigo-950 min-h-screen rounded-lg shadow-2xl mt-2">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-lg sm:text-lg font-serif tracking-widest font-medium bg-gradient-to-r from-slate-300 to-teal-100 bg-clip-text text-transparent">
            Reviews
          </h1>
          <MessageSquare className="w-5 h-5 sm:w-6 sm:h-6 text-indigo-400" />
        </div>

        <div className="space-y-4">
          {/* My Answered Reviews Section */}
          <AnsweredReviewsSection
            answeredReviews={answeredReviews}
            handleReviewSelect={handleReviewSelect}
            businessName={businessName}
            showUserDropdown={showUserDropdown}
            setShowUserDropdown={setShowUserDropdown}
            selectedUser={selectedUser}
            handleUserSelect={handleUserSelect}
            handleRecordClick={handleRecordClick}
            users={users}
          />

          {/* Reviews to Answer Section */}
          <ReviewsToAnswerSection
            unansweredReviews={unansweredReviews}
            selectedReview={selectedReview}
            handleReviewSelect={handleReviewSelect}
          />

          {/* Shared With Me Section */}
          <div className="bg-slate-800/60 rounded-lg p-4 border border-slate-700/50 shadow-lg backdrop-blur-sm">
            <h2 className="text-base sm:text-lg font-serif mb-3 text-slate-200 flex items-center gap-2">
              <Share2 className="w-4 h-4 text-cyan-400" />
              Shared With Me
            </h2>
            <SharedWithMe
              businessName={businessName}
              onReviewSelect={(review) =>
                handleReviewSelect(review, "sharedWithMe")
              }
            />
          </div>

          {/* Team Reviews Section */}
          <div className="bg-slate-800/60 rounded-lg p-4 border border-slate-700/50 shadow-lg backdrop-blur-sm">
            <h2 className="text-base sm:text-lg font-serif mb-3 text-slate-200 flex items-center gap-2">
              <Users className="w-4 h-4 text-indigo-400" />
              Team Reviews
            </h2>
            <TeamReviewPage
              businessName={businessName}
              onReviewSelect={(review) =>
                handleReviewSelect(review, "teamReviews")
              }
            />
          </div>
        </div>

        {/* Modal Component */}
        <ReviewModal
          isOpen={modalOpen}
          onClose={closeModal}
          selectedReview={selectedReview}
          activeModalType={activeModalType}
          answers={answers}
          handleAnswerChange={handleAnswerChange}
          handleSubmitAnswers={handleSubmitAnswers}
        />
      </div>
    </div>
  );
};

export default MyReviews;
