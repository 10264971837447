import React, { useState, useEffect } from "react";
import axios from "axios";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import QuestionOrder from "./QuestionOrder";
import DraggableBankQuestion from "./DraggableQuestionBank";

const QuestionList = ({ businessName }) => {
  const [questions, setQuestions] = useState([]);
  const [bankQuestions, setBankQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bankLoading, setBankLoading] = useState(true);
  const [newQuestion, setNewQuestion] = useState("");
  const [activeOrderTab, setActiveOrderTab] = useState(1);
  const [useQuestionBank, setUseQuestionBank] = useState(true); // Default to question bank
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([]);

  // Fetch regular questions
  useEffect(() => {
    fetchQuestions();
  }, [businessName]);

  // Fetch question bank
  useEffect(() => {
    fetchQuestionBank();
  }, [businessName, selectedCategory]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/questions`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setQuestions(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching questions:", err);
      setError("Failed to fetch questions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchQuestionBank = async () => {
    try {
      setBankLoading(true);
      const token = localStorage.getItem("access_token");
      let url = `/api/v1/businesses/${businessName}/question-bank`;

      if (selectedCategory !== "all") {
        url += `?category=${selectedCategory}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setBankQuestions(response.data);

      // Extract unique categories
      const uniqueCategories = [
        ...new Set(response.data.map((q) => q.category).filter(Boolean)),
      ];
      setCategories(uniqueCategories);
    } catch (err) {
      console.error("Error fetching question bank:", err);
      setError("Failed to load question bank. Please try again.");
    } finally {
      setBankLoading(false);
    }
  };

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/questions`,
        { question_text: newQuestion },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setQuestions((prevQuestions) =>
        [...prevQuestions, response.data].sort((a, b) => a.order - b.order),
      );
      setNewQuestion("");
      window.dispatchEvent(new Event("questionsUpdated"));
    } catch (err) {
      console.error("Error adding question:", err);
      if (err.response && err.response.data && err.response.data.detail) {
        setError(`Failed to add question: ${err.response.data.detail}`);
      } else {
        setError("Failed to add question. Please try again.");
      }
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(
        `/api/v1/businesses/${businessName}/questions/${questionId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setQuestions(questions.filter((q) => q.id !== questionId));
      window.dispatchEvent(new Event("questionsUpdated"));
    } catch (err) {
      console.error("Error deleting question:", err);
      setError("Failed to delete question. Please try again.");
    }
  };

  const handleSelectQuestion = (questionText) => {
    setNewQuestion(questionText);
  };

  // Pass along bank questions to the order component
  const handleBankQuestionUse = async (bankQuestion) => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/questions`,
        { question_text: bankQuestion.question_text },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const newQuestion = response.data;
      setQuestions((prev) => [...prev, newQuestion]);
      return newQuestion;
    } catch (err) {
      console.error("Error creating question from bank:", err);
      return null;
    }
  };

  if (error) {
    return (
      <div className="text-sm text-rose-600 p-4 bg-rose-50 rounded">
        {error}
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="bg-gray-100 p-10 border-l border-zinc-200 shadow-lg rounded-lg mt-24">
        <h3 className="text-lg font-semibold font-grotesk tracking-wide text-zinc-700 text-start">
          Vibe Check Questions
        </h3>
        <hr class="mt-1 border-t border-gray-300 mb-8" />

        {/* Toggle button between manual entry and question bank */}
        <div className="flex justify-center mb-8">
          <div className="bg-neutral-200 p-1 rounded-lg">
            <button
              className={`px-4 py-2 rounded-md text-sm font-grotesk transition-all ${
                !useQuestionBank
                  ? "bg-zinc-800 text-white shadow-md"
                  : "bg-transparent text-slate-700 hover:text-zinc-800"
              }`}
              onClick={() => setUseQuestionBank(false)}
            >
              Write Your Own
            </button>
            <button
              className={`px-4 py-2 rounded-md text-sm font-grotesk transition-all ${
                useQuestionBank
                  ? "bg-zinc-800 text-white shadow-md"
                  : "bg-transparent text-zinc-600 hover:text-zinc-800"
              }`}
              onClick={() => setUseQuestionBank(true)}
            >
              Question Bank
            </button>
          </div>
        </div>

        {useQuestionBank ? (
          <div className="mb-8">
            <div className="bg-gray-100 p-6 border border-zinc-100 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-base font-semibold font-grotesk tracking-wide text-zinc-700">
                  Question Bank
                </h4>
                <div className="h-px flex-grow bg-slate-300 ml-4"></div>

                <div className="flex items-center font-grotesk">
                  <div className="text-xs text-zinc-500 italic mr-2">
                    Drag questions directly to question sections
                  </div>
                  <div className="px-2 py-1 bg-amber-100 text-amber-800 text-xs rounded-md">
                    Drag & Drop
                  </div>
                </div>
              </div>

              <div className="mb-4 flex flex-wrap gap-2">
                <button
                  onClick={() => setSelectedCategory("all")}
                  className={`px-3 py-1.5 text-xs rounded-full ${
                    selectedCategory === "all"
                      ? "bg-zinc-700 text-white font-grotesk"
                      : "bg-zinc-100 text-zinc-700 hover:bg-zinc-200 font-grotesk"
                  }`}
                >
                  All
                </button>
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={`px-3 py-1.5 text-xs rounded-full ${
                      selectedCategory === category
                        ? "bg-zinc-700 text-white font-grotesk"
                        : "bg-zinc-100 font-grotesk text-zinc-700 hover:bg-zinc-200"
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>

              <div className="space-y-2 max-h-64 overflow-y-auto pr-2">
                {bankLoading ? (
                  <div className="flex justify-center py-6">
                    <div className="w-8 h-8 border-t-2 border-l-2 border-zinc-400 animate-spin rounded-full"></div>
                  </div>
                ) : bankQuestions.length === 0 ? (
                  <p className="text-center text-sm text-zinc-500 py-6">
                    No questions available in this category.
                  </p>
                ) : (
                  bankQuestions.map((question) => (
                    <DraggableBankQuestion
                      key={question.id}
                      question={question}
                      onSelectQuestion={() =>
                        handleSelectQuestion(question.question_text)
                      }
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleAddQuestion} className="mb-8 space-y-4">
            <input
              type="text"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              placeholder="Enter new question..."
              className="w-full px-4 py-3 font-grotesk bg-neutral-50 border border-zinc-300 focus:border-zinc-400 transition-colors duration-200 rounded text-zinc-700"
              required
            />
            <button
              type="submit"
              className="w-full py-3 px-6 bg-zinc-200 border border-gray-50 hover:bg-zinc-300 text-zinc-900 rounded transition-all duration-200 shadow-lg font-grotesk"
            >
              Add Question
            </button>
          </form>
        )}

        {/* Always show the input field for adding selected bank questions to the list */}
        {useQuestionBank && newQuestion && (
          <form onSubmit={handleAddQuestion} className="mb-8 space-y-4">
            <div className="p-4 bg-green-50 border border-green-200 rounded-md">
              <h4 className="text-sm font-medium text-green-800 mb-2">
                Selected Question:
              </h4>
              <p className="text-sm text-zinc-700 mb-3">{newQuestion}</p>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white rounded transition-all duration-200 text-sm"
              >
                Add to Question List
              </button>
            </div>
          </form>
        )}

        <div className="flex bg-neutral-50 p-4 rounded-lg border-l border-zinc-200 shadow-lg mb-6">
          <button
            className={`px-4 py-2 mr-2 rounded font-grotesk text-sm ${
              activeOrderTab === 1 ? "bg-gray-500" : "bg-zinc-800"
            } text-white`}
            onClick={() => setActiveOrderTab(1)}
          >
            1st Question Set
          </button>
          <button
            className={`px-4 py-2 rounded font-grotesk text-sm ${
              activeOrderTab === 2 ? "bg-zinc-500" : "bg-zinc-800"
            } text-white`}
            onClick={() => setActiveOrderTab(2)}
          >
            2nd Question Set
          </button>
        </div>

        <QuestionOrder
          businessName={businessName}
          orderId={activeOrderTab}
          onDeleteQuestion={handleDeleteQuestion}
          bankQuestions={bankQuestions}
          onBankQuestionUse={handleBankQuestionUse}
        />
      </div>
    </DndProvider>
  );
};

export default QuestionList;
