// DashboardHeader.jsx
import React from "react";
import { LogOut, Menu, X } from "lucide-react";

const DashboardHeader = ({
  user,
  handleLogout,
  isSidebarOpen,
  toggleSidebar,
  getDisplayName,
}) => (
  <header className="bg-gradient-to-b  from-slate-950 to-[#070707]  p-2 px-4 lg:px-4">
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center space-x-2">
        <button
          onClick={toggleSidebar}
          className="md:hidden text-slate-200 p-2"
        >
          {isSidebarOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
        <h1 className="text-3xl font-semibold text-yellow-600 font-serif">
          matcha
        </h1>
        <h1 className="text-xl lg:text-2xl text-slate-300 font-serif">
          + {getDisplayName(user.business_name)}
        </h1>
      </div>
      <button
        onClick={handleLogout}
        className="flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-md text-slate-400 hover:text-amber-700 focus:outline-none"
      >
        <LogOut className="mr-2 h-4 w-4" />
        Logout
      </button>
    </div>
  </header>
);

export default DashboardHeader;
