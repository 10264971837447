import React from "react";

/**
 * Simple summary header that displays:
 *  - "Yearly Average: XX"
 *  - Trend arrow (up/down/same)
 *  - Year selector dropdown
 */
const YearlySummary = ({
  yearlyAverage,
  previousYearAverage,
  trendDirection,
  selectedYear,
  availableYears,
  handleYearChange,
  getScoreColor,
  getTrendIcon,
}) => {
  return (
    <>
      {/* Desktop header with year selector and yearly average */}
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-8">
        <div>
          <h2 className="text-xl font-semibold text-slate-800 mb-1">
            Quarterly eNPS Analytics
          </h2>
          <p className="text-slate-500 text-sm">
            Track employee loyalty metrics by quarter
          </p>
        </div>

        <div className="mt-4 lg:mt-0 flex items-center">
          {/* Show yearly average on desktop */}
          <div className="mr-6 hidden lg:block">
            <div className="text-slate-800 font-semibold text-lg mb-1">
              Yearly Average:{" "}
              <span style={{ color: getScoreColor(yearlyAverage) }}>
                {yearlyAverage}
              </span>
            </div>
            {previousYearAverage !== null && (
              <div className="flex items-center">
                {getTrendIcon(trendDirection)}
              </div>
            )}
          </div>

          {/* Year dropdown */}
          <div className="flex flex-col">
            <label
              htmlFor="year-select"
              className="mb-1 text-xs font-medium text-slate-500"
            >
              YEAR
            </label>
            <select
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              className="bg-white border border-slate-200 text-slate-700 py-2 px-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
            >
              {availableYears && availableYears.length > 0 ? (
                availableYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))
              ) : (
                <option value={2024}>2024</option>
              )}
            </select>
          </div>
        </div>
      </div>

      {/* Mobile yearly average - only visible on smaller screens */}
      <div className="block lg:hidden bg-slate-50 rounded-lg p-4 mb-6">
        <div className="text-slate-800 font-semibold text-lg mb-1">
          Yearly Average:{" "}
          <span style={{ color: getScoreColor(yearlyAverage) }}>
            {yearlyAverage}
          </span>
        </div>
        {previousYearAverage !== null && (
          <div className="flex items-center">
            {getTrendIcon(trendDirection)}
          </div>
        )}
      </div>
    </>
  );
};

export default YearlySummary;
