import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../utils/apiConfig";

const ENPS = ({ businessName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [emails, setEmails] = useState("");
  const [sendToAll, setSendToAll] = useState(false);
  const [questions, setQuestions] = useState([
    { id: 1, text: "" },
    { id: 2, text: "" },
    { id: 3, text: "" },
    { id: 4, text: "" },
    { id: 5, text: "" },
  ]);

  const handleQuestionChange = (id, text) => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, text } : q)));
  };

  const handleEmailChange = (e) => {
    setEmails(e.target.value);
  };

  const handleSendToAllChange = (e) => {
    setSendToAll(e.target.checked);
  };

  const handleSendENPS = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const emailList = sendToAll
      ? []
      : emails
          .split(/[,\s]+/)
          .map((email) => email.trim())
          .filter((email) => email !== "");

    if (
      questions.some((q) => q.text.trim() === "") ||
      (!sendToAll && emailList.length === 0)
    ) {
      setError(
        'Please fill in all questions and provide at least one email address (or select "Send to All Users") before sending the survey.',
      );
      setIsLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `${API_URL}/api/v1/businesses/${businessName}/create-enps-survey`,
        {
          questions: questions.map((q) => q.text),
          emails: emailList,
          send_to_all: sendToAll,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      console.log("eNPS response:", response.data);
      setSuccess(
        `survey created and sent to ${sendToAll ? "all users" : emailList.length} recipient(s)`,
      );
    } catch (err) {
      console.error("Error sending eNPS:", err.response?.data || err.message);
      setError(
        `Failed to send eNPS: ${err.response?.data?.detail || err.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-6">
      <h3 className="text-3xl font-semibold mt-1 mb-4 text-slate-200 text-right font-serif">
        employee net promoter score
      </h3>
      <form onSubmit={handleSendENPS}>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={sendToAll}
              onChange={handleSendToAllChange}
              className="mr-2"
            />
            <span className="text-teal-500 text-xl font-serif tracking-wide">
              send to all users
            </span>
          </label>
        </div>
        {!sendToAll && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300 mb-2">
              email addresses (separated by commas or spaces)
            </label>
            <input
              type="text"
              value={emails}
              onChange={handleEmailChange}
              className="w-full p-2 bg-slate-700 text-white rounded"
              placeholder="Enter email addresses"
            />
          </div>
        )}
        <div className="grid grid-cols-2 gap-4">
          {questions.map((question, index) => (
            <div key={question.id} className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Question {index + 1}
              </label>
              <input
                type="text"
                value={question.text}
                onChange={(e) =>
                  handleQuestionChange(question.id, e.target.value)
                }
                className="w-full p-2 bg-slate-700 text-white rounded"
                placeholder={`Enter question ${index + 1}`}
                required
              />
            </div>
          ))}
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full py-2 px-4 bg-indigo-950 text-slate-200 font-serif tracking-wider rounded hover:bg-teal-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          {isLoading ? "sending..." : "send eNPS survey"}
        </button>
      </form>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {success && (
        <p className="text-slate-200 mt-6 font-semibold">{success}</p>
      )}
    </div>
  );
};

export default ENPS;
