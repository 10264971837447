import React, { useState, useEffect } from "react";
import Header from "../../components/LandingPage/Header.jsx";
const Login = React.lazy(() => import("../../components/Login.jsx"));
const HeroSection = React.lazy(() => import("./HeroSection.jsx"));
const AnimatedBackground = React.lazy(() => import("./AnimatedBackground.jsx"));
const AboutSection = React.lazy(() => import("./AboutSection.jsx"));
const ContactSection = React.lazy(() => import("./ContactSection.jsx"));
const Footer = React.lazy(() => import("./Footer.jsx"));

const LandingPage = ({ onLoginSuccess }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="min-h-screen overflow-hidden bg-neutral-100 text-neutral-100">
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center min-h-screen">
            <div className="text-neutral-400">Loading...</div>
          </div>
        }
      >
        <AnimatedBackground mousePosition={mousePosition} />
        <Header
          setShowLoginForm={setShowLoginForm}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
        <main className="pt-16 md:pt-20">
          <HeroSection scrollPosition={scrollPosition} />
          <div id="about">
            <AboutSection />
          </div>
          <div id="contact">
            <ContactSection />
          </div>
          <Footer />
        </main>
        {/* Floating cursor effect - hidden on mobile */}
        <div
          className="fixed w-4 h-4 border-2 border-emerald-400 rounded-full pointer-events-none mix-blend-difference z-50 hidden md:block"
          style={{
            left: mousePosition.x - 8,
            top: mousePosition.y - 8,
            transition: "all 0.15s ease",
            boxShadow: "0 0 20px rgba(52, 211, 153, 0.3)",
          }}
        />
        {/* Login Modal */}
        {showLoginForm && (
          <Login
            onLoginSuccess={onLoginSuccess}
            onClose={() => setShowLoginForm(false)}
          />
        )}
      </React.Suspense>
    </div>
  );
};

export default LandingPage;
