import React, { useState, useEffect } from "react";
import axios from "axios";
import SentimentAnalysis from "./analytics/SentimentAnalysis";
import ScoreGrid from "./analytics/ScoreGrid";
import ReviewStats from "./analytics/ReviewStats";
import QuestionStats from "./analytics/QuestionStats";
import ENPSStats from "./ENPS/ENPSStats";
import ENPSQuarterlyStats from "./ENPS/ENPSQuarterlyStats";
import WeeklyVibeAverages from "./analytics/WeeklyVibeAverages";
import QuarterlyVibeAverages from "./analytics/QuarterlyVibeAverages";

import { Activity, Users, Star, MessageCircle } from "lucide-react";
import RetroAnalyticsWelcome from "./analytics/RetroAnalyticsWelcome";
import AnalyticsWelcome from "./analytics/AnalyticsWelcome";

const StatCard = ({ title, value, icon: Icon, loading }) => (
  <div className="bg-neutral-50 p-6 border-l border-zinc-200 shadow-lg">
    <div className="flex items-center justify-between mb-2 font-grotesk">
      <h3 className="text-sm font-light tracking-wide text-zinc-700">
        {title}
      </h3>
      <Icon className="w-5 h-5 text-zinc-500" />
    </div>
    {loading ? (
      <div className="h-7 w-1/2 bg-neutral-50 animate-pulse rounded" />
    ) : (
      <div className="text-2xl font-bold text-zinc-800">{value}</div>
    )}
  </div>
);

const Analytics = ({ businessName }) => {
  // Analytics states
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [welcomeShown, setWelcomeShown] = useState(false);

  // Average Score states
  const [averageScore, setAverageScore] = useState(null);
  const [lastCalculated, setLastCalculated] = useState(null);
  const [averageScoreError, setAverageScoreError] = useState(null);

  // Review stats states
  const [reviewStats, setReviewStats] = useState(null);
  const [reviewStatsLoading, setReviewStatsLoading] = useState(true);
  const [reviewStatsError, setReviewStatsError] = useState(null);

  // Question stats states
  const [questionStats, setQuestionStats] = useState(null);
  const [questionStatsLoading, setQuestionStatsLoading] = useState(true);
  const [questionStatsError, setQuestionStatsError] = useState(null);

  // Quarter selection state (lifted up from ReviewStats)
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    fetchUser();
    fetchAnalytics();
    fetchAverageScore();
    fetchReviewStats();
    fetchQuestionStats();
  }, [businessName]);

  // Set minimum time for welcome screen
  useEffect(() => {
    // Set a timer for minimum welcome screen display
    const welcomeTimer = setTimeout(() => {
      setWelcomeShown(true);
    }, 4000); // 2 seconds minimum display time

    return () => clearTimeout(welcomeTimer);
  }, []);

  // Set initial quarter and year
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;
    setSelectedYear(currentYear);
    setSelectedQuarter(currentQuarter);
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`/api/v1/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setUser(response.data);
    } catch (err) {
      console.error("Error fetching user:", err);
    }
  };

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/analytics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAnalytics(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching analytics:", err);
      setError("Failed to fetch analytics. Please try again.");
      setLoading(false);
    }
  };

  const fetchAverageScore = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/average-vc-score`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAverageScore(response.data.average_score);
      setLastCalculated(response.data.last_calculated);
    } catch (err) {
      console.error("Error fetching average score:", err);
      setAverageScoreError("Failed to fetch average score. Please try again.");
    }
  };

  const fetchReviewStats = async () => {
    setReviewStatsLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/review-stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setReviewStats(response.data);
      setReviewStatsLoading(false);
    } catch (err) {
      console.error("Error fetching review stats:", err);
      setReviewStatsError("Failed to fetch review statistics.");
      setReviewStatsLoading(false);
    }
  };

  const fetchQuestionStats = async () => {
    setQuestionStatsLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/question-stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuestionStats(response.data);
      setQuestionStatsLoading(false);
    } catch (err) {
      console.error("Error fetching question stats:", err);
      setQuestionStatsError("Failed to fetch question statistics.");
      setQuestionStatsLoading(false);
    }
  };

  const getTotalReviews = () => {
    if (!reviewStats?.submissions_data || !selectedQuarter || !selectedYear)
      return "-";

    return reviewStats.submissions_data.reduce((total, review) => {
      if (review.recent_submissions && review.recent_submissions.length > 0) {
        const submissionDate = new Date(
          review.recent_submissions[0].submitted_at,
        );
        const year = submissionDate.getFullYear();
        const quarter = Math.floor(submissionDate.getMonth() / 3) + 1;

        if (year === selectedYear && quarter === selectedQuarter) {
          return total + review.submissions_this_week;
        }
      }
      return total;
    }, 0);
  };

  if (loading || !welcomeShown)
    return (
      <div className="bg-neutral-50 flex items-center justify-center py-12">
        <AnalyticsWelcome />
      </div>
    );
  if (error)
    return (
      <div className="mb-6 p-4 bg-rose-50 border-l border-rose-500 text-rose-600 text-center">
        {error}
      </div>
    );

  return (
    <div className="bg-gray-200 text-zinc-800 border border-neutral-200 mt-20 rounded-lg">
      <div className="max-w-6xl mx-auto px-6 py-7">
        <h1 className="text-xl font-grotesk font-light tracking-wide text-zinc-700 mb-1 text-left ml-10 flex flex-row mt-4">
          Analytics Dashboard{" "}
          <p className="text-amber-600 text-sm ml-1"> (beta)</p>
        </h1>
        {/* Stat Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-4"></div>
        {/* Analysis Sections */}
        <div className="space-y-6 mt-10">
          <h2 className="text-xl font-grotesk font-light tracking-wide text-zinc-700 mb-2 text-center"></h2>
          <QuestionStats
            questionStats={questionStats}
            questionStatsLoading={questionStatsLoading}
            questionStatsError={questionStatsError}
            businessName={businessName}
          />

          {/*
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
          <StatCard
            title="Average Score"
            value={averageScore?.toFixed(2) || "-"}
            icon={Activity}
            loading={loading}
          />
          <StatCard
            title="Total Reviews"
            value={getTotalReviews()}
            icon={MessageCircle}
            loading={reviewStatsLoading}
          />
          <StatCard
            title="Active Users"
            value={analytics?.active_users || "-"}
            icon={Users}
            loading={loading}
          />
          <StatCard
            title="Satisfaction Rate"
            value={
              analytics?.satisfaction_rate
                ? `${analytics.satisfaction_rate}%`
                : "-"
            }
            icon={Star}
            loading={loading}
          />
        </div>
        */}

          <div className="bg-gray-200 p-1 border-zinc-50 mt-6">
            <WeeklyVibeAverages businessName={businessName} />
          </div>

          <div className="bg-gray-50 p-6 border-l border-zinc-50 shadow-lg mt-5">
            <QuarterlyVibeAverages businessName={businessName} />
          </div>

          {/* Charts Section */}
          <div className="grid grid-cols-2 lg:grid-cols-1 gap-6 mb-6 mt-4">
            <div className="p-1">
              <ENPSStats businessName={businessName} />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 mb-6 mt-4">
            <ENPSQuarterlyStats businessName={businessName} />
          </div>

          {/*
<div className="bg-white p-6 border-l border-zinc-200 shadow-2xl">
            <ReviewStats
              reviewStats={reviewStats}
              reviewStatsLoading={reviewStatsLoading}
              reviewStatsError={reviewStatsError}
              selectedQuarter={selectedQuarter}
              setSelectedQuarter={setSelectedQuarter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </div>
<div className="bg-white p-5 border-l border-zinc-200 shadow-xl">
            <h2 className="text-xl font-grotesk font-light tracking-wide text-zinc-700 mb-3 p-2 text-center">
              Score Distribution
            </h2>
        
          </div>
          */}
        </div>
        {/* Sentiment Analysis Section for Admin */}
        {user?.is_admin && (
          <div className="bg-white p-6 border-l border-zinc-200 shadow-2xl mt-4">
            {/*
 <h2 className="text-xl font-grotesk font-light tracking-wide text-zinc-700 mb-4">
              Sentiment Analysis Coming Soon
            </h2>

            */}
            {/*
   <SentimentAnalysis businessName={businessName} />

            */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
