import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import CurrentQuestions from "../VibeCheck/CurrentQuestions";
import QuarterlyNominations from "../QuarterlyNominations/QuarterlyNominations";
import axios from "axios";

const DashboardContent = ({
  user,
  latestSurveyResponse,
  averageScore,
  lastCalculated,
  averageScoreError,
  getDisplayName,
}) => {
  const [showQuarterlyNominations, setShowQuarterlyNominations] =
    useState(true); // Default to true to ensure visibility
  const [hasSubmittedNomination, setHasSubmittedNomination] = useState(false);
  const [checkingNominationStatus, setCheckingNominationStatus] =
    useState(true);
  const [debugInfo, setDebugInfo] = useState(null); // Debug information for logging

  useEffect(() => {
    // Check if quarterly nominations should be shown
    const checkQuarterlyStatus = async () => {
      try {
        setCheckingNominationStatus(true);

        // 1. Get current date and determine quarter
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth(); // 0-11
        const quarter = Math.floor(month / 3) + 1; // 1-4
        const quarterKey = `${year}-${quarter}`;

        // Debug info to help troubleshoot
        const debugData = {
          currentDate: now.toISOString(),
          year,
          month,
          calculatedQuarter: quarter,
          quarterKey,
          username: user.username,
        };

        console.log("Quarterly Nomination Debug:", debugData);
        setDebugInfo(debugData);

        // 2. Check if user has already submitted a nomination for this quarter
        try {
          const nominationStatus = await axios.get(
            `/api/businesses/${user.business_name}/quarterly-nominations/status`,
          );

          setHasSubmittedNomination(nominationStatus.data.has_submitted);
          console.log("Nomination status:", nominationStatus.data);

          // If user hasn't submitted, always show the form
          if (!nominationStatus.data.has_submitted) {
            setShowQuarterlyNominations(true);
            return;
          }
        } catch (error) {
          console.error("Error checking nomination status API:", error);
          // If API fails, default to showing the form
          setShowQuarterlyNominations(true);
          return;
        }

        // 3. If we reach here, the user has already submitted - check if we should show anyway
        // (e.g., if they submitted very recently, we might still want to show confirmation)
        const lastShownKey = `quarterly_nomination_shown_${user.username}`;
        const lastSubmittedKey = `quarterly_nomination_submitted_${user.username}`;
        const lastShown = localStorage.getItem(lastShownKey);
        const lastSubmitted = localStorage.getItem(lastSubmittedKey);

        console.log("Last shown:", lastShown, "Last submitted:", lastSubmitted);

        // Logic: if they've submitted but haven't seen the confirmation, show it briefly
        if (
          hasSubmittedNomination &&
          (!lastSubmitted || lastSubmitted !== quarterKey)
        ) {
          setShowQuarterlyNominations(true);
          localStorage.setItem(lastSubmittedKey, quarterKey);

          // Auto-hide after a timeout
          setTimeout(() => {
            setShowQuarterlyNominations(false);
          }, 10000); // Show for 10 seconds
          return;
        }

        // Otherwise, respect the user's preference to hide
        setShowQuarterlyNominations(false);
      } catch (error) {
        console.error("General error in nomination logic:", error);
        // If any error, default to showing the form
        setShowQuarterlyNominations(true);
      } finally {
        setCheckingNominationStatus(false);
      }
    };

    checkQuarterlyStatus();

    // Debug display current quarter for development
    const now = new Date();
    const month = now.getMonth();
    const quarter = Math.floor(month / 3) + 1;
    console.log(
      `Current date: ${now.toLocaleDateString()}, Quarter: ${quarter}`,
    );
  }, [user.username, user.business_name, hasSubmittedNomination]);

  // Handler for when user submits a nomination
  const handleNominationSubmitted = () => {
    setHasSubmittedNomination(true);

    // Update localStorage to remember this quarter is done
    const now = new Date();
    const year = now.getFullYear();
    const quarter = Math.floor(now.getMonth() / 3) + 1;
    const quarterKey = `${year}-${quarter}`;

    localStorage.setItem(
      `quarterly_nomination_submitted_${user.username}`,
      quarterKey,
    );

    // Keep showing for a bit so user can see confirmation
    setTimeout(() => {
      setShowQuarterlyNominations(false);
    }, 5000); // Hide after 5 seconds of showing the success message
  };

  // Handler to dismiss nomination section
  const handleDismissNominations = () => {
    setShowQuarterlyNominations(false);

    // Remember that the user has dismissed this quarter's nomination form
    const now = new Date();
    const year = now.getFullYear();
    const quarter = Math.floor(now.getMonth() / 3) + 1;
    const quarterKey = `${year}-${quarter}`;

    localStorage.setItem(
      `quarterly_nomination_shown_${user.username}`,
      quarterKey,
    );
  };

  return (
    <div className="mt-16 bg-zinc-900 text-zinc-gray pt-2 ml-2 shadow-2xl font-grotesk rounded-lg border-l border-amber-800">
      <h2 className="flex justify-end text-xl tracking-wide text-amber-400 mr-3 mt-2 p-2 mb-3">
        welcome,{" "}
        <span className="text-slate-50 ml-2 font-semibold tracking-wider">
          {user.username}
        </span>
      </h2>
      <div className="grid grid-cols-2 gap-3 mb-2 text-sm">
        {latestSurveyResponse !== null && (
          <p>
            <span className="text-zinc-500">Your Latest Rating:</span>{" "}
            {latestSurveyResponse}/5
          </p>
        )}
        {user.is_admin && (
          <div className="col-span-2 rounded bg-gray-400 p-2 shadow-md text-right mr-5">
            {averageScore !== null ? (
              <>
                <p className="text-lg text-zinc-50 font-thin">
                  {getDisplayName(user.business_name)} vibe-check avg:{" "}
                  {averageScore.toFixed(2)}
                </p>
                {lastCalculated && (
                  <p className="text-xs mt-3 text-gray-600">
                    calculated: {new Date(lastCalculated).toLocaleString()}
                  </p>
                )}
              </>
            ) : (
              <p className="text-rose-600">{averageScoreError}</p>
            )}
          </div>
        )}
      </div>

      {/* Debug Info (remove in production) */}
      {debugInfo && (
        <div
          className="bg-black text-white p-2 text-xs"
          style={{ display: "none" }}
        >
          <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
        </div>
      )}

      {(showQuarterlyNominations || true) && !checkingNominationStatus && (
        <div className="mb-4 relative">
          <button
            onClick={handleDismissNominations}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 z-10"
            aria-label="Dismiss quarterly nominations"
          ></button>
          <div className="p-4">
            <QuarterlyNominations
              businessName={user.business_name}
              user={user}
              onNominationSubmitted={handleNominationSubmitted}
            />
          </div>
        </div>
      )}

      {/* Quarterly Nominations Section - FORCE SHOW for now */}
      <div className="mb-6">
        <CurrentQuestions businessName={user.business_name} />
      </div>
    </div>
  );
};

export default DashboardContent;
