import React, { useState, useEffect } from "react";
import axios from "axios";
import { Users, Shield, Check, UserPlus } from "lucide-react";

const ManageUserPermissions = ({ businessName }) => {
  const [users, setUsers] = useState([]);
  const [selectedViewer, setSelectedViewer] = useState("");
  const [selectedViewees, setSelectedViewees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users-with-ids`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
      setLoading(false);
    }
  };

  const handleViewerChange = (e) => {
    const username = e.target.value;
    setSelectedViewer(username);
    if (username) {
      const selectedUser = users.find((user) => user.username === username);
      setSelectedViewees(selectedUser ? selectedUser.view_questions_ids : []);
    } else {
      setSelectedViewees([]);
    }
  };

  const handleVieweeChange = (mainUserId) => {
    setSelectedViewees((prev) =>
      prev.includes(mainUserId)
        ? prev.filter((id) => id !== mainUserId)
        : [...prev, mainUserId],
    );
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${selectedViewer}/set-viewable-users`,
        { viewable_user_ids: selectedViewees },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      const viewableUsernames = users
        .filter((user) => selectedViewees.includes(user.main_id))
        .map((user) => user.username)
        .join(", ");
      setSuccessMessage(
        `${selectedViewer} can now view information from ${viewableUsernames}`,
      );
      setSelectedViewer("");
      setSelectedViewees([]);
      fetchUsers();
    } catch (err) {
      console.error("Error updating permissions:", err);
      setError("Failed to update permissions. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-900 flex items-center justify-center">
        <div className="animate-pulse text-cyan-400">Loading...</div>
      </div>
    );
  }

  return (
    <div className="bg-slate-900">
      <div className="max-w-6xl mx-auto px-6 py-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-serif font-semibold bg-gradient-to-r from-cyan-400 to-teal-500 bg-clip-text text-transparent">
            Manage Permissions
          </h1>
          <Users className="w-8 h-8 text-indigo-400" />
        </div>

        {/* Main Content Card */}
        <div className="bg-slate-800 rounded-xl p-6 border border-slate-700 shadow-lg">
          {error && (
            <div className="mb-6 p-4 bg-red-900/20 border border-red-800 rounded-lg text-red-400">
              {error}
            </div>
          )}

          {successMessage && (
            <div className="mb-6 p-4 bg-teal-900/20 border border-teal-800 rounded-lg text-teal-400 flex items-center gap-2">
              <Check className="w-5 h-5" />
              {successMessage}
            </div>
          )}

          {/* Viewer Selection */}
          <div className="mb-8">
            <label className="block text-lg font-semibold mb-4 flex items-center gap-2">
              <Shield className="w-5 h-5 text-cyan-400" />
              Select User to Configure
            </label>
            <select
              value={selectedViewer}
              onChange={handleViewerChange}
              className="w-full px-4 py-3 bg-slate-900 rounded-lg border border-slate-700 focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 transition-colors duration-200"
            >
              <option value="">Choose a user</option>
              {users.map((user) => (
                <option key={user.id} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>

          {/* Viewee Selection */}
          {selectedViewer && (
            <div className="mb-8">
              <label className="block text-lg font-semibold mb-4 flex items-center gap-2">
                <UserPlus className="w-5 h-5 text-teal-400" />
                Grant View Access To
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {users.map((user) => (
                  <div
                    key={user.main_id}
                    className="flex items-center p-4 bg-slate-900 rounded-lg border border-slate-700 hover:border-cyan-500 transition-colors duration-200"
                  >
                    <input
                      type="checkbox"
                      id={`user-${user.main_id}`}
                      checked={selectedViewees.includes(user.main_id)}
                      onChange={() => handleVieweeChange(user.main_id)}
                      className="w-4 h-4 rounded border-slate-600 text-cyan-500 focus:ring-cyan-500"
                    />
                    <label
                      htmlFor={`user-${user.main_id}`}
                      className="ml-3 text-gray-300"
                    >
                      {user.username}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Submit Button */}
          {selectedViewer && (
            <button
              onClick={handleSubmit}
              className="px-6 py-3 bg-gradient-to-r from-cyan-500 to-teal-600 hover:from-cyan-600 hover:to-teal-700 text-white rounded-lg font-medium flex items-center gap-2 transition-all duration-200"
            >
              <Shield className="w-4 h-4" />
              Update Permissions
            </button>
          )}
        </div>

        {/* Current Permissions Overview */}
        <div className="mt-8 bg-slate-800 rounded-xl p-6 border border-slate-700">
          <h2 className="text-xl font-semibold mb-6 flex items-center gap-2">
            <Users className="w-5 h-5 text-indigo-400" />
            Current Access Overview
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {users.map((user) => (
              <div
                key={user.main_id}
                className="p-4 bg-slate-900 rounded-lg border border-slate-700"
              >
                <span className="font-semibold text-cyan-400">
                  {user.username}
                </span>
                <div className="mt-2 text-sm text-gray-400">
                  Can view:{" "}
                  {user.view_questions_ids.length > 0
                    ? user.view_questions_ids.join(", ")
                    : "None"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserPermissions;
