import React, { useState, useEffect } from "react";
import axios from "axios";
import { Award, RefreshCw, UserX, Save, Filter } from "lucide-react";
import PastWinnersTable from "../components/AdminQuarterlyNominations/PastWinnersTable";
import CurrentNominationsList from "../components/AdminQuarterlyNominations/CurrentNominations";

const QuarterlyNominationsAdmin = ({ businessName }) => {
  const [nominations, setNominations] = useState([]);
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [winnerLoading, setWinnerLoading] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [previousQuarter, setPreviousQuarter] = useState(null);
  const [previousYear, setPreviousYear] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [excludedUsers, setExcludedUsers] = useState([]);
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [savingExclusions, setSavingExclusions] = useState(false);

  // Helper function to calculate previous quarter
  const getPreviousQuarter = (quarter, year) => {
    if (quarter === 1) {
      return { quarter: 4, year: year - 1 };
    } else {
      return { quarter: quarter - 1, year };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const [nominationsRes, winnersRes, usersRes, excludedUsersRes] =
          await Promise.all([
            axios.get(
              `/api/v1/businesses/${businessName}/quarterly-nominations/current-quarter`,
              { headers },
            ),
            axios.get(
              `/api/v1/businesses/${businessName}/quarterly-nominations/winners`,
              { headers },
            ),
            // Fetch all users in the system
            axios.get(`/api/v1/businesses/${businessName}/all-users`, {
              headers,
            }),
            // Fetch the list of excluded users (this would be a new API endpoint)
            axios
              .get(
                `/api/v1/businesses/${businessName}/quarterly-nominations/excluded-users`,
                { headers },
              )
              .catch(() => ({ data: [] })), // Default to empty array if endpoint doesn't exist yet
          ]);

        setNominations(nominationsRes.data);
        setWinners(winnersRes.data);
        setAllUsers(usersRes.data);
        setExcludedUsers(excludedUsersRes.data || []);

        const now = new Date();
        const year = now.getFullYear();
        const quarter = Math.floor(now.getMonth() / 3) + 1;

        setCurrentYear(year);
        setCurrentQuarter(quarter);

        // Calculate previous quarter (what we're actually collecting nominations for)
        const prev = getPreviousQuarter(quarter, year);
        setPreviousQuarter(prev.quarter);
        setPreviousYear(prev.year);
      } catch (err) {
        setError("Error loading data.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [businessName]);

  const calculateWinner = async () => {
    setWinnerLoading(true);
    const token = localStorage.getItem("access_token");

    try {
      // We're calculating the winner for the PREVIOUS quarter
      const res = await axios.post(
        `/api/v1/businesses/${businessName}/quarterly-nominations/calculate-winner`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { year: previousYear, quarter: previousQuarter },
        },
      );

      setWinners([res.data, ...winners]);
      setSuccessMessage(
        `Winner calculated for Q${previousQuarter} ${previousYear}`,
      );
    } catch (err) {
      setError("Error calculating winner.");
    } finally {
      setWinnerLoading(false);
    }
  };

  const handleSaveExclusions = async () => {
    setSavingExclusions(true);
    const token = localStorage.getItem("access_token");

    try {
      // This would be a new API endpoint to save the excluded users
      await axios.post(
        `/api/v1/businesses/${businessName}/quarterly-nominations/excluded-users`,
        {
          excludedUserIds: excludedUsers.map((user) => user.id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      setSuccessMessage("User exclusion list saved successfully!");
      setShowExcludeModal(false);
    } catch (err) {
      setError("Error saving excluded users list.");
      console.error("Error saving exclusions:", err);
    } finally {
      setSavingExclusions(false);
    }
  };

  const toggleUserExclusion = (user) => {
    if (excludedUsers.find((u) => u.id === user.id)) {
      // Remove from excluded list
      setExcludedUsers(excludedUsers.filter((u) => u.id !== user.id));
    } else {
      // Add to excluded list
      setExcludedUsers([...excludedUsers, user]);
    }
  };

  if (loading) {
    return (
      <div className="text-center text-white p-8 bg-gradient-to-br froms-slate-700 to-gray-900 rounded-lg shadow-xl">
        Loading...
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-800 to-gray-900 text-white rounded-3xl p-16 shadow-2xl relative overflow-hidden mt-16">
      <div className="absolute inset-0 opacity-20">
        <svg width="100%" height="100%">
          <defs>
            <pattern
              id="pattern"
              width="40"
              height="40"
              patternUnits="userSpaceOnUse"
            >
              <circle cx="20" cy="20" r="2" fill="#6366f1" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#pattern)" />
        </svg>
      </div>

      <h2 className="text-3xl font-grotesk mb-6 flex items-center space-x-2">
        <Award className="text-amber-400" />
        <span>Quarterly Nominations Dashboard</span>
      </h2>

      {error && (
        <div className="bg-red-500/20 text-red-300 p-3 rounded-lg mb-4">
          {error}
        </div>
      )}
      {successMessage && (
        <div className="bg-green-500/20 text-green-300 p-3 rounded-lg mb-4">
          {successMessage}
        </div>
      )}

      <div className="bg-amber-700/20 p-5 rounded-lg mb-6 backdrop-blur-md">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-xl font-light font-grotesk">
              Current Quarter: Q{currentQuarter} {currentYear}
            </h3>
            <p className="text-lg opacity-90">
              Collecting nominations for: Q{previousQuarter} {previousYear}
            </p>
            <p className="opacity-80">
              Total Nominations: {nominations.length}
            </p>
            <p className="text-amber-200 mt-2">
              <span className="opacity-70">
                Users excluded from nominations:{" "}
              </span>
              {excludedUsers.length}
            </p>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={() => setShowExcludeModal(true)}
              className="bg-gray-600 hover:bg-gray-500 transition px-4 py-2 rounded-xl flex items-center"
            >
              <UserX className="mr-2" size={18} />
              Manage Excluded Users
            </button>
            <button
              onClick={calculateWinner}
              disabled={winnerLoading || nominations.length === 0}
              className="bg-amber-700 hover:bg-indigo-600 disabled:bg-gray-500 transition px-5 py-3 rounded-xl flex items-center"
            >
              {winnerLoading ? (
                <RefreshCw className="animate-spin mr-2" />
              ) : (
                <Award className="mr-2" />
              )}
              {winnerLoading
                ? "Calculating..."
                : `Calculate Q${previousQuarter} Winner`}
            </button>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="bg-gray-800/50 rounded-xl p-4 backdrop-blur-lg">
          <CurrentNominationsList
            nominations={nominations}
            quarter={previousQuarter}
            year={previousYear}
          />
        </div>

        <div className="bg-gray-800/50 rounded-xl p-4 backdrop-blur-lg">
          <PastWinnersTable winners={winners} />
        </div>
      </div>

      {/* Modal for managing excluded users */}
      {showExcludeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-auto p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-medium flex items-center">
                <UserX className="mr-2 text-red-400" />
                Manage Excluded Users
              </h3>
              <button
                onClick={() => setShowExcludeModal(false)}
                className="text-gray-400 hover:text-white"
              >
                ×
              </button>
            </div>

            <p className="text-gray-300 mb-4">
              Select users to exclude from the nomination selection form. These
              users will not be available for selection when employees submit
              nominations.
            </p>

            <div className="mb-4">
              <div className="bg-gray-700/50 p-2 rounded-md">
                <div className="flex justify-between border-b border-gray-600 pb-2 mb-2 font-medium text-gray-300">
                  <span className="w-12 text-center">#</span>
                  <span className="flex-1">Username</span>
                  <span className="w-24 text-center">Exclude</span>
                </div>

                {allUsers.map((user, index) => (
                  <div
                    key={user.id}
                    className="flex justify-between items-center py-2 border-b border-gray-700/40"
                  >
                    <span className="w-12 text-center text-gray-400">
                      {index + 1}
                    </span>
                    <span className="flex-1 truncate">{user.username}</span>
                    <span className="w-24 text-center">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={
                            !!excludedUsers.find((u) => u.id === user.id)
                          }
                          onChange={() => toggleUserExclusion(user)}
                        />
                        <div className="relative w-11 h-6 bg-gray-700 rounded-full peer peer-checked:bg-indigo-600 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full"></div>
                      </label>
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowExcludeModal(false)}
                className="px-4 py-2 rounded-md bg-gray-700 hover:bg-gray-600 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveExclusions}
                className="px-4 py-2 rounded-md bg-indigo-600 hover:bg-indigo-500 flex items-center transition"
                disabled={savingExclusions}
              >
                {savingExclusions ? (
                  <RefreshCw className="animate-spin mr-2" size={16} />
                ) : (
                  <Save className="mr-2" size={16} />
                )}
                {savingExclusions ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuarterlyNominationsAdmin;
