import React from "react";
import { Users, User } from "lucide-react";

const UserList = ({ meetings, selectedUsername, onUserSelect }) => {
  return (
    <div className="bg-slate-900/20 border border-[#1F1F23] rounded-lg">
      <div className="p-4 border-b border-[#1F1F23]">
        <div className="flex items-center space-x-3">
          <div className="h-[2px] w-8 bg-gradient-to-r from-yellow-500/40 to-transparent" />
          <span className="text-slate-300 text-xs uppercase tracking-wider">
            Team Members
          </span>
        </div>
      </div>

      <div className="p-4">
        <div className="space-y-1.5">
          {Object.keys(meetings).length === 0 ? (
            <div className="py-8 flex flex-col items-center justify-center">
              <Users
                className="w-8 h-8 text-yellow-400 mb-3"
                strokeWidth={1.5}
              />
              <p className="text-slate-200 text-xs">No users available</p>
            </div>
          ) : (
            Object.keys(meetings).map((username) => (
              <button
                key={username}
                onClick={() => onUserSelect(username)}
                className={`group w-full px-3 py-2 rounded-lg text-left transition-all duration-200 flex items-center space-x-3
                  ${
                    selectedUsername === username
                      ? "bg-yellow-500/10 border border-slate-500/20"
                      : "border border-transparent hover:border-[#1F1F23] hover:bg-[#0A0A0B]"
                  }`}
              >
                <User
                  className={`w-4 h-4 ${
                    selectedUsername === username
                      ? "text-yellow-200"
                      : "text-slate-200 group-hover:text-[#9A9A9D]"
                  }`}
                  strokeWidth={1.5}
                />
                <span
                  className={`text-sm tracking-wide ${
                    selectedUsername === username
                      ? "text-yellow-200"
                      : "text-slate-200"
                  }`}
                >
                  {username}
                </span>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default UserList;
