import React, { useState, useEffect } from "react";
import { AudioLines } from "lucide-react";
import axios from "axios";

const QuestionSubmissionsList = ({ submissions, title, columns = 1 }) => (
  <div>
    <h5 className="text-xs font-medium text-slate-600 mb-3 font-grotesk uppercase tracking-wider">
      {title}
    </h5>
    <div className={`grid grid-cols-${columns} gap-3`}>
      {submissions.map((submission, idx) => (
        <div
          key={idx}
          className="p-4 bg-slate-50 border-l-2 border-slate-300 shadow-sm rounded-lg hover:bg-white hover:shadow-md transition-all duration-200 backdrop-blur-sm"
        >
          <div className="flex justify-between items-center">
            <span className="text-xs font-medium text-slate-800">
              {submission.username}
            </span>
            {submission.submitted_at && (
              <span className="text-xs text-slate-500 font-mono">
                {new Date(submission.submitted_at).toLocaleDateString()}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

const StatCard = ({ title, value }) => (
  <div className="p-4 bg-slate-50 border-l-2 border-slate-300 shadow-sm rounded-lg hover:border-slate-400 transition-all duration-200 backdrop-blur-sm">
    <p className="text-xs font-medium text-slate-600 uppercase tracking-wider font-grotesk">
      {title}
    </p>
    <p className="text-xl mt-2 font-semibold text-slate-800 font-grotesk">
      {value}
    </p>
  </div>
);

const QuestionStats = ({
  questionStats,
  questionStatsLoading,
  questionStatsError,
  businessName,
}) => {
  const [showPending, setShowPending] = useState(false);
  const [currentWeekAverage, setCurrentWeekAverage] = useState(null);
  const [averageLoading, setAverageLoading] = useState(true);
  const [averageError, setAverageError] = useState(null);
  const [perfectVibes, setPerfectVibes] = useState(0);
  const [badVibes, setBadVibes] = useState(0);
  const [statsLoading, setStatsLoading] = useState(true);

  useEffect(() => {
    // Clear states when component mounts or businessName changes
    setAverageLoading(true);
    setAverageError(null);
    setCurrentWeekAverage(null);
    setStatsLoading(true);

    const fetchWeeklyAverage = async () => {
      // Make sure we have the business name
      if (!businessName) {
        console.error("Missing businessName prop");
        setAverageError("Missing business name");
        setAverageLoading(false);
        return;
      }

      try {
        // Get auth token
        const token = localStorage.getItem("access_token");
        if (!token) {
          throw new Error("No access token found");
        }

        console.log(
          `Making API call to /api/v1/businesses/${businessName}/weekly-vc-scores`,
        );

        // Fetch all weekly averages without limit to ensure we get the most recent one
        const response = await axios.get(
          `/api/v1/businesses/${businessName}/weekly-vc-scores`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        console.log("API response:", response.data);

        // Check if we have data in the response
        if (response.data?.weekly_averages?.length > 0) {
          // Filter to find the entry with ID 47 (based on your screenshot)
          const weeklyData = response.data.weekly_averages;

          // Find the most recent week by comparing week_start dates
          let mostRecentWeek = weeklyData[0];

          // Log all the weekly data to debug
          weeklyData.forEach((week, index) => {
            console.log(
              `Week ${index}: ID=${week.id || "unknown"}, Start=${week.week_start}, Average=${week.average_score}`,
            );
          });

          // If we have access to the ID, filter by it
          const currentWeekData = weeklyData.find((week) => week.id === 47);
          if (currentWeekData) {
            console.log(
              `Found data with ID 47: ${currentWeekData.average_score}`,
            );
            setCurrentWeekAverage(currentWeekData.average_score);
          } else {
            // If we can't find by ID, take the most recent by date
            for (let i = 1; i < weeklyData.length; i++) {
              const currentDate = new Date(weeklyData[i].week_start);
              const mostRecentDate = new Date(mostRecentWeek.week_start);
              if (currentDate > mostRecentDate) {
                mostRecentWeek = weeklyData[i];
              }
            }

            console.log(
              `Using most recent week by date: ${mostRecentWeek.week_start}, Average: ${mostRecentWeek.average_score}`,
            );
            setCurrentWeekAverage(mostRecentWeek.average_score);
          }
        } else if (
          response.data?.debug_info?.direct_query?.average !== undefined
        ) {
          // If no weekly averages, try to use the direct query average from debug info
          const directAvg = response.data.debug_info.direct_query.average;
          console.log(`Using fallback direct query average: ${directAvg}`);
          setCurrentWeekAverage(directAvg);
        } else {
          // No average score found
          console.warn("No average score data found in response");
          setCurrentWeekAverage(null);
        }

        // Complete the loading process
        setAverageLoading(false);
      } catch (error) {
        console.error("Error fetching weekly average:", error);
        setAverageError(error.message || "Failed to fetch average");
        setAverageLoading(false);
        setCurrentWeekAverage(null);
      }
    };

    // Fetch perfect and bad vibes data
    const fetchVibesStats = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          throw new Error("No access token found");
        }

        // Fetch analytics data that contains perfect_scores and bad_scores
        const response = await axios.get(
          `/api/v1/businesses/${businessName}/analytics`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        console.log("Analytics response:", response.data);

        // Set perfect and bad vibes from analytics response
        if (response.data) {
          setPerfectVibes(response.data.perfect_scores || 0);
          setBadVibes(response.data.bad_scores || 0);
        }

        setStatsLoading(false);
      } catch (error) {
        console.error("Error fetching vibes stats:", error);
        setStatsLoading(false);
      }
    };

    // Only fetch if we have question stats
    if (questionStats) {
      fetchWeeklyAverage();
      fetchVibesStats();
    }
  }, [businessName, questionStats]);

  // Format the average score for display
  const formatAverageScore = (score) => {
    if (score === null || score === undefined || isNaN(score)) {
      return "N/A";
    }
    return parseFloat(score).toFixed(2);
  };

  if (questionStatsLoading) {
    return (
      <div className="bg-slate-50 p-6 border-l-2 border-slate-300 shadow-lg rounded-lg">
        <div className="h-7 bg-slate-200 rounded-md w-3/4 mb-8"></div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="h-24 bg-slate-200 rounded-md"></div>
          ))}
        </div>
      </div>
    );
  }

  if (questionStatsError) {
    return (
      <div className="bg-slate-50 border-l-2 border-slate-400 p-6 rounded-lg text-slate-800 shadow-lg">
        <h4 className="text-md mb-4 font-grotesk text-slate-700">
          Error Loading Submissions
        </h4>
        <p className="text-slate-600 bg-slate-100 p-3 rounded-md">
          {questionStatsError}
        </p>
      </div>
    );
  }

  if (!questionStats) return null;

  return (
    <div className="bg-gray-50/80 backdrop-blur-md rounded-xl shadow-sm border border-slate-200 p-2">
      <div className="flex items-center justify-between mb-8 bg-gray-700 p-2 rounded">
        <div className="flex items-center">
          <AudioLines className="w-5 h-5 mr-2 text-amber-500" />
          <h3 className="text-lg font-grotesk text-gray-100 tracking-tight">
            Weekly Vibes
          </h3>
        </div>
      </div>

      <div className="space-y-8">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
          <StatCard
            title="Weekly Average"
            value={
              averageLoading ? "..." : formatAverageScore(currentWeekAverage)
            }
          />
          <StatCard
            title="Active Users"
            value={questionStats.total_active_users - 6}
          />
          <StatCard
            title="This Week"
            value={questionStats.submissions_this_week}
          />
          <StatCard
            title="Submission Rate"
            value={`${questionStats.submission_rate}%`}
          />
          <StatCard
            title="Perfect Vibes"
            value={statsLoading ? "..." : perfectVibes}
          />
          <StatCard title="Bad Vibes" value={statsLoading ? "..." : badVibes} />
        </div>

        <div className="text-xs text-slate-500 px-1 font-mono">
          Week: {new Date(questionStats.week_range.start).toLocaleDateString()}{" "}
          - {new Date(questionStats.week_range.end).toLocaleDateString()}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className={showPending ? "col-span-1" : "md:col-span-2"}>
            <QuestionSubmissionsList
              title="Recent Submissions"
              submissions={questionStats.recent_submissions || []}
              columns={showPending ? 1 : 2}
            />
          </div>

          {showPending ? (
            <div>
              <div className="flex justify-between items-center mb-3">
                <h5 className="text-xs font-medium text-slate-600 uppercase tracking-wider font-grotesk">
                  Pending ({questionStats.pending_submissions?.length - 6 || 0})
                </h5>
                <button
                  onClick={() => setShowPending(false)}
                  className="text-xs text-slate-500 hover:text-slate-800 transition-colors duration-200 font-medium"
                >
                  Hide
                </button>
              </div>
              <div className="space-y-3">
                {questionStats.pending_submissions?.map((submission, idx) => (
                  <div
                    key={idx}
                    className="p-4 bg-slate-50 border-l-2 border-slate-300 shadow-sm rounded-lg"
                  >
                    <span className="text-xs font-medium text-slate-800">
                      {submission.username}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <button
              onClick={() => setShowPending(true)}
              className="text-xs font-medium text-slate-500 hover:text-slate-800 transition-colors duration-200 ml-auto"
            >
              Show Pending ({questionStats.pending_submissions?.length - 6 || 0}
              )
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionStats;
