import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import QuestionItem from "./QuestionItem";
import QuestionSection from "./QuestionSection";

const QuestionOrder = ({
  businessName,
  orderId,
  onDeleteQuestion,
  bankQuestions = [],
  onBankQuestionUse = () => {},
}) => {
  const [questions, setQuestions] = useState([]);
  const [questionOrder, setQuestionOrder] = useState({
    static_q_1: null,
    static_q_2: null,
    q_3: null,
    q_4: null,
    remaining_questions: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const [questionsResponse, orderResponse] = await Promise.all([
        axios.get(`/api/v1/businesses/${businessName}/questions`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(
          `/api/v1/businesses/${businessName}/question-order/${orderId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
      ]);
      setQuestions(questionsResponse.data || []);
      setQuestionOrder(
        orderResponse.data || {
          static_q_1: null,
          static_q_2: null,
          q_3: null,
          q_4: null,
          remaining_questions: [],
        },
      );
      setError(null);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Failed to fetch questions. Please try again.");
      setQuestions([]);
    } finally {
      setLoading(false);
    }
  }, [businessName, orderId]);

  useEffect(() => {
    fetchQuestions();

    const handleQuestionsUpdated = () => {
      fetchQuestions();
    };

    window.addEventListener("questionsUpdated", handleQuestionsUpdated);
    return () => {
      window.removeEventListener("questionsUpdated", handleQuestionsUpdated);
    };
  }, [fetchQuestions]);

  const handleDrop = useCallback(
    async (questionId, sectionTitle) => {
      // Update our local state first for immediate feedback
      const updatedOrder = { ...questionOrder };

      // Remove the question from any current positions
      Object.keys(updatedOrder).forEach((key) => {
        if (key !== "remaining_questions" && updatedOrder[key] === questionId) {
          updatedOrder[key] = null;
        }
      });

      // Update the target section
      updatedOrder[sectionTitle] = questionId;

      // Update state
      setQuestionOrder(updatedOrder);

      try {
        // Make sure we have valid values for all fields
        const apiPayload = {
          static_q_1: updatedOrder.static_q_1 || null,
          static_q_2: updatedOrder.static_q_2 || null,
          q_3: updatedOrder.q_3 || null,
          q_4: updatedOrder.q_4 || null,
          next_q_5: updatedOrder.next_q_5 || null,
          next_q_6: updatedOrder.next_q_6 || null,
          remaining_questions: Array.isArray(updatedOrder.remaining_questions)
            ? updatedOrder.remaining_questions
            : [],
        };

        // Save to API
        const token = localStorage.getItem("access_token");
        await axios.post(
          `/api/v1/businesses/${businessName}/question-order/${orderId}`,
          apiPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );

        // Refresh data
        await fetchQuestions();
      } catch (error) {
        console.error("Error saving question order:", error);
        if (error.response) {
          console.error("Response:", error.response.data);
        }
        setError("Failed to save question order. Please try again.");
      }
    },
    [questionOrder, businessName, orderId, fetchQuestions],
  );

  const handleSubmit = async () => {
    try {
      const apiPayload = {
        static_q_1: questionOrder.static_q_1 || null,
        static_q_2: questionOrder.static_q_2 || null,
        q_3: questionOrder.q_3 || null,
        q_4: questionOrder.q_4 || null,
        next_q_5: questionOrder.next_q_5 || null,
        next_q_6: questionOrder.next_q_6 || null,
        remaining_questions: Array.isArray(questionOrder.remaining_questions)
          ? questionOrder.remaining_questions
          : [],
      };

      const token = localStorage.getItem("access_token");
      await axios.post(
        `/api/v1/businesses/${businessName}/question-order/${orderId}`,
        apiPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      alert(`Question order saved successfully!`);
      await fetchQuestions();
    } catch (error) {
      console.error("Error saving question order:", error);
      if (error.response) {
        console.error("Response:", error.response.data);
      }
      setError("Failed to save question order. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-40">
        <div className="w-8 h-8 border-t-2 border-l-2 border-zinc-400 animate-spin rounded-full"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-rose-400 p-4 border border-rose-600 bg-rose-950/20 rounded-md">
        {error}
      </div>
    );
  }

  const assignedQuestionIds = Object.keys(questionOrder)
    .filter((key) => key !== "remaining_questions")
    .map((key) => questionOrder[key])
    .filter((id) => id !== null && id !== undefined);

  return (
    <div className="bg-gray-100 rounded-md shadow-2xl border-l border-gray-50 transition-all duration-300 hover:border-zinc-400 overflow-hidden relative">
      <div className="relative p-6">
        <h3 className="flex items-center text-lg font-light font-grotesk mb-6 text-zinc-700">
          <span className="mr-2 font-grotesk tracking-wider">
            {orderId === 1 ? "First Question Set" : "Second Question Set"}
          </span>
          <div className="h-px flex-grow bg-zinc-200 ml-4"></div>
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <QuestionSection
            title="static_q_1"
            questionId={questionOrder.static_q_1}
            questions={questions}
            onDrop={handleDrop}
            bankQuestions={bankQuestions}
            onBankQuestionUse={onBankQuestionUse}
          />
          <QuestionSection
            title="static_q_2"
            questionId={questionOrder.static_q_2}
            questions={questions}
            onDrop={handleDrop}
            bankQuestions={bankQuestions}
            onBankQuestionUse={onBankQuestionUse}
          />
          <QuestionSection
            title="q_3"
            questionId={questionOrder.q_3}
            questions={questions}
            onDrop={handleDrop}
            bankQuestions={bankQuestions}
            onBankQuestionUse={onBankQuestionUse}
          />
          <QuestionSection
            title="q_4"
            questionId={questionOrder.q_4}
            questions={questions}
            onDrop={handleDrop}
            bankQuestions={bankQuestions}
            onBankQuestionUse={onBankQuestionUse}
          />
        </div>

        <div className="bg-gray-100 p-5 rounded-md border border-zinc-100 shadow-md mb-6">
          <h4 className="text-zinc-700 font-medium mb-4">
            Available Questions
          </h4>
          {questions.filter(
            (question) => !assignedQuestionIds.includes(question.id),
          ).length === 0 ? (
            <div className="text-center p-4 bg-gray-50 rounded-md text-zinc-500">
              No remaining questions available
            </div>
          ) : (
            questions
              .filter((question) => !assignedQuestionIds.includes(question.id))
              .map((question) => (
                <QuestionItem
                  key={question.id}
                  id={question.id}
                  text={question.question_text}
                  isDraggable={true}
                  onDeleteQuestion={onDeleteQuestion}
                />
              ))
          )}
        </div>

        <button
          onClick={handleSubmit}
          className="w-full py-3 px-4 font-grotesk bg-gray-800 border border-zinc-200 text-zinc-100 font-medium rounded-md hover:bg-zinc-100 transition-colors focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
        >
          Save Question Order
        </button>
      </div>
    </div>
  );
};

export default QuestionOrder;
