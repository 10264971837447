import React from "react";

const NominationDisplay = ({ nomination, quarter, year }) => {
  const getQuarterDateRange = (quarter, year) => {
    switch (quarter) {
      case 1:
        return `January 1 - March 31, ${year}`;
      case 2:
        return `April 1 - June 30, ${year}`;
      case 3:
        return `July 1 - September 30, ${year}`;
      case 4:
        return `October 1 - December 31, ${year}`;
      default:
        return "Current quarter";
    }
  };

  return (
    <div className="max-w-6xl mx-auto my-1 shadow-2xl">
      <div className="bg-zinc-900 border-l-2 border-amber-500 rounded overflow-hidden relative group transition-all duration-500 hover:border-l-2">
        {/* Decorative elements - minimized but still present */}
        <div className="absolute -top-10 -right-10 w-20 h-20 bg-amber-500/5 rounded-full blur-xl"></div>
        <div className="absolute -bottom-8 -left-8 w-16 h-16 bg-amber-600/5 rounded-full blur-lg"></div>

        <div className="bg-zinc-700 p-3 relative z-10 border-b border-gray-300">
          <div className="flex items-center">
            <span className="mr-2 text-xs bg-gray-800 px-2 py-0.5 rounded text-amber-500 font-light">
              Q{quarter}
            </span>
            <h3 className="text-sm uppercase tracking-wider text-gray-300">
              {year} <span className="text-amber-500">Nomination</span>
            </h3>
            <div className="text-xs text-gray-500 ml-auto font-light">
              {getQuarterDateRange(quarter, year)}
            </div>
          </div>
        </div>

        <div className="p-4 relative z-10">
          <div className="mb-3">
            <span className="block text-gray-400 text-xs uppercase tracking-wider mb-1">
              You Nominated
            </span>
            <span className="block text-gray-300 text-sm">
              {nomination.nominee_username}
            </span>
          </div>

          <div className="mb-3">
            <span className="block text-gray-400 text-xs uppercase tracking-wider mb-1">
              Your Reason
            </span>
            <p className="p-3 bg-gray-950 border-l border-amber-600 text-gray-400 text-xs">
              {nomination.reason}
            </p>
          </div>

          <p className="text-gray-500 text-xs mt-4 flex items-center">
            <span className="inline-block w-1 h-1 bg-amber-500 rounded-full mr-1.5"></span>
            Nomination submitted for {getQuarterDateRange(quarter, year)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NominationDisplay;
