import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utils/apiConfig";

const ENPSSurvey = () => {
  const { businessName, surveyToken } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/businesses/${businessName}/enps-survey/${surveyToken}`,
        );
        setQuestions(response.data.questions);
        setAlreadyAnswered(response.data.already_answered || false);
        setLoading(false);
      } catch (err) {
        console.error(
          "Error fetching survey:",
          err.response?.data || err.message,
        );
        setError("Failed to load survey questions. Please try again later.");
        setLoading(false);
      }
    };
    fetchSurvey();
  }, [businessName, surveyToken]);

  const handleInputChange = (questionIndex, value) => {
    setAnswers((prev) => ({ ...prev, [`q${questionIndex + 1}`]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${API_URL}/api/v1/businesses/${businessName}/enps-survey/${surveyToken}/submit`,
        { answers },
      );
      setSubmitted(true);
    } catch (err) {
      setError("Failed to submit survey. Please try again.");
    }
  };

  const renderContent = () => {
    if (loading) return <p className="text-white">Loading survey...</p>;
    if (error) return <p className="text-red-500">{error}</p>;
    if (submitted)
      return (
        <p className="text-teal-500 font-semibold">
          Thank you for completing the survey!
        </p>
      );
    if (alreadyAnswered)
      return (
        <p className="text-indigo-300 font-semibold">
          This survey has already been answered. Thank you for your
          participation!
        </p>
      );

    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        {questions.map((question, index) => (
          <div key={index}>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              {question}
            </label>
            <input
              type="text"
              value={answers[`q${index + 1}`] || ""}
              onChange={(e) => handleInputChange(index, e.target.value)}
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
        ))}
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header className="bg-indigo-950 py-4 px-6 flex justify-between items-center">
        <h1 className="text-4xl font-serif text-slate-200">{businessName}</h1>
        <span className="text-5xl text-teal-500 font-semibold mr-10 font-serif">
          matcha
        </span>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="bg-gray-800 shadow-xl rounded-lg p-6">
            <h2 className="text-2xl font-bold font-serif text-slate-200 mb-6">
              Survey
            </h2>
            {renderContent()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ENPSSurvey;
