import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const CreateReview = () => {
  const { businessName } = useParams();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const [questions, setQuestions] = useState(Array(8).fill(""));
  const [error, setError] = useState(null);
  const [isCustom, setIsCustom] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchTemplates();
  }, [businessName]);

  const fetchTemplates = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/template-reviews`,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setTemplates(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setError("Couldn't fetch templates.");
      setIsLoading(false);
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setReviewText(template.template_name);
    setQuestions(Object.values(template).slice(2, 10));
    setIsCustom(false);
  };

  const handleCustomSelect = () => {
    setSelectedTemplate(null);
    setReviewText("");
    setQuestions(Array(8).fill(""));
    setIsCustom(true);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("access_token");
      const endpoint = isCustom
        ? `/api/v1/businesses/${businessName}/reviews`
        : `/api/v1/businesses/${businessName}/fork-template`;

      const payload = isCustom
        ? {
            reviews_text: reviewText,
            ...questions.reduce(
              (acc, q, i) => ({ ...acc, [`question_${i + 1}`]: q }),
              {},
            ),
          }
        : {
            template_id: selectedTemplate.id,
            reviews_text: reviewText,
            questions: questions.filter(Boolean),
          };

      await axios.post(endpoint, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      navigate(`/businesses/${businessName}/dashboard`, {
        state: { activeSection: "Create Reviews" },
      });
    } catch (err) {
      console.error(err);
      setError("Review creation failed.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center p-6 font-grotesk">
      <div className="w-full max-w-3xl bg-gray-800/40 backdrop-blur-lg shadow-2xl rounded-2xl border border-slate-700/50 overflow-hidden">
        {/* Header with glass effect */}
        <div className="relative bg-gradient-to-r from-slate-900/80 to-slate-800/80 backdrop-blur-md p-6 border-b border-slate-700/50">
          <div className="absolute top-0 left-0 w-full h-full opacity-10 bg-grid-pattern"></div>

          <button
            type="button"
            onClick={() =>
              navigate(`/businesses/${businessName}/dashboard`, {
                state: { activeSection: "Create Reviews" },
              })
            }
            className="mb-12 px-4 py-2 bg-slate-700/70 hover:bg-slate-600/90 text-gray-200 text-sm font-medium rounded-lg 
                       focus:outline-none focus:ring-2 focus:ring-cyan-400/50 transition-all duration-200 backdrop-blur-sm
                       shadow-lg border border-slate-600/30 flex items-center gap-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Back
          </button>

          <h2 className="text-2xl font-semibold tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-emerald-300 to-teal-500 mt-4">
            Design New Review
          </h2>
          <p className="text-slate-400 text-sm mt-1">
            Create a customized review experience for your team
          </p>
        </div>

        <div className="p-8">
          <div className="mb-8">
            <h3 className="text-lg font-medium text-slate-300 mb-4 flex items-center">
              <span className="bg-cyan-500/10 text-cyan-400 rounded-full p-1 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
              </span>
              Select Template
            </h3>

            {isLoading ? (
              <div className="flex justify-center items-center py-6">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-cyan-500"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {templates.map((template) => (
                  <button
                    key={template.id}
                    onClick={() => handleTemplateSelect(template)}
                    className={`px-5 py-3 rounded-xl transition-all duration-200 text-sm
                      focus:outline-none focus:ring-2 focus:ring-cyan-400/50 text-left
                      border ${
                        selectedTemplate?.id === template.id
                          ? "bg-gradient-to-r from-cyan-500/20 to-blue-500/20 border-cyan-500/50 shadow-lg shadow-cyan-500/10"
                          : "bg-slate-800/40 border-slate-700/50 hover:bg-slate-700/40"
                      } group`}
                  >
                    <div className="flex items-center">
                      <div
                        className={`w-3 h-3 rounded-full mr-2 transition-colors ${
                          selectedTemplate?.id === template.id
                            ? "bg-cyan-400"
                            : "bg-slate-600 group-hover:bg-slate-500"
                        }`}
                      ></div>
                      <span
                        className={
                          selectedTemplate?.id === template.id
                            ? "text-cyan-300"
                            : "text-slate-300"
                        }
                      >
                        {template.template_name}
                      </span>
                    </div>
                  </button>
                ))}
                <button
                  onClick={handleCustomSelect}
                  className={`px-5 py-3 rounded-xl transition-all duration-200 text-sm
                    focus:outline-none focus:ring-2 focus:ring-cyan-400/50 text-left
                    border ${
                      isCustom
                        ? "bg-gradient-to-r from-purple-500/20 to-pink-500/20 border-purple-500/50 shadow-lg shadow-purple-500/10"
                        : "bg-slate-800/40 border-slate-700/50 hover:bg-slate-700/40"
                    } group`}
                >
                  <div className="flex items-center">
                    <div
                      className={`w-3 h-3 rounded-full mr-2 transition-colors ${
                        isCustom
                          ? "bg-purple-400"
                          : "bg-slate-600 group-hover:bg-slate-500"
                      }`}
                    ></div>
                    <span
                      className={
                        isCustom ? "text-purple-300" : "text-slate-300"
                      }
                    >
                      ✨ Custom Template
                    </span>
                  </div>
                </button>
              </div>
            )}
          </div>

          {(selectedTemplate || isCustom) && (
            <form onSubmit={handleSubmit} className="space-y-6 mt-8">
              {/* Review Title with floating label */}
              <div className="relative">
                <input
                  type="text"
                  id="reviewTitle"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  className="block w-full px-4 py-3 bg-slate-800/40 rounded-xl text-white border border-slate-700/50
                            focus:outline-none focus:ring-2 focus:ring-cyan-400/50 focus:border-transparent
                            transition-all placeholder-transparent"
                  placeholder="Review title"
                  required
                />
                <label
                  htmlFor="reviewTitle"
                  className={`absolute left-4 transition-all duration-200 pointer-events-none
                            ${reviewText ? "text-xs text-cyan-400 -top-2.5 bg-slate-800 px-1" : "text-slate-500 top-3"}`}
                >
                  Review Title
                </label>
              </div>

              {/* Questions Section */}
              <div className="space-y-4">
                <h4 className="text-sm font-medium text-slate-400 flex items-center">
                  <span className="bg-slate-700/50 rounded-full p-1 mr-2 inline-flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-cyan-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  Questions
                </h4>

                <div className="bg-slate-900/30 rounded-xl p-4 border border-slate-700/30">
                  {questions.map((question, index) => (
                    <div key={index} className="relative mb-4 last:mb-0">
                      <div className="absolute top-3 left-3 flex items-center justify-center w-6 h-6 rounded-full bg-slate-800 text-cyan-400 text-xs font-medium border border-cyan-800/30">
                        {index + 1}
                      </div>
                      <input
                        type="text"
                        value={question}
                        onChange={(e) =>
                          handleQuestionChange(index, e.target.value)
                        }
                        className="w-full pl-12 pr-4 py-3 bg-slate-800/60 rounded-lg text-white placeholder-slate-500
                                focus:outline-none focus:ring-1 focus:ring-cyan-400/30 border border-slate-700/30
                                transition-all duration-200"
                        placeholder={`Enter question ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full py-3 px-4 bg-gradient-to-r from-cyan-500 to-blue-600 hover:from-cyan-600 hover:to-blue-700
                          text-white font-medium rounded-xl shadow-lg shadow-cyan-500/20
                          transition-all duration-200 focus:outline-none focus:ring-2 
                          focus:ring-cyan-400/50 relative overflow-hidden group"
              >
                <span className="relative z-10 flex items-center justify-center">
                  Create Review
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </span>
                <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-20 group-hover:animate-shine" />
              </button>
            </form>
          )}

          {error && (
            <div className="mt-6 p-4 rounded-lg bg-red-900/20 border border-red-700/30 text-red-400 text-sm flex items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{error}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Add this to your CSS or tailwind config
// .animate-shine { animation: shine 1.5s ease-in-out infinite; }
// @keyframes shine { 100% { right: -200%; } }

export default CreateReview;
