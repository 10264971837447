import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
  Area,
} from "recharts";

/**
 * Displays a composed chart with the eNPS score as a line, and
 * the stacked bar for Promoters/Passives/Detractors.
 */
const ENPSTrendChart = ({ quarterlyData, selectedYear, colors }) => {
  console.log("Rendering trend chart with data:", quarterlyData);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Because of how the data is stacked, payload[0] may be eNPS or promoter bar.
      // We can parse them carefully.
      // Or we can rely on the fact that the line (score) is typically first in the config below.
      let scoreObj = payload.find((p) => p.dataKey === "enpsScore");
      let promotersObj = payload.find(
        (p) => p.dataKey === "promotersPercentage",
      );
      let passivesObj = payload.find((p) => p.dataKey === "passivesPercentage");
      let detractorsObj = payload.find(
        (p) => p.dataKey === "detractorsPercentage",
      );

      const score =
        scoreObj && typeof scoreObj.value === "number"
          ? scoreObj.value.toFixed(1)
          : "0.0";

      const promoters =
        promotersObj && typeof promotersObj.value === "number"
          ? promotersObj.value.toFixed(1)
          : "0.0";

      const passives =
        passivesObj && typeof passivesObj.value === "number"
          ? passivesObj.value.toFixed(1)
          : "0.0";

      const detractors =
        detractorsObj && typeof detractorsObj.value === "number"
          ? detractorsObj.value.toFixed(1)
          : "0.0";

      // totalResponses is in the raw data object
      const responses =
        (payload[0] &&
          payload[0].payload &&
          payload[0].payload.totalResponses) ||
        0;

      return (
        <div className="bg-gray-800 bg-opacity-90 p-4 border border-gray-600 rounded-lg">
          <h3 className="font-semibold text-white text-sm mb-2">{label}</h3>
          <div className="flex items-center mb-1">
            <span className="w-3 h-3 bg-blue-400 rounded-full mr-2"></span>
            <span className="text-blue-300 font-medium">
              eNPS Score: {score}
            </span>
          </div>
          <div className="flex items-center mb-1">
            <span className="w-3 h-3 bg-green-400 rounded-full mr-2"></span>
            <span className="text-green-300">Promoters: {promoters}%</span>
          </div>
          <div className="flex items-center mb-1">
            <span className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></span>
            <span className="text-yellow-300">Passives: {passives}%</span>
          </div>
          <div className="flex items-center mb-2">
            <span className="w-3 h-3 bg-red-400 rounded-full mr-2"></span>
            <span className="text-red-300">Detractors: {detractors}%</span>
          </div>
          <div className="text-gray-400 text-xs pt-1 border-t border-gray-600">
            Based on {responses} responses
          </div>
        </div>
      );
    }
    return null;
  };

  // Handle case with no data
  if (!quarterlyData || quarterlyData.length === 0) {
    return (
      <div className="bg-gray-800 rounded-lg border border-gray-700 p-6 shadow-xl">
        <h3 className="text-xl font-bold text-white mb-4">
          eNPS Trend Analysis
        </h3>
        <div className="flex justify-center items-center h-64 bg-gray-900 rounded-lg">
          <p className="text-gray-400">No data available for trend analysis</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800 rounded-lg border border-gray-700 p-6 shadow-xl">
      <h3 className="text-xl font-semibold text-white mb-4">
        eNPS Trend Analysis
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={quarterlyData}
          margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
        >
          <defs>
            <linearGradient id="colorScore" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={colors.enpsScore}
                stopOpacity={0.3}
              />
              <stop offset="95%" stopColor={colors.enpsScore} stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke="rgba(255,255,255,0.1)"
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#9CA3AF", fontSize: 12 }}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            domain={[-100, 100]}
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#9CA3AF", fontSize: 12 }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[0, 100]}
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#9CA3AF", fontSize: 12 }}
            hide
          />

          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            height={60}
            iconType="circle"
            iconSize={10}
            formatter={(value) => (
              <span className="text-sm text-gray-300">{value}</span>
            )}
          />

          <ReferenceLine
            y={0}
            stroke="rgba(156, 163, 175, 0.5)"
            strokeWidth={1}
            yAxisId="left"
          />

          {/* Fill area under the eNPS Score line */}
          <Area
            yAxisId="left"
            type="monotone"
            dataKey="enpsScore"
            stroke="none"
            fill="url(#colorScore)"
            dot={false}
          />

          {/* The eNPS Score line */}
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="enpsScore"
            name="eNPS Score"
            stroke={colors.enpsScore}
            strokeWidth={3}
            dot={{ r: 6, fill: "#fff", strokeWidth: 3 }}
            activeDot={{ r: 8 }}
          />

          {/* Stacked bars for promoters/passives/detractors */}
          <Bar
            yAxisId="right"
            dataKey="promotersPercentage"
            name="Promoters"
            fill={colors.promoters}
            stackId="a"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            yAxisId="right"
            dataKey="passivesPercentage"
            name="Passives"
            fill={colors.passives}
            stackId="a"
            radius={[0, 0, 0, 0]}
          />
          <Bar
            yAxisId="right"
            dataKey="detractorsPercentage"
            name="Detractors"
            fill={colors.detractors}
            stackId="a"
            radius={[0, 0, 4, 4]}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ENPSTrendChart;
